import { useState, useEffect, useCallback } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Autocomplete, Divider, Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { customFormContainer, flexContainer, pageHeaderContainer } from "layouts/common/styles";
import MatchesService from "services/MatchService";

import FormField from "../../FormField";
import { EventTabs, PollType, UserRole } from "../../../../common/constants";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .MuiOutlinedInput-root .MuiAutocomplete-input`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
    padding: 0,
    width: "35px",
  },
  [`& .MuiOutlinedInput-root`]: {
    padding: "0px",
    paddingLeft: "10px",
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

function SelectPollCategory({ typeForm, nextHandler }) {
  const initialState = {
    title: "",
    description: "",
    type: "",
    match: null,
    team: null,
  };
  const [data, setData] = useState(initialState);
  const [matches, setMatches] = useState([]);

  const adminProfile = useSelector((state) => state.users.profile);
  const teams = useSelector((state) => state.teams);

  const navigate = useNavigate();

  useEffect(() => {
    if (typeForm) {
      setData(typeForm);
    } else {
      setData(initialState);
    }
  }, [typeForm]);

  const getMatches = useCallback(async () => {
    let temp = [];
    let search = null;
    if (adminProfile.role === UserRole.TeamAdmin) search = adminProfile.teamId;
    else if (data.team) {
      search = data.team.id;
    } else {
      setMatches([]);
      return;
    }
    const promises = await Promise.all([
      new Promise((resolve) => {
        let matchData = [];
        MatchesService.retrive(EventTabs.Ongoing, search, null, null, null, null, true).then(
          (res) => {
            matchData = res.data.data;
            resolve({ matchData });
          },
        );
      }),
      new Promise((resolve) => {
        let matchData = [];
        MatchesService.retrive(EventTabs.Upcoming, search, null, null, null, null, true).then(
          (res) => {
            matchData = res.data.data;
            resolve({ matchData });
          },
        );
      }),
    ]);

    promises.forEach((res) => {
      temp = [...temp].concat(res.matchData);
    });

    temp = temp.sort((a, b) => {
      if (a.start < b.start) return 1;
      if (a.start > b.start) return -1;
      return 0;
    });

    setMatches(temp);
  }, [data?.team, adminProfile]);

  useEffect(() => {
    getMatches();
  }, [getMatches]);

  const handleNext = () => {
    if (data.type !== "" && data.team && data.title !== "") {
      nextHandler(data);
    }
  };

  const handleBack = () => {
    navigate("/polls");
  };

  const handleChange = (field, value) => {
    setData((prevState) => ({ ...prevState, [field]: value }));
  };
  return data ? (
    <MDBox>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "200px" }}>
          Add Poll
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={handleBack}>
            Back
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox sx={(theme) => customFormContainer(theme)}>
        {adminProfile.role === UserRole.SuperAdmin && (
          <Grid container spacing={2} mb={4} alignItems="center">
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">Select Team</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <StyledAutocomplete
                options={teams}
                value={data?.team}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => handleChange("team", v)}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
                placeholder="Type"
              />
            </Grid>
          </Grid>
        )}
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Poll Title</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormField
              value={data?.title}
              onChange={(e) => handleChange("title", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Poll Description</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormField
              value={data?.description}
              onChange={(e) => handleChange("description", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Poll Type</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <StyledAutocomplete
              options={Object.values(PollType)}
              value={data?.type}
              onChange={(e, v) => handleChange("type", v)}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose type"
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Match/Event</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <StyledAutocomplete
              value={data?.match}
              options={matches}
              getOptionLabel={(option) => `${option?.title}`}
              onChange={(e, v) => handleChange("match", v)}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose type"
            />
          </Grid>
        </Grid>
      </MDBox>
      <Grid container justifyContent="flex-end">
        <MDButton color="secondary" size="large" onClick={handleNext}>
          Next
        </MDButton>
      </Grid>
    </MDBox>
  ) : null;
}

SelectPollCategory.defaultProps = {
  typeForm: null,
  nextHandler: {},
};

SelectPollCategory.propTypes = {
  typeForm: PropTypes.objectOf(PropTypes.any),
  nextHandler: PropTypes.func,
};

export default SelectPollCategory;
