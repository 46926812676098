import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import MDBox from "components/MDBox";

import { setLoading } from "store/appSlice";
import { createChallenge, updateChallenge } from "store/slices/challengeSlice";

import ChallengeService from "services/ChallengeService";
import ToastrService from "services/ToastrService";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { ChallengeTypes, UserRole } from "layouts/common/constants";

import SelectCategory from "./forms/SelectCategory";
import CheckInDetail from "./forms/CheckInDetail";
import MultiCheckInDetail from "./forms/MultiCheckInDetail";
import MultiReferrerDetail from "./forms/MultiReferrerDetail";
import SurveyDetail from "./forms/SurveyDetail";
import CheckInReview from "./forms/CheckInReview";
import MultiCheckInReview from "./forms/MultiCheckInReview";
import MultiReferrerReview from "./forms/MultiReferrerReview";
import SurveyReview from "./forms/SurveyReview";
import SurveyFreeTextDetail from "./forms/SurveyFreeTextDetail";
import SurveyFreeTextReview from "./forms/SurveyFreeTextReview";

const formSwitcher = (
  screen,
  prevHandler,
  nextHandler,
  saveHandler,
  handleCategorySelection,
  changeHandler,
  typeForm,
  newChallenge,
) => {
  switch (screen) {
    case 1:
      return <SelectCategory nextHandler={handleCategorySelection} typeForm={typeForm} />;
    case 2:
      if (typeForm.type === ChallengeTypes.CheckIn)
        return (
          <CheckInDetail
            prevHandler={prevHandler}
            nextHandler={nextHandler}
            changeHandler={changeHandler}
            typeForm={typeForm}
            item={newChallenge}
          />
        );
      if (typeForm.type === ChallengeTypes.MultiCheckIn)
        return <MultiCheckInDetail prevHandler={prevHandler} nextHandler={nextHandler} />;
      if (typeForm.type === ChallengeTypes.MultiReferrer)
        return <MultiReferrerDetail prevHandler={prevHandler} nextHandler={nextHandler} />;
      if (typeForm.type === ChallengeTypes.SurveyMultichoice)
        return (
          <SurveyDetail
            prevHandler={prevHandler}
            nextHandler={nextHandler}
            changeHandler={changeHandler}
            typeForm={typeForm}
            item={newChallenge}
          />
        );
      if (typeForm.type === ChallengeTypes.SurveyFreeText)
        return (
          <SurveyFreeTextDetail
            prevHandler={prevHandler}
            nextHandler={nextHandler}
            changeHandler={changeHandler}
            typeForm={typeForm}
            item={newChallenge}
          />
        );

      return <div>&nbsp;</div>;

    case 3:
      if (typeForm.type === ChallengeTypes.CheckIn)
        return (
          <CheckInReview
            prevHandler={prevHandler}
            saveHandler={saveHandler}
            typeForm={typeForm}
            item={newChallenge}
          />
        );
      if (typeForm.type === ChallengeTypes.MultiCheckIn)
        return <MultiCheckInReview prevHandler={prevHandler} saveHandler={saveHandler} />;
      if (typeForm.type === ChallengeTypes.MultiReferrer)
        return <MultiReferrerReview prevHandler={prevHandler} saveHandler={saveHandler} />;
      if (typeForm.type === ChallengeTypes.SurveyMultichoice)
        return (
          <SurveyReview
            prevHandler={prevHandler}
            saveHandler={saveHandler}
            typeForm={typeForm}
            item={newChallenge}
          />
        );
      if (typeForm.type === ChallengeTypes.SurveyFreeText)
        return (
          <SurveyFreeTextReview
            prevHandler={prevHandler}
            saveHandler={saveHandler}
            typeForm={typeForm}
            item={newChallenge}
          />
        );

      return <div>&nbsp;</div>;

    default:
      return <SelectCategory nextHandler={handleCategorySelection} />;
  }
};

function AddChallenge() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, type } = useParams();

  const adminProfile = useSelector((state) => state.users.profile);
  const teams = useSelector((state) => state.teams);

  const [screen, setScreen] = useState(1);
  const [typeForm, setTypeForm] = useState(null);
  const [newChallenge, setNewChallenge] = useState(null);

  const getChallenge = (_id, _type) => {
    dispatch(setLoading(true));
    ChallengeService.get(_id, _type)
      .then((response) => {
        setNewChallenge(response.data);
        if (_type === ChallengeTypes.CheckIn) {
          setTypeForm({ team: response.data.team, type: ChallengeTypes.CheckIn });
        } else if (response.data.isOptional) {
          setTypeForm({ team: response.data.team, type: ChallengeTypes.SurveyMultichoice });
        } else {
          setTypeForm({ team: response.data.team, type: ChallengeTypes.SurveyFreeText });
        }

        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load challenge data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id && type) {
      getChallenge(id, type);
    } else {
      setNewChallenge(null);
    }
  }, [id, type]);

  useEffect(() => {
    if (adminProfile.role === UserRole.TeamAdmin) {
      const tmp = teams.find((t) => t.id === adminProfile.teamId);
      setTypeForm({ ...typeForm, team: tmp });
    }
  }, [adminProfile]);

  const handleCategorySelection = (_typeForm) => {
    setTypeForm(_typeForm);
    setScreen(screen + 1);
  };

  const handleChange = (data) => {
    setNewChallenge(data);
    return true;
  };

  const handleSave = (isDraft = false) => {
    const newData = { ...newChallenge };
    const dataId = newData.id;

    newData.teamId = typeForm.team.id;
    newData.sponsorId = newData.sponsor.id;

    newData.matchId = newData.match.id;
    newData.isDraft = isDraft;

    if (!newData.participants)
      // When create new data;
      newData.participants = 0;

    ["id", "team", "match", "sponsor"].forEach((key) => {
      delete newData[key];
    });

    dispatch(setLoading(true));
    if (dataId === null) {
      dispatch(createChallenge({ type: typeForm.type, data: newData }))
        .unwrap()
        .then((data) => {
          ToastrService.success("Successfully added new challenge");
          dispatch(setLoading(false));
          if (typeForm.type === ChallengeTypes.CheckIn)
            navigate(`/challenge/view/check-in/${data.id}`);
          if (
            typeForm.type === ChallengeTypes.SurveyMultichoice ||
            typeForm.type === ChallengeTypes.SurveyFreeText
          )
            navigate(`/challenge/view/survey/${data.id}`);
        })
        .catch((e) => {
          ToastrService.error(e.response?.data?.message?.message || "Failed to add challenge");
          dispatch(setLoading(false));
        });
    } else {
      // remove keys
      ["createdAt", "updatedAt", "type"].forEach((key) => {
        delete newData[key];
      });

      dispatch(updateChallenge({ type: typeForm.type, id: dataId, data: newData }))
        .unwrap()
        .then((data) => {
          ToastrService.success("Successfully updated challenge");
          dispatch(setLoading(false));
          if (typeForm.type === ChallengeTypes.CheckIn)
            navigate(`/challenge/view/check-in/${data.id}`);
          if (
            typeForm.type === ChallengeTypes.SurveyMultichoice ||
            typeForm.type === ChallengeTypes.SurveyFreeText
          )
            navigate(`/challenge/view/survey/${data.id}`);
        })
        .catch((e) => {
          ToastrService.error(e.response?.data?.message?.message || "Failed to update challenge");
          dispatch(setLoading(false));
        });
    }
  };

  const nextScreen = () => {
    setScreen(screen + 1);
  };

  const prevScreen = () => {
    setScreen(screen - 1);
  };

  return (
    <DashboardLayout>
      <MDBox my={3}>
        {formSwitcher(
          screen,
          prevScreen,
          nextScreen,
          handleSave,
          handleCategorySelection,
          handleChange,
          typeForm,
          newChallenge,
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default AddChallenge;
