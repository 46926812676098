import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { customFormContainer } from "layouts/common/styles";
import { UserRole } from "layouts/common/constants";
import { ViewMode } from "../../types";

function TeamDetail({ item, switchUIHandler }) {
  const adminProfile = useSelector((state) => state.users.profile);

  return (
    <MDBox>
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Team Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team Name
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.name}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team Description
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.description}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team Logo
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">
              {item?.logo && item?.logo !== "" && (
                <MDAvatar src={item?.logo} alt="Avatar" sx={{ width: "250px", height: "250px" }} />
              )}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Platform Name
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.platformName}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Platform Url
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.platformUrl}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team Sport
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.teamSport}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Token Name
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.tokenSymbol}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Token Logo
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">
              {item?.tokenImage && item?.tokenImage !== "" && (
                <MDAvatar
                  src={item?.tokenImage}
                  alt="Avatar"
                  sx={{ width: "250px", height: "250px" }}
                />
              )}
            </MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Admin Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              First Name
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.adminFirstName}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Last Name
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.adminLastName}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Email ID
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.adminEmail}</MDTypography>
          </Grid>
          {adminProfile.role === UserRole.TeamAdmin && (
            <MDBox
              my={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                textDecoration: "underline",
              }}
            >
              <MDButton
                variant="text"
                color="info"
                onClick={() => switchUIHandler(ViewMode.UPDATE_USER)}
              >
                Update details
              </MDButton>
              <MDButton
                variant="text"
                color="info"
                onClick={() => switchUIHandler(ViewMode.MANAGE_ADMIN)}
              >
                Manage admin members
              </MDButton>
            </MDBox>
          )}
        </Grid>
      </MDBox>
    </MDBox>
  );
}

TeamDetail.defaultProps = {
  item: null,
  switchUIHandler: {},
};

TeamDetail.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  switchUIHandler: PropTypes.func,
};

export default TeamDetail;
