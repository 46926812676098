import PropTypes from "prop-types";
import { Checkbox, Divider, Grid } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { customFormContainer } from "layouts/common/styles";
import { BoosterType, UnlockRewardType } from "layouts/common/constants";

function ViewMiniGameTheme({ item }) {
  return (
    <MDBox sx={(theme) => customFormContainer(theme)}>
      <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
        Design
      </MDTypography>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={3} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Mini Game Logo
          </MDTypography>
          <MDTypography fontSize="10px">Recommended 500 × 500px</MDTypography>
        </Grid>
        <Grid item xs={12} sm={9}>
          {item?.logo !== null && item?.logo !== "" && (
            <MDAvatar
              src={item?.logo}
              alt="logo"
              sx={{ width: "250px", height: "250px", borderRadius: "0px" }}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={3} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Background Image
          </MDTypography>
          <MDTypography fontSize="10px">Recommended 500 × 1000px</MDTypography>
        </Grid>
        <Grid item xs={12} sm={9}>
          {item?.background !== null && item?.background !== "" && (
            <MDAvatar
              src={item?.background}
              alt="background"
              sx={{ width: "250px", height: "500px", borderRadius: "0px" }}
            />
          )}
        </Grid>
      </Grid>
      <Divider />
      <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
        Sponsors
      </MDTypography>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={3}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Add sponsor to game?
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={2} md={1.5}>
          <Checkbox checked={item?.enableSponsor || false} disabled="true" />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Game Sponsor
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <MDTypography fontSize="15px">{item?.sponsor?.title}</MDTypography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={3} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Sponsor Logo
          </MDTypography>
          <MDTypography fontSize="10px">Recommended 500 × 500px</MDTypography>
        </Grid>
        <Grid item xs={12} sm={9}>
          {item?.sponsorLogo !== null && item?.sponsorLogo !== "" && (
            <MDAvatar
              src={item?.sponsorLogo}
              alt="sponsorLogo"
              sx={{ width: "250px", height: "250px", borderRadius: "0px" }}
            />
          )}
        </Grid>
      </Grid>
      <Divider />
      <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
        Game Mechanics
      </MDTypography>
      <Grid container spacing={2} mb={4} alignItems="center">
        <Grid item xs={12} sm={3} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Collectible booster type
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <MDTypography fontSize="15px">{BoosterType[item?.boosterType || 0]}</MDTypography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={3} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Collectible booster image
          </MDTypography>
          <MDTypography fontSize="10px">Recommended 500 × 500px</MDTypography>
        </Grid>
        <Grid item xs={12} sm={9}>
          {item?.boosterLogo !== null && item?.boosterLogo !== "" && (
            <MDAvatar
              src={item?.boosterLogo}
              alt="boosterLogo"
              sx={{ width: "250px", height: "250px", borderRadius: "0px" }}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={3} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            No to collect to unlock reward
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <MDTypography fontSize="15px">{item?.unlockRewardNumber}</MDTypography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={4} alignItems="center">
        <Grid item xs={12} sm={3} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Reward to unlock
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <MDTypography fontSize="15px">{UnlockRewardType[item?.unlockReward || 0]}</MDTypography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={3} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            No reward item to send
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <MDTypography fontSize="15px">{item?.rewardSendTimeNumber}</MDTypography>
        </Grid>
      </Grid>
    </MDBox>
  );
}

ViewMiniGameTheme.defaultProps = {
  item: null,
};

ViewMiniGameTheme.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
};

export default ViewMiniGameTheme;
