import { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Autocomplete, Grid, TextField } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { pageHeaderContainer } from "layouts/common/styles";
import { UserRole } from "layouts/common/constants";

import { retriveUsers } from "store/slices/userSlice";
import { retriveGames } from "store/slices/gameSlice";

import TeamsService from "services/TeamService";
import GameService from "services/GamesService";

import QuickVerifyPredictionTable from "./components/QuickVerifyPredictionTable";
import QuickVerifyMilestoneTable from "./components/QuickVerifyMilestoneTable";
import MyTeamCard from "./components/Cards/MyTeamCard";
import OverviewCard from "./components/Cards/OverviewCard";

function Overview() {
  const dispatch = useDispatch();

  const teams = useSelector((state) => state.teams);
  const [team, setTeam] = useState();
  const gameCount = useSelector((state) => state.games.totalCount);
  const userCount = useSelector((state) => state.users.totalCount);
  const adminProfile = useSelector((state) => state.users.profile);

  const [selectedTeams, setSelectedTeams] = useState([]);
  const [showVerifyPage, setShowVerifyPage] = useState(false);
  const [unverifiedGameCount, setUnverifiedGameCount] = useState(0);

  const search = useMemo(() => {
    if (adminProfile.role === UserRole.SuperAdmin && selectedTeams.length > 0) {
      return selectedTeams.map((d) => d.id).join(",");
    }
    if (adminProfile.role === UserRole.TeamAdmin) {
      return adminProfile.teamId;
    }
    return "";
  }, [adminProfile, selectedTeams]);

  const fetchBySearchOptions = useCallback(() => {
    dispatch(retriveUsers({ searchTeam: search, searchText: "", page: 0, rowsPerPage: 1 }));
    dispatch(retriveGames({ filter: "", search, page: 0, rowsPerPage: 1 }));
  }, [dispatch, search]);

  useEffect(() => {
    fetchBySearchOptions();
  }, [fetchBySearchOptions]);

  const fetchUnverifiedGames = useCallback(async () => {
    if (!showVerifyPage) {
      let tempVal = 0;
      const promises = await Promise.all([
        new Promise((resolve) => {
          GameService.retriveMilestone(0, 5, false, search).then(({ data }) => {
            resolve({ data });
          });
        }),
        new Promise((resolve) => {
          GameService.retrivePrediction(0, 5, false, search).then(({ data }) => {
            resolve({ data });
          });
        }),
      ]);

      promises.forEach(({ data }) => {
        tempVal += data.count;
      });

      setUnverifiedGameCount(tempVal);
    }
  }, [showVerifyPage, search]);

  useEffect(() => {
    fetchUnverifiedGames();
  }, [fetchUnverifiedGames]);

  const getTeam = (_id) => {
    TeamsService.get(_id).then(async (response) => {
      setTeam(response.data.team);
    });
  };

  useEffect(() => {
    if (adminProfile.teamId && adminProfile.teamId !== "") {
      getTeam(adminProfile.teamId);
    }
  }, [adminProfile]);

  return (
    <DashboardLayout>
      <MDBox py={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold">
            {showVerifyPage ? "Games To Verify" : "Dashboard"}
          </MDTypography>
          {showVerifyPage && (
            <MDButton size="small" color="dark" onClick={() => setShowVerifyPage(false)}>
              Back
            </MDButton>
          )}
        </MDBox>
        {adminProfile.role === UserRole.SuperAdmin && (
          <MDBox mb={3}>
            <MDTypography fontSize="12px" fontWeight="bold">
              Select Teams:{" "}
            </MDTypography>
            <Autocomplete
              multiple
              value={selectedTeams}
              options={teams}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => setSelectedTeams(v)}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              sx={{ maxWidth: "568px", width: "100%" }}
              placeholder="Select Teams"
            />
          </MDBox>
        )}
        {showVerifyPage ? (
          <MDBox>
            <MDBox mb={3}>
              <QuickVerifyPredictionTable selectedTeams={search} />
            </MDBox>
            <MDBox mb={3}>
              <QuickVerifyMilestoneTable selectedTeams={search} />
            </MDBox>
          </MDBox>
        ) : (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={4}>
              {adminProfile.role === UserRole.TeamAdmin ? (
                <MyTeamCard team={team} />
              ) : (
                <OverviewCard
                  color="info"
                  title="Teams"
                  value={teams.length}
                  icon="/images/analytics/referrer-anlaytics-icon.svg"
                  action="/dashboards/teams"
                  actionText="View Teams"
                />
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <OverviewCard
                color="info"
                title="Games"
                value={gameCount}
                icon="/images/analytics/game-analytics-icon.svg"
                action="/games"
                actionText="View Games"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <OverviewCard
                color="info"
                title="Users"
                value={userCount}
                icon="/images/analytics/referrer-anlaytics-icon.svg"
                action="/dashboards/analytics"
                actionText="View Analytics"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <OverviewCard
                color="info"
                title="Games To Verify"
                value={unverifiedGameCount}
                icon="/images/analytics/game-analytics-icon.svg"
                actionText="See all"
                onClickHandler={() => setShowVerifyPage(true)}
              />
            </Grid>
          </Grid>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Overview;
