import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { customFormContainer } from "layouts/common/styles";
import FormField from "layouts/assets/components/FormField";
import ToastrService from "services/ToastrService";

function AssetDetail({ formData, prevHandler, nextHandler }) {
  const imageInputRef = useRef(null);
  const [data, setData] = useState(formData);
  const [validationMsg, setValidationMsg] = useState({
    title: "",
    description: "",
    logo: "",
    logoFile: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    totalCount: "",
    maxPerUser: "",
    tokenRequired: "",
    kudosEligible: "",
    kudosReward: "",
    tokenReward: "",
  });

  const handlePrev = () => {
    prevHandler();
  };

  const validate = () => {
    let validated = true;
    let temp = { ...validationMsg };
    Object.keys(temp).map((key) => {
      if (
        key === "tokenRequired" ||
        key === "kudosEligible" ||
        key === "kudosReward" ||
        key === "tokenReward"
      ) {
        if (data[key] === "" || data[key] === null || (!data[key] && data[key] !== 0)) {
          validated = false;
          temp = { ...temp, [key]: `Invalid ${key}` };
        } else {
          temp = { ...temp, [key]: "" };
        }
      } else if (key === "logoFile" && formData.id) {
        temp = { ...temp, [key]: "" };
      } else if (!data[key] || data[key] === "" || data[key] === null) {
        validated = false;
        temp = { ...temp, [key]: `Invalid ${key}` };
      } else {
        temp = { ...temp, [key]: "" };
      }
      return "";
    });

    setValidationMsg(temp);
    return validated;
  };

  const handleNext = () => {
    if (validate()) {
      nextHandler({ ...data });
    } else {
      ToastrService.error(`Invalid input`);
    }
  };

  const handleSubmitChange = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleSubmitChangeNumber = (field, value) => {
    if (parseFloat(value) < 0) return;
    setData({ ...data, [field]: parseFloat(value) });
  };

  const handleFilePathChange = (event) => {
    const [file] = event.target.files;
    setData({ ...data, logo: URL.createObjectURL(file), logoFile: file });
  };

  const handleRemoveImage = () => {
    setData({ ...data, logo: null, logoFile: null });
  };

  useEffect(() => {
    setData(formData);
  }, [formData]);
  return (
    <MDBox>
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        <Grid container spacing={2} mb={4} alignItems="center">
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Sponsor / Team
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{data.type}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Asset Category
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{data.category}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{data.team.name}</MDTypography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" value="sdfas">
              Asset Title
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormField
              value={data?.title}
              onChange={(e) => {
                handleSubmitChange("title", e.target.value);
              }}
              error={validationMsg.title !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Asset Description</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormField
              value={data?.description}
              onChange={(e) => {
                handleSubmitChange("description", e.target.value);
              }}
              error={validationMsg.description !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Asset Image</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDBox mb={2}>
              <Button
                variant="contained"
                component="label"
                color="white"
                onClick={() => {
                  imageInputRef.current.value = null;
                }}
                sx={{ marginRight: "20px" }}
                error="true"
              >
                Upload Image
                <input
                  ref={imageInputRef}
                  type="file"
                  hidden
                  name="logo"
                  onChange={handleFilePathChange}
                  accept=".jpg,.jpeg,.png"
                />
              </Button>
              {data.logo && data.logo !== "" && (
                <Button
                  variant="contained"
                  component="label"
                  color="error"
                  onClick={handleRemoveImage}
                >
                  Remove Image
                </Button>
              )}
            </MDBox>
            {data.logo && data.logo !== "" && (
              <MDBox
                component="img"
                src={data.logo}
                alt="asset image"
                shadow="md"
                width="250px"
                height="250px"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px">Start Date & Time</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="date"
              label=""
              value={data.startDate}
              onChange={(e) => {
                handleSubmitChange("startDate", e.target.value);
              }}
              error={validationMsg.startDate !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="time"
              label=""
              value={data.startTime}
              onChange={(e) => {
                handleSubmitChange("startTime", e.target.value);
              }}
              error={validationMsg.startTime !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px">Expiration Date & Time</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="date"
              label=""
              value={data.endDate}
              onChange={(e) => {
                handleSubmitChange("endDate", e.target.value);
              }}
              error={validationMsg.endDate !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="time"
              label=""
              value={data.endTime}
              onChange={(e) => {
                handleSubmitChange("endTime", e.target.value);
              }}
              error={validationMsg.endTime !== ""}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Total Asset Count</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              min={0}
              value={data.totalCount === undefined ? 0 : data.totalCount}
              onChange={(e) => {
                handleSubmitChangeNumber("totalCount", e.target.value);
              }}
              error={validationMsg.totalCount !== ""}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Max Redemption per user</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              min={0}
              value={data.maxPerUser === undefined ? 0 : data.maxPerUser}
              onChange={(e) => {
                handleSubmitChangeNumber("maxPerUser", e.target.value);
              }}
              error={validationMsg.maxPerUser !== ""}
            />
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Redemption
        </MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Tokens required to redeem</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              min={0}
              value={data.tokenRequired === undefined ? 0 : data.tokenRequired}
              onChange={(e) => {
                handleSubmitChangeNumber("tokenRequired", e.target.value);
              }}
              error={validationMsg.tokenRequired !== ""}
            />
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Eligibility
        </MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">No of Kudos Points</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              min={0}
              value={data.kudosEligible === undefined ? 0 : data.kudosEligible}
              onChange={(e) => {
                handleSubmitChangeNumber("kudosEligible", e.target.value);
              }}
              error={validationMsg.kudosEligible !== ""}
            />
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Reward
        </MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">No of Kudos Points</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              min={0}
              value={data.kudosReward === undefined ? 0 : data.kudosReward}
              onChange={(e) => {
                handleSubmitChangeNumber("kudosReward", e.target.value);
              }}
              error={validationMsg.kudosReward !== ""}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">No of Tokens</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              min={0}
              value={data.tokenReward === undefined ? 0 : data.tokenReward}
              onChange={(e) => {
                handleSubmitChangeNumber("tokenReward", e.target.value);
              }}
              error={validationMsg.tokenReward !== ""}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Grid container justifyContent="flex-end" gap={2}>
        <Grid item>
          <MDButton color="secondary" size="large" onClick={handlePrev}>
            Prev
          </MDButton>
        </Grid>
        <Grid item>
          <MDButton color="secondary" size="large" onClick={handleNext}>
            Next
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

AssetDetail.defaultProps = {
  prevHandler: {},
  nextHandler: {},
  formData: null,
};

AssetDetail.propTypes = {
  prevHandler: PropTypes.func,
  nextHandler: PropTypes.func,
  formData: PropTypes.objectOf(PropTypes.any),
};

export default AssetDetail;
