import UserService from "services/UserService";
import { setLoading } from "store/appSlice";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  profile: {
    id: "",
    role: "",
    teamId: "",
    email: "",
  },
  totalCount: 0,
  data: [],
};

export const retriveUsers = createAsyncThunk(
  "/user/retrive",
  async ({ searchTeam, searchText, page, rowsPerPage, sortFilter, sortDirection }) => {
    const res = await UserService.getAll(
      searchTeam,
      searchText,
      page,
      rowsPerPage,
      sortFilter,
      sortDirection,
    );
    return res.data;
  },
);

export const deleteUser = createAsyncThunk("/user/delete", async (id) => {
  await UserService.remove(id);
  return { id };
});

export const activateUser = createAsyncThunk("/user/activate", async (data, { dispatch }) => {
  try {
    dispatch(setLoading(true));
    const res = await UserService.activate(data.id, data.active);
    dispatch(setLoading(false));
    return res.data;
  } catch (e) {
    dispatch(setLoading(false));
    return {};
  }
});

export const getProfile = createAsyncThunk("/user/profile", async (data, { dispatch }) => {
  try {
    dispatch(setLoading(true));
    const res = await UserService.getProfile();
    dispatch(setLoading(false));
    return res.data;
  } catch (e) {
    dispatch(setLoading(false));
    return {};
  }
});

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    [getProfile.fulfilled]: (state, action) => {
      state.profile = action.payload;
    },
    [retriveUsers.fulfilled]: (state, action) => {
      state.totalCount = action.payload.count;
      state.data = [...action.payload.data];
    },
    [deleteUser.fulfilled]: (state, action) => {
      const index = state.data.findIndex(({ id }) => id === action.payload.id);
      state.data.splice(index, 1);
    },
    [activateUser.fulfilled]: (state, action) => {
      const index = state.data.findIndex((user) => user.id === action.payload.id);
      state.data[index] = {
        ...state.data[index],
        ...action.payload,
      };
    },
  },
  reducers: {
    setAdminProfile: (state, action) => {
      state.profile = action.payload;
    },
  },
});

export const { setAdminProfile } = userSlice.actions;
const { reducer } = userSlice;
export default reducer;
