import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { customFormContainer } from "layouts/common/styles";

import sponsorOptions from "layouts/challenges/data/sponsorOptions";
import FormField from "../../FormField";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .MuiOutlinedInput-root .MuiAutocomplete-input`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
    padding: 0,
    width: "35px",
  },
  [`& .MuiOutlinedInput-root`]: {
    padding: "0px",
    paddingLeft: "10px",
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

function MultiCheckInDetail({ nextHandler }) {
  const handleNext = () => {
    nextHandler();
  };

  return (
    <MDBox>
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <Grid container spacing={2} mb={4} alignItems="center">
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">Adelaide Giants</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Challenge Type
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">Multi Check In</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px">Start Date & Time</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField type="date" label="Date" value="2018-11-23" />
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField type="time" label="Time" value="10:30:00" />
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px">Expiration Date & Time</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField type="date" label="Date" value="2018-11-23" />
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField type="time" label="Time" value="10:30:00" />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Participants</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9} md={8.5}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel value="All" control={<Radio />} label="All Users" />
                <FormControlLabel value="Limited" control={<Radio />} label="Limited" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Eligibility
        </MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px">
              No of check ins required to complete challenge
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField type="number" />
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Rewards
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} md={6}>
            <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
              If user is at venue
            </MDTypography>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px">No of Kudos Points</MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField type="number" />
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px">No of Tokens</MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField type="number" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Sponsors
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Game Sponsor</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <StyledAutocomplete
              options={sponsorOptions}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose Sponsor"
            />
          </Grid>
        </Grid>
      </MDBox>
      <Grid container justifyContent="flex-end" gap={2}>
        <Grid item>
          <MDButton color="secondary" size="large" onClick={() => handleNext()}>
            Next
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

MultiCheckInDetail.defaultProps = {
  nextHandler: {},
};

MultiCheckInDetail.propTypes = {
  nextHandler: PropTypes.func,
};

export default MultiCheckInDetail;
