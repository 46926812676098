import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/illustrations/SportzFan.png";

import useAuth from "hooks/useAuth";
import { validateEmail } from "utils/validation";
import MDTypography from "components/MDTypography";
import AuthService from "services/AuthService";
import { setLoading } from "store/appSlice";
import { UserRole } from "layouts/common/constants";

function Illustration() {
  const dispatch = useDispatch();
  const { addToken } = useAuth();

  const adminProfile = useSelector((state) => state.users.profile);
  const { loaded, token } = useAuth();

  const [data, setData] = useState({
    email: "",
    password: "",
    isAdminLogin: true,
  });

  const [validations, setValidations] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const validate = () => {
    let validated = true;
    if (data.email === "") {
      setValidations((prevState) => ({ ...prevState, email: "Email is Empty" }));
      validated = false;
    } else if (!validateEmail(data.email)) {
      setValidations((prevState) => ({ ...prevState, email: "Email is Invalid" }));
      validated = false;
    } else {
      setValidations((prevState) => ({ ...prevState, email: "" }));
    }

    if (data.password === "") {
      setValidations((prevState) => ({ ...prevState, password: "Password is empty" }));
      validated = false;
    } else {
      setValidations((prevState) => ({ ...prevState, password: "" }));
    }

    return validated;
  };

  const handleChange = (field, value) => {
    setData((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleSignIn = () => {
    if (validate()) {
      dispatch(setLoading(true));
      AuthService.login({ ...data, email: data.email.toLowerCase() })
        .then((res) => {
          dispatch(setLoading(false));
          addToken(res.access_token);
        })
        .catch(() => {
          setValidations((prevState) => ({
            ...prevState,
            password: "Login failed, please confirm email and password!",
          }));
          dispatch(setLoading(false));
        });
    }
  };

  const handleRecoverPassword = () => {
    navigate("/recover-password");
  };

  useEffect(() => {
    if (adminProfile.teamId !== "") {
      if (adminProfile.role === UserRole.SuperAdmin) {
        navigate("/dashboards/teams");
      } else {
        navigate(`/dashboards/teams/view/${adminProfile.teamId}`);
      }
    }
  }, [adminProfile, loaded, token]);

  return (
    <IllustrationLayout
      title="Login"
      description="Enter your email and password to sign in"
      illustration={bgImage}
    >
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            type="email"
            label="Email"
            fullWidth
            value={data.email}
            onChange={(e) => {
              handleChange("email", e.target.value);
            }}
          />
          {validations.email !== "" && (
            <MDTypography fontSize="12px" mt={1} sx={{ color: "red" }}>
              {validations.email}
            </MDTypography>
          )}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            label="Password"
            fullWidth
            value={data.password}
            onChange={(e) => {
              handleChange("password", e.target.value);
            }}
          />
          {validations.password !== "" && (
            <MDTypography fontSize="12px" mt={1} sx={{ color: "red" }}>
              {validations.password}
            </MDTypography>
          )}
        </MDBox>
        <MDBox>
          <MDButton
            variant="text"
            color="info"
            size="middle"
            sx={{ padding: 0 }}
            onClick={() => handleRecoverPassword()}
          >
            Forgot Password?
          </MDButton>
        </MDBox>
        <MDBox mt={2} mb={1}>
          <MDButton
            variant="gradient"
            color="info"
            size="large"
            fullWidth
            onClick={() => handleSignIn()}
          >
            login
          </MDButton>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}

export default Illustration;
