import { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Divider, Grid } from "@mui/material";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDProgress from "components/MDProgress";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { flexContainer, pageHeaderContainer } from "layouts/common/styles";

import { setLoading } from "store/appSlice";

import ToastrService from "services/ToastrService";
import PollService from "services/PollService";

function PollImageResult() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [item, setItem] = useState(null);
  const [result, setResult] = useState([]);

  const getPoll = (_id) => {
    dispatch(setLoading(true));
    PollService.get(_id)
      .then(async (response) => {
        const temp = response.data;

        let tempResult = [...temp.options];
        await PollService.getResult(_id).then(({ data }) => {
          tempResult = tempResult.map((d) => {
            const optionResult = { ...d };
            if (data.participants.length > 0) {
              const voteCount = data.participants.filter(
                (participant) => participant.pollOptionId === d.id,
              ).length;
              optionResult.votePercent = ((voteCount / data.participants.length) * 100).toFixed(2);
            } else {
              optionResult.votePercent = 0;
            }
            return optionResult;
          });
          tempResult = tempResult.sort((a, b) => {
            if (Number(a.votePercent) > Number(b.votePercent)) return -1;
            if (Number(a.votePercent) < Number(b.votePercent)) return 1;
            return 0;
          });
        });
        tempResult = tempResult.sort((a, b) => {
          if (Number(a.votePercent) > Number(b.votePercent)) return -1;
          if (Number(a.votePercent) < Number(b.votePercent)) return 1;
          return 0;
        });

        setResult(tempResult);
        setItem(temp);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load poll data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) getPoll(id);
  }, [id]);

  const handleBack = () => {
    navigate(`/polls`);
  };

  return item ? (
    <DashboardLayout>
      <MDBox my={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" sx={{ width: "200px" }}>
            Poll - Image-Summary
          </MDTypography>
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDButton size="small" onClick={handleBack}>
              Back
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Team</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.team?.name}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Sponsor</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.sponsor?.title}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Poll Title</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.title}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Poll Description</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.description}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Poll Options Type</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.optionType}</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Results
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Voting</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.participants?.length}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Final Result</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">Image {result[0]?.order}</MDTypography>
          </Grid>
        </Grid>
        <MDBox mb={3}>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Popular Predictions
          </MDTypography>
          {result.map((data) => (
            <Grid key={data.id} container spacing={1} mb={1} alignItems="center">
              <Grid item xs={3} sm={2}>
                <MDTypography fontSize="15px">Image {data.order}:</MDTypography>
              </Grid>
              <Grid item xs={9} sm={10}>
                <MDProgress value={Number(data.votePercent)} variant="gradient" label />
              </Grid>
              <Grid item xs={12} sm={12}>
                <MDAvatar
                  src={data.details}
                  alt="Avatar"
                  sx={{ width: "100px", height: "100px" }}
                />
              </Grid>
            </Grid>
          ))}
        </MDBox>
      </MDBox>
    </DashboardLayout>
  ) : null;
}

export default PollImageResult;
