import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Divider, Grid } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";

import { dateToDateAndTime } from "utils/converter";

import AssetService from "services/AssetService";
import ToastrService from "services/ToastrService";

import { setLoading } from "store/appSlice";

import claimTypes from "layouts/assets/data/claimTypes";

function ViewAsset() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});

  const getAsset = (_id) => {
    dispatch(setLoading(true));
    AssetService.get(_id)
      .then((response) => {
        const data = { ...response.data };
        const startDateTime = dateToDateAndTime(new Date(data.start));
        const endDateTime = dateToDateAndTime(new Date(data.end));
        data.startDate = startDateTime.date;
        data.startTime = startDateTime.time;
        data.endDate = endDateTime.date;
        data.endTime = endDateTime.time;
        data.logo = data.imageUrl;
        setFormData(data);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load asset data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) getAsset(id);
  }, [id]);

  const handleBack = () => {
    navigate("/assets");
  };

  const handleEdit = () => {
    navigate(`/assets/edit/${id}`);
  };

  const go2Inventory = () => {
    navigate(`/assets/inventory/${id}`);
  };

  return (
    <DashboardLayout>
      {formData.id ? (
        <MDBox>
          <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
            <MDTypography width="100%" fontSize="20px" fontWeight="bold" sx={{ minWidth: "150px" }}>
              {formData?.title}
            </MDTypography>
            <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
              <MDButton size="small" onClick={handleBack}>
                Back
              </MDButton>
            </MDBox>
          </MDBox>
          <Divider />
          <MDBox sx={(theme) => customFormContainer(theme)}>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Sponsor / Team
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{formData.type}</MDTypography>
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Team
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{formData.team.name}</MDTypography>
              </Grid>
            </Grid>
            <MDTypography
              fontSize="15px"
              fontWeight="bold"
              mb={3}
              sx={{ textDecoration: "underline" }}
            >
              Details
            </MDTypography>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Asset Category
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{formData.category}</MDTypography>
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Asset Title
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{formData.title}</MDTypography>
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Image
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                {formData.logo !== null && formData.logo !== "" && (
                  <MDBox
                    component="img"
                    src={formData.logo}
                    alt="asset image"
                    shadow="md"
                    width="250px"
                    height="250px"
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Start Date & Time
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12} md={8.5}>
                <MDTypography fontSize="15px">
                  {" "}
                  {`${formData.startDate} ${formData.startTime}`}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Expiration Date & Time
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12} md={8.5}>
                <MDTypography fontSize="15px">
                  {" "}
                  {`${formData.endDate} ${formData.endTime}`}{" "}
                </MDTypography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} md={6}>
                <MDTypography
                  fontSize="15px"
                  fontWeight="bold"
                  mb={3}
                  sx={{ textDecoration: "underline" }}
                >
                  Redemption
                </MDTypography>
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px" fontWeight="bold">
                      Tokens required to redeem
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MDTypography fontSize="15px">{formData.tokenRequired}</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px" fontWeight="bold">
                      Total Asset Count
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MDTypography fontSize="15px">{formData.totalCount}</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px" fontWeight="bold">
                      Max Redemption per user
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MDTypography fontSize="15px">{formData.maxPerUser}</MDTypography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <MDTypography
                  fontSize="15px"
                  fontWeight="bold"
                  mb={3}
                  sx={{ textDecoration: "underline" }}
                >
                  Eligibility
                </MDTypography>
                <Grid container spacing={2} mb={4}>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px" fontWeight="bold">
                      No of Kudos Points
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">{formData.kudosEligible}</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px" fontWeight="bold">
                      No of Tokens
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">{formData?.tokenEligible}</MDTypography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <MDTypography
              fontSize="15px"
              fontWeight="bold"
              mb={3}
              sx={{ textDecoration: "underline" }}
            >
              Rewards
            </MDTypography>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  No of Kudos Point
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{formData.kudosReward}</MDTypography>
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  No of Tokens
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{formData.tokenReward}</MDTypography>
              </Grid>
            </Grid>
            <MDTypography
              fontSize="15px"
              fontWeight="bold"
              mb={3}
              sx={{ textDecoration: "underline" }}
            >
              Claim details
            </MDTypography>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Claim type
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{formData.claimType}</MDTypography>
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Claim description
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{formData.claimDescription}</MDTypography>
              </Grid>
            </Grid>
            {formData.claimType === claimTypes[0] &&
              (formData.category === "Tickets" || formData.category === "Merchandise") && (
                <>
                  <MDTypography
                    fontSize="15px"
                    fontWeight="bold"
                    mb={3}
                    sx={{ textDecoration: "underline" }}
                  >
                    Coupon details
                  </MDTypography>
                  <Grid container spacing={2} mb={4}>
                    <Grid item xs={12} sm={3} md={2.5}>
                      <MDTypography fontSize="15px" fontWeight="bold">
                        Total asset count
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <MDTypography fontSize="15px">{formData.totalCount}</MDTypography>
                    </Grid>
                    {formData.coupons
                      .sort((a, b) => {
                        if (a.order > b.order) return 1;
                        if (a.order < b.order) return -1;
                        return 0;
                      })
                      .map((coupon, idx) => (
                        <Fragment key={`coupon-${idx + 1}`}>
                          <Grid item xs={12} sm={3} md={2.5}>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Coupon code #{idx + 1}
                            </MDTypography>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <MDTypography fontSize="15px">{coupon.code}</MDTypography>
                          </Grid>
                        </Fragment>
                      ))}
                  </Grid>
                  <MDTypography
                    fontSize="15px"
                    fontWeight="bold"
                    mb={3}
                    sx={{ textDecoration: "underline" }}
                  >
                    URL Link to Claim
                  </MDTypography>
                  <Grid container spacing={2} mb={4}>
                    <Grid item xs={12} sm={9}>
                      <MDTypography fontSize="15px">{formData.claimUrl}</MDTypography>
                    </Grid>
                  </Grid>
                </>
              )}
          </MDBox>
          <Grid container justifyContent="flex-end" gap={2} mb={3}>
            <Grid item>
              <MDButton color="secondary" size="large" onClick={go2Inventory}>
                Inventory
              </MDButton>
            </Grid>
            <Grid item>
              <MDButton color="secondary" size="large" onClick={handleEdit}>
                Edit
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <MDTypography fontSize="15px" fontWeight="bold">
          No data found.
        </MDTypography>
      )}
    </DashboardLayout>
  );
}

export default ViewAsset;
