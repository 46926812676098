import http from "../apis/http-common";

const transfer = (data) => http.post("/transaction/transfer", data);

const getUserActivities = (id, page, rowsPerPage) => {
  const skip = page * rowsPerPage;
  return http.get(`/transaction/activity/${id}/list?skip=${skip}&take=${rowsPerPage}`);
};

const TransactionService = {
  transfer,
  getUserActivities,
};

export default TransactionService;
