import { useCallback } from "react";
import PropTypes from "prop-types";
import { Checkbox, Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { customFormContainer, pageHeaderContainer } from "layouts/common/styles";
import { dateToDateAndTime } from "utils/converter";
import MDAvatar from "../../../../../components/MDAvatar";

function ImageSummaryForm({ typeForm, item, prevHandler, saveHandler }) {
  const startDateTime = dateToDateAndTime(new Date(item.start));
  const endDateTime = dateToDateAndTime(new Date(item.end));

  const start = `${startDateTime.date}  ${startDateTime.time}`;
  const end = `${endDateTime.date}  ${endDateTime.time}`;

  const handleBack = () => {
    prevHandler();
  };

  let debounceTimeout;

  const handleSave = useCallback(
    (isDraft = false) =>
      () => {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
          saveHandler(isDraft);
        }, 1000);
      },
    [saveHandler],
  );

  return (
    <MDBox>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "200px" }}>
          Add Poll - Image-Summary
        </MDTypography>
      </MDBox>
      <Divider />
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Poll Title
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{typeForm.title}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Poll Description
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{typeForm.description}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Poll Type</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{typeForm.type}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Match / Event</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{typeForm?.match?.title}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDBox mb={4}>
              <Grid container spacing={2}>
                {item?.options?.map((option, idx) => (
                  <Grid item xs={12} sm={12} md={6} key={`poll-option-image-${idx.toString()}`}>
                    <MDBox>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5} md={3}>
                          <MDTypography fontSize="15px">Option {idx + 1}</MDTypography>
                        </Grid>
                        <Grid item xs={12} sm={7} md={9} display="flex" alignItems="center">
                          <MDAvatar
                            src={option.details}
                            alt="Avatar"
                            sx={{ width: "100px", height: "100px" }}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Grid>
                ))}
              </Grid>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Start Date & Time
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <MDTypography fontSize="15px">{start}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Expiration Date & Time
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <MDTypography fontSize="15px">{end}</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Eligibility
        </MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              No of Kudos Points
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <MDTypography fontSize="15px">{item.kudosEligible}</MDTypography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              No of Tokens
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <MDTypography fontSize="15px">{item.tokenEligible}</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Rewards
        </MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              No of Kudos Points
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <MDTypography fontSize="15px">{item.kudosReward}</MDTypography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              No of Tokens
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <MDTypography fontSize="15px">{item.tokenReward}</MDTypography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Send asset as reward?
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox disabled checked={item?.enableAssetReward} />
          </Grid>
          {item?.enableAssetReward && (
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                select asset
              </MDTypography>
            </Grid>
          )}
          {item?.enableAssetReward && (
            <Grid item xs={12} sm={4} md={5}>
              <MDTypography fontSize="15px">{item?.asset?.title}</MDTypography>
            </Grid>
          )}
        </Grid>
        {item?.enableAssetReward && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Users to send to (1st to complete poll)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{item?.winnerLimit}</MDTypography>
            </Grid>
          </Grid>
        )}
        {item?.enableAssetReward && (
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of Bonus Asset to send per winner
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{item?.rewardAssetCount}</MDTypography>
            </Grid>
          </Grid>
        )}
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Sponsors
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Game Sponsor
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.sponsor?.title}</MDTypography>
          </Grid>
        </Grid>
      </MDBox>
      <Grid container justifyContent="flex-end" gap={2}>
        <MDButton color="secondary" size="large" onClick={handleBack}>
          Prev
        </MDButton>

        <MDButton color="secondary" size="large" onClick={handleSave(true)}>
          Save as Draft
        </MDButton>

        <MDButton color="secondary" size="large" onClick={handleSave()}>
          Publish
        </MDButton>
      </Grid>
    </MDBox>
  );
}

ImageSummaryForm.defaultProps = {
  typeForm: null,
  item: null,
  prevHandler: {},
  saveHandler: {},
};

ImageSummaryForm.propTypes = {
  typeForm: PropTypes.objectOf(PropTypes.any),
  item: PropTypes.objectOf(PropTypes.any),
  prevHandler: PropTypes.func,
  saveHandler: PropTypes.func,
};

export default ImageSummaryForm;
