import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC5tcrDBNZFd1vllrmH_JTTAMWSs6STS4I",
  authDomain: "sportzfan-dd524.firebaseapp.com",
  projectId: "sportzfan-dd524",
  storageBucket: "sportzfan-dd524.appspot.com",
  messagingSenderId: "437947290363",
  appId: "1:437947290363:web:f00aab7457b8f9ca2cd65e",
  measurementId: "G-RKFM6LYBHP",
};

const app = initializeApp(firebaseConfig);

// Export methods
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
