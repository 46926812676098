import { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Checkbox, Divider, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";

import ToastrService from "services/ToastrService";
import PollService from "services/PollService";
import { setLoading } from "store/appSlice";
import { dateToDateAndTime } from "utils/converter";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDAvatar from "../../../../components/MDAvatar";

function PollImageView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [item, setItem] = useState(null);

  const getPoll = (_id) => {
    dispatch(setLoading(true));
    PollService.get(_id)
      .then((response) => {
        const temp = response.data;
        const startDateTime = dateToDateAndTime(new Date(temp.start));
        const endDateTime = dateToDateAndTime(new Date(temp.end));

        temp.start = `${startDateTime.date}  ${startDateTime.time}`;
        temp.end = `${endDateTime.date}  ${endDateTime.time}`;
        setItem(temp);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load poll data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) getPoll(id);
  }, [id]);

  const handleViewResult = () => {
    navigate(`/polls/result/image/${id}`);
  };

  const handleEdit = () => {
    navigate(`/polls/edit/${id}`);
  };

  const handleBack = () => {
    navigate(`/polls`);
  };

  return item ? (
    <DashboardLayout>
      <MDBox my={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" sx={{ width: "200px" }}>
            Poll - Image-View
          </MDTypography>
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDButton size="small" onClick={handleBack}>
              Back
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox sx={(theme) => customFormContainer(theme)}>
          <Grid container spacing={2} mb={4} alignItems="center">
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">Team</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.team?.name}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Details
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">Poll Title</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">Poll Description</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item.description}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">Poll Type</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item.optionType}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">Match / Event</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.match?.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <MDBox mb={4}>
                <Grid container spacing={2}>
                  {item?.options?.map((option, idx) => (
                    <Grid item xs={12} sm={12} md={6} key={`poll-option-image-${idx.toString()}`}>
                      <MDBox>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={5} md={3}>
                            <MDTypography fontSize="15px">Option {idx + 1}</MDTypography>
                          </Grid>
                          <Grid item xs={12} sm={7} md={9} display="flex" alignItems="center">
                            <MDAvatar
                              src={option.details}
                              alt="Avatar"
                              sx={{ width: "100px", height: "100px" }}
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Grid>
                  ))}
                </Grid>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
              <MDTypography fontSize="15px">Start Date & Time</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <MDTypography fontSize="15px">{item.start}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
              <MDTypography fontSize="15px">Expiration Date & Time</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <MDTypography fontSize="15px">{item.end}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Eligibility
          </MDTypography>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">No of Kudos Points</MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{item.kudosEligible}</MDTypography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">No of Tokens</MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{item.tokenEligible}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Rewards
          </MDTypography>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">No of Kudos Points</MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{item.kudosReward}</MDTypography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">No of Tokens</MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{item.tokenReward}</MDTypography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Send asset as reward?
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <Checkbox disabled checked={item?.enableAssetReward} />
            </Grid>
            {item?.enableAssetReward && (
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  select asset
                </MDTypography>
              </Grid>
            )}
            {item?.enableAssetReward && (
              <Grid item xs={12} sm={4} md={5}>
                <MDTypography fontSize="15px">{item?.asset?.title}</MDTypography>
              </Grid>
            )}
          </Grid>
          {item?.enableAssetReward && (
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Users to send to (1st to complete poll)
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={2} md={1.5}>
                <MDTypography fontSize="15px">{item?.winnerLimit}</MDTypography>
              </Grid>
            </Grid>
          )}
          {item?.enableAssetReward && (
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={3}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  No of Bonus Asset to send per winner
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={2} md={1.5}>
                <MDTypography fontSize="15px">{item?.rewardAssetCount}</MDTypography>
              </Grid>
            </Grid>
          )}
          <Divider />
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Sponsors
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={2.5}>
              <MDTypography fontSize="15px">Game Sponsor</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.sponsor?.title}</MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container justifyContent="flex-end" gap={2}>
          <Grid item>
            <MDButton color="secondary" size="large" onClick={handleViewResult}>
              View Results
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton color="secondary" size="large" onClick={handleEdit}>
              Edit
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  ) : null;
}

export default PollImageView;
