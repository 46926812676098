import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Divider, Grid } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { pageHeaderContainer, flexContainer } from "layouts/common/styles";
import { UserRole } from "layouts/common/constants";

import { ENVIRONMENT } from "config";

import TeamDetail from "./TeamDetail";
import VirtualCurrency from "./VirtualCurrency";
import PlayerLevels from "./PlayerLevels";
import ThemeDetail from "./Theme";
import RewardDetail from "./Reward";

function TeamOverview({ item, backHandler, switchUIHandler }) {
  const navigate = useNavigate();
  const adminProfile = useSelector((state) => state.users.profile);

  // 0 - team detail, 1-virtual currency 2-player levels, 3-theme, 4-rewards
  const [activeView, setActiveView] = useState(0);

  const handleEdit = () => {
    navigate(`/dashboards/teams/edit/${item?.id}`);
  };
  return (
    <MDBox my={3}>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ maxWidth: "500px", width: "100%" }}>
          Team View - Overview
        </MDTypography>
        {adminProfile.role === UserRole.SuperAdmin && (
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDButton size="small" onClick={() => backHandler()}>
              Back
            </MDButton>
          </MDBox>
        )}
        {adminProfile.role === UserRole.TeamAdmin && (
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <a
              href={`https://${item?.platformUrl}.${
                ENVIRONMENT === "production" ? "sportzfan.io" : "dev-sportzfan.io"
              }`}
              target="_blank"
              rel="noreferrer"
            >
              <MDButton size="small" color="secondary">
                View Site
              </MDButton>
            </a>
          </MDBox>
        )}
      </MDBox>
      <Divider />
      <MDBox>
        <MDBox mb={3} sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          <MDButton
            variant={activeView === 0 ? "contained" : "text"}
            color="dark"
            size="large"
            onClick={() => setActiveView(0)}
          >
            Team / Admin Details
          </MDButton>
          <MDButton
            variant={activeView === 1 ? "contained" : "text"}
            color="dark"
            size="large"
            onClick={() => setActiveView(1)}
          >
            Virtual Currency
          </MDButton>
          <MDButton
            variant={activeView === 2 ? "contained" : "text"}
            color="dark"
            size="large"
            onClick={() => setActiveView(2)}
          >
            Player Levels
          </MDButton>
          <MDButton
            variant={activeView === 3 ? "contained" : "text"}
            color="dark"
            size="large"
            onClick={() => setActiveView(3)}
          >
            Theme
          </MDButton>
          <MDButton
            variant={activeView === 4 ? "contained" : "text"}
            color="dark"
            size="large"
            onClick={() => setActiveView(4)}
          >
            Rewards
          </MDButton>
        </MDBox>

        {activeView === 0 && <TeamDetail item={item} switchUIHandler={switchUIHandler} />}
        {activeView === 1 && <VirtualCurrency item={item} />}
        {activeView === 2 && <PlayerLevels item={item} />}
        {activeView === 3 && <ThemeDetail item={item} />}

        {activeView === 4 && <RewardDetail item={item} />}
      </MDBox>
      <Grid container justifyContent="flex-end">
        <MDButton color="secondary" size="large" onClick={handleEdit}>
          Edit
        </MDButton>
      </Grid>
    </MDBox>
  );
}

TeamOverview.defaultProps = {
  item: null,
  backHandler: {},
  switchUIHandler: {},
};

TeamOverview.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  backHandler: PropTypes.func,
  switchUIHandler: PropTypes.func,
};

export default TeamOverview;
