import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Checkbox, Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";
import { GameTypes } from "layouts/common/constants";
import { setLoading } from "store/appSlice";
import GameService from "services/GamesService";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { dateToDateAndTime } from "utils/converter";
import ToastrService from "services/ToastrService";

function ViewTrivia() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState(null);
  const [status, setStatus] = useState("");

  const getGame = (_id) => {
    dispatch(setLoading(true));
    GameService.get(_id, GameTypes.Trivia)
      .then((response) => {
        const temp = { ...response.data };

        if (temp.isDraft === true) {
          setStatus("Draft");
        } else if (temp.start < new Date().toISOString() && temp.end > new Date().toISOString()) {
          setStatus("Live");
        } else if (temp.start > new Date().toISOString()) {
          setStatus("Upcoming");
        } else {
          setStatus("Past");
        }

        const startDateTime = dateToDateAndTime(new Date(temp.start));
        const endDateTime = dateToDateAndTime(new Date(temp.end));

        temp.start = `${startDateTime.date}  ${startDateTime.time}`;
        temp.end = `${endDateTime.date}  ${endDateTime.time}`;

        setData(temp);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load trivia data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) getGame(id);
  }, [id]);

  const handleViewResult = () => {
    navigate(`/game/result-trivia/${id}`);
  };

  const handleFinishTrivia = () => {
    GameService.finish(GameTypes.Trivia, id)
      .then((response) => {
        const temp = { ...response.data };
        if (temp.success) {
          ToastrService.success("Successfully finished trivia game.");
          setData((prevState) => ({ ...prevState, isEnded: true }));
        } else {
          ToastrService.error(temp.message);
        }
      })
      .catch(() => {
        ToastrService.error("Failed to finish trivia game");
      });
  };

  const handleEdit = () => {
    navigate(`/game/edit-trivia/${id}`);
  };

  const handleBack = () => {
    navigate(`/games`);
  };

  return (
    <DashboardLayout>
      <MDBox my={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "200px" }}>
            Trivia - View
          </MDTypography>
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDButton size="small" onClick={() => handleBack()}>
              Back
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox sx={(theme) => customFormContainer(theme)}>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Details
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.team?.name}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Sponsor
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.sponsor.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Trivia Type
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.triviaType}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Match / Event
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.match?.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Trivia Title
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Trivia Description
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.description}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of Questions
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={9}>
              <MDTypography fontSize="15px">{data?.triviaQuestions?.length}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Start Date & Time
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={8.5}>
              <MDTypography fontSize="15px">{data?.start}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Expiration Date & Time
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={8.5}>
              <MDTypography fontSize="15px">{data?.end}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Eligibility
          </MDTypography>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of Kudos Points
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{data?.eligbleKudos}</MDTypography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of Tokens
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{data?.eligbleToken}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Rewards
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} md={6}>
              <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
                For participation
              </MDTypography>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    No of Kudos Points(Per player)
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography fontSize="15px">{data?.rewardKudosAll}</MDTypography>
                </Grid>
              </Grid>
              <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
                For Winners
              </MDTypography>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    No of Kudos Points(Total)
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography fontSize="15px">{data?.rewardKudosWinnerTotal}</MDTypography>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    No of Tokens(Total)
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography fontSize="15px">{data?.rewardTokenWinnerTotal}</MDTypography>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    No of Winners
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography fontSize="15px">{data?.rewardDistribution?.length}</MDTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
                Reward distribution
              </MDTypography>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={4} sm={4}>
                  &nbsp;
                </Grid>
                <Grid item xs={4} sm={4}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    KUDOS
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Tokens
                  </MDTypography>
                </Grid>
              </Grid>
              {data?.rewardDistribution
                .sort((a, b) => {
                  if (a.winnerOrder > b.winnerOrder) return 1;
                  if (a.winnerOrder < b.winnerOrder) return -1;
                  return 0;
                })
                .map((d) => (
                  <Grid key={d.id} container spacing={2} mb={2}>
                    <Grid item xs={4} sm={4}>
                      <MDTypography fontSize="15px" fontWeight="bold">
                        Winner #{d.winnerOrder}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <MDTypography fontSize="15px">{d.rewardKudos}</MDTypography>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <MDTypography fontSize="15px">{d.rewardToken}</MDTypography>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Send asset as reward?
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox disabled checked={data?.enableAssetReward || false} />
          </Grid>
          {data?.enableAssetReward && (
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                select asset
              </MDTypography>
            </Grid>
          )}
          {data?.enableAssetReward && (
            <Grid item xs={12} sm={4} md={5}>
              <MDTypography fontSize="15px">{data?.asset?.title}</MDTypography>
            </Grid>
          )}
        </Grid>
        {data?.enableAssetReward && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Number of top ranked scores to send to
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{data?.winnerLimit}</MDTypography>
            </Grid>
          </Grid>
        )}
        {data?.enableAssetReward && (
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of Bonus Asset to send per winner
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{data?.rewardAssetCount}</MDTypography>
            </Grid>
          </Grid>
        )}
        <Divider />

        <Grid container justifyContent="flex-end" gap={2} mt={10}>
          <Grid item>
            {(data?.isEnded || status === "Past") && (
              <MDButton color="secondary" size="large" onClick={() => handleViewResult()}>
                View Results
              </MDButton>
            )}
            {!data?.isEnded && status === "Live" && (
              <MDButton color="secondary" size="large" onClick={() => handleFinishTrivia()}>
                Finish
              </MDButton>
            )}
          </Grid>
          <Grid item>
            <MDButton color="secondary" size="large" onClick={() => handleEdit()}>
              Edit
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ViewTrivia;
