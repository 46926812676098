import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Divider } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import AssetService from "services/AssetService";
import UploadService from "services/UploadService";
import ToastrService from "services/ToastrService";
import { setLoading } from "store/appSlice";
import { createAsset, updateAsset } from "store/slices/assetSlice";
import { dateToDateAndTime, dateAndTimeToDate } from "utils/converter";

import { pageHeaderContainer, flexContainer } from "layouts/common/styles";
import { UserRole } from "layouts/common/constants";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SelectBonusAssetCategory from "./forms/SelectBonusAssetCategory";
import BonusAssetClaimDetail from "./forms/BonusAssetClaimDetail";
import BonusAssetReview from "./forms/BonusAssetReview";
import BonusAssetDetail from "./forms/BonusAssetDetail";

const formSwitcher = (screen, prevHandler, nextHandler, submitHandler, formData) => {
  switch (screen) {
    case 1:
      return <SelectBonusAssetCategory formData={formData} nextHandler={nextHandler} />;
    case 2:
      return (
        <BonusAssetDetail formData={formData} prevHandler={prevHandler} nextHandler={nextHandler} />
      );
    case 3:
      return (
        <BonusAssetClaimDetail
          formData={formData}
          prevHandler={prevHandler}
          nextHandler={nextHandler}
        />
      );
    case 4:
      return (
        <BonusAssetReview
          formData={formData}
          prevHandler={prevHandler}
          submitHandler={submitHandler}
        />
      );
    default:
      return <SelectBonusAssetCategory nextHandler={nextHandler} />;
  }
};

const headerSwitcher = (formData, screen) => {
  switch (screen) {
    case 1:
      return formData.id ? "Edit Bonus Asset" : "Add Bonus Asset";
    case 2:
      return `Bonus Asset Details - ${formData.category}`;
    case 3:
      return `Bonus Asset - ${formData.category} - Claim Details`;
    case 4:
      return `Bonus Asset - ${formData.category} - Summary`;
    default:
      return formData.id ? "Edit Bonus Asset" : "Add Bonus Asset";
  }
};

function AddBonusAsset() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const adminProfile = useSelector((state) => state.users.profile);

  const initialState = {
    type: "",
    team: "",
    category: "",
    title: "",
    description: "",
    logo: "",
    logoFile: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    totalCount: 0,
    claimType: "",
    cliamDescription: "",
    coupons: [{ code: "", order: 1 }],
    claimUrl: "",
  };

  const [screen, setScreen] = useState(1);
  const [formData, setFormData] = useState(initialState);

  const goToMain = () => {
    navigate(`/assets`);
  };

  const backHandler = () => {
    if (screen > 1) {
      setScreen(screen - 1);
    } else {
      goToMain();
    }
  };

  const resetHandler = () => {
    switch (screen) {
      case 1:
        setFormData((prev) => ({
          ...prev,
          type: "",
          team: "",
          category: "",
        }));
        break;
      case 2:
        setFormData((prev) => ({
          ...prev,
          title: "",
          description: "",
          logo: "",
          logoFile: "",
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          totalCount: 0,
        }));
        break;
      case 3:
        setFormData((prev) => ({
          ...prev,
          claimType: "",
          claimDescription: "",
          coupons: [{ code: "", order: 1 }],
          claimUrl: "",
        }));
        break;
      default:
        if (id) {
          setFormData({ id, ...initialState });
        } else {
          setFormData(initialState);
        }
        setScreen(1);
        break;
    }
  };

  const nextScreen = async (data) => {
    setFormData(data);
    setScreen(screen + 1);
  };

  const prevScreen = () => {
    setScreen(screen - 1);
  };

  const getAsset = (_id) => {
    dispatch(setLoading(true));
    AssetService.get(_id)
      .then((response) => {
        const data = { ...response.data };
        const startDateTime = dateToDateAndTime(new Date(data.start));
        const endDateTime = dateToDateAndTime(new Date(data.end));
        data.startDate = startDateTime.date;
        data.startTime = startDateTime.time;
        data.endDate = endDateTime.date;
        data.endTime = endDateTime.time;
        data.logo = data.imageUrl;
        data.coupons = data.coupons
          .sort((a, b) => {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
          })
          .map((d, idx) => {
            const coupon = d;
            coupon.order = idx + 1;
            return coupon;
          });
        setFormData(data);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load asset data.");
        dispatch(setLoading(false));
      });
  };

  const submitHandler = async (data, isDraft = false) => {
    dispatch(setLoading(true));
    const saveData = { ...data };
    if (adminProfile.role === UserRole.TeamAdmin) {
      saveData.teamId = adminProfile.teamId;
    } else {
      saveData.teamId = saveData.team.id;
    }

    saveData.isDraft = isDraft;
    saveData.isBonus = true;
    saveData.maxPerUser = 1;
    saveData.tokenRequired = 0;
    saveData.kudosEligible = 0;
    saveData.tokenEligible = 0;
    saveData.kudosReward = 0;
    saveData.tokenReward = 0;

    saveData.start = dateAndTimeToDate(saveData.startDate, saveData.startTime).toISOString();
    saveData.end = dateAndTimeToDate(saveData.endDate, saveData.endTime).toISOString();

    // Uploading image before creating asset.
    let isSuccessUploading = true;

    // Upload image
    if (saveData.logoFile) {
      const fileFormData = new FormData();
      fileFormData.append("file", saveData.logoFile);
      await UploadService.upload("assets", fileFormData)
        .then((res) => {
          saveData.logo = res.data.url;
          saveData.imageUrl = res.data.url;
        })
        .catch(() => {
          ToastrService.error("Failed to upload new team logo");
          isSuccessUploading = false;
        });
    }

    if (!isSuccessUploading) {
      dispatch(setLoading(false));
      return;
    }

    // Add
    if (!saveData.id) {
      dispatch(createAsset({ data: saveData }))
        .unwrap()
        .then(() => {
          ToastrService.success("Successfully added new Asset");
          dispatch(setLoading(false));
          goToMain();
        })
        .catch((e) => {
          ToastrService.error(e.response?.data?.message?.message || "Failed to add new Asset");
          dispatch(setLoading(false));
        });
    } else {
      // remove keys
      ["createdAt", "updatedAt"].forEach((key) => {
        delete saveData[key];
      });
      dispatch(updateAsset({ id: saveData.id, data: saveData }))
        .unwrap()
        .then(() => {
          dispatch(setLoading(false));
          ToastrService.success("Successfully updated Asset");
          goToMain();
        })
        .catch((e) => {
          ToastrService.error(e.response?.data?.message?.message || "Failed to update Asset");
          dispatch(setLoading(false));
        });
    }
  };

  useEffect(() => {
    if (id) getAsset(id);
  }, [id]);

  return (
    <DashboardLayout>
      <MDBox my={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "100%" }}>
            {headerSwitcher(formData, screen)}
          </MDTypography>
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDButton size="small" onClick={backHandler}>
              Back
            </MDButton>
            <MDButton size="small" onClick={resetHandler}>
              Reset
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        {formSwitcher(screen, prevScreen, nextScreen, submitHandler, formData)}
      </MDBox>
    </DashboardLayout>
  );
}
export default AddBonusAsset;
