import SportsService from "services/SportsService";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = [];

export const retriveSports = createAsyncThunk("/sport/retrive", async () => {
  const res = await SportsService.retrive(0, 999, "");
  return res.data?.data;
});

export const createSport = createAsyncThunk("/sport/create", async (data) => {
  const res = await SportsService.create(data);
  return res.data;
});

export const updateSport = createAsyncThunk("/sport/update", async ({ id, data }) => {
  const res = await SportsService.update(id, data);
  return res.data;
});

export const deleteSport = createAsyncThunk("/sport/delete", async (id) => {
  await SportsService.remove(id);
  return { id };
});

const sportSlice = createSlice({
  name: "sport",
  initialState,
  extraReducers: {
    [createSport.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
    [retriveSports.fulfilled]: (state, action) => [...action.payload],
    [updateSport.fulfilled]: (state, action) => {
      const index = state.findIndex((sport) => sport.id === action.payload.id);
      state[index] = {
        ...state[index],
        ...action.payload,
      };
    },
    [deleteSport.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id);
      state.splice(index, 1);
    },
  },
});

const { reducer } = sportSlice;

export default reducer;
