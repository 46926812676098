import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Button, Divider, Grid } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import { pageHeaderContainer, customFormContainer, flexContainer } from "layouts/common/styles";

import FormField from "components/FormField";
import { validateFileSize } from "utils/validation";
import ToastrService from "services/ToastrService";

function TeamTierForm({ item, initialState, prevHandler, nextHandler, changeHandler }) {
  const resetState = {
    tokenSaleToFan: 0,
    tokenSaleToSponsors: 0,
    price: 0,
    tokenSymbol: "",
    kudosToTire1: 0,
    kudosToTire2: 0,
    kudosToTire3: 0,
    kudosToTire4: 0,
    memberLevelName1: "",
    memberLevelName2: "",
    memberLevelName3: "",
    memberLevelName4: "",
    players: [],
  };

  const [team, setTeam] = useState(resetState);
  const [validationMsg, setValidationMsg] = useState(resetState);

  useEffect(() => {
    if (item) {
      const newItem = { ...item };
      setTeam(newItem);
    }
  }, [item]);

  const handleSubmitChange = (event) => {
    const { name, value } = event.target;
    setTeam({ ...team, [name]: value });
  };

  const handleSubmitChangeNumber = (field, value) => {
    setTeam({ ...team, [field]: parseFloat(value) });
  };

  const validate = () => {
    let validated = true;
    let errorMsg = validationMsg;
    const keys = Object.keys(resetState);
    for (let i = 0; i < keys.length; i += 1) {
      let msg = "";
      if (team[keys[i]] === "" && keys[i] !== "id") {
        msg = "Field should not be left empty";
        validated = false;
      }
      errorMsg = { ...errorMsg, [keys[i]]: msg };
    }
    setValidationMsg(errorMsg);

    if (team.players && team.players.length > 0) {
      for (let i = 0; i < team.players.length; i += 1) {
        if (team.players[i].file && !validateFileSize(team.players[i].file.size)) {
          ToastrService.warning(`Avatar File Size of Player${i + 1} should be less than 2MB`);
          validated = false;
          break;
        }
      }
    }

    return validated;
  };

  const handleAddPlayer = () => {
    const temp = [...team.players, { avatar: "", name: "", file: null }];
    setTeam({ ...team, players: temp });
  };

  const handleRemovePlayer = () => {
    const temp = [...team.players];
    temp.pop();
    setTeam({ ...team, players: temp });
  };

  const handlePlayerFilePathChange = (event, idx) => {
    const [file] = event.target.files;
    const temp = [...team.players];
    temp[idx].avatar = URL.createObjectURL(file);
    temp[idx].file = file;
    setTeam({ ...team, players: temp });
    event.target.value = null;
  };

  const handleChangePlayerName = (value, idx) => {
    const temp = [...team.players];
    temp[idx].name = value;
    setTeam({ ...team, players: temp });
  };

  const handleNext = () => {
    if (!validate()) return;

    if (changeHandler(team)) {
      nextHandler();
    }
  };

  const handleReset = () => {
    let temp = { ...team };
    const keys = Object.keys(resetState);
    for (let i = 0; i < keys.length; i += 1) {
      temp = { ...temp, [keys[i]]: initialState[keys[i]] };
    }
    setTeam(temp);
    setValidationMsg(resetState);
  };

  const handleBack = () => {
    prevHandler();
  };

  return (
    <MDBox my={3}>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "100%" }}>
          {team?.id ? "Edit Team" : "Add Team"} - Tokens | Tier levels
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handleBack()}>
            Back
          </MDButton>
          <MDButton size="small" onClick={() => handleReset()}>
            Reset
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox>
        <MDBox sx={(theme) => customFormContainer(theme)}>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Token Details
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">Token sale to fan (commission%)</MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormField
                type="number"
                name="tokenSaleToFan"
                value={team.tokenSaleToFan}
                onChange={(e) => {
                  handleSubmitChangeNumber("tokenSaleToFan", e.target.value);
                }}
                error={validationMsg.tokenSaleToFan !== "" && validationMsg.tokenSaleToFan !== 0}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">Token sale to sponsors (commission%)</MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormField
                type="number"
                name="tokenSaleToSponsors"
                value={team.tokenSaleToSponsors}
                onChange={(e) => {
                  handleSubmitChangeNumber("tokenSaleToSponsors", e.target.value);
                }}
                error={
                  validationMsg.tokenSaleToSponsors !== "" &&
                  validationMsg.tokenSaleToSponsors !== 0
                }
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">Price per token($AUD)</MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormField
                type="number"
                name="price"
                value={team.price}
                onChange={(e) => {
                  handleSubmitChangeNumber("price", e.target.value);
                }}
                error={validationMsg.price !== "" && validationMsg.price !== 0}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">Token name</MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormField
                name="tokenSymbol"
                value={team.tokenSymbol}
                onChange={handleSubmitChange}
                error={validationMsg.tokenSymbol !== ""}
              />
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Membership Tier Details
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">Kudos required to hit Tier 1</MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormField
                type="number"
                name="kudosToTire1"
                value={team.kudosToTire1}
                onChange={(e) => {
                  handleSubmitChangeNumber("kudosToTire1", e.target.value);
                }}
                error={validationMsg.kudosToTire1 !== "" && validationMsg.kudosToTire1 !== 0}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">Kudos required to hit Tier 2</MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormField
                type="number"
                name="kudosToTire2"
                value={team.kudosToTire2}
                onChange={(e) => {
                  handleSubmitChangeNumber("kudosToTire2", e.target.value);
                }}
                error={validationMsg.kudosToTire2 !== "" && validationMsg.kudosToTire2 !== 0}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">Kudos required to hit Tier 3</MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormField
                type="number"
                name="kudosToTire3"
                value={team.kudosToTire3}
                onChange={(e) => {
                  handleSubmitChangeNumber("kudosToTire3", e.target.value);
                }}
                error={validationMsg.kudosToTire3 !== "" && validationMsg.kudosToTire3 !== 0}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">Kudos required to hit Tier 4</MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormField
                type="number"
                name="kudosToTire4"
                value={team.kudosToTire4}
                onChange={(e) => {
                  handleSubmitChangeNumber("kudosToTire4", e.target.value);
                }}
                error={validationMsg.kudosToTire4 !== "" && validationMsg.kudosToTire4 !== 0}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">Member tier level 1 (Name)</MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormField
                name="memberLevelName1"
                value={team.memberLevelName1}
                onChange={handleSubmitChange}
                error={validationMsg.memberLevelName1 !== ""}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">Member tier level 2 (Name)</MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormField
                name="memberLevelName2"
                value={team.memberLevelName2}
                onChange={handleSubmitChange}
                error={validationMsg.memberLevelName2 !== ""}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">Member tier level 3 (Name)</MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormField
                name="memberLevelName3"
                value={team.memberLevelName3}
                onChange={handleSubmitChange}
                error={validationMsg.memberLevelName3 !== ""}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">Member tier level 4 (Name)</MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormField
                name="memberLevelName4"
                value={team.memberLevelName4}
                onChange={handleSubmitChange}
                error={validationMsg.memberLevelName4 !== ""}
              />
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Player Details
          </MDTypography>
          {team.players?.map((player, idx) => (
            <Grid container spacing={2} key={`team-player-${idx.toString()}`} mb={2}>
              <Grid item xs={12} sm={3}>
                <MDTypography fontSize="15px">Player {idx + 1}</MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  name={`playerName${idx}`}
                  value={player.name}
                  onChange={(e) => handleChangePlayerName(e.target.value, idx)}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <MDBox mb={2}>
                  <Button
                    variant="contained"
                    component="label"
                    color="white"
                    sx={{ marginRight: "20px" }}
                  >
                    {player.avatar === "" || player.avatar === null
                      ? `Upload Image`
                      : "Change Image"}
                    <input
                      type="file"
                      hidden
                      name="option"
                      onChange={(e) => {
                        handlePlayerFilePathChange(e, idx);
                      }}
                      accept=".jpg,.jpeg,.png"
                    />
                  </Button>
                </MDBox>
                {player.avatar && player.avatar !== "" && (
                  <MDAvatar
                    src={player.avatar}
                    alt="Avatar"
                    sx={{ width: "100px", height: "100px" }}
                  />
                )}
              </Grid>
            </Grid>
          ))}
          <Grid
            item
            xs={12}
            sm={12}
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="10px"
          >
            <MDButton size="small" color="secondary" onClick={handleAddPlayer}>
              Player +
            </MDButton>
            {team.players?.length > 0 && (
              <MDButton size="small" color="secondary" onClick={handleRemovePlayer}>
                Player -
              </MDButton>
            )}
          </Grid>
        </MDBox>
        <Grid container justifyContent="flex-end">
          <MDButton color="secondary" size="large" onClick={handleNext}>
            Next
          </MDButton>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

TeamTierForm.defaultProps = {
  item: null,
  initialState: null,
  nextHandler: {},
  prevHandler: {},
  changeHandler: {},
};

TeamTierForm.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  initialState: PropTypes.objectOf(PropTypes.any),
  nextHandler: PropTypes.func,
  prevHandler: PropTypes.func,
  changeHandler: PropTypes.func,
};

export default TeamTierForm;
