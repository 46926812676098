import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import MatchesService from "services/MatchService";
import ToastrService from "services/ToastrService";

import { setLoading } from "store/appSlice";
import { createMatch, updateMatch } from "store/slices/matchSlice";
import { UserRole } from "layouts/common/constants";

import UploadService from "services/UploadService";
import EventDetail from "./forms/EventDetail";
import EventReview from "./forms/EventReview";

const formSwitcher = (screen, prevHandler, nextHandler, handleChange, handleSave, newEvent) => {
  switch (screen) {
    case 1:
      return <EventDetail item={newEvent} nextHandler={nextHandler} changeHandler={handleChange} />;
    case 2:
      return <EventReview item={newEvent} prevHandler={prevHandler} publishHandler={handleSave} />;

    default:
      return <EventDetail item={newEvent} nextHandler={nextHandler} changeHandler={handleChange} />;
  }
};

function AddEvent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const adminProfile = useSelector((state) => state.users.profile);

  const [screen, setScreen] = useState(1);
  const [newEvent, setEvent] = useState(null);

  const getEvent = (_id) => {
    dispatch(setLoading(true));
    MatchesService.get(_id)
      .then((response) => {
        setEvent(response.data.match);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load match data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      getEvent(id);
    } else {
      setEvent(null);
    }
  }, [id]);

  const handleChange = (data) => {
    setEvent(data);
    return true;
  };

  const handleSave = async (isDraft) => {
    const newData = { ...newEvent };
    const dataId = newData.id;
    if (adminProfile.role === UserRole.TeamAdmin) {
      newData.teamId = adminProfile.teamId;
    } else {
      newData.teamId = newData.team.id;
    }
    newData.homeTeamId = newData.homeTeam.id;
    newData.awayTeamId = newData.awayTeam.id;
    newData.isDraft = isDraft;
    ["id", "homeTeam", "awayTeam", "team"].forEach((key) => {
      delete newData[key];
    });
    // const formData = new FormData();

    // // Appending each property of newData to the FormData object
    // Object.keys(newData).forEach((key) => {
    //   formData.append(key, newData[key]);
    // });

    let isExistTeamOneImage = "";
    if (newData?.teamOneImage) {
      if (typeof newData?.teamOneImage === "string") {
        isExistTeamOneImage = newData?.teamOneImage;
      } else {
        const fileFormData = new FormData();
        fileFormData.append("file", newData?.teamOneImage);

        await UploadService.upload("match", fileFormData)
          .then((res) => {
            isExistTeamOneImage = res.data.url;
          })
          .catch(() => {
            ToastrService.error("Failed to upload new team logo");
          });
      }
    }
    newData.teamOneImage = isExistTeamOneImage;
    // if (newData?.teamOneImage) {
    //   const fileFormData = new FormData();
    //   fileFormData.append("file", newData?.teamOneImage);

    //   await UploadService.upload("match", fileFormData)
    //     .then((res) => {
    //       newData.teamOneImage = res.data.url;
    //     })
    //     .catch(() => {
    //       ToastrService.error("Failed to upload new team logo");
    //     });
    // }
    let isImageTsExistTeamTwoImage = "";
    if (newData?.teamTwoImage) {
      if (typeof newData?.teamTwoImage === "string") {
        isImageTsExistTeamTwoImage = newData?.teamTwoImage;
      } else {
        const fileFormData = new FormData();
        fileFormData.append("file", newData?.teamTwoImage);

        await UploadService.upload("match", fileFormData)
          .then((res) => {
            isImageTsExistTeamTwoImage = res.data.url;
          })
          .catch(() => {
            ToastrService.error("Failed to upload new team logo");
          });
      }
    }
    newData.teamTwoImage = isImageTsExistTeamTwoImage;
    // if (newData?.teamTwoImage) {
    //   const fileFormData = new FormData();
    //   fileFormData.append("file", newData?.teamTwoImage);

    //   await UploadService.upload("match", fileFormData)
    //     .then((res) => {
    //       newData.teamTwoImage = res.data.url;
    //     })
    //     .catch(() => {
    //       ToastrService.error("Failed to upload new team logo");
    //     });
    // }

    let isImageTsExistRecommendedImage = "";
    if (newData?.recommendedImage) {
      if (typeof newData?.recommendedImage === "string") {
        isImageTsExistRecommendedImage = newData?.recommendedImage;
      } else {
        const fileFormData = new FormData();
        fileFormData.append("file", newData?.recommendedImage);

        await UploadService.upload("match", fileFormData)
          .then((res) => {
            isImageTsExistRecommendedImage = res.data.url;
          })
          .catch(() => {
            ToastrService.error("Failed to upload new team logo");
          });
      }
    }
    newData.recommendedImage = isImageTsExistRecommendedImage;
    // if (newData?.teamTwoImage) {
    //   const fileFormData = new FormData();
    //   fileFormData.append("file", newData?.recommendedImage);

    //   await UploadService.upload("match", fileFormData)
    //     .then((res) => {
    //       newData.recommendedImage = res.data.url;
    //     })
    //     .catch(() => {
    //       ToastrService.error("Failed to upload new team logo");
    //     });
    // }

    let isImageTsExist = "";
    if (newData?.gallaryImage) {
      if (typeof newData?.gallaryImage === "string") {
        isImageTsExist = newData?.gallaryImage;
      } else {
        const filesList = Object.values(newData?.gallaryImage);
        const uploadPromises = filesList.map(async (key) => {
          const fileFormData = new FormData();
          fileFormData.append("file", key);
          try {
            const res = await UploadService.upload("match", fileFormData);
            return res.data.url;
          } catch (error) {
            ToastrService.error("Failed to upload new team logo");
            return null; // or handle error accordingly
          }
        });
        const galleryImgs = await Promise.all(uploadPromises);
        isImageTsExist = galleryImgs.join(",");
      }
    }
    newData.gallaryImage = isImageTsExist;
    // if (newData?.gallaryImage) {

    //   const filesList = Object.values(newData?.gallaryImage);
    //   const uploadPromises = filesList.map(async (key) => {
    //     const fileFormData = new FormData();
    //     fileFormData.append("file", key);
    //     try {
    //       const res = await UploadService.upload("match", fileFormData);
    //       return res.data.url;
    //     } catch (error) {
    //       ToastrService.error("Failed to upload new team logo");
    //       return null; // or handle error accordingly
    //     }
    //   });
    //   const galleryImgs = await Promise.all(uploadPromises);
    //   newData.gallaryImage = galleryImgs.join(",");
    // }
    if (dataId === null) {
      dispatch(createMatch(newData))
        .unwrap()
        .then((data) => {
          ToastrService.success("Successfully added new Match");
          navigate(`/match/view/${data.id}`);
        })
        .catch((e) => {
          ToastrService.error(
            e.response?.data?.message?.message || "Failed to add new match data.",
          );
        });
    } else {
      ["createdAt", "updatedAt"].forEach((key) => {
        delete newData[key];
      });
      dispatch(updateMatch({ id: dataId, data: newData }))
        .unwrap()
        .then(() => {
          ToastrService.success("Successfully updated Match");
          navigate(`/match/view/${dataId}`);
        })
        .catch((e) => {
          ToastrService.error(e.response?.data?.message?.message || "Failed to update match data");
        });
    }
  };

  const nextScreen = () => {
    setScreen(screen + 1);
  };

  const prevScreen = () => {
    setScreen(screen - 1);
  };
  return (
    <DashboardLayout>
      <MDBox my={3}>
        {formSwitcher(screen, prevScreen, nextScreen, handleChange, handleSave, newEvent)}
      </MDBox>
    </DashboardLayout>
  );
}

export default AddEvent;
