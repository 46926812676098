import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";

import { Checkbox, Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";
import { UserRole } from "layouts/common/constants";
import { dateToDateAndTime } from "utils/converter";

function MilestoneSummaryForm({ item, prevHandler, saveHandler }) {
  const adminProfile = useSelector((state) => state.users.profile);

  const [data, setData] = useState({
    anyWithVenue: false,
    onlyInVenue: false,
    enableAssetReward: false,
  });

  useEffect(() => {
    const temp = { ...item };

    const startDateTime = dateToDateAndTime(new Date(temp.start));
    const endDateTime = dateToDateAndTime(new Date(temp.end));

    temp.start = `${startDateTime.date}  ${startDateTime.time}`;
    temp.end = `${endDateTime.date}  ${endDateTime.time}`;

    setData(temp);
  }, [item]);

  const handlePrev = () => {
    prevHandler();
  };

  let debounceTimeout;

  const handleSave = useCallback(
    (isDraft = false) =>
      () => {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
          saveHandler(isDraft);
        }, 1000);
      },
    [saveHandler],
  );

  return (
    <MDBox>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "250px" }}>
          Milestone - Summary
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handlePrev()}>
            Back
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        {adminProfile.role === UserRole.SuperAdmin && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.team?.name}</MDTypography>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Match/Event
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{data?.match?.title}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Milestone Title
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{data?.title}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Milestone Description
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{data?.description}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Start Date & Time
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={8.5}>
            <MDTypography fontSize="15px">{data?.start}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Expiration Date & Time
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={8.5}>
            <MDTypography fontSize="15px">{data?.end}</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Eligibility
        </MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              No of Kudos Points
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <MDTypography fontSize="15px">{data?.eligbleKudos}</MDTypography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              No of Tokens
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <MDTypography fontSize="15px">{data?.eligbleToken}</MDTypography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              User must be checked in to match (in venue or out of venue)
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Checkbox disabled checked={data?.anyWithVenue} />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              User must be checked in to match (IN VENUE only)
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox disabled checked={data?.onlyInVenue} />
          </Grid>
        </Grid>
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Rewards
        </MDTypography>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
          For each time the event occurs
        </MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              No of Kudos Points(per player)
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <MDTypography fontSize="15px">{data?.rewardKudosPerPlayer}</MDTypography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              No of Tokens(per player)
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <MDTypography fontSize="15px">{data?.rewardTokenPerPlayer}</MDTypography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Send asset as reward?
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox disabled checked={data?.enableAssetReward} />
          </Grid>
          {data?.enableAssetReward && (
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                select asset
              </MDTypography>
            </Grid>
          )}
          {data?.enableAssetReward && (
            <Grid item xs={12} sm={4} md={5}>
              <MDTypography fontSize="15px">{data?.asset?.title}</MDTypography>
            </Grid>
          )}
        </Grid>
        {data?.enableAssetReward && (
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of Bonus Asset to send per winner
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{data?.rewardAssetCount}</MDTypography>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Maximum no of winners
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <MDTypography fontSize="15px">{data?.winnerLimit}</MDTypography>
          </Grid>
        </Grid>
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Sponsors
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Game Sponsor</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px">{data?.sponsor?.title}</MDTypography>
          </Grid>
        </Grid>
      </MDBox>

      <Grid container justifyContent="flex-end" gap={2} mt={6}>
        {data?.isDraft !== false && (
          <MDButton color="secondary" size="large" onClick={handleSave(true)}>
            Save as Draft
          </MDButton>
        )}
        <MDButton color="secondary" size="large" onClick={handleSave()}>
          Publish
        </MDButton>
      </Grid>
    </MDBox>
  );
}

MilestoneSummaryForm.defaultProps = {
  item: null,
  prevHandler: {},
  saveHandler: {},
};

MilestoneSummaryForm.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  prevHandler: PropTypes.func,
  saveHandler: PropTypes.func,
};

export default MilestoneSummaryForm;
