const colourTheme = {
  Light: {
    landingPageBackgroundColor: "#131418",
    landingPageBackgroundTopLayerColor: "#14264b",
    landingPageBackgroundBottomLayerColor: "#e41e2e",
    landingPageTextColor: "#ffffff",
    authInputBackgroundColor: "#ffffff",
    primaryTextColor: "#2f2f2f",
    headingTextColor: "#2f2f2f",
    menuTextColor: "#6B6B6B",
    secondaryTextColor: "#6B6B6B",
    primaryButtonColor: "#e41e2e",
    secondaryButtonColor: "#596169",
    modalBackgroundColor: "#ffffff",
    tabHighlightColor: "#e41e2e",
    buttonTextColor: "#ced2d6",
    tokenTextColor: "#e41e2e",
    kudosTextColor: "#feb201",
    kudosIconColor: "#feb201",
    dateTextColor: "#14264b",
    successColor: "#33be51",
    errorColor: "#ff0000",
    warningColor: "#feb201",
    iconColor: "#e41e2e",
    landingPageCardColor: "#14264b",
    mainPageCardColor: "#ffffff",
    mainPageBackgroundColor: "#f5f5f5",
    mainPageHeaderColor: "#ffffff",
    mainPageSidebarColor: "#ffffff",
    membershipTierOneColor: "#c6cfd9",
    membershipTierTwoColor: "#9eddff",
    membershipTierThreeColor: "#ee7fce",
    membershipTierFourColor: "#ffed93",
  },
  Dark: {
    landingPageBackgroundColor: "#14264b",
    landingPageBackgroundTopLayerColor: "#14264b",
    landingPageBackgroundBottomLayerColor: "#ba0c2f",
    landingPageTextColor: "#ffffff",
    authInputBackgroundColor: "#ffffff",
    primaryTextColor: "#ced2d6",
    headingTextColor: "#ced2d6",
    menuTextColor: "#9C9CA4",
    secondaryTextColor: "#9C9CA4",
    primaryButtonColor: "#ba0c2f",
    secondaryButtonColor: "#596169",
    modalBackgroundColor: "#ffffff",
    tabHighlightColor: "#ba0c2f",
    buttonTextColor: "#ced2d6",
    tokenTextColor: "#f998ab",
    kudosTextColor: "#feb201",
    kudosIconColor: "#feb201",
    dateTextColor: "#a3833f",
    successColor: "#5df891",
    errorColor: "#d42222",
    warningColor: "#feb201",
    iconColor: "#b39100",
    landingPageCardColor: "#a3833f",
    mainPageCardColor: "#ffffff",
    mainPageBackgroundColor: "#1a1e23",
    mainPageHeaderColor: "#ffffff",
    mainPageSidebarColor: "#ffffff",
    membershipTierOneColor: "#c6cfd9",
    membershipTierTwoColor: "#9eddff",
    membershipTierThreeColor: "#ee7fce",
    membershipTierFourColor: "#ffed93",
  },
};

export default colourTheme;
