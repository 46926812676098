import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { customFormContainer } from "layouts/common/styles";

function VirtualCurrency({ item }) {
  return (
    <MDBox>
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team Name
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.name}</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Token Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Token sale to fan (commission%)
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <MDTypography fontSize="15px">{item?.tokenSaleToFan}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Token sale to sponsors (commission%)
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <MDTypography fontSize="15px">{item?.tokenSaleToSponsors}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Price per token
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <MDTypography fontSize="15px">{item?.price}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Vault token balance
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <MDTypography fontSize="15px">{item?.vaultTokenBalance}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team token wallet balance
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <MDTypography fontSize="15px">{item?.teamWalletBalance}</MDTypography>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

VirtualCurrency.defaultProps = {
  item: null,
};

VirtualCurrency.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
};

export default VirtualCurrency;
