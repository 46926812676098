import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import MDButton from "components/MDButton";

import { customTableHeader } from "layouts/common/styles";

import { FaEye } from "react-icons/fa";

import { dateToDateAndTime } from "utils/converter";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontSize: "13px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "13px",
    fontWeight: "bold",
  },
}));

function UserActivityTable({ data }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const tempData = data.map((d) => {
      const temp = { ...d };
      const createdAtDateTime = dateToDateAndTime(new Date(d.createdAt));
      temp.createdAt = `${createdAtDateTime.date}  ${createdAtDateTime.time}`;
      return temp;
    });

    setItems(tempData);
  }, [data]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table" variant="">
        <TableHead sx={(theme) => customTableHeader(theme)}>
          <TableRow>
            <StyledTableCell align="left">Activity</StyledTableCell>
            <StyledTableCell align="left">Title</StyledTableCell>
            <StyledTableCell align="left">Kudos</StyledTableCell>
            <StyledTableCell align="left">Tokens</StyledTableCell>
            <StyledTableCell align="left">Date</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow key={row.id}>
              <StyledTableCell
                align="left"
                component="th"
                scope="row"
                sx={{ color: "#0076B2", cursor: "pointer" }}
              >
                {row.type === "AssetEligible" ? "Redeem Asset" : row.type}
              </StyledTableCell>
              <StyledTableCell align="left">{row.reason}</StyledTableCell>
              <StyledTableCell align="left">{row.kudosamount}</StyledTableCell>
              <StyledTableCell align="left">{row.token_amount}</StyledTableCell>
              <StyledTableCell align="left">{row.createdAt}</StyledTableCell>
              <StyledTableCell align="center">
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                >
                  <FaEye />
                </MDButton>
              </StyledTableCell>
            </TableRow>
          ))}
          {items.length === 0 && (
            <TableRow>
              <StyledTableCell colSpan={6} align="center" color="dark">
                There is no content.
              </StyledTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

UserActivityTable.defaultProps = {
  data: [],
};

UserActivityTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
};

export default UserActivityTable;
