import { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { styled } from "@mui/material/styles";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";
import { SponsorCategory, UserRole } from "layouts/common/constants";

import { createSponsor, updateSponsor } from "store/slices/sponsorSlice";
import { setLoading } from "store/appSlice";

import SponsorService from "services/SponsorService";
import ToastrService from "services/ToastrService";

import FormField from "../FormField";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .MuiOutlinedInput-root .MuiAutocomplete-input`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
    padding: 0,
    width: "35px",
  },
  [`& .MuiOutlinedInput-root`]: {
    padding: "0px",
    paddingLeft: "10px",
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

function AddSponsor() {
  const initialState = {
    id: null,
    team: null,
    category: "",
    title: "",
    description: "",
    logo: "",
  };

  const adminProfile = useSelector((state) => state.users.profile);
  const teams = useSelector((state) => state.teams);

  const [validationMsg, setValidationMsg] = useState(initialState);
  const [initialSponsorState, setInitialSponsorState] = useState(initialState);
  const [sponsor, setSponsor] = useState(initialState);
  const [logo, setLogo] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const fileRef = useRef();

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getSponsor = (_id) => {
    dispatch(setLoading(true));
    SponsorService.get(_id)
      .then((response) => {
        setInitialSponsorState(response.data);
        setSponsor(response.data);
        setLogo(response.data.logo);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load sponsor data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      getSponsor(id);
    }
  }, [id]);

  const validate = () => {
    let validated = true;
    let errorMsg = validationMsg;
    const keys = Object.keys(sponsor);
    for (let i = 0; i < keys.length; i += 1) {
      let msg = "";
      if (
        (sponsor[keys[i]] === "" || sponsor[keys[i]] === null) &&
        keys[i] !== "logo" &&
        keys[i] !== "id" &&
        keys[i] !== "team"
      ) {
        msg = "Field should not be left empty";
        validated = false;
      }
      if (
        (sponsor[keys[i]] === "" || sponsor[keys[i]] === null) &&
        keys[i] === "team" &&
        adminProfile.role === UserRole.SuperAdmin
      ) {
        msg = "Field should not be left empty";
        validated = false;
      }
      errorMsg = { ...errorMsg, [keys[i]]: msg };
    }
    setValidationMsg(errorMsg);
    return validated;
  };

  const handleSubmit = async () => {
    if (!validate()) return;

    const temp = { ...sponsor };
    if (adminProfile.role === UserRole.TeamAdmin) {
      temp.teamId = adminProfile.teamId;
    } else {
      temp.teamId = temp.team.id;
    }
    const dataId = temp.id;
    delete temp.id;

    if (logoFile !== null) {
      const formData = new FormData();
      formData.append("file", logoFile);
      await SponsorService.upload(formData)
        .then((res) => {
          temp.logo = res.data.url;
        })
        .catch(() => {
          ToastrService.error("Failed to upload new sponsor logo");
        });
    }

    dispatch(setLoading(true));
    if (dataId === null) {
      dispatch(createSponsor(temp))
        .unwrap()
        .then((data) => {
          ToastrService.success("Successfully added new sponsor");
          dispatch(setLoading(false));
          navigate(`/sponsor/view/${data.id}`);
        })
        .catch((e) => {
          ToastrService.error(e.response?.data?.message?.message || "Failed to create new sponsor");
          dispatch(setLoading(false));
        });
    } else {
      dispatch(updateSponsor({ id: dataId, data: temp }))
        .unwrap()
        .then((data) => {
          ToastrService.success("Successfully updated sponsor");
          dispatch(setLoading(false));
          navigate(`/sponsor/view/${data.id}`);
        })
        .catch((e) => {
          ToastrService.error(e.response?.data?.message?.message || "Failed to update sponsor");
          dispatch(setLoading(false));
        });
    }
  };

  let debounceTimeout;

  const handleSave = useCallback(() => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      handleSubmit();
    }, 1000);
  }, [handleSubmit]);

  const handleSubmitChange = (field, value) => {
    setSponsor({ ...sponsor, [field]: value });
  };

  const handleFilePathChange = (event) => {
    const [file] = event.target.files;
    setLogo(URL.createObjectURL(file));
    setLogoFile(file);
  };

  const handleRemoveImage = () => {
    setLogo(null);
    setLogoFile(null);
    fileRef.current.value = "";
  };

  const handleReset = () => {
    setSponsor(initialSponsorState);
    setValidationMsg(initialState);
    setLogo(initialSponsorState.logo);
    setLogoFile(null);
    fileRef.current.value = "";
  };

  const handleBack = () => {
    navigate("/sponsors");
  };

  return (
    <DashboardLayout>
      <MDBox py={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "150px" }}>
            Add Sponsor
          </MDTypography>
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDButton size="small" color="dark" onClick={() => handleBack()}>
              Back
            </MDButton>
            <MDButton size="small" color="dark" onClick={() => handleReset()}>
              Reset
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox sx={(theme) => customFormContainer(theme)}>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Details
          </MDTypography>
          {adminProfile.role === UserRole.SuperAdmin && (
            <Grid container spacing={2} mb={4} alignItems="center">
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px">Team</MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <StyledAutocomplete
                  options={teams}
                  value={sponsor.team}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, v) => handleSubmitChange("team", v)}
                  renderInput={(params) => (
                    <TextField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                  placeholder="Team"
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2} mb={4} alignItems="center">
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">Sponsor Category</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <StyledAutocomplete
                options={Object.values(SponsorCategory)}
                value={sponsor.category}
                onChange={(e, v) => handleSubmitChange("category", v)}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
                placeholder="category"
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">Sponsor title</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <FormField
                value={sponsor.title}
                onChange={(e) => {
                  handleSubmitChange("title", e.target.value);
                }}
                error={validationMsg.title !== ""}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">Sponsor Description</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <FormField
                value={sponsor.description}
                onChange={(e) => {
                  handleSubmitChange("description", e.target.value);
                }}
                error={validationMsg.description !== ""}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">Add Logo</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDBox mb={2}>
                <Button
                  variant="contained"
                  component="label"
                  color="white"
                  sx={{ marginRight: "20px" }}
                >
                  Upload Image
                  <input
                    ref={fileRef}
                    type="file"
                    hidden
                    name="logo"
                    onChange={handleFilePathChange}
                    accept=".jpg,.jpeg,.png"
                  />
                </Button>
                {logo !== null && logo !== "" && (
                  <Button
                    variant="contained"
                    component="label"
                    color="error"
                    onClick={() => handleRemoveImage()}
                  >
                    Remove Image
                  </Button>
                )}
              </MDBox>
              {logo !== null && logo !== "" && (
                <MDAvatar src={logo} alt="Avatar" sx={{ width: "250px", height: "250px" }} />
              )}
            </Grid>
          </Grid>
        </MDBox>
        <Grid container justifyContent="flex-end">
          <MDButton color="secondary" size="large" onClick={handleSave}>
            Submit
          </MDButton>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AddSponsor;
