// const {API_BASE_URL} = process.env;

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = "http://localhost:3005";
const GOOGLE_FONT_API_KEY = process.env.REACT_APP_GOOGLE_FONT_API_KEY;
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

module.exports = {
  API_BASE_URL,
  GOOGLE_FONT_API_KEY,
  ENVIRONMENT,
};
