import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import { Button, Divider, Grid, Autocomplete, TextField } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import { pageHeaderContainer, customFormContainer, flexContainer } from "layouts/common/styles";

import ToastrService from "services/ToastrService";

import { validateEmail, validateFileSize } from "utils/validation";
import FormField from "components/FormField";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .MuiOutlinedInput-root .MuiAutocomplete-input`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
    padding: 0,
    width: "35px",
  },
  [`& .MuiOutlinedInput-root`]: {
    padding: "0px",
    paddingLeft: "10px",
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

function TeamBasicForm({ item, initialState, nextHandler, changeHandler }) {
  const resetState = {
    name: "",
    description: "",
    logo: "",
    logoFile: null,
    platformName: "",
    platformUrl: "",
    sport: null,
    adminFirstName: "",
    adminLastName: "",
    adminEmail: "",
  };

  const teams = useSelector((state) => state.teams);
  const teamSports = useSelector((state) => state.sports);
  const [team, setTeam] = useState(resetState);
  const [validationMsg, setValidationMsg] = useState(resetState);

  const navigate = useNavigate();

  useEffect(() => {
    if (item) {
      if (item.id) {
        const newItem = {
          ...item,
          sport: teamSports.find((sport) => sport.id === item.sportId) ?? null,
        };
        setTeam(newItem);
      } else {
        setTeam(item);
      }
    }
  }, [item]);

  const handleSubmitChange = (event) => {
    const { name, value } = event.target;
    if (name === "platformUrl") {
      setTeam({ ...team, [name]: value.toLowerCase() });
    } else {
      setTeam({ ...team, [name]: value });
    }
  };

  const handleChange = (field, value) => {
    setTeam({ ...team, [field]: value });
  };

  const handleFilePathChange = (event) => {
    const [file] = event.target.files;
    setTeam({ ...team, logo: URL.createObjectURL(file), logoFile: file });
  };

  const handleRemoveImage = () => {
    setTeam({ ...team, logo: "", logoFile: null });
  };

  const validate = () => {
    let validated = true;
    let errorMsg = validationMsg;
    const keys = Object.keys(resetState);
    for (let i = 0; i < keys.length; i += 1) {
      let msg = "";
      if ((!team[keys[i]] || team[keys[i]] === "") && keys[i] !== "id" && keys[i] !== "logoFile") {
        msg = "Field should not be left empty";
        validated = false;
      }
      if (keys[i] === "adminEmail" && !validateEmail(team.adminEmail)) {
        msg = "Email is invalid";
        validated = false;
      }
      if (keys[i] === "logo" && team.logoFile && !validateFileSize(team.logoFile.size)) {
        ToastrService.warning("Logo file size should be less than 1MB");
        msg = "File size should be less than 2MB";
        validated = false;
      }
      errorMsg = { ...errorMsg, [keys[i]]: msg };
    }
    setValidationMsg(errorMsg);

    return validated;
  };

  const handleNext = () => {
    if (!validate()) return;

    const data = teams.filter((t) => t.platformUrl === team.platformUrl);

    if (data.length === 0 || (data.length === 1 && data[0].id === team.id) || !team.platformUrl) {
      if (changeHandler(team)) {
        nextHandler();
      }
    } else {
      ToastrService.warning("Warning!: this platform url already exists");
    }
  };

  const handleReset = () => {
    let temp = { ...team };
    const keys = Object.keys(resetState);
    for (let i = 0; i < keys.length; i += 1) {
      temp = { ...temp, [keys[i]]: initialState[keys[i]] };
    }
    setTeam(temp);
    setValidationMsg(resetState);
  };

  const handleBack = () => {
    navigate("/dashboards/teams");
  };

  return (
    <MDBox my={3}>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "150px" }}>
          {team?.id ? "Edit Team" : "Add Team"}
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handleBack()}>
            Back
          </MDButton>
          <MDButton size="small" onClick={() => handleReset()}>
            Reset
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox>
        <MDBox sx={(theme) => customFormContainer(theme)}>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Team Details
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Team Name</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormField
                name="name"
                value={team.name}
                onChange={handleSubmitChange}
                error={validationMsg.name !== ""}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Team Description</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormField
                name="description"
                value={team.description}
                onChange={handleSubmitChange}
                error={validationMsg.description !== ""}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Team Logo</MDTypography>
              <MDTypography fontSize="12px">(Recommended - 100 × 100px | Max 1MB)</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <MDBox mb={2}>
                <Button
                  variant="contained"
                  component="label"
                  color="white"
                  sx={{ marginRight: "20px" }}
                >
                  Upload Image
                  <input
                    type="file"
                    hidden
                    name="logo"
                    onChange={handleFilePathChange}
                    accept=".jpg,.jpeg,.png"
                  />
                </Button>
                {team.logo !== null && team.logo !== "" && (
                  <Button
                    variant="contained"
                    component="label"
                    color="error"
                    onClick={() => handleRemoveImage()}
                  >
                    Remove Image
                  </Button>
                )}
              </MDBox>
              {team.logo !== null && team.logo !== "" && (
                <MDAvatar src={team.logo} alt="Avatar" sx={{ width: "250px", height: "250px" }} />
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Platform Name</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormField
                name="platformName"
                value={team.platformName}
                onChange={handleSubmitChange}
                error={validationMsg.platformName !== ""}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Platform url</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              {!team.id ? (
                <FormField
                  name="platformUrl"
                  value={team.platformUrl}
                  onChange={handleSubmitChange}
                  error={validationMsg.platformUrl !== ""}
                />
              ) : (
                <MDTypography fontSize="15px">{team.platformUrl}</MDTypography>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Team Sport</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledAutocomplete
                options={teamSports}
                value={team.sport}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => handleChange("sport", v)}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
                placeholder="Type"
                error={!validationMsg.sport}
              />
            </Grid>
          </Grid>

          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Admin Details
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">First Name</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormField
                disabled={!!team.id}
                name="adminFirstName"
                value={team.adminFirstName}
                onChange={handleSubmitChange}
                error={validationMsg.adminFirstName !== ""}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Last Name</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormField
                disabled={!!team.id}
                name="adminLastName"
                value={team.adminLastName}
                onChange={handleSubmitChange}
                error={validationMsg.adminLastName !== ""}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Email ID</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormField
                disabled={!!team.id}
                name="adminEmail"
                value={team.adminEmail}
                onChange={handleSubmitChange}
                error={validationMsg.adminEmail !== ""}
              />
            </Grid>
          </Grid>
        </MDBox>
        <Grid container justifyContent="flex-end">
          <MDButton color="secondary" size="large" onClick={handleNext}>
            Next
          </MDButton>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

TeamBasicForm.defaultProps = {
  item: null,
  initialState: null,
  nextHandler: {},
  changeHandler: {},
};

TeamBasicForm.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  initialState: PropTypes.objectOf(PropTypes.any),
  nextHandler: PropTypes.func,
  changeHandler: PropTypes.func,
};

export default TeamBasicForm;
