import { ActivationTabs } from "layouts/common/constants";
import SponsorService from "services/SponsorService";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  totalCount: 0,
  sponsors: [],
  activationCount: 0,
  filter: ActivationTabs.Ongoing,
  activations: [],
};

export const retriveSponsors = createAsyncThunk(
  "/sponsor/retrive",
  async ({ search, page, rowsPerPage }) => {
    const res = await SponsorService.retrive(search, page, rowsPerPage);
    return res.data;
  },
);

export const retriveSponsorActivations = createAsyncThunk(
  "/sponsor/retriveActivations",
  async ({ id, filter, page, rowsPerPage }) => {
    const res = await SponsorService.retriveActivations(id, filter, page, rowsPerPage);
    return res.data;
  },
);

export const createSponsor = createAsyncThunk("/sponsor/create", async (data) => {
  const res = await SponsorService.create(data);
  return res.data;
});

export const updateSponsor = createAsyncThunk("/sponsor/update", async ({ id, data }) => {
  const res = await SponsorService.update(id, data);
  return res.data;
});

export const deleteSponsor = createAsyncThunk("/sponsor/delete", async (id) => {
  await SponsorService.remove(id);
  return { id };
});

export const deleteActivation = createAsyncThunk(
  "/sponsor/deleteActivation",
  async ({ type, id }) => {
    await SponsorService.removeActivation(type, id);
    return { id };
  },
);

const sponsorSlice = createSlice({
  name: "sponsor",
  initialState,
  extraReducers: {
    [retriveSponsors.fulfilled]: (state, action) => {
      state.totalCount = action.payload.count;
      state.sponsors = [...action.payload.data];
    },
    [retriveSponsorActivations.fulfilled]: (state, action) => {
      state.activationCount = action.payload.count;
      state.activations = [...action.payload.data];
    },
    [updateSponsor.fulfilled]: (state, action) => {
      const index = state.sponsors.findIndex((sponsor) => sponsor.id === action.payload.id);
      state.sponsors[index] = {
        ...state.sponsors[index],
        ...action.payload,
      };
    },
    [deleteSponsor.fulfilled]: (state, action) => {
      const index = state.sponsors.findIndex(({ id }) => id === action.payload.id);
      state.sponsors.splice(index, 1);
      state.totalCount -= 1;
    },
    [deleteActivation.fulfilled]: (state, action) => {
      const index = state.activations.findIndex(({ id }) => id === action.payload.id);
      state.activations.splice(index, 1);
      state.activationCount -= 1;
    },
  },
  reducers: {
    setFilterSponsorActivation: (state, action) => {
      state.filter = action.payload;
    },
  },
});

export const { setFilterSponsorActivation } = sponsorSlice.actions;

const { reducer } = sponsorSlice;
export default reducer;
