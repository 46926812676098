import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";

import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";
import { SponsorActivationTypes } from "layouts/common/constants";

import ToastrService from "services/ToastrService";
import SponsorService from "services/SponsorService";

import { setLoading } from "store/appSlice";

function ViewSponsor() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [item, setItem] = useState(null);
  const [activations, setActivations] = useState([]);
  const [overview, setOverview] = useState({
    users: 0,
    games: 0,
    surveys: 0,
    challenges: 0,
    polls: 0,
    merchandise: 0,
    collectibles: 0,
    gameitems: 0,
  });

  const getSponsor = (_id) => {
    SponsorService.get(_id)
      .then((response) => {
        setItem(response.data);
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load sponsor data.");
      });
  };

  const getActivations = (_id) => {
    dispatch(setLoading(true));
    SponsorService.retriveAllActivations(_id)
      .then((response) => {
        setActivations(response.data.data);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(
          e.response?.data?.message?.message || "Failed to load sponsor activations.",
        );
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      getSponsor(id);
      getActivations(id);
    }
  }, [id]);

  useEffect(() => {
    const gameCount = activations.filter(
      (d) =>
        d.type === SponsorActivationTypes.Trivia ||
        d.type === SponsorActivationTypes.Prediction ||
        d.type === SponsorActivationTypes.Milestone,
    ).length;
    const surveyCount = activations.filter((d) => d.type === SponsorActivationTypes.Survey).length;
    const challengeCount = activations.filter(
      (d) => d.type === SponsorActivationTypes.CheckIn || d.type === SponsorActivationTypes.Survey,
    ).length;
    const pollCount = activations.filter((d) => d.type === SponsorActivationTypes.Poll).length;
    setOverview((prevState) => ({
      ...prevState,
      games: gameCount,
      surveys: surveyCount,
      challenges: challengeCount,
      polls: pollCount,
    }));
  }, [activations]);

  const handleActivation = () => {
    navigate(`/sponsor/activation/${id}`);
  };

  const handleBack = () => {
    navigate("/sponsors");
  };

  return (
    <DashboardLayout>
      <MDBox py={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "250px" }}>
            Sponsor | {item?.title}
          </MDTypography>
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDButton size="small" color="dark" onClick={() => handleBack()}>
              Back
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox sx={(theme) => customFormContainer(theme)}>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Details
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Sponsor
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Sponsor Category
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.category}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Sponsor Logo
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">
                {item?.logo !== null && item?.logo !== "" && (
                  <MDAvatar
                    src={item?.logo}
                    alt="Avatar"
                    sx={{ width: "250px", height: "250px" }}
                  />
                )}
              </MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Tokens
          </MDTypography>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Token Balance
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography fontSize="15px">
                {item?.team?.teamWalletBalance > 0 ? item?.team?.teamWalletBalance : 0}
              </MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Users
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={4} md={3}>
              <DefaultStatisticsCard
                title="Users"
                count={overview.users}
                dropdown={{
                  value: "See all",
                }}
              />
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            onClick={() => handleActivation()}
            mb={3}
            sx={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Activations
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={4} md={3}>
              <DefaultStatisticsCard
                title="Games"
                count={overview.games}
                dropdown={{
                  value: "See all",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <DefaultStatisticsCard
                title="Surveys"
                count={overview.surveys}
                dropdown={{
                  value: "See all",
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={4} md={3}>
              <DefaultStatisticsCard
                title="Challenges"
                count={overview.challenges}
                dropdown={{
                  value: "See all",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <DefaultStatisticsCard
                title="Polls"
                count={overview.polls}
                dropdown={{
                  value: "See all",
                }}
              />
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Assets
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={4} md={3}>
              <DefaultStatisticsCard
                title="Merchandise"
                count={overview.merchandise}
                dropdown={{
                  value: "See all",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <DefaultStatisticsCard
                title="Collectibles"
                count={overview.collectibles}
                dropdown={{
                  value: "See all",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <DefaultStatisticsCard
                title="In game items"
                count={overview.gameitems}
                dropdown={{
                  value: "See all",
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}
export default ViewSponsor;
