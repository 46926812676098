import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MDBox from "components/MDBox";

import ToastrService from "services/ToastrService";
import { createNotification } from "store/slices/notificationSlice";

import { setLoading } from "store/appSlice";
import { UserRole } from "layouts/common/constants";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import NotificationDetail from "./forms/NotificationDetail";
import NotificationSummary from "./forms/NotificationSummary";

const formSwitcher = (
  screen,
  prevHandler,
  nextHandler,
  handleSave,
  handleChange,
  newNotification,
) => {
  switch (screen) {
    case 1:
      return (
        <NotificationDetail
          item={newNotification}
          nextHandler={nextHandler}
          changeHandler={handleChange}
        />
      );
    case 2:
      return (
        <NotificationSummary
          item={newNotification}
          prevHandler={prevHandler}
          saveHandler={handleSave}
        />
      );
    default:
      return (
        <NotificationDetail
          item={newNotification}
          nextHandler={nextHandler}
          changeHandler={handleChange}
        />
      );
  }
};

function AddNotification() {
  const adminProfile = useSelector((state) => state.users.profile);

  const [screen, setScreen] = useState(1);
  const [newNotification, setNewNotification] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (data) => {
    setNewNotification(data);
    return true;
  };

  const handleSave = () => {
    const newData = { ...newNotification };

    if (adminProfile.role === UserRole.TeamAdmin) {
      newData.teamId = adminProfile.teamId;
    } else {
      newData.teamId = newData.team.id;
    }

    ["id", "team", "enableLinkToPage"].forEach((key) => {
      delete newData[key];
    });

    newData.linkPage = newData.linkPage?.value || "";
    newData.criteria = newData.criteria?.value;

    newData.criteriaValue = newData.criteriaValue?.title || newData.criteriaValue;

    // temporary
    newData.isDraft = false;

    dispatch(createNotification({ data: newData }))
      .unwrap()
      .then((data) => {
        ToastrService.success("Successfully add new notification");
        dispatch(setLoading(false));
        navigate(`/notification/view/${data.id}`);
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to add new notificaiton");
        dispatch(setLoading(false));
      });
  };

  const nextScreen = () => {
    setScreen(screen + 1);
  };

  const prevScreen = () => {
    setScreen(screen - 1);
  };

  return (
    <DashboardLayout>
      <MDBox my={3}>
        {formSwitcher(screen, prevScreen, nextScreen, handleSave, handleChange, newNotification)}
      </MDBox>
    </DashboardLayout>
  );
}

export default AddNotification;
