import { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { pageHeaderContainer, flexContainer } from "layouts/common/styles";

import FormField from "components/FormField";

import UserService from "services/UserService";
import ToastrService from "services/ToastrService";

import { setLoading } from "store/appSlice";

function ResetPassword({ item, backHandler }) {
  const dispatch = useDispatch();
  const [userDetail, setUserDetail] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleSubmitChange = (field, value) => {
    setUserDetail({ ...userDetail, [field]: value });
  };

  const handleResetPassword = () => {
    if (
      userDetail.oldPassword === "" ||
      userDetail.newPassword === "" ||
      userDetail.confirmPassword === ""
    ) {
      ToastrService.warning("Please fill out all fields");
      return;
    }
    if (userDetail.newPassword !== userDetail.confirmPassword) {
      ToastrService.warning("Passwords do not match");
      return;
    }

    const tmp = { ...userDetail };
    delete tmp.confirmPassword;

    dispatch(setLoading(true));
    UserService.resetPassword(tmp)
      .then(() => {
        ToastrService.success("Successfully changed password");
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to reset password");
        dispatch(setLoading(false));
      });
  };

  return (
    <MDBox my={3}>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ maxWidth: "500px", width: "100%" }}>
          Admin Member/Update
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={backHandler}>
            Back
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={3}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Team Name
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <MDTypography fontSize="15px">{item?.name}</MDTypography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Name
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <MDTypography fontSize="15px">
            {item?.adminFirstName} {item?.adminLastName}
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Email ID
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <MDTypography fontSize="15px">{item?.adminEmail}</MDTypography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={3}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Current Password
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormField
            value={userDetail.oldPassword}
            onChange={(e) => handleSubmitChange("oldPassword", e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={3}>
          <MDTypography fontSize="15px" fontWeight="bold">
            New Passord
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormField
            value={userDetail.newPassword}
            onChange={(e) => handleSubmitChange("newPassword", e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={3}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Confirm Password
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormField
            value={userDetail.confirmPassword}
            onChange={(e) => handleSubmitChange("confirmPassword", e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" gap={2}>
        <MDButton color="secondary" size="large" onClick={handleResetPassword}>
          Submit
        </MDButton>
      </Grid>
    </MDBox>
  );
}

ResetPassword.defaultProps = {
  item: null,
  backHandler: {},
};

ResetPassword.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  backHandler: PropTypes.func,
};

export default ResetPassword;
