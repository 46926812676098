import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { pageHeaderContainer, flexContainer, tabButton } from "layouts/common/styles";
import { PollTabs, UserRole } from "layouts/common/constants";

import PollsTable from "./components/PollsTable";
import { retrivePolls, setFilter, deletePoll } from "../../store/slices/pollSlice";
import ToastrService from "../../services/ToastrService";

function Polls() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminProfile = useSelector((state) => state.users.profile);

  const totalCount = useSelector((state) => state.polls.totalCount);
  const polls = useSelector((state) => state.polls.polls);
  const filter = useSelector((state) => state.polls.filter);
  const teams = useSelector((state) => state.teams);

  // Search options.
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [sortFilter, setSortFilter] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const fetchBySearchOptions = useCallback(() => {
    let search = "";
    if (adminProfile.role === UserRole.SuperAdmin && selectedTeams.length > 0) {
      search = selectedTeams.map((d) => d.id).join(",");
    } else if (adminProfile.role === UserRole.TeamAdmin) {
      search = adminProfile.teamId;
    }
    dispatch(retrivePolls({ search, filter, page, rowsPerPage, sortFilter, sortDirection }));
  }, [dispatch, filter, selectedTeams, page, rowsPerPage, sortFilter, sortDirection, adminProfile]);

  useEffect(() => {
    fetchBySearchOptions();
  }, [fetchBySearchOptions]);

  const sortHandler = (sort, direction) => {
    setSortFilter(sort);
    setSortDirection(direction);
  };

  const deleteHandler = (_id) => {
    dispatch(deletePoll({ id: _id }))
      .unwrap()
      .then(() => {
        ToastrService.success("Successfully deleted poll");
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to delete poll data.");
      });
  };

  const handleAdd = () => {
    navigate("/polls/add");
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };

  const changePageNum = (direct) => {
    const maxPageNum = Math.floor((totalCount - 1) / rowsPerPage);
    if (page === 0 && direct === -1) return;
    if (page === maxPageNum && direct === 1) return;

    setPage((prevState) => prevState + direct);
  };

  const changeTab = (tab) => {
    dispatch(setFilter(tab));
  };

  return (
    <DashboardLayout>
      <MDBox py={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold">
            Polls
          </MDTypography>
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDButton size="small" color="secondary" onClick={handleAdd}>
              Poll +
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox mb={1} sx={(theme) => pageHeaderContainer(theme)}>
          {adminProfile.role === UserRole.SuperAdmin && (
            <MDBox sx={(theme) => flexContainer(theme)}>
              <MDTypography fontSize="12px" fontWeight="bold">
                Select Teams:{" "}
              </MDTypography>
              <Autocomplete
                multiple
                value={selectedTeams}
                options={teams}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => setSelectedTeams(v)}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
                sx={{ maxWidth: "568px", width: "100%" }}
                placeholder="Select Teams"
              />
            </MDBox>
          )}
          <MDBox py={0.5} sx={{ width: "250px", textAlign: "center", border: "1px solid #d2d6da" }}>
            <MDTypography fontSize="13px" fontWeight="bold">
              Total Poll Count = {totalCount}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox>
          <Grid container spacing={2} mt={3} mb={3}>
            <Grid item xs={12} md={3}>
              <MDButton
                size="large"
                color={filter === PollTabs.Upcoming ? "secondary" : "white"}
                sx={(theme) => tabButton(theme)}
                onClick={() => changeTab(PollTabs.Upcoming)}
              >
                Upcoming
              </MDButton>
            </Grid>
            <Grid item xs={12} md={3}>
              <MDButton
                size="large"
                color={filter === PollTabs.Ongoing ? "secondary" : "white"}
                sx={(theme) => tabButton(theme)}
                onClick={() => changeTab(PollTabs.Ongoing)}
              >
                Ongoing
              </MDButton>
            </Grid>
            <Grid item xs={12} md={3}>
              <MDButton
                size="large"
                color={filter === PollTabs.Past ? "secondary" : "white"}
                sx={(theme) => tabButton(theme)}
                onClick={() => changeTab(PollTabs.Past)}
              >
                Past
              </MDButton>
            </Grid>
            <Grid item xs={12} md={3}>
              <MDButton
                size="large"
                color={filter === PollTabs.Draft ? "secondary" : "white"}
                sx={(theme) => tabButton(theme)}
                onClick={() => changeTab(PollTabs.Draft)}
              >
                Draft
              </MDButton>
            </Grid>
          </Grid>
          <PollsTable data={polls} deleteHandler={deleteHandler} sortHandler={sortHandler} />
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end" my={3}>
            <FormControl>
              <InputLabel id="rows_per_page">Rows per page</InputLabel>
              <Select
                labelId="rows_per_page"
                id="rows_per_page_select"
                value={rowsPerPage}
                label="Rows per page"
                onChange={handleChangeRowsPerPage}
                sx={{ height: "38px", width: "150px", minWidth: "50px" }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>
            </FormControl>
            <MDTypography fontSize="12px" fontWeight="bold">
              {totalCount > 0 ? page * rowsPerPage + 1 : "0"}
              &nbsp;~&nbsp;
              {totalCount > page * rowsPerPage + rowsPerPage
                ? page * rowsPerPage + rowsPerPage
                : totalCount}
              &nbsp;of&nbsp;
              {totalCount}
            </MDTypography>

            <MDButton icon="text" onClick={() => changePageNum(-1)}>
              <FaArrowLeft />
            </MDButton>
            <MDButton icon="text" onClick={() => changePageNum(1)}>
              <FaArrowRight />
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Polls;
