import { doc, getDoc } from "firebase/firestore";
import { firestore } from "helpers/firebase";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

function ViewLeaderboard({ item }) {
  const [gameData, setGameData] = useState();

  useEffect(() => {
    if (!gameData && item) {
      (async () => {
        const res = await getDoc(doc(firestore, "tournaments", item.id));
        setGameData(res.data());
      })();
    }
  }, [item]);

  return (
    <div>
      <p style={{ fontWeight: "bold", marginBottom: 10, textDecoration: "underline" }}>Game Data</p>
      <div style={{ fontWeight: "bold", marginBottom: 10 }}>
        <p>Total Plays: {gameData ? gameData?.plays || 0 : "..."}</p>
      </div>
      <div style={{ fontWeight: "bold", marginBottom: 10 }}>
        <p>Registered Players: {gameData ? gameData?.players?.length || 0 : "..."}</p>
      </div>
      <div>
        <p style={{ fontWeight: "bold", marginBottom: 10, textDecoration: "underline" }}>
          Leaderboard
        </p>
        {!gameData ? (
          <p>Loading...</p>
        ) : gameData?.leaderboard?.length > 0 ? (
          gameData?.leaderboard
            ?.sort((a, b) => b.score - a.score)
            ?.map((i, ix) => (
              <div key={ix} style={{ display: "flex" }}>
                <p style={{ marginRight: 5 }}>{ix + 1}.</p>
                <p style={{ marginRight: 5 }}>{i.name}</p>
                <p>({i.score})</p>
              </div>
            ))
        ) : (
          <p>No Leaderboard Entrants Yet</p>
        )}
      </div>
    </div>
  );
}

ViewLeaderboard.defaultProps = {
  item: null,
};

ViewLeaderboard.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
};

export default ViewLeaderboard;
