import { PollTabs } from "layouts/common/constants";
import PollService from "services/PollService";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  filter: PollTabs.Upcoming,
  totalCount: 0,
  polls: [],
};

export const retrivePolls = createAsyncThunk(
  "/polls/retrive",
  async ({ filter, search, page, rowsPerPage, sortFilter, sortDirection }) => {
    const res = await PollService.retrive(
      filter,
      search,
      page,
      rowsPerPage,
      sortFilter,
      sortDirection,
    );
    return res.data;
  },
);

export const createPoll = createAsyncThunk("/polls/create", async ({ data }) => {
  const res = await PollService.create(data);
  return res.data;
});

export const updatePoll = createAsyncThunk("/polls/update", async ({ id, data }) => {
  const res = await PollService.update(id, data);
  return res.data;
});

export const deletePoll = createAsyncThunk("/polls/delete", async ({ id }) => {
  await PollService.remove(id);
  return { id };
});

const pollSlice = createSlice({
  name: "polls",
  initialState,
  extraReducers: {
    [retrivePolls.fulfilled]: (state, action) => {
      state.totalCount = action.payload.count;
      state.polls = [...action.payload.data];
    },
    [updatePoll.fulfilled]: (state, action) => {
      const index = state.polls.findIndex((poll) => poll.id === action.payload.id);
      state.polls[index] = {
        ...state.polls[index],
        ...action.payload,
      };
    },
    [deletePoll.fulfilled]: (state, action) => {
      const index = state.polls.findIndex(({ id }) => id === action.payload.id);
      state.polls.splice(index, 1);
      state.totalCount -= 1;
    },
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
});
export const { setFilter } = pollSlice.actions;

const { reducer } = pollSlice;
export default reducer;
