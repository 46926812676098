import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import MDButton from 'components/MDButton';

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import MDButton from "components/MDButton";

import { FaEdit, FaTrash } from "react-icons/fa";

import MDSnackbar from "components/MDSnackbar";
import ConfirmSnackBarContent from "layouts/common/components/ConfirmSnackbarContent";

import { dateToDateAndTime } from "utils/converter";

import { SponsorActivationTypes } from "layouts/common/constants";

import ToastrService from "services/ToastrService";
import { deleteActivation } from "store/slices/sponsorSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    fontWeight: "bold",
  },
}));

const StyledTableButton = styled(MDButton)(({ theme }) => ({
  [`&.MuiButton-root`]: {
    width: "100px",
    color: theme.palette.background.sidenav,
    background: "rgba(204, 204, 204, 0.25)",
  },
}));

function ActivationTable({ data }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);

  const [deleteId, setDeleteId] = useState("");
  const [deleteType, setDeleteType] = useState("");

  const [items, setItems] = useState([]);

  const handleEdit = (_id, _type) => {
    if (_type === SponsorActivationTypes.Trivia) navigate(`/game/edit-trivia/${_id}`);
    if (_type === SponsorActivationTypes.Prediction) navigate(`/game/edit-prediction/${_id}`);
    if (_type === SponsorActivationTypes.Milestone) navigate(`/game/edit-milestone/${_id}`);
    if (_type === SponsorActivationTypes.CheckIn || _type === SponsorActivationTypes.Survey)
      navigate(`/challenge/edit/${_type}/${_id}`);
  };

  const handleDelete = (_id, _type) => {
    setShow(true);
    setDeleteId(_id);
    setDeleteType(_type);
  };

  const confirmDelete = () => {
    if (deleteId !== "") {
      setShow(false);
      dispatch(deleteActivation({ type: deleteType, id: deleteId }))
        .unwrap()
        .then(() => {
          ToastrService.success("Successfully deleted activation");
        })
        .catch(() => {
          ToastrService.error("Failed to delete activation");
        });
      setDeleteId("");
      setDeleteType("");
    }
  };

  useEffect(() => {
    const tempData = data.map((d) => {
      const temp = { ...d };
      const startDateTime = dateToDateAndTime(new Date(d.start));
      temp.start = `${startDateTime.date}  ${startDateTime.time}`;
      return temp;
    });

    setItems(tempData);
  }, [data]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableBody>
          {items.map((row) => (
            <TableRow key={row.id}>
              <StyledTableCell align="left" component="th" scope="row">
                <StyledTableButton color="secondary">{row.type}</StyledTableButton>
              </StyledTableCell>
              <StyledTableCell align="left" sx={{ color: "#0076B2" }}>
                {row.title}
              </StyledTableCell>
              <StyledTableCell align="right">{row.start}</StyledTableCell>
              <StyledTableCell align="right">
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={() => handleEdit(row.id, row.type)}
                >
                  <FaEdit />
                </MDButton>
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={() => handleDelete(row.id, row.type)}
                >
                  <FaTrash />
                </MDButton>
              </StyledTableCell>
            </TableRow>
          ))}
          {items.length === 0 && (
            <TableRow>
              <StyledTableCell align="center" color="dark">
                No Content
              </StyledTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <MDSnackbar
        icon="notification"
        color="error"
        dateTime=""
        title="Delete Activation"
        content={
          <ConfirmSnackBarContent
            title="Are you sure to delete this data?"
            confirmHandler={confirmDelete}
          />
        }
        open={show}
        close={toggleSnackbar}
      />
    </TableContainer>
  );
}

ActivationTable.defaultProps = {
  data: [],
};

// Typechecking props for the DashboardNavbar
ActivationTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
};

export default ActivationTable;
