import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Divider, Grid } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// slices
import { setLoading } from "store/appSlice";

// services
import ToastrService from "services/ToastrService";
import MatchesService from "services/MatchService";

import { dateToDateAndTime } from "utils/converter";

function ViewEvent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [item, setItem] = useState(null);

  const getEvent = (_id) => {
    dispatch(setLoading(true));
    MatchesService.get(_id)
      .then((response) => {
        const temp = { ...response.data.match };

        const startDateTime = dateToDateAndTime(new Date(temp.start));
        const endDateTime = dateToDateAndTime(new Date(temp.end));

        temp.start = `${startDateTime.date}  ${startDateTime.time}`;
        temp.end = `${endDateTime.date}  ${endDateTime.time}`;

        setItem(temp);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load match data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) getEvent(id);
  }, [id]);

  const handleEdit = () => {
    navigate(`/match/edit/${id}`);
  };

  const handleBack = () => {
    navigate("/matches");
  };
  return (
    <DashboardLayout>
      <MDBox my={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "200px" }}>
            Event View
          </MDTypography>
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDButton size="small" onClick={() => handleBack()}>
              Back
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox sx={(theme) => customFormContainer(theme)}>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.team?.name}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Details
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Sponsor/Team
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.type}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Match title
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team / Player #1
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.teamOne}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team / Player #1 image
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              {item?.teamOneImage && (
                <MDBox
                  component="img"
                  src={item?.teamOneImage}
                  alt="asset image"
                  shadow="md"
                  width="150px"
                  height="100px"
                />
              )}
              {/* <MDTypography fontSize="15px">{event?.teamOneImage?.name}</MDTypography> */}
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team / Player #2
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.teamTwo}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team / Player #2 image
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              {item?.teamTwoImage && (
                <MDBox
                  component="img"
                  src={item?.teamTwoImage}
                  alt="asset image"
                  shadow="md"
                  width="150px"
                  height="100px"
                />
              )}
              {/* <MDTypography fontSize="15px">{event?.teamTwoImage?.name}</MDTypography> */}
            </Grid>
            {/* <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Home team
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.homeTeam?.name}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Away Team
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.awayTeam?.name}</MDTypography>
            </Grid> */}
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            {" "}
            Add features Image
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Recommended Image
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              {item?.recommendedImage && (
                <MDBox
                  component="img"
                  src={item?.recommendedImage}
                  alt="asset image"
                  shadow="md"
                  width="150px"
                  height="100px"
                />
              )}
              {/* <MDTypography fontSize="15px">{event?.recommendedImage?.name}</MDTypography> */}
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Add Image gallery
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                gallery title
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              {item?.gallaryImage &&
                item?.gallaryImage
                  ?.split(",")
                  ?.map((elm) => (
                    <MDBox
                      component="img"
                      src={elm}
                      alt="asset image"
                      shadow="md"
                      width="150px"
                      height="100px"
                      style={{ marginRight: "10px", marginBottom: "10px" }}
                    />
                  ))}
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Location
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Venue title
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.venueTitle}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Venue address
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.venueAddress}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Venue Google coordinates
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.venueGoogleCoordinates}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Start Date & Time
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.start}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Expiration Date & Time
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.end}</MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container justifyContent="flex-end">
          <MDButton color="secondary" size="large" onClick={() => handleEdit()}>
            Edit
          </MDButton>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ViewEvent;
