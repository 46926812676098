import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function ConfirmSnackBarContent({ title, confirmHandler }) {
  const handleConfirm = () => {
    confirmHandler();
  };
  return (
    <MDBox sx={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" }}>
      <MDTypography color="white" fontSize="16px">
        {title}
      </MDTypography>
      <MDButton color="white" onClick={() => handleConfirm()}>
        Confirm
      </MDButton>
    </MDBox>
  );
}

ConfirmSnackBarContent.defaultProps = {
  confirmHandler: {},
  title: "",
};

ConfirmSnackBarContent.propTypes = {
  confirmHandler: PropTypes.func,
  title: PropTypes.string,
};

export default ConfirmSnackBarContent;
