import http from "../apis/http-common";

const getAll = (searchTeam, searchText, page, rowsPerPage, sortFilter = "", sortDirection = "") => {
  const skip = page * rowsPerPage;

  const sortParams =
    sortFilter !== "" && sortDirection !== ""
      ? `&sort=${sortFilter}&direction=${sortDirection}`
      : "";

  return http.get(
    `/push-notification/push-notification-history?skip=${skip}&take=${rowsPerPage}&search=${searchText}&teams=${searchTeam}${sortParams}`,
  );
};

const get = (id) => http.get(`/push-notification/push-notification-history/${id}`);

const create = (data) => http.post("/push-notification/push-notification-history", data);

const NotificationService = {
  getAll,
  get,
  create,
};

export default NotificationService;
