import { useState, useEffect, useCallback } from "react";

// react-router components
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import AdminNavbar from "examples/Navbars/AdminNavbar";
import ProtectedRoute from "components/ProtectedRoute";

import SignInIllustration from "layouts/authentication/sign-in/illustration";
import RecoverPassword from "layouts/authentication/recover";

import Loader from "components/Loader";
import { useSelector, useDispatch } from "react-redux";
import useAuth from "hooks/useAuth";
import { setLoading, retriveFonts } from "store/appSlice";
import { retriveTeams } from "store/slices/teamSlice";
import { retriveSports } from "store/slices/sportSlice";
import { getProfile } from "store/slices/userSlice";

import "toastr/build/toastr.min.css";
import ResetPassword from "layouts/authentication/reset-password";

export default function App() {
  const [controller, dispatchM] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const { loading } = useSelector((state) => state.app);
  const { loaded, token } = useAuth();
  const dispatch = useDispatch();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatchM, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatchM, true);
      setOnMouseEnter(false);
    }
  };
  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const loadProfile = useCallback(() => {
    dispatch(getProfile());
  }, [dispatch]);

  const initTeamsFetch = useCallback(() => {
    dispatch(retriveTeams());
  }, [dispatch]);

  const loadTeamSports = useCallback(() => {
    dispatch(retriveSports());
  }, [dispatch]);

  const loadGoogleFonts = useCallback(() => {
    dispatch(retriveFonts());
  }, [dispatch]);

  useEffect(async () => {
    if (loaded && token) {
      // load basic datas;
      dispatch(setLoading(true));
      await Promise.all([loadProfile(), initTeamsFetch(), loadTeamSports(), loadGoogleFonts()]);
      dispatch(setLoading(false));
    }
  }, [loaded, token, loadProfile, initTeamsFetch, loadGoogleFonts]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <AdminNavbar />
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName=""
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      <Routes>
        <Route exact path="/sign-in" element={<SignInIllustration />} key="sign-in" />;
        <Route
          exact
          path="/recover-password"
          element={<RecoverPassword />}
          key="recover-password"
        />
        <Route exact path="/reset-password" element={<ResetPassword />} key="recover-password" />
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <Routes>
                {getRoutes(routes)}
                <Route path="*" element={<Navigate to="/dashboards/token-management" />} />
              </Routes>
            </ProtectedRoute>
          }
        />
      </Routes>
      {loading ? <Loader /> : null}
    </ThemeProvider>
  );
}
