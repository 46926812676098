import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setLoading } from "store/appSlice";
import { createTeam, updateTeam, createTeamToken, updateTeamToken } from "store/slices/teamSlice";

import TeamsService from "services/TeamService";
import ToastrService from "services/ToastrService";

// import { validateEmail } from "utils/validation";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { UserRole } from "layouts/common/constants";

import TeamBasicForm from "./forms/TeamBasicForm";
import TeamRewardForm from "./forms/TeamRewardForm";
import TeamThemeForm from "./forms/TeamThemeForm";
import TeamTierForm from "./forms/TeamTierForm";
import TeamReview from "./forms/TeamReview";

const formSwitcher = (
  screen,
  prevHandler,
  nextHandler,
  handleChange,
  handleSave,
  newTeam,
  initialState,
) => {
  switch (screen) {
    case 1:
      return (
        <TeamBasicForm
          item={newTeam}
          initialState={initialState}
          nextHandler={nextHandler}
          changeHandler={handleChange}
        />
      );
    case 2:
      return (
        <TeamTierForm
          item={newTeam}
          initialState={initialState}
          prevHandler={prevHandler}
          nextHandler={nextHandler}
          changeHandler={handleChange}
        />
      );
    case 3:
      return (
        <TeamThemeForm
          item={newTeam}
          initialState={initialState}
          prevHandler={prevHandler}
          nextHandler={nextHandler}
          changeHandler={handleChange}
        />
      );
    case 4:
      return (
        <TeamRewardForm
          item={newTeam}
          initialState={initialState}
          prevHandler={prevHandler}
          nextHandler={nextHandler}
          changeHandler={handleChange}
        />
      );
    case 5:
      return <TeamReview item={newTeam} saveHandler={handleSave} prevHandler={prevHandler} />;

    default:
      return (
        <TeamBasicForm item={newTeam} nextHandler={nextHandler} changeHandler={handleChange} />
      );
  }
};

function AddTeam() {
  const initialTeamState = {
    id: null,
    name: "",
    description: "",
    logo: "",
    logoFile: null,
    platformName: "",
    platformUrl: "",
    sport: null,
    sportId: "",
    adminFirstName: "",
    adminLastName: "",
    adminEmail: "",
    adminPhoneNumber: "",
    tokenSaleToFan: 0,
    tokenSaleToSponsors: 0,
    price: 0,
    tokenSymbol: "",
    tokenImage: "",
    tokenImageFile: null,
    appLogoImage: "",
    appLogoImageFile: null,
    landingPageHeroImage: "",
    landingPageHeroImageFile: null,
    pwaIcon: "",
    pwaIconFile: null,
    kudosToTire1: 0,
    kudosToTire2: 0,
    kudosToTire3: 0,
    kudosToTire4: 0,
    memberLevelName1: "",
    memberLevelName2: "",
    memberLevelName3: "",
    memberLevelName4: "",
    players: [],
    backgroundTheme: "Light",
    landingPageBackgroundColor: "#131418",
    landingPageBackgroundTopLayerColor: "#14264b",
    landingPageBackgroundBottomLayerColor: "#e41e2e",
    landingPageTextColor: "#ffffff",
    authInputBackgroundColor: "#ffffff",
    primaryTextColor: "#2f2f2f",
    headingTextColor: "#2f2f2f",
    menuTextColor: "#6B6B6B",
    secondaryTextColor: "#6B6B6B",
    primaryButtonColor: "#e41e2e",
    secondaryButtonColor: "#596169",
    modalBackgroundColor: "#ffffff",
    tabHighlightColor: "#e41e2e",
    buttonTextColor: "#ced2d6",
    tokenTextColor: "#e41e2e",
    kudosTextColor: "#feb201",
    kudosIconColor: "#feb201",
    dateTextColor: "#14264b",
    successColor: "#33be51",
    errorColor: "#ff0000",
    warningColor: "#feb201",
    iconColor: "#e41e2e",
    landingPageCardColor: "#14264b",
    mainPageCardColor: "#ffffff",
    mainPageBackgroundColor: "#f5f5f5",
    mainPageHeaderColor: "#ffffff",
    mainPageSidebarColor: "#ffffff",
    membershipTierOneColor: "#c6cfd9",
    membershipTierTwoColor: "#9eddff",
    membershipTierThreeColor: "#ee7fce",
    membershipTierFourColor: "#ffed93",
    primaryFont: 0,
    secondaryFont: 0,
    referralSignupTokenReward: 0,
    referralSignupKudosReward: 0,
    referralPlayTokenReward: 0,
    referralPlayKudosReward: 0,
    enableEarlySignupReward: false,
    earlySignupLimitCount: 0,
    earlySignupTokenReward: 0,
    earlySignupKudosReward: 0,
    earlySignupAsset: null,
    enableWeeklyReward: false,
    weeklyRewardLimitCount: 0,
    weeklyTokenReward: 0,
    weeklyKudosReward: 0,
    weeklyAsset: null,
  };

  const adminProfile = useSelector((state) => state.users.profile);
  const [initialState, setInitialState] = useState(initialTeamState);
  const [newTeam, setNewTeam] = useState(initialTeamState);
  const [currentPlayers, setCurrentPlayers] = useState([]);
  const [screen, setScreen] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (adminProfile.role === UserRole.TeamAdmin && !id) {
      navigate(`/dashboards/teams/view/${adminProfile.teamId}`);
    }
  }, [adminProfile, id]);

  const getTeam = (_id) => {
    dispatch(setLoading(true));
    TeamsService.get(_id)
      .then(async (response) => {
        const tmp = { ...response.data };

        // load token of team
        try {
          if (tmp.team?.tokenId) {
            const resToken = await TeamsService.getTeamToken(tmp.team?.tokenId);

            if (resToken?.data) {
              tmp.team.tokenImage = resToken?.data.logo;
              tmp.team.tokenSymbol = resToken?.data.symbol;
              tmp.team.tokenBalance = Number(resToken?.data.totalBalance);
              tmp.team.price = Number(resToken?.data.price);
              tmp.team.tokenSaleToFan = Number(resToken?.data.fanSaleFee);
              tmp.team.tokenSaleToSponsors = Number(resToken?.data.sponsorSaleFee);
            } else {
              ToastrService.error("Failed to token data");
            }
          }
        } catch {
          ToastrService.error("Failed to token data");
        }

        // load fan players of team

        try {
          const resPlayers = await TeamsService.getTeamPlayers(_id);
          if (resPlayers.data.count > 0) {
            setCurrentPlayers(resPlayers.data.data);
            const tempPlayers = resPlayers.data.data
              .sort((a, b) => {
                if (a.createdAt > b.createdAt) return 1;
                if (a.createdAt < b.createdAt) return -1;
                return 0;
              })
              .map((d) => ({
                id: d.id,
                name: d.name,
                avatar: d.avatar,
              }));

            tmp.team.players = [...tempPlayers];
          } else {
            tmp.team.players = [];
            setCurrentPlayers([]);
          }
        } catch {
          tmp.team.players = [];
          setCurrentPlayers([]);
        }

        // load admin of team
        if (tmp?.admin) {
          tmp.team.adminFirstName = tmp?.admin.firstName;
          tmp.team.adminLastName = tmp?.admin.lastName;
          tmp.team.adminEmail = tmp?.admin.email;
        }

        setInitialState(tmp.team);
        setNewTeam(tmp.team);

        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) getTeam(id);
  }, [id]);

  const handleChange = (data) => {
    setNewTeam(data);
    return true;
  };

  const handleSave = async () => {
    dispatch(setLoading(true));
    const newData = {
      ...newTeam,
      sportId: newTeam.sport.id,
      adminEmail: newTeam.adminEmail.toLowerCase(),
    };
    const dataId = newData.id;
    delete newData.id;
    newData.price = Number(newData.price);

    // Sorting players
    let playersToCreate = [];
    let playersToUpdate = [];
    let playersToDelete = [];

    if (newData.players.length > 0) {
      playersToCreate = newData.players.filter((d) => !d.id);
      playersToUpdate = newData.players.filter((d) => {
        if (currentPlayers.length === 0) return false;
        const idx = currentPlayers.findIndex((curData) => curData.id === d.id);
        if (idx === -1) return false;
        if (currentPlayers[idx].avatar === d.avatar && currentPlayers[idx].name === d.name)
          return false;
        return true;
      });
      playersToDelete = currentPlayers.filter((curData) => {
        const idx = newData.players.findIndex((d) => curData.id === d.id);
        if (idx === -1) return true;
        return false;
      });
    }

    // Uploading all images before creating team.
    let isSuccessUploading = true;

    // Uploading Player Images
    // create fan players.
    await Promise.all(
      playersToCreate.map(async (d) => {
        const tempPlayer = { ...d };
        if (d.file) {
          const formData = new FormData();
          formData.append("file", d.file);
          await TeamsService.upload(formData)
            .then((res) => {
              tempPlayer.avatar = res.data.url;
            })
            .catch((e) => {
              ToastrService.error(
                e.response?.data?.message?.message || "Failed to upload new player avatar",
              );
              isSuccessUploading = false;
            });
        }
        delete tempPlayer.file;
        return tempPlayer;
      }),
    ).then((data) => {
      playersToCreate = data;
    });

    // update fan players
    await Promise.all(
      playersToUpdate.map(async (d) => {
        const tempPlayer = { ...d };
        if (d.file) {
          const formData = new FormData();
          formData.append("file", d.file);
          await TeamsService.upload(formData)
            .then((res) => {
              tempPlayer.avatar = res.data.url;
            })
            .catch((e) => {
              ToastrService.error(
                e.response?.data?.message?.message || "Failed to upload new player avatar",
              );
              isSuccessUploading = false;
            });
        }

        delete tempPlayer.file;
        return tempPlayer;
      }),
    ).then((data) => {
      playersToUpdate = data;
    });

    // Uploading team logo
    if (newData.logoFile) {
      const formData = new FormData();
      formData.append("file", newData.logoFile);
      await TeamsService.upload(formData)
        .then((res) => {
          newData.logo = res.data.url;
        })
        .catch((e) => {
          ToastrService.error(
            e.response?.data?.message?.message || "Failed to upload new team logo",
          );
          isSuccessUploading = false;
        });
    }

    // Uploading token Image
    if (newData.tokenImageFile) {
      const formData = new FormData();
      formData.append("file", newData.tokenImageFile);
      await TeamsService.upload(formData)
        .then((res) => {
          newData.tokenImage = res.data.url;
        })
        .catch((e) => {
          ToastrService.error(
            e.response?.data?.message?.message || "Failed to upload new token logo",
          );
          isSuccessUploading = false;
        });
    }

    // Uploading App Logo
    if (newData.appLogoImageFile) {
      const formData = new FormData();
      formData.append("file", newData.appLogoImageFile);
      await TeamsService.upload(formData)
        .then((res) => {
          newData.appLogoImage = res.data.url;
        })
        .catch((e) => {
          ToastrService.error(
            e.response?.data?.message?.message || "Failed to upload new app logo",
          );
          isSuccessUploading = false;
        });
    }

    // Uploading Landing Hero Image
    if (newData.landingPageHeroImageFile) {
      const formData = new FormData();
      formData.append("file", newData.landingPageHeroImageFile);
      await TeamsService.upload(formData)
        .then((res) => {
          newData.landingPageHeroImage = res.data.url;
        })
        .catch((e) => {
          ToastrService.error(
            e.response?.data?.message?.message || "Failed to upload landing image",
          );
          isSuccessUploading = false;
        });
    }

    // Uploading PWA icon
    if (newData.pwaIconFile) {
      const formData = new FormData();
      formData.append("file", newData.pwaIconFile);
      await TeamsService.upload(formData)
        .then((res) => {
          newData.pwaIcon = res.data.url;
        })
        .catch((e) => {
          ToastrService.error(e.response?.data?.message?.message || "Failed to upload pwa icon");
          isSuccessUploading = false;
        });
    }

    if (!isSuccessUploading) {
      dispatch(setLoading(false));
      return;
    }

    if (newData.earlySignupAsset) {
      newData.earlySignupAssetId = newData.earlySignupAsset.id;
    }

    if (newData.weeklyAsset) {
      newData.weeklyAssetId = newData.weeklyAsset.id;
    }

    [
      "tokenImageFile",
      "logoFile",
      "appLogoImageFile",
      "pwaIconFile",
      "landingPageHeroImageFile",
      "earlySignupAsset",
      "weeklyAsset",
      "players",
    ].forEach((key) => {
      delete newData[key];
    });

    if (dataId === null) {
      // creating new team
      dispatch(createTeam(newData))
        .unwrap()
        .then(async (data) => {
          // creating fan players
          await Promise.all(
            playersToCreate.map(async (d) => {
              const tempPlayer = { ...d };
              tempPlayer.teamId = data.id;
              TeamsService.createTeamPlayer(tempPlayer);
            }),
          );
          ToastrService.success("Successfully added new team");
          navigate(`/dashboards/teams/view/${data.id}`);
          dispatch(setLoading(false));
        })
        .catch(() => {
          dispatch(setLoading(false));
        });
    } else {
      [
        "createdAt",
        "updatedAt",
        "isEmailVerified",
        "isPhoneVerified",
        "vaultTokenBalance",
        "teamWalletBalance",
        "isActivated",
      ].forEach((d) => {
        delete newData[d];
      });
      dispatch(updateTeam({ id: dataId, data: newData }))
        .unwrap()
        .then(async (data) => {
          ToastrService.success("Successfully updated team");

          // create fan players.
          await Promise.all(
            playersToCreate.map(async (d) => {
              const tempPlayer = { ...d };
              tempPlayer.teamId = dataId;
              TeamsService.createTeamPlayer(tempPlayer);
            }),
          );

          // update fan players
          await Promise.all(
            playersToUpdate.map(async (d) => {
              const tempPlayer = { ...d };
              tempPlayer.teamId = dataId;
              const tempPlayerId = tempPlayer.id;
              delete tempPlayer.id;
              TeamsService.updateTeamPlayer(tempPlayerId, tempPlayer);
            }),
          );

          // delete fan players
          await Promise.all(
            playersToDelete.map(async (d) => {
              TeamsService.removeTeamPlayer(d.id);
            }),
          );

          if (newData.tokenId) {
            // update team token
            dispatch(
              updateTeamToken({
                id: newData.tokenId,
                data: {
                  teamId: dataId,
                  symbol: newData.tokenSymbol,
                  logo: newData.tokenImage || "",
                  fanSaleFee: newData.tokenSaleToFan,
                  sponsorSaleFee: newData.tokenSaleToSponsors,
                  price: newData.price,
                },
              }),
            )
              .unwrap()
              .then(() => {
                ToastrService.success("Successfully updated team token info");
              })
              .catch((e) => {
                ToastrService.error(
                  e.response?.data?.message?.message || "Failed to update team token info",
                );
              })
              .finally(() => {
                dispatch(setLoading(false));
                navigate(`/dashboards/teams/view/${data.id}`);
              });
          } else {
            // create team token
            dispatch(
              createTeamToken({
                teamId: data.id,
                symbol: newData.tokenSymbol,
                logo: newData.tokenImage || "",
                fanSaleFee: newData.tokenSaleToFan,
                sponsorSaleFee: newData.tokenSaleToSponsors,
                price: newData.price,
                tokenBalance: 0,
              }),
            )
              .unwrap()
              .then(() => {
                ToastrService.success("Successfully added new team token");
              })
              .catch((e) => {
                ToastrService.error(
                  e.response?.data?.message?.message || "Failed to create new team token",
                );
              })
              .finally(() => {
                dispatch(setLoading(false));
                navigate(`/dashboards/teams/view/${data.id}`);
              });
          }
        })
        .catch(() => {
          dispatch(setLoading(false));
        });
    }
  };

  const nextScreen = () => {
    setScreen(screen + 1);
  };

  const prevScreen = () => {
    setScreen(screen - 1);
  };

  return (
    <DashboardLayout>
      {formSwitcher(
        screen,
        prevScreen,
        nextScreen,
        handleChange,
        handleSave,
        newTeam,
        initialState,
      )}
    </DashboardLayout>
  );
}

export default AddTeam;
