import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Grid, InputLabel, MenuItem, Select, FormControl } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { pageHeaderContainer, flexContainer, tabButton } from "layouts/common/styles";
import { ActivationTabs } from "layouts/common/constants";

import ToastrService from "services/ToastrService";
import SponsorService from "services/SponsorService";

import { setFilterSponsorActivation, retriveSponsorActivations } from "store/slices/sponsorSlice";
import { setLoading } from "store/appSlice";

import ActivationTable from "../ActivationTable";

function ViewActivation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const totalCount = useSelector((state) => state.sponsors.activationCount);
  const activations = useSelector((state) => state.sponsors.activations);
  const filter = useSelector((state) => state.sponsors.filter);

  // Search options.
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [item, setItem] = useState(null);

  const getSponsor = (_id) => {
    dispatch(setLoading(true));
    SponsorService.get(_id)
      .then((response) => {
        setItem(response.data);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load sponsor data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      getSponsor(id);
    }
  }, [id]);

  const fetchBySearchOptions = useCallback(() => {
    dispatch(retriveSponsorActivations({ id, filter, page, rowsPerPage }));
  }, [dispatch, id, filter, page, rowsPerPage]);

  useEffect(() => {
    fetchBySearchOptions();
  }, [fetchBySearchOptions]);

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };

  const changePageNum = (direct) => {
    const maxPageNum = Math.floor((totalCount - 1) / rowsPerPage);
    if (page === 0 && direct === -1) return;
    if (page === maxPageNum && direct === 1) return;

    setPage((prevState) => prevState + direct);
  };

  const changeTab = (tab) => {
    dispatch(setFilterSponsorActivation(tab));
  };

  const handleBack = () => {
    navigate(`/sponsor/view/${id}`);
  };

  return (
    <DashboardLayout>
      <MDBox py={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold">
            {item?.title} - Activations
          </MDTypography>
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDButton size="small" color="dark" onClick={() => handleBack()}>
              Back
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox>
          <Grid container spacing={2} mt={3} mb={3}>
            <Grid item xs={12} md={4}>
              <MDButton
                size="large"
                color={filter === ActivationTabs.Upcoming ? "secondary" : "white"}
                sx={(theme) => tabButton(theme)}
                onClick={() => changeTab(ActivationTabs.Upcoming)}
              >
                Upcoming
              </MDButton>
            </Grid>
            <Grid item xs={12} md={4}>
              <MDButton
                size="large"
                color={filter === ActivationTabs.Ongoing ? "secondary" : "white"}
                sx={(theme) => tabButton(theme)}
                onClick={() => changeTab(ActivationTabs.Ongoing)}
              >
                Ongoing
              </MDButton>
            </Grid>
            <Grid item xs={12} md={4}>
              <MDButton
                size="large"
                color={filter === ActivationTabs.Past ? "secondary" : "white"}
                sx={(theme) => tabButton(theme)}
                onClick={() => changeTab(ActivationTabs.Past)}
              >
                Past
              </MDButton>
            </Grid>
          </Grid>
          <ActivationTable data={activations} />
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end" my={3}>
            <FormControl>
              <InputLabel id="rows_per_page">Rows per page</InputLabel>
              <Select
                labelId="rows_per_page"
                id="rows_per_page_select"
                value={rowsPerPage}
                label="Rows per page"
                onChange={handleChangeRowsPerPage}
                sx={{ height: "38px", width: "150px", minWidth: "50px" }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>
            </FormControl>
            <MDTypography fontSize="12px" fontWeight="bold">
              {totalCount > 0 ? page * rowsPerPage + 1 : "0"}
              &nbsp;~&nbsp;
              {totalCount > page * rowsPerPage + rowsPerPage
                ? page * rowsPerPage + rowsPerPage
                : totalCount}
              &nbsp;of&nbsp;
              {totalCount}
            </MDTypography>

            <MDButton icon="text" onClick={() => changePageNum(-1)}>
              <FaArrowLeft />
            </MDButton>
            <MDButton icon="text" onClick={() => changePageNum(1)}>
              <FaArrowRight />
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}
export default ViewActivation;
