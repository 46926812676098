import http from "../apis/http-common";

const retrive = (filter, search, page, rowsPerPage, sortFilter = "", sortDirection = "") => {
  const skip = page * rowsPerPage;
  const sortParams =
    sortFilter !== "" && sortDirection !== ""
      ? `&sort=${sortFilter}&direction=${sortDirection}`
      : "";
  return http.get(
    `/asset/list?skip=${skip}&take=${rowsPerPage}&search=${search}&filter=${filter}${sortParams}`,
  );
};

const get = (id) => http.get(`/asset/${id}`);

const getResult = (id) => http.get(`/asset/result/${id}`);

const create = (data) => http.post("/asset", data);

const update = (id, data) => http.put(`/asset/${id}`, data);

const remove = (id) => {
  http.delete(`/asset/${id}`);
};

const sendReward = (id, data) => http.post(`/asset/${id}/send-bonus`, data);

const upload = (formData) => http.post("/upload/assets", formData);

const AssetService = {
  retrive,
  get,
  getResult,
  create,
  update,
  remove,
  upload,
  sendReward,
};

export default AssetService;
