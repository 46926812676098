import toastr from "toastr";

const success = (title) => {
  toastr.options = {
    positionClass: "toast-top-full-width",
    hideDuration: 300,
    timeOut: 60000,
  };
  toastr.clear();
  setTimeout(() => toastr.success(title), 300);
};

const error = (title) => {
  toastr.options = {
    positionClass: "toast-top-full-width",
    hideDuration: 300,
    timeOut: 60000,
  };
  toastr.clear();
  setTimeout(() => toastr.error(title), 300);
};

const warning = (title) => {
  toastr.options = {
    positionClass: "toast-top-full-width",
    hideDuration: 300,
    timeOut: 60000,
  };
  toastr.clear();
  setTimeout(() => toastr.warning(title), 300);
};

const ToastrService = {
  success,
  error,
  warning,
};

export default ToastrService;
