import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import QRCode from "react-qr-code";

import { Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { flexContainer, pageHeaderContainer } from "layouts/common/styles";
import { GameTypes } from "layouts/common/constants";

import { setLoading } from "store/appSlice";
import GameService from "services/GamesService";
import ToastrService from "services/ToastrService";

import { dateToDateAndTime } from "utils/converter";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ViewMiniGameDetail from "./Details";
import ViewMiniGameTheme from "./Theme";
import ViewMiniGameReward from "./Rewards";
import ViewLeaderboard from "../ViewLeaderboard";

function ViewMiniGame() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState({});
  const [status, setStatus] = useState("");
  const [copied, setCopied] = useState(false);
  // 0 - detail, 1-theme 2-reward
  const [activeView, setActiveView] = useState(0);

  const getGame = (_id) => {
    dispatch(setLoading(true));
    GameService.get(_id, GameTypes.MiniGame)
      .then((response) => {
        const temp = { ...response.data };

        if (temp.isDraft === true) {
          setStatus("Draft");
        } else if (temp.start < new Date().toISOString() && temp.end > new Date().toISOString()) {
          setStatus("Live");
        } else if (temp.start > new Date().toISOString()) {
          setStatus("Upcoming");
        } else {
          setStatus("Past");
        }

        const startDateTime = dateToDateAndTime(new Date(temp.start));
        const endDateTime = dateToDateAndTime(new Date(temp.end));

        temp.start = `${startDateTime.date}  ${startDateTime.time}`;
        temp.end = `${endDateTime.date}  ${endDateTime.time}`;

        setData(temp);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load mini game data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) getGame(id);
  }, [id]);

  const handleBack = () => {
    navigate(`/games`);
  };

  const handleResult = () => {
    navigate(`/game/result-minigame/${id}`);
  };

  const handleFinish = () => {
    dispatch(setLoading(true));
    GameService.finish(GameTypes.MiniGame, id)
      .then((response) => {
        const temp = { ...response.data };
        if (temp.success) {
          ToastrService.success("Successfully finished mini game.");
          setData((prevState) => ({ ...prevState, isEnded: true }));
          dispatch(setLoading(false));
        } else {
          ToastrService.error(temp.message);
          dispatch(setLoading(false));
        }
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to finish mini game.");
        dispatch(setLoading(false));
      });
  };

  const handleEdit = () => {
    navigate(`/game/edit-minigame/${id}`);
  };

  const generateAndCopyLink = async (url) => {
    // const response = await fetch(`https://api.shrtco.de/v2/shorten?url=${url}`)
    // const text = await response.json()
    // const shortUrl = text?.result?.full_short_link
    const shortUrl = url;
    await navigator.clipboard.writeText(shortUrl);
    setCopied(true);
  };

  return (
    <DashboardLayout>
      <MDBox my={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "250px" }}>
            Mini Game Overview
          </MDTypography>
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDButton size="small" onClick={() => handleBack()}>
              Back
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox>
          <MDBox mb={3} sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            <MDButton
              variant={activeView === 0 ? "contained" : "text"}
              color="dark"
              size="large"
              onClick={() => setActiveView(0)}
            >
              Team / Admin Details
            </MDButton>
            <MDButton
              variant={activeView === 1 ? "contained" : "text"}
              color="dark"
              size="large"
              onClick={() => setActiveView(1)}
            >
              Theme
            </MDButton>
            <MDButton
              variant={activeView === 2 ? "contained" : "text"}
              color="dark"
              size="large"
              onClick={() => setActiveView(2)}
            >
              Rewards / Mechanics
            </MDButton>
            <MDButton
              variant={activeView === 3 ? "contained" : "text"}
              color="dark"
              size="large"
              onClick={() => setActiveView(3)}
            >
              Progress
            </MDButton>
          </MDBox>
        </MDBox>
        {activeView === 0 && <ViewMiniGameDetail item={data} />}
        {activeView === 1 && <ViewMiniGameTheme item={data} />}
        {activeView === 2 && <ViewMiniGameReward item={data} />}
        {activeView === 3 && <ViewLeaderboard item={data} />}
        <MDBox
          mb={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            gap: "10px",
            paddingTop: 5,
          }}
        >
          <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "250px" }}>
            Quick Play
          </MDTypography>
          <QRCode
            value={`https://sportzfan-arcade.vercel.app/?gameType=${
              data.type
            }&userName=QRPLAYER&gameId=${data?.id}&gameLogo=${data.logo}&lifeCount=${
              data.lifeCount
            }&teamId=${data?.team?.id}&backgroundImage=${data.background}&refreshTime=${
              data.refreshTime
            }&refreshAmount=${data.refreshAmount}&sponsorTitle=${data.sponsor?.title}&sponsorLogo=${
              data.sponsor?.logo
            }&userId=QR_PLAYER&teamName=${data.team?.name}&platformUrl=${
              data.team?.platformUrl
            }&backgroundColor=${data.team?.mainPageCardColor.replace(
              "#",
              "",
            )}&accentColor=${data.team?.primaryButtonColor.replace(
              "#",
              "",
            )}&textColor=${data.team?.primaryTextColor.replace("#", "")}`}
          />
          <MDButton
            // variant={activeView === 1 ? "contained" : "text"}
            sx={{ width: 260 }}
            color="dark"
            size="large"
            onClick={() =>
              generateAndCopyLink(
                `https://sportzfan-arcade.vercel.app/?gameType=${
                  data.type
                }&userName=QRPLAYER&platformUrl=${data.team?.platformUrl}&gameId=${
                  data?.id
                }&gameLogo=${data.logo}&lifeCount=${data.lifeCount}&teamId=${
                  data?.team?.id
                }&backgroundImage=${data.background}&refreshTime=${
                  data.refreshTime
                }&refreshAmount=${data.refreshAmount}&sponsorTitle=${
                  data.sponsor?.title
                }&sponsorLogo=${data.sponsor?.logo}&userId=QR_PLAYER&teamName=${
                  data.team?.name
                }&backgroundColor=${data.team?.mainPageCardColor.replace(
                  "#",
                  "",
                )}&accentColor=${data.team?.primaryButtonColor.replace(
                  "#",
                  "",
                )}&textColor=${data.team?.primaryTextColor.replace("#", "")}`,
              )
            }
          >
            {copied ? "Link Copied" : "Copy Link"}
          </MDButton>
        </MDBox>
        <Grid container justifyContent="flex-end" gap={2}>
          <Grid item>
            {data?.isEnded && status !== "Draft" && (
              <MDButton color="secondary" size="large" onClick={handleResult}>
                View Results
              </MDButton>
            )}
            {!data?.isEnded && status !== "Draft" && (
              <MDButton color="secondary" size="large" onClick={handleFinish}>
                Finish
              </MDButton>
            )}
          </Grid>
          <Grid item>
            <MDButton color="secondary" size="large" onClick={handleEdit}>
              Edit
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ViewMiniGame;
