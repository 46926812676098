import http from "../apis/http-common";

const getAll = (searchTeam, searchText, page, rowsPerPage, sortFilter = "", sortDirection = "") => {
  const skip = page * rowsPerPage;

  const sortParams =
    sortFilter !== "" && sortDirection !== ""
      ? `&sort=${sortFilter}&direction=${sortDirection}`
      : "";

  return http.get(
    `/user?skip=${skip}&take=${rowsPerPage}&search=${searchText}&teams=${searchTeam}${sortParams}`,
  );
};

const get = (teamId, email) => http.get(`/user/get-one/${teamId}/${email}`);

const getOne = (id) => http.get(`/user/get-one/${id}`);

const update = (id, data) => http.put(`/user/${id}`, data);

const remove = (id) => http.delete(`/user/${id}`);

const activate = (id, active) => {
  if (active) {
    return http.put(`/user/activate-user/${id}`);
  }

  return http.put(`/user/inactivate-user/${id}`);
};

const getProfile = () => http.get(`/auth/profile`);

const addTeamAdmin = (data) => http.post("/user/add-team-admin", data);

const updateTeamAdmin = (id, data) => http.post(`/user/update-team-admin/${id}`, data);

const profileUpdate = (data) => http.put("/user/profile-update", data);

const resetPassword = (data) => http.put("/user/reset-password", data);

const getAnalysisInfo = (id) => http.get(`/user/analysis-info/${id}`);

const UserService = {
  getAll,
  get,
  getOne,
  update,
  remove,
  activate,
  getProfile,
  addTeamAdmin,
  updateTeamAdmin,
  resetPassword,
  profileUpdate,
  getAnalysisInfo,
};

export default UserService;
