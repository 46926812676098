import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Autocomplete, Divider, Grid, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";
import FormField from "layouts/assets/components/FormField";

import AssetService from "services/AssetService";
import CommonService from "services/CommonService";
import ToastrService from "services/ToastrService";

import { setLoading } from "store/appSlice";

import { dateToDateAndTime } from "utils/converter";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .MuiOutlinedInput-root .MuiAutocomplete-input`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
    padding: 0,
    width: "35px",
  },
  [`& .MuiOutlinedInput-root`]: {
    padding: "0px",
    paddingLeft: "10px",
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

function SendBonusAsset() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [countPerRecipient, setCountPerRecipient] = useState(0);
  const [messageToSend, setMessageToSend] = useState("");
  const [type, setType] = useState("");
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [detail, setDetail] = useState({});
  const [result, setResult] = useState({});

  const getAsset = (_id) => {
    AssetService.get(_id)
      .then((response) => {
        const data = { ...response.data };
        const startDateTime = dateToDateAndTime(new Date(data.start));
        const endDateTime = dateToDateAndTime(new Date(data.end));
        data.startDate = startDateTime.date;
        data.startTime = startDateTime.time;
        data.endDate = endDateTime.date;
        data.endTime = endDateTime.time;
        data.logo = data.imageUrl;
        setDetail(data);
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load asset data.");
      });
  };

  const getAssetResult = (_id) => {
    dispatch(setLoading(true));
    AssetService.getResult(_id)
      .then((response) => {
        const data = { ...response.data };
        setResult(data);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(
          e.response?.data?.message?.message || "Failed to load asset result data.",
        );
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      getAsset(id);
      getAssetResult(id);
    }
  }, [id]);

  const getUserList = (searchText = "") => {
    dispatch(setLoading(true));
    CommonService.getUserList(searchText, detail?.team ? detail?.team?.id : "")
      .then((response) => {
        setUserList(response.data?.data);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load user list.");
        dispatch(setLoading(false));
      });
  };

  const handleSearch = (searchText) => {
    getUserList(searchText);
  };

  const handleSendReward = () => {
    if (selectedUsers.length <= 0) {
      ToastrService.error("Invalid input: Recipients");
      return;
    }
    if (countPerRecipient <= 0) {
      ToastrService.error("Invalid input: Asset Count Per Recipient");
      return;
    }
    if (messageToSend === "") {
      ToastrService.error("Invalid input: Message to send");
      return;
    }
    if (
      (detail.totalCount - result?.participants?.length ?? 0) <
      selectedUsers.length * countPerRecipient
    ) {
      ToastrService.error("Could not send the assets over the total");
      return;
    }

    dispatch(setLoading(true));
    AssetService.sendReward(id, {
      users: selectedUsers.map((user) => user.id),
      message: messageToSend,
      count: countPerRecipient,
    })
      .then((res) => {
        if (res.data.success === true) {
          ToastrService.success("Successfully send reward");
        } else {
          ToastrService.error(res.data.message);
        }
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to send reward.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    getUserList();
  }, []);

  const handleBack = () => {
    navigate("/assets");
  };

  return (
    <DashboardLayout>
      {detail.id ? (
        <MDBox mb={5}>
          <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
            <MDTypography width="100%" fontSize="20px" fontWeight="bold" sx={{ minWidth: "150px" }}>
              {`${detail?.title} - Send Reward`}
            </MDTypography>
            <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
              <MDButton size="small" onClick={handleBack}>
                Back
              </MDButton>
            </MDBox>
          </MDBox>
          <Divider />
          <MDBox sx={(theme) => customFormContainer(theme)}>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Sponsor / Team
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{detail.type}</MDTypography>
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Team
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{detail.team?.name}</MDTypography>
              </Grid>
            </Grid>
            <MDTypography
              fontSize="15px"
              fontWeight="bold"
              mb={3}
              sx={{ textDecoration: "underline" }}
            >
              Details
            </MDTypography>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Asset Category
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{detail.category}</MDTypography>
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Asset Title
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{detail.title}</MDTypography>
              </Grid>
            </Grid>
            <MDTypography
              fontSize="15px"
              fontWeight="bold"
              mb={3}
              sx={{ textDecoration: "underline" }}
            >
              Send Asset
            </MDTypography>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  On Hand
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">
                  {detail.totalCount - result?.participants?.length ?? 0}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  No of assets to send each recipient
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={2} md={1.5}>
                <FormField
                  type="number"
                  min={0}
                  value={countPerRecipient}
                  onChange={(e) => {
                    setCountPerRecipient(parseInt(e.target.value, 10));
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Message to send
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                <FormField
                  value={messageToSend}
                  onChange={(e) => {
                    setMessageToSend(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <MDTypography
              fontSize="15px"
              fontWeight="bold"
              mb={3}
              sx={{ textDecoration: "underline" }}
            >
              Select recipients
            </MDTypography>
            <Grid container spacing={2} mb={4} alignItems="center">
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Send to type
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <StyledAutocomplete
                  options={[
                    { value: "Select users", label: "Select users" },
                    {
                      value: "Users who have completed an action",
                      label: "Users who have completed an action",
                    },
                  ]}
                  renderInput={(params) => (
                    <TextField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                  placeholder="Type"
                  onChange={(e, v) => setType(v.value)}
                  value={type}
                  disableClearable
                />
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Select recipients
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Autocomplete
                  multiple
                  value={selectedUsers}
                  options={userList}
                  onKeyUp={(e) => {
                    handleSearch(e.target.value);
                  }}
                  getOptionLabel={(option) =>
                    `${option.firstName} ${option.lastName} (${option.email})`
                  }
                  onChange={(e, v) => setSelectedUsers(v)}
                  renderInput={(params) => (
                    <TextField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                  sx={{ maxWidth: "568px", width: "100%" }}
                  placeholder="Select Users"
                />
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Total no recipients to send asset to
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">
                  {selectedUsers.length * countPerRecipient}
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <Grid container justifyContent="flex-end" gap={2}>
            <Grid item>
              <MDButton color="secondary" size="large" onClick={handleSendReward}>
                Send Reward
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <MDTypography fontSize="15px" fontWeight="bold">
          No data found.
        </MDTypography>
      )}
    </DashboardLayout>
  );
}

export default SendBonusAsset;
