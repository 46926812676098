import PropTypes from "prop-types";
import { Checkbox, Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { customFormContainer } from "layouts/common/styles";
import {
  RewardActionType,
  RewardActionRewardItemType,
  MarketplaceItemType,
  MarketplaceItemSubType,
} from "layouts/common/constants";

function ViewMiniGameReward({ item }) {
  return (
    <MDBox sx={(theme) => customFormContainer(theme)}>
      <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
        Eligibility
      </MDTypography>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={3} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            No of Kudos Points
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={2} md={1.5}>
          <MDTypography fontSize="15px">{item?.eligbleKudos}</MDTypography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={3} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            No of Tokens
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={2} md={1.5}>
          <MDTypography fontSize="15px">{item?.eligbleToken}</MDTypography>
        </Grid>
      </Grid>
      <Divider />
      <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
        Rewards
      </MDTypography>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} md={6}>
          <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
            For participation
          </MDTypography>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={6}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of Kudos Points(Per player per play)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDTypography fontSize="15px">{item?.rewardKudos}</MDTypography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={6}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of Tokens Points(Per campaign)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDTypography fontSize="15px">{item?.rewardToken}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
            For Winners
          </MDTypography>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={6}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of Kudos Points(Total)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDTypography fontSize="15px">{item?.rewardKudosWinnerTotal}</MDTypography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={6}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of Tokens(Total)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDTypography fontSize="15px">{item?.rewardTokenWinnerTotal}</MDTypography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={6}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of Winners
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDTypography fontSize="15px">{item?.rewardDistribution?.length}</MDTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
            Reward distribution
          </MDTypography>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={4} sm={4}>
              &nbsp;
            </Grid>
            <Grid item xs={4} sm={4}>
              <MDTypography fontSize="15px" fontWeight="bold">
                KUDOS
              </MDTypography>
            </Grid>
            <Grid item xs={4} sm={4}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Tokens
              </MDTypography>
            </Grid>
          </Grid>
          {item?.rewardDistribution
            .sort((a, b) => {
              if (a.winnerOrder > b.winnerOrder) return 1;
              if (a.winnerOrder < b.winnerOrder) return -1;
              return 0;
            })
            .map((d) => (
              <Grid key={`winner-${d.winnerOrder}`} container spacing={2} mb={2}>
                <Grid item xs={4} sm={4}>
                  <MDTypography fontSize="15px">Winner #{d.winnerOrder}</MDTypography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <MDTypography fontSize="15px">{d.rewardKudos}</MDTypography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <MDTypography fontSize="15px">{d.rewardToken}</MDTypography>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={3} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Send asset as reward?
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={2} md={1.5}>
          <Checkbox disabled checked={item?.enableAssetReward || false} />
        </Grid>
        {item?.enableAssetReward && (
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              select asset
            </MDTypography>
          </Grid>
        )}
        {item?.enableAssetReward && (
          <Grid item xs={12} sm={4} md={5}>
            <MDTypography fontSize="15px">{item?.asset?.title}</MDTypography>
          </Grid>
        )}
      </Grid>
      {item?.enableAssetReward && (
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Number of top ranked scores to send to
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <MDTypography fontSize="15px">{item?.winnerLimit}</MDTypography>
          </Grid>
        </Grid>
      )}
      {item?.enableAssetReward && (
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              No of Bonus Asset to send per winner
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <MDTypography fontSize="15px">{item?.rewardAssetCount}</MDTypography>
          </Grid>
        </Grid>
      )}
      <Divider />
      <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
        Rewards (In Game)
      </MDTypography>
      <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
        Reward Actions (Paywall)
      </MDTypography>
      {item?.rewardAction?.map((action, actionIndex) => (
        <MDBox>
          <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
            Action #{actionIndex + 1}
          </MDTypography>
          <Grid container spacing={2} mb={4} alignItems="center">
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Action
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{RewardActionType[action?.type || 0]}</MDTypography>
            </Grid>
          </Grid>
          {action.type === 0 && (
            <Grid container spacing={2} mb={4} alignItems="center">
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Select URL
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{action.videoUrl}</MDTypography>
              </Grid>
            </Grid>
          )}
          {action.type === 1 && (
            <Grid container spacing={2} mb={4} alignItems="center">
              <Grid item xs={12} sm={12}>
                {action?.rewardActionQuestions?.map((surveyQuestion, surveyIndex) => (
                  <MDBox key={`surveyQuestion-${surveyIndex.toString()}`} mb={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3} md={2.5}>
                        <MDTypography fontSize="15px" fontWeight="bold">
                          Question {surveyIndex + 1}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <MDTypography fontSize="15px">{surveyQuestion.question}</MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <MDBox>
                          <Grid container spacing={2}>
                            {surveyQuestion.options.map((option, optionIdx) => (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                key={`survey-option-${surveyIndex.toString()}-${optionIdx.toString()}`}
                              >
                                <MDBox mb={2}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={5}>
                                      <MDTypography fontSize="15px" fontWeight="bold">
                                        Option {optionIdx + 1}
                                      </MDTypography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} display="flex" alignItems="center">
                                      <MDTypography fontSize="15px">
                                        {option.optionText}
                                      </MDTypography>
                                    </Grid>
                                  </Grid>
                                </MDBox>
                              </Grid>
                            ))}
                          </Grid>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                ))}
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2} mb={4} alignItems="center">
            <Grid item xs={12} sm={12}>
              {action.rewardActionRewardItems?.map((reward, rewardIndex) => (
                <MDBox key={`reward-item-${actionIndex.toString()}-${rewardIndex.toString()}`}>
                  <Grid container spacing={2} mb={4} alignItems="center">
                    <Grid item xs={12} sm={3} md={2.5}>
                      <MDTypography fontSize="15px" fontWeight="bold">
                        Reward #{rewardIndex + 1} per{" "}
                        {action.type === 0 ? "action" : "survey answer"}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <MDTypography fontSize="15px">
                        {RewardActionRewardItemType[reward?.type || 0]}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2.5}>
                      <MDTypography fontSize="15px" fontWeight="bold">
                        No of item #{rewardIndex + 1} rewarded
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <MDTypography fontSize="15px">{reward.count}</MDTypography>
                    </Grid>
                  </Grid>
                </MDBox>
              ))}
            </Grid>
          </Grid>
          {action.type === 0 && (
            <Grid container spacing={2} mb={4} alignItems="center">
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Max no of actions per user per day
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{action.maximumActionPerUserPerDay}</MDTypography>
              </Grid>
            </Grid>
          )}
        </MDBox>
      ))}
      <Divider />
      <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
        In game store (marketplace)
      </MDTypography>
      {item?.rewardMarketplaceItem?.map((gameItem, itemIndex) => (
        <MDBox>
          <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
            Item #{itemIndex + 1}
          </MDTypography>
          <Grid container spacing={2} mb={4} alignItems="center">
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team or sponsor item
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{MarketplaceItemType[gameItem.type || 0]}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Select item type
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">
                {MarketplaceItemSubType[gameItem.subType || 0]}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                description
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{gameItem.description}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of item
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{gameItem.itemCount}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Cost of item (coins)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{gameItem.itemCost}</MDTypography>
            </Grid>
          </Grid>
        </MDBox>
      ))}
    </MDBox>
  );
}

ViewMiniGameReward.defaultProps = {
  item: null,
};

ViewMiniGameReward.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
};

export default ViewMiniGameReward;
