import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { customFormContainer } from "layouts/common/styles";
import MDBox from "components/MDBox";

function MultiReferrerReview({ prevHandler, nextHandler }) {
  const handlePrev = () => {
    prevHandler();
  };

  const handleNext = () => {
    nextHandler();
  };

  return (
    <MDBox>
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">Adelaide Giants</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Challenge Type
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">Multi Referrer</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Challenge Description
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">
              Time to add some fan spirit to our website! Which of these pictureis your favourite?
              The picture with max votes will be displayed on the landing page’s carousel for a
              month.{" "}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Challenge Title
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">Image</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Start Date & Time
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <MDTypography fontSize="15px">05/18/2022 12:22</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Expiration Date & Time
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <MDTypography fontSize="15px">05/18/2022 12:22</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Participants
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9} md={8.5}>
            <MDTypography fontSize="15px">All Users</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Eligibility
        </MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              No of referrals required to complete challenge
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <MDTypography fontSize="15px">0</MDTypography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Time to completee challenge from signup
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <MDTypography fontSize="15px">0</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Rewards
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} md={6}>
            <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
              If user is at venue
            </MDTypography>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  No of Kudos Points
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography fontSize="15px">0</MDTypography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  No of Tokens
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography fontSize="15px">0</MDTypography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Sponsors
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Game Sponsor
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px">Nike</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Results
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Users completed
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px">457</MDTypography>
          </Grid>
        </Grid>
      </MDBox>
      <Grid container justifyContent="flex-end" gap={2}>
        <Grid item>
          <MDButton color="secondary" size="large" onClick={() => handlePrev()}>
            Prev
          </MDButton>
        </Grid>
        <Grid item>
          <MDButton color="secondary" size="large" onClick={() => handleNext()}>
            Submit
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

MultiReferrerReview.defaultProps = {
  prevHandler: {},
  nextHandler: {},
};

MultiReferrerReview.propTypes = {
  prevHandler: PropTypes.func,
  nextHandler: PropTypes.func,
};

export default MultiReferrerReview;
