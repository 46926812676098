import axios from "axios";

import { API_BASE_URL } from "config";
import { getToken } from "utils/auth";

// Quick dev mode switcher
const devMode = false;

const api = axios.create();
api.interceptors.request.use(
  async (request) => {
    const baseURL = devMode ? "http://localhost:3005" : API_BASE_URL || "";
    const token = getToken();
    request.url = baseURL + request.url;

    if (token) {
      request.headers = {
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      };
    }
    return request;
  },
  (error) => Promise.reject(error),
);

export default api;
