import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/illustrations/SportzFan.png";

import MDTypography from "components/MDTypography";
import { setLoading } from "store/appSlice";

import AuthService from "services/AuthService";
import { validatePassword } from "utils/validation";

function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");

  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [validations, setValidations] = useState({
    password: "",
    confirmPassword: "",
  });
  const [success, setSuccesse] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onChange = (type, value) => {
    setData((prevState) => ({ ...prevState, [type]: value }));
    setValidations((prevState) => ({ ...prevState, [type]: "" }));
  };

  const onSubmit = () => {
    setValidations({ password: "", confirmPassword: "" });
    if (data.password === "") {
      setValidations((prevState) => ({ ...prevState, password: "empty" }));
    } else if (!validatePassword(data.password)) {
      setValidations((prevState) => ({ ...prevState, password: "invalid" }));
    } else if (data.confirmPassword === "") {
      setValidations((prevState) => ({ ...prevState, confirmPassword: "empty" }));
    } else if (data.confirmPassword !== data.password) {
      setValidations((prevState) => ({ ...prevState, confirmPassword: "invalid" }));
    } else {
      dispatch(setLoading(true));
      AuthService.resetPassword(String(token), data.password)
        .then(() => {
          setSuccesse(true);
          dispatch(setLoading(false));
        })
        .catch(() => {
          setErrorMessage(
            "Failed to reset password. Please check if the reset password link is right.",
          );
          dispatch(setLoading(false));
        });
    }
  };

  const handleBackLogin = () => {
    navigate("/sign-in");
  };

  return (
    <IllustrationLayout title="Reset password" illustration={bgImage}>
      <MDBox component="form" role="form">
        <MDBox mb={1}>
          {success ? (
            <>
              <MDTypography fontSize="12px" mb={4} sx={{ color: "green" }}>
                Great! Password reset successful, now you can login with your new password.
              </MDTypography>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                className="w-full h-50 rounded-[20px] leading-20 uppercase"
                onClick={handleBackLogin}
              >
                BACK TO LOGIN
              </MDButton>
            </>
          ) : (
            <>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="New Password"
                  fullWidth
                  value={data.password}
                  onChange={(e) => {
                    onChange("password", e.target.value);
                  }}
                />
                {validations.password !== "" && (
                  <MDTypography fontSize="12px" mt={1} sx={{ color: "red" }}>
                    {validations.password === "empty"
                      ? "Please enter a password."
                      : "Password must be a minimum length of 8 characters and have one number and one special character ()?!@#$%&*"}
                  </MDTypography>
                )}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Confirm Password"
                  fullWidth
                  value={data.confirmPassword}
                  onChange={(e) => {
                    onChange("confirmPassword", e.target.value);
                  }}
                />
                {validations.confirmPassword !== "" && (
                  <MDTypography fontSize="12px" mt={1} sx={{ color: "red" }}>
                    {validations.confirmPassword === "empty"
                      ? "Please confirm your password."
                      : "Passwords do not match."}
                  </MDTypography>
                )}
                {errorMessage !== "" && (
                  <MDTypography fontSize="12px" mt={1} sx={{ color: "red" }}>
                    {errorMessage}
                  </MDTypography>
                )}
              </MDBox>
              <MDButton
                variant="gradient"
                color="info"
                size="large"
                fullWidth
                onClick={() => onSubmit()}
              >
                Submit
              </MDButton>
            </>
          )}
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}

export default ResetPassword;
