import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  Grid,
  Select,
  TextField,
  MenuItem,
} from "@mui/material";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ToastrService from "services/ToastrService";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { pageHeaderContainer, flexContainer, tabButton } from "layouts/common/styles";
import { AssetTabs, UserRole } from "layouts/common/constants";
import { retriveAsset, deleteAsset, setFilterAsset } from "store/slices/assetSlice";
import AssetsTable from "./components/AssetsTable";

function Assets() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminProfile = useSelector((state) => state.users.profile);

  const teams = useSelector((state) => state.teams);
  const totalCount = useSelector((state) => state.assets.totalCount);
  const assets = useSelector((state) => state.assets.assets);
  const filter = useSelector((state) => state.assets.filter);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [sortFilter, setSortFilter] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const fetchBySearchOptions = useCallback(() => {
    let search = "";
    if (adminProfile.role === UserRole.SuperAdmin && selectedTeams.length > 0) {
      search = selectedTeams.map((d) => d.id).join(",");
    } else if (adminProfile.role === UserRole.TeamAdmin) {
      search = adminProfile.teamId;
    }
    dispatch(retriveAsset({ search, filter, page, rowsPerPage, sortFilter, sortDirection }));
  }, [dispatch, filter, selectedTeams, page, rowsPerPage, sortFilter, sortDirection, adminProfile]);

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };

  const changePageNum = (direct) => {
    const maxPageNum = Math.floor((totalCount - 1) / rowsPerPage);
    if (page === 0 && direct === -1) return;
    if (page === maxPageNum && direct === 1) return;

    setPage((prevState) => prevState + direct);
  };

  const changeTab = (tab) => {
    dispatch(setFilterAsset(tab));
  };

  const handleAdd = () => {
    navigate("/assets/add");
  };

  const handleAddBonus = () => {
    navigate("/assets/add-bonus");
  };

  const sortHandler = (sort, direction) => {
    setSortFilter(sort);
    setSortDirection(direction);
  };

  const deleteHandler = (id) => {
    dispatch(deleteAsset(id))
      .unwrap()
      .then(() => {
        ToastrService.success("Successfully delete asset");
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to delete asset");
      });
  };

  useEffect(() => {
    fetchBySearchOptions();
  }, [fetchBySearchOptions]);

  return (
    <DashboardLayout>
      <MDBox py={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold">
            Assets
          </MDTypography>
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDBox>
              <MDButton size="small" color="secondary" onClick={handleAddBonus}>
                Bonus Asset +
              </MDButton>
            </MDBox>
            <MDBox>
              <MDButton size="small" color="secondary" onClick={handleAdd}>
                Asset +
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        {adminProfile.role === UserRole.SuperAdmin && (
          <MDBox>
            <MDTypography fontSize="12px" fontWeight="bold">
              Select Teams:{" "}
            </MDTypography>
            <Autocomplete
              multiple
              value={selectedTeams}
              options={teams}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => setSelectedTeams(v)}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              sx={{ maxWidth: "568px", width: "100%" }}
              placeholder="Select Teams"
            />
          </MDBox>
        )}
        <MDBox>
          <Grid container spacing={2} mt={3} mb={3}>
            <Grid item xs={12} md={3}>
              <MDButton
                size="large"
                color={filter === AssetTabs.Live ? "secondary" : "white"}
                sx={(theme) => tabButton(theme)}
                onClick={() => changeTab(AssetTabs.Live)}
              >
                Live
              </MDButton>
            </Grid>
            <Grid item xs={12} md={3}>
              <MDButton
                size="large"
                color={filter === AssetTabs.Upcoming ? "secondary" : "white"}
                sx={(theme) => tabButton(theme)}
                onClick={() => changeTab(AssetTabs.Upcoming)}
              >
                Upcoming
              </MDButton>
            </Grid>
            <Grid item xs={12} md={3}>
              <MDButton
                size="large"
                color={filter === AssetTabs.Draft ? "secondary" : "white"}
                sx={(theme) => tabButton(theme)}
                onClick={() => changeTab(AssetTabs.Draft)}
              >
                Draft
              </MDButton>
            </Grid>
            <Grid item xs={12} md={3}>
              <MDButton
                size="large"
                color={filter === AssetTabs.Expired ? "secondary" : "white"}
                sx={(theme) => tabButton(theme)}
                onClick={() => changeTab(AssetTabs.Expired)}
              >
                Expired
              </MDButton>
            </Grid>
          </Grid>
          <AssetsTable data={assets} deleteHandler={deleteHandler} sortHandler={sortHandler} />
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end" my={3}>
            <FormControl>
              <InputLabel id="rows_per_page">Rows per page</InputLabel>
              <Select
                labelId="rows_per_page"
                id="rows_per_page_select"
                value={rowsPerPage}
                label="Rows per page"
                onChange={handleChangeRowsPerPage}
                sx={{ height: "38px", width: "150px", minWidth: "50px" }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>
            </FormControl>
            <MDTypography fontSize="12px" fontWeight="bold">
              {totalCount > 0 ? page * rowsPerPage + 1 : "0"}
              &nbsp;~&nbsp;
              {totalCount > page * rowsPerPage + rowsPerPage
                ? page * rowsPerPage + rowsPerPage
                : totalCount}
              &nbsp;of&nbsp;
              {totalCount}
            </MDTypography>

            <MDButton icon="text" onClick={() => changePageNum(-1)}>
              <FaArrowLeft />
            </MDButton>
            <MDButton icon="text" onClick={() => changePageNum(1)}>
              <FaArrowRight />
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Assets;
