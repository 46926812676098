import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import { Autocomplete, Divider, Grid, TextField } from "@mui/material";

import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";

import GameService from "services/GamesService";
import { setLoading } from "store/appSlice";

import { GameTypes } from "layouts/common/constants";

import FormField from "components/FormField";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .MuiOutlinedInput-root .MuiAutocomplete-input`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
    padding: 0,
    width: "35px",
  },
  [`& .MuiOutlinedInput-root`]: {
    padding: "0px",
    paddingLeft: "10px",
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

function TriviaQuestionsForm({ item, prevHandler, nextHandler, changeHandler }) {
  const initialQuestionState = [
    {
      question: "",
      options: [
        { optionText: "", isCorrect: false },
        { optionText: "", isCorrect: false },
        { optionText: "", isCorrect: false },
        { optionText: "", isCorrect: false },
      ],
    },
  ];
  const validationQuestionState = [
    {
      question: "",
      options: [
        { optionText: "", isCorrect: false },
        { optionText: "", isCorrect: false },
        { optionText: "", isCorrect: false },
        { optionText: "", isCorrect: false },
      ],
    },
  ];

  const [trivia, setTrivia] = useState({});
  const [validationMsg, setValidationMsg] = useState(validationQuestionState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (item.triviaQuestions.length > 0) {
      setTrivia(item);
      // Add questions to validation
      const validationQuestions = item.triviaQuestions.map(() => ({
        question: "",
        options: [
          { optionText: "", isCorrect: false },
          { optionText: "", isCorrect: false },
          { optionText: "", isCorrect: false },
          { optionText: "", isCorrect: false },
        ],
      }));
      setValidationMsg(validationQuestions);
    } else {
      setTrivia({ ...item, triviaQuestions: initialQuestionState });
    }
  }, [item]);

  const validate = () => {
    let validated = true;
    const errorMsgs = [...validationMsg];
    for (let i = 0; i < trivia.triviaQuestions.length; i += 1) {
      if (trivia.triviaQuestions[i].question === "") {
        errorMsgs[i].question = "empty";
        validated = false;
      } else {
        errorMsgs[i].question = "";
      }
      for (let j = 0; j < trivia.triviaQuestions[i].options.length; j += 1) {
        if (trivia.triviaQuestions[i].options[j].optionText === "") {
          errorMsgs[i].options[j].optionText = "empty";
          validated = false;
        } else {
          errorMsgs[i].options[j].optionText = "";
        }
      }
    }
    setValidationMsg(errorMsgs);
    return validated;
  };
  const handleUpdateQuestion = (questionIdx, _question) => {
    const temp = [...trivia.triviaQuestions];
    temp[questionIdx] = { ...temp[questionIdx], question: _question };
    setTrivia({ ...trivia, triviaQuestions: temp });
  };

  const handleUpdateOption = (questionIdx, optionIdx, _option) => {
    const temp = [...trivia.triviaQuestions];
    temp[questionIdx].options[optionIdx] = {
      ...temp[questionIdx].options[optionIdx],
      optionText: _option,
    };
    setTrivia({ ...trivia, triviaQuestions: temp });
  };

  const handleUpdateCorrect = (questionIdx, correctOptionId) => {
    const temp = [...trivia.triviaQuestions];
    const idx = temp[questionIdx].options.findIndex((d) => d.isCorrect === true);
    if (idx !== -1) temp[questionIdx].options[idx].isCorrect = false;
    temp[questionIdx].options[correctOptionId].isCorrect = true;
    setTrivia({ ...trivia, triviaQuestions: temp });
  };

  const getCorrectOptionId = (questionIdx) => {
    const temp = [...trivia.triviaQuestions];
    const idx = temp[questionIdx].options.findIndex((d) => d.isCorrect === true);
    if (idx !== -1) return { value: idx, label: `Option ${idx + 1}` };
    return {};
  };

  const handleAddQuestion = () => {
    const tempValidation = [
      ...validationMsg,
      {
        question: "",
        options: [
          { optionText: "", isCorrect: false },
          { optionText: "", isCorrect: false },
          { optionText: "", isCorrect: false },
          { optionText: "", isCorrect: false },
        ],
      },
    ];
    setValidationMsg(tempValidation);

    const temp = [
      ...trivia.triviaQuestions,
      {
        question: "",
        options: [
          { optionText: "", isCorrect: false },
          { optionText: "", isCorrect: false },
          { optionText: "", isCorrect: false },
          { optionText: "", isCorrect: false },
        ],
      },
    ];

    setTrivia({ ...trivia, triviaQuestions: temp });
  };

  const handleRemoveQuestion = () => {
    const temp = [...trivia.triviaQuestions];
    const tempValidation = [...validationMsg];
    if (temp.length > 1) {
      temp.pop();
      tempValidation.pop();
      setTrivia({ ...trivia, triviaQuestions: temp });
      setValidationMsg(tempValidation);
    }
  };

  const handlePrev = () => {
    prevHandler();
  };

  const handleNext = () => {
    if (!validate()) {
      return;
    }
    if (changeHandler(trivia)) {
      nextHandler();
    }
  };

  async function getTrivia(_id) {
    let temp = null;
    dispatch(setLoading(true));
    await GameService.get(_id, GameTypes.Trivia)
      .then((response) => {
        temp = response.data;
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
    return temp;
  }

  const handleReset = async () => {
    if (trivia.id) {
      const temp = await getTrivia(trivia.id);
      setTrivia({ ...trivia, triviaQuestions: temp.triviaQuestions });
      // Add questions to validation
      const validationQuestions = temp.triviaQuestions.map(() => ({
        question: "",
        options: [
          { optionText: "", isCorrect: false },
          { optionText: "", isCorrect: false },
          { optionText: "", isCorrect: false },
          { optionText: "", isCorrect: false },
        ],
      }));
      setValidationMsg(validationQuestions);
    } else {
      setTrivia({ ...trivia, triviaQuestions: initialQuestionState });
      setValidationMsg(validationQuestionState);
    }
  };

  return (
    <MDBox>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "200px" }}>
          Trivia - Questions
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handlePrev()}>
            Back
          </MDButton>
          <MDButton size="small" onClick={() => handleReset()}>
            Reset
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox sx={(theme) => customFormContainer(theme)}>
        {trivia.triviaQuestions?.map((q, questionIdx) => (
          <MDBox key={`question-${questionIdx.toString()}`}>
            <MDTypography
              fontSize="15px"
              fontWeight="bold"
              mb={3}
              sx={{ textDecoration: "underline" }}
            >
              Question {questionIdx + 1}
            </MDTypography>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3}>
                <MDTypography fontSize="15px">title</MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <FormField
                  value={q.question}
                  onChange={(e) => handleUpdateQuestion(questionIdx, e.target.value)}
                  error={validationMsg[questionIdx].question !== ""}
                />
              </Grid>
              {q.options.map((o, optionIdx) => (
                <Grid item xs={12} sm={6}>
                  <MDBox>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <MDTypography fontSize="15px">Option {optionIdx + 1}</MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          value={o.optionText}
                          onChange={(e) =>
                            handleUpdateOption(questionIdx, optionIdx, e.target.value)
                          }
                          error={validationMsg[questionIdx].options[optionIdx].optionText !== ""}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              ))}
              <Grid item xs={12} sm={3}>
                <MDTypography fontSize="15px">Correct Option</MDTypography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <StyledAutocomplete
                  value={getCorrectOptionId(questionIdx)}
                  options={q.options.map((d, optionIdx) => ({
                    value: optionIdx,
                    label: `Option ${optionIdx + 1}`,
                  }))}
                  getOptionLabel={(option) => (option.label ? option.label : "")}
                  onChange={(e, v) => handleUpdateCorrect(questionIdx, v.value)}
                  renderInput={(params) => (
                    <TextField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                  placeholder="Choose type"
                />
              </Grid>
            </Grid>
          </MDBox>
        ))}
      </MDBox>
      <MDBox
        sx={(theme) => {
          flexContainer(theme);
        }}
      >
        <MDButton size="small" color="secondary" onClick={() => handleAddQuestion()}>
          Question +
        </MDButton>
        {trivia.triviaQuestions?.length > 1 && (
          <MDButton
            size="small"
            color="secondary"
            sx={{ marginLeft: "10px" }}
            onClick={() => handleRemoveQuestion()}
          >
            Question -
          </MDButton>
        )}
      </MDBox>
      <Grid container justifyContent="flex-end" gap={2}>
        <Grid item>
          <MDButton color="secondary" size="large" onClick={() => handlePrev()}>
            Prev
          </MDButton>
        </Grid>
        <Grid item>
          <MDButton color="secondary" size="large" onClick={() => handleNext()}>
            Next
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

TriviaQuestionsForm.defaultProps = {
  item: null,
  prevHandler: {},
  nextHandler: {},
  changeHandler: {},
};

TriviaQuestionsForm.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  prevHandler: PropTypes.func,
  nextHandler: PropTypes.func,
  changeHandler: PropTypes.func,
};

export default TriviaQuestionsForm;
