import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Custom styles for DashboardNavbar
import {
  // navbar,
  navbarContainer,
  navbarRow,
  // navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setTransparentNavbar, setMiniSidenav } from "context";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import AuthService from "services/AuthService";
import TeamsService from "services/TeamService";
import { UserRole } from "layouts/common/constants";
import { setAdminProfile } from "store/slices/userSlice";

function AdminNavbar({ absolute, light, isMini }) {
  const adminProfile = useSelector((state) => state.users.profile);
  const dispatch = useDispatch();
  const [team, setTeam] = useState();

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatchApp] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;

  const navigate = useNavigate();

  const getTeam = (_id) => {
    TeamsService.get(_id).then(async (response) => {
      setTeam(response.data.team);
    });
  };

  useEffect(() => {
    if (adminProfile.teamId && adminProfile.teamId !== "") {
      getTeam(adminProfile.teamId);
    }
  }, [adminProfile]);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatchApp, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatchApp, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatchApp, !miniSidenav);

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const handleLogout = () => {
    dispatch(
      setAdminProfile({
        id: "",
        role: "",
        teamId: "",
        email: "",
      }),
    );
    if (AuthService.logout()) {
      navigate("/sign-in");
    }
  };

  return (
    <AppBar position={absolute ? "absolute" : navbarType} color="inherit">
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          {adminProfile.role === UserRole.TeamAdmin &&
            team?.appLogoImage &&
            team?.appLogoImage !== "" && (
              <img src={team.appLogoImage} alt="logo" style={{ height: "80px" }} />
            )}
          {adminProfile.role === UserRole.SuperAdmin && (
            <img src="/images/SportzFan.png" alt="logo" style={{ height: "80px" }} />
          )}
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <MDButton size="small" variant="text" color="dark" onClick={() => handleLogout()}>
                logout
              </MDButton>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
AdminNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default AdminNavbar;
