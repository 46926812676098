import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Divider, Grid } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";

import { setLoading } from "store/appSlice";
import AssetService from "services/AssetService";
import ToastrService from "services/ToastrService";

import { dateToDateAndTime } from "utils/converter";

function InventoryBonusAsset() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [detail, setDetail] = useState({});
  const [result, setResult] = useState({});

  const getAsset = (_id) => {
    AssetService.get(_id)
      .then((response) => {
        const data = { ...response.data };
        const startDateTime = dateToDateAndTime(new Date(data.start));
        const endDateTime = dateToDateAndTime(new Date(data.end));
        data.startDate = startDateTime.date;
        data.startTime = startDateTime.time;
        data.endDate = endDateTime.date;
        data.endTime = endDateTime.time;
        data.logo = data.imageUrl;
        setDetail(data);
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load asset data.");
      });
  };

  const getAssetResult = (_id) => {
    dispatch(setLoading(true));
    AssetService.getResult(_id)
      .then((response) => {
        const data = { ...response.data };
        setResult(data);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(
          e.response?.data?.message?.message || "Failed to load asset result data.",
        );
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      getAsset(id);
      getAssetResult(id);
    }
  }, [id]);

  const handleBack = () => {
    navigate("/assets");
  };

  const handleSendReward = () => {
    navigate(`/assets/send-bonus/${id}`);
  };

  return (
    <DashboardLayout>
      {detail.id ? (
        <MDBox>
          <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
            <MDTypography width="100%" fontSize="20px" fontWeight="bold" sx={{ minWidth: "150px" }}>
              {`${detail?.title} - Inventory`}
            </MDTypography>
            <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
              <MDButton size="small" onClick={handleBack}>
                Back
              </MDButton>
            </MDBox>
          </MDBox>
          <Divider />
          <MDBox sx={(theme) => customFormContainer(theme)}>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Sponsor / Team
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{detail.type}</MDTypography>
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Team
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{detail.team?.name}</MDTypography>
              </Grid>
            </Grid>
            <MDTypography
              fontSize="15px"
              fontWeight="bold"
              mb={3}
              sx={{ textDecoration: "underline" }}
            >
              Details
            </MDTypography>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Asset Category
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{detail.category}</MDTypography>
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Asset Title
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{detail.title}</MDTypography>
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Image
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                {detail.logo !== null && detail.logo !== "" && (
                  <MDBox
                    component="img"
                    src={detail.logo}
                    alt="asset image"
                    shadow="md"
                    width="250px"
                    height="250px"
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Start Date & Time
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12} md={8.5}>
                <MDTypography fontSize="15px">
                  {" "}
                  {`${detail.startDate} ${detail.startTime}`}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Expiration Date & Time
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12} md={8.5}>
                <MDTypography fontSize="15px">
                  {" "}
                  {`${detail.endDate} ${detail.endTime}`}{" "}
                </MDTypography>
              </Grid>
            </Grid>
            <MDTypography
              fontSize="15px"
              fontWeight="bold"
              mb={3}
              sx={{ textDecoration: "underline" }}
            >
              Inventory Detail
            </MDTypography>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Total Asset Count
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{detail.totalCount}</MDTypography>
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Asset Rewarded Count
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{result?.participants?.length ?? 0}</MDTypography>
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  On Hand
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">
                  {detail.totalCount - result?.participants?.length ?? 0}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Asset claim count
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">
                  {result?.participants?.length > 0
                    ? result?.participants?.reduce(
                        (prev, participant) => (participant.claimDate !== null ? prev + 1 : prev),
                        0,
                      )
                    : 0}
                </MDTypography>
              </Grid>
            </Grid>
            <MDTypography
              fontSize="15px"
              fontWeight="bold"
              mb={3}
              sx={{ textDecoration: "underline" }}
            >
              User Details
            </MDTypography>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Total Users Count
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">
                  {
                    [...new Set(result.participants?.map((participant) => participant.userId))]
                      .length
                  }
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <Grid container justifyContent="flex-end" gap={2} mb={3}>
            <Grid item>
              <MDButton color="secondary" size="large" onClick={handleBack}>
                Back
              </MDButton>
            </Grid>
            <Grid item>
              <MDButton color="secondary" size="large" onClick={handleSendReward}>
                Send Reward
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <MDTypography fontSize="15px" fontWeight="bold">
          No data found.
        </MDTypography>
      )}
    </DashboardLayout>
  );
}

export default InventoryBonusAsset;
