import { GameTypes, GameTabs } from "layouts/common/constants";
import http from "../apis/http-common";

const retrive = (filter, search, page, rowsPerPage, sortFilter = "", sortDirection = "") => {
  const skip = page * rowsPerPage;
  const sortParams =
    sortFilter !== "" && sortDirection !== ""
      ? `&sort=${sortFilter}&direction=${sortDirection}`
      : "";
  if (filter === GameTabs.Draft) {
    return http.get(
      `/game/list?skip=${skip}&take=${rowsPerPage}&search=${search}&isDraft=true${sortParams}`,
    );
  }
  if (filter === "") {
    return http.get(
      `/game/list?skip=${skip}&take=${rowsPerPage}&search=${search}&isDraft=false${sortParams}`,
    );
  }
  return http.get(
    `/game/list?skip=${skip}&take=${rowsPerPage}&search=${search}&filter=${filter}&isDraft=false${sortParams}`,
  );
};

const retrivePrediction = (page, rowsPerPage, isVerified = false, search) => {
  const skip = page * rowsPerPage;
  return http.get(
    `/game/prediction?skip=${skip}&take=${rowsPerPage}&isVerified=${isVerified}${
      search ? `&search=${search}` : ""
    }`,
  );
};

const retriveMilestone = (page, rowsPerPage, isVerified = false, search) => {
  const skip = page * rowsPerPage;
  return http.get(
    `/milestone/milestone?skip=${skip}&take=${rowsPerPage}&isVerified=${isVerified}${
      search ? `&search=${search}` : ""
    }`,
  );
};

const get = (id, type) => {
  if (type === GameTypes.Trivia) {
    return http.get(`/game/trivia/${id}`);
  }
  if (type === GameTypes.Prediction) {
    return http.get(`/game/prediction/${id}`);
  }
  if (type === GameTypes.Milestone) {
    return http.get(`/milestone/milestone/${id}`);
  }
  if (type === GameTypes.MiniGame) {
    return http.get(`/game/mini-game/${id}`);
  }
  return null;
};

const create = (type, data) => {
  if (type === GameTypes.Trivia) {
    return http.post("/game/trivia", data);
  }
  if (type === GameTypes.Prediction) {
    return http.post("/game/prediction", data);
  }
  if (type === GameTypes.Milestone) {
    return http.post("/milestone/milestone", data);
  }
  if (type === GameTypes.MiniGame) {
    return http.post("/game/mini-game", data);
  }
  return null;
};

const update = (type, id, data) => {
  if (type === GameTypes.Trivia) {
    return http.put(`/game/trivia/${id}`, data);
  }
  if (type === GameTypes.Prediction) {
    return http.put(`/game/prediction/${id}`, data);
  }
  if (type === GameTypes.Milestone) {
    return http.put(`/milestone/milestone/${id}`, data);
  }
  if (type === GameTypes.MiniGame) {
    return http.put(`/game/mini-game/${id}`, data);
  }
  return null;
};

const remove = (type, id) => {
  if (type === GameTypes.Trivia) {
    return http.delete(`/game/trivia/${id}`);
  }
  if (type === GameTypes.Prediction) {
    return http.delete(`/game/prediction/${id}`);
  }
  if (type === GameTypes.Milestone) {
    return http.delete(`/milestone/milestone/${id}`);
  }
  if (type === GameTypes.MiniGame) {
    return http.delete(`/game/mini-game/${id}`);
  }
  return null;
};

const updateResult = (type, id, data) => {
  if (type === GameTypes.Prediction) {
    return http.put(`/game/prediction/result/${id}`, data);
  }
  if (type === GameTypes.Milestone) {
    return http.put(`/milestone/milestone/${id}`, data);
  }
  return null;
};

const getResult = (type, id) => {
  if (type === GameTypes.Trivia) {
    return http.get(`/game/trivia/result/${id}`);
  }
  if (type === GameTypes.Prediction) {
    return http.get(`/game/prediction/result/${id}`);
  }
  if (type === GameTypes.MiniGame) {
    return http.get(`/game/mini-game/result/${id}`);
  }
  if (type === GameTypes.Milestone) {
    return http.get(`/milestone/milestone/result/${id}`);
  }
  return null;
};

const finish = (type, id) => {
  if (type === GameTypes.Trivia) {
    return http.post(`/game/trivia/finish/${id}`);
  }
  if (type === GameTypes.Prediction) {
    return http.post(`/game/prediction/finish/${id}`);
  }
  if (type === GameTypes.MiniGame) {
    return http.post(`/game/mini-game/finish/${id}`);
  }
  return null;
};

const verifyOccurNumber = (count, id) =>
  http.put(`/milestone/occur-milestone/${id}/count/${count}`);

const getBonusAssets = (search, page, rowsPerPage) => {
  const skip = page * rowsPerPage;
  return http.get(`/asset/list?skip=${skip}&take=${rowsPerPage}&search=${search}`);
};

const upload = (formData) => http.post("/upload/game", formData);

const GameService = {
  retrive,
  get,
  create,
  update,
  remove,
  getResult,
  updateResult,
  finish,
  getBonusAssets,
  verifyOccurNumber,
  retrivePrediction,
  retriveMilestone,
  upload,
};

export default GameService;
