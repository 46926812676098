import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  customFormContainer,
  flexContainer,
  pageHeaderContainer,
  hiddenOnMobile,
} from "layouts/common/styles";
import FormField from "components/FormField";

import { GameTypes, PredictionType } from "layouts/common/constants";
import { setLoading } from "store/appSlice";
import GameService from "services/GamesService";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { dateToDateAndTime } from "utils/converter";
import ToastrService from "services/ToastrService";

function ViewPrediction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState(null);
  const [verifyData, setVerifyData] = useState({
    resultMain: null,
    resultOpposition: null,
  });

  const [status, setStatus] = useState("");

  const getGame = (_id) => {
    dispatch(setLoading(true));
    GameService.get(_id, GameTypes.Prediction)
      .then((response) => {
        const temp = { ...response.data };

        if (temp.isDraft === true) {
          setStatus("Draft");
        } else if (temp.start < new Date().toISOString() && temp.end > new Date().toISOString()) {
          setStatus("Live");
        } else if (temp.start > new Date().toISOString()) {
          setStatus("Upcoming");
        } else {
          setStatus("Past");
        }

        const startDateTime = dateToDateAndTime(new Date(temp.start));
        const endDateTime = dateToDateAndTime(new Date(temp.end));

        temp.start = `${startDateTime.date}  ${startDateTime.time}`;
        temp.end = `${endDateTime.date}  ${endDateTime.time}`;

        setData(temp);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load predict data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) getGame(id);
  }, [id]);

  const handleViewResult = () => {
    navigate(`/game/result-prediction/${id}`);
  };

  const handleEdit = () => {
    navigate(`/game/edit-prediction/${id}`);
  };

  const handleChangeNumber = (field, value) => {
    setVerifyData((prevState) => ({ ...prevState, [field]: parseFloat(value) }));
  };

  const handleFinish = async () => {
    dispatch(setLoading(true));
    GameService.finish(GameTypes.Prediction, id)
      .then((response) => {
        const temp = { ...response.data };
        if (temp.success) {
          ToastrService.success("Successfully finished prediction game.");
          setData((prevState) => ({ ...prevState, isEnded: true }));
          dispatch(setLoading(false));
        } else {
          ToastrService.error(temp.message);
          dispatch(setLoading(false));
        }
      })
      .catch((e) => {
        ToastrService.error(
          e.response?.data?.message?.message || "Failed to finish prediction game.",
        );
        dispatch(setLoading(false));
      });
  };

  const handleVerify = async () => {
    await GameService.updateResult(GameTypes.Prediction, id, verifyData)
      .then((response) => {
        const temp = { ...response.data };
        if (temp.success) {
          ToastrService.success("Successfully saved the final score");
          setData((prevState) => ({ ...prevState, resultMain: verifyData.resultMain }));
          setData((prevState) => ({
            ...prevState,
            resultOpposition: verifyData.resultOpposition,
          }));
          dispatch(setLoading(false));
          handleFinish();
        } else {
          ToastrService.error(response.message);
        }
      })
      .catch((e) => {
        ToastrService.error(
          e.response?.data?.message?.message || "Failed to save new final score.",
        );
      });
  };

  const handleBack = () => {
    navigate(`/games`);
  };

  return (
    <DashboardLayout>
      <MDBox my={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "250px" }}>
            Prediction - View
          </MDTypography>
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDButton size="small" onClick={() => handleBack()}>
              Back
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox sx={(theme) => customFormContainer(theme)}>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Details
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.team?.name}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Predict Type
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.predictionType}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Match / Event
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.match?.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Predict Title
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Predict Description
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.description}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Start Date & Time
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={8.5}>
              <MDTypography fontSize="15px">{data?.start}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Expiration Date & Time
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={8.5}>
              <MDTypography fontSize="15px">{data?.end}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Eligibility
          </MDTypography>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of Kudos Points
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{data?.eligbleKudos}</MDTypography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of Tokens
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{data?.eligbleToken}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Rewards
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} md={6}>
              <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
                For participation
              </MDTypography>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    No of Kudos Points(Per player)
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography fontSize="15px">{data?.rewardKudosAll}</MDTypography>
                </Grid>
              </Grid>
              <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
                For Winners
              </MDTypography>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    No of Kudos Points(Total)
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography fontSize="15px">{data?.rewardKudosWinnerTotal}</MDTypography>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    No of Tokens(Total)
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography fontSize="15px">{data?.rewardTokenWinnerTotal}</MDTypography>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    No of Winners
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography fontSize="15px">{data?.rewardDistribution?.length}</MDTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
                Reward distribution
              </MDTypography>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={4} sm={4}>
                  &nbsp;
                </Grid>
                <Grid item xs={4} sm={4}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    KUDOS
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Tokens
                  </MDTypography>
                </Grid>
              </Grid>
              {data?.rewardDistribution
                .sort((a, b) => {
                  if (a.winnerOrder > b.winnerOrder) return 1;
                  if (a.winnerOrder < b.winnerOrder) return -1;
                  return 0;
                })
                .map((d) => (
                  <Grid key={d.id} container spacing={2} mb={2}>
                    <Grid item xs={4} sm={4}>
                      <MDTypography fontSize="15px" fontWeight="bold">
                        Winner #{d.winnerOrder}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <MDTypography fontSize="15px">{d.rewardKudos}</MDTypography>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <MDTypography fontSize="15px">{d.rewardToken}</MDTypography>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Divider />
          <MDBox>
            <MDTypography
              fontSize="15px"
              fontWeight="bold"
              mb={3}
              sx={{ textDecoration: "underline" }}
            >
              Sponsors
            </MDTypography>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3}>
                <MDTypography fontSize="15px">Game Sponsor</MDTypography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <MDTypography fontSize="15px">{data?.sponsor?.title}</MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          {data?.predictionType === PredictionType.Score && data?.resultMain !== null && (
            <MDBox>
              <MDTypography
                fontSize="15px"
                fontWeight="bold"
                mb={3}
                sx={{ textDecoration: "underline" }}
              >
                Correct Score
              </MDTypography>
              <Grid container spacing={2} mb={4}>
                <Grid item xs={12} sm={3}>
                  <MDTypography fontSize="15px">Final score</MDTypography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <MDBox>
                    <Grid container spacing={2} mb={4}>
                      <Grid item xs={12} sm={5}>
                        <MDBox
                          sx={(theme) => flexContainer(theme)}
                          style={{ justifyContent: "space-between", alignItems: "start" }}
                        >
                          <MDTypography fontSize="15px">
                            {data?.match?.homeTeam?.name || "Home Team"}
                          </MDTypography>
                          <MDTypography fontSize="15px" fontWeight="bold">
                            {data?.resultMain}
                          </MDTypography>
                          <MDTypography fontSize="15px" sx={(theme) => hiddenOnMobile(theme)}>
                            -
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <MDBox
                          sx={(theme) => flexContainer(theme)}
                          style={{ justifyContent: "space-between", alignItems: "start" }}
                        >
                          <MDTypography fontSize="15px">
                            {data?.match?.awayTeam?.name || "Away Team"}
                          </MDTypography>
                          <MDTypography fontSize="15px" fontWeight="bold">
                            {data?.resultOpposition}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          )}
          {data?.predictionType === PredictionType.Score &&
            data?.resultMain === null &&
            status !== "Upcoming" &&
            status !== "Draft" && (
              <MDBox>
                <MDTypography
                  fontSize="15px"
                  fontWeight="bold"
                  mb={3}
                  sx={{ textDecoration: "underline" }}
                >
                  Correct Score
                </MDTypography>
                <Grid container spacing={2} mb={4}>
                  <Grid item xs={12} sm={2}>
                    <MDTypography fontSize="15px">Final score</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <MDBox>
                      <Grid container spacing={2} mb={4}>
                        <Grid item xs={12} sm={5}>
                          <MDBox
                            sx={(theme) => flexContainer(theme)}
                            style={{ justifyContent: "space-between", alignItems: "start" }}
                          >
                            <MDTypography fontSize="15px">
                              {data?.match?.homeTeam?.name || "Home Team"}
                            </MDTypography>
                            <FormField
                              sx={{ minWidth: "120px", maxWidth: "120px" }}
                              type="number"
                              value={verifyData.resultMain}
                              onChange={(e) => handleChangeNumber("resultMain", e.target.value)}
                            />
                            <MDTypography fontSize="15px" sx={(theme) => hiddenOnMobile(theme)}>
                              -
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <MDBox
                            sx={(theme) => flexContainer(theme)}
                            style={{ justifyContent: "space-between", alignItems: "start" }}
                          >
                            <MDTypography fontSize="15px">
                              {data?.match?.awayTeam?.name || "Away Team"}
                            </MDTypography>
                            <FormField
                              sx={{ minWidth: "120px", maxWidth: "120px" }}
                              type="number"
                              value={verifyData.resultOpposition}
                              onChange={(e) =>
                                handleChangeNumber("resultOpposition", e.target.value)
                              }
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <MDBox>
                            <MDButton color="secondary" size="small" onClick={() => handleVerify()}>
                              Verify
                            </MDButton>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            )}
        </MDBox>
        <Grid container justifyContent="flex-end" gap={2}>
          <Grid item>
            {!data?.isEnded && data?.resultMain !== null && (
              <MDButton color="secondary" size="large" onClick={() => handleFinish()}>
                Finish
              </MDButton>
            )}
            {data?.isEnded && (
              <MDButton color="secondary" size="large" onClick={() => handleViewResult()}>
                View Results
              </MDButton>
            )}
          </Grid>
          <Grid item>
            <MDButton color="secondary" size="large" onClick={() => handleEdit()}>
              Edit
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ViewPrediction;
