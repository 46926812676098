import { createContext, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { useLocalStorage } from "hooks/useLocalStorage";

const defaultContextValue = {
  loaded: false,
  token: null,
  addToken: () => null,
};

export const AuthContext = createContext(defaultContextValue);

function AuthProvider({ children }) {
  const [storedToken, setStoredToken] = useLocalStorage("admin-token", null);
  const [token, setToken] = useState(storedToken);
  const [loaded, setLoaded] = useState(false);

  const addToken = (value) => {
    setToken(value);
    setStoredToken(value);
  };

  useEffect(() => {
    setToken(storedToken);
    setLoaded(true);
  }, [storedToken]);

  const values = useMemo(
    () => ({
      loaded,
      token,
      addToken,
    }),
    [loaded, token],
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
