import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Checkbox, Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import FormField from "components/FormField";

import { GameTypes } from "layouts/common/constants";

import { setLoading } from "store/appSlice";
import GameService from "services/GamesService";

import { dateToDateAndTime } from "utils/converter";
import ToastrService from "services/ToastrService";

function ViewMilestone() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState({
    anyWithVenue: false,
    onlyInVenue: false,
    enableAssetReward: false,
  });
  const [status, setStatus] = useState("");

  const [occurCount, setOccurCount] = useState(0);

  const getGame = (_id) => {
    dispatch(setLoading(true));
    GameService.get(_id, GameTypes.Milestone)
      .then((response) => {
        const temp = { ...response.data };

        if (temp.isDraft === true) {
          setStatus("Draft");
        } else if (temp.start < new Date().toISOString() && temp.end > new Date().toISOString()) {
          setStatus("Live");
        } else if (temp.start > new Date().toISOString()) {
          setStatus("Upcoming");
        } else {
          setStatus("Past");
        }

        const startDateTime = dateToDateAndTime(new Date(temp.start));
        const endDateTime = dateToDateAndTime(new Date(temp.end));

        temp.start = `${startDateTime.date}  ${startDateTime.time}`;
        temp.end = `${endDateTime.date}  ${endDateTime.time}`;

        setData(temp);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load milestone data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) getGame(id);
  }, [id]);

  const handleViewResult = () => {
    navigate(`/game/result-milestone/${id}`);
  };

  const handleVerify = () => {
    if (occurCount < 1) return;
    GameService.verifyOccurNumber(occurCount, id)
      .then((response) => {
        const temp = { ...response.data };
        ToastrService.success("Successfully verified occur count.");
        setData((prevState) => ({ ...prevState, occurCount: temp.occurCount }));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to verify occur count.");
      });
  };

  const handleEdit = () => {
    navigate(`/game/edit-milestone/${id}`);
  };

  const handleBack = () => {
    navigate(`/games`);
  };

  return (
    <DashboardLayout>
      <MDBox my={4}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "250px" }}>
            Milestone - View
          </MDTypography>
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDButton size="small" onClick={() => handleBack()}>
              Back
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox sx={(theme) => customFormContainer(theme)}>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Details
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.team?.name}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Match/Event
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.match?.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Milestone Title
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Milestone Description
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.description}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Start Date & Time
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={8.5}>
              <MDTypography fontSize="15px">{data?.start}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Expiration Date & Time
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={8.5}>
              <MDTypography fontSize="15px">{data?.end}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Eligibility
          </MDTypography>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of Kudos Points
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{data?.eligbleKudos}</MDTypography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of Tokens
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{data?.eligbleToken}</MDTypography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                User must be checked in to match (in venue or out of venue)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Checkbox disabled checked={data?.anyWithVenue} />
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                User must be checked in to match (IN VENUE only)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <Checkbox disabled checked={data?.onlyInVenue} />
            </Grid>
          </Grid>
          <Divider />
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Rewards
          </MDTypography>
          <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
            For each time the event occurs
          </MDTypography>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of Kudos Points(per player)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{data?.rewardKudosPerPlayer}</MDTypography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of Tokens(per player)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{data?.rewardTokenPerPlayer}</MDTypography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Send asset as reward?
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <Checkbox disabled checked={data?.enableAssetReward} />
            </Grid>
            {data?.enableAssetReward && (
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  select asset
                </MDTypography>
              </Grid>
            )}
            {data?.enableAssetReward && (
              <Grid item xs={12} sm={4} md={5}>
                <MDTypography fontSize="15px">{data?.asset?.title}</MDTypography>
              </Grid>
            )}
          </Grid>
          {data?.enableAssetReward && (
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={3}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  No of Bonus Asset to send per winner
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={2} md={1.5}>
                <MDTypography fontSize="15px">{data?.rewardAssetCount}</MDTypography>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Number of top ranked scores to send to
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{data?.winnerLimit}</MDTypography>
            </Grid>
          </Grid>
          <Divider />
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Sponsors
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Game Sponsor</MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">{data?.sponsor?.title}</MDTypography>
            </Grid>
          </Grid>
          <Divider />
          {(data?.occurCount > 0 ||
            (data?.occurCount === 0 && !data?.isEnded && status === "Live")) && (
            <MDTypography
              fontSize="15px"
              fontWeight="bold"
              mb={3}
              sx={{ textDecoration: "underline" }}
            >
              Result
            </MDTypography>
          )}

          {data?.occurCount > 0 && (
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={5}>
                <MDTypography fontSize="15px">Number of times the Milestone occurred</MDTypography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <MDTypography fontSize="15px">{data?.occurCount}</MDTypography>
              </Grid>
            </Grid>
          )}
          {data?.occurCount === 0 && status !== "Upcoming" && status !== "Draft" && (
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={5}>
                <MDTypography fontSize="15px">Number of times the Milestone occurred</MDTypography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormField
                  type="number"
                  value={occurCount}
                  onChange={(e) => setOccurCount(parseFloat(e.target.value))}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <MDButton size="small" color="secondary" onClick={() => handleVerify()}>
                  Verify
                </MDButton>
              </Grid>
            </Grid>
          )}
        </MDBox>

        <Grid container justifyContent="flex-end" gap={2} mt={10}>
          <Grid item>
            <MDButton color="secondary" size="large" onClick={() => handleViewResult()}>
              View Results
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton color="secondary" size="large" onClick={() => handleEdit()}>
              Edit
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ViewMilestone;
