import { useState, useEffect, useCallback } from "react";

import PropTypes from "prop-types";

import { Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { flexContainer, pageHeaderContainer } from "layouts/common/styles";
import { dateToDateAndTime } from "utils/converter";
import ViewMiniGameDetail from "../../ViewMiniGame/Details";
import ViewMiniGameTheme from "../../ViewMiniGame/Theme";
import ViewMiniGameReward from "../../ViewMiniGame/Rewards";

function MiniGameSummaryForm({ item, saveHandler, prevHandler }) {
  const [data, setData] = useState({});
  // 0 - detail, 1-theme 2-reward
  const [activeView, setActiveView] = useState(0);

  useEffect(() => {
    if (item) {
      const temp = { ...item };
      const startDateTime = dateToDateAndTime(new Date(temp.start));
      const endDateTime = dateToDateAndTime(new Date(temp.end));
      temp.start = `${startDateTime.date}  ${startDateTime.time}`;
      temp.end = `${endDateTime.date}  ${endDateTime.time}`;
      setData(temp);
    }
  }, [item]);

  let debounceTimeout;

  const handleSave = useCallback(
    (isDraft = false) =>
      () => {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
          saveHandler(isDraft);
        }, 1000);
      },
    [saveHandler],
  );

  const handleBack = () => {
    prevHandler();
  };

  return (
    <MDBox my={3}>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ maxWidth: "500px", width: "100%" }}>
          {data.id ? "Edit Mini Game - Review + Publish" : "Add Mini Game - Review + Publish"}
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handleBack()}>
            Back
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox>
        <MDBox mb={3} sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          <MDButton
            variant={activeView === 0 ? "contained" : "text"}
            color="dark"
            size="large"
            onClick={() => setActiveView(0)}
          >
            Team / Admin Details
          </MDButton>
          <MDButton
            variant={activeView === 1 ? "contained" : "text"}
            color="dark"
            size="large"
            onClick={() => setActiveView(1)}
          >
            Theme
          </MDButton>
          <MDButton
            variant={activeView === 2 ? "contained" : "text"}
            color="dark"
            size="large"
            onClick={() => setActiveView(2)}
          >
            Rewards / Mechanics
          </MDButton>
        </MDBox>
      </MDBox>
      {activeView === 0 && <ViewMiniGameDetail item={data} />}
      {activeView === 1 && <ViewMiniGameTheme item={data} />}
      {activeView === 2 && <ViewMiniGameReward item={data} />}
      <Grid container justifyContent="flex-end" gap={2}>
        <Grid item>
          {data?.isDraft !== false && (
            <MDButton color="secondary" size="large" onClick={handleSave(true)}>
              Save as Draft
            </MDButton>
          )}
        </Grid>
        <Grid item>
          <MDButton color="secondary" size="large" onClick={handleSave()}>
            Publish
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

MiniGameSummaryForm.defaultProps = {
  item: null,
  prevHandler: {},
  saveHandler: {},
};

MiniGameSummaryForm.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  prevHandler: PropTypes.func,
  saveHandler: PropTypes.func,
};

export default MiniGameSummaryForm;
