import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { pageHeaderContainer, flexContainer } from "layouts/common/styles";

function Help() {

  return (
    <DashboardLayout>
      <MDBox py={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "200px" }}>
            Help and Support
          </MDTypography>
        </MDBox>
          <MDBox mb={2}>
            <MDTypography fontSize="12px" fontWeight="bold">
              Please consult the PDF documentation below for instructions on how to manage your SportzFan account.
            </MDTypography>
          </MDBox>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDBox sx={(theme) => flexContainer(theme)}>
          <MDButton icon="text" onClick={() => null}>
          <MDTypography fontSize="12px" fontWeight="bold" onClick={() => window.open('https://drive.google.com/file/d/1hxzDnVUns6fUoRgosd1k34lQAP0Oirth/view?usp=drive_link', '__blank')}>
                Access PDF Documentation
            </MDTypography>
            </MDButton>


          </MDBox>

        </MDBox>
        <MDTypography fontSize="12px" fontWeight="bold">
              Still having issues? Contact us at <a href="mailto:hello@sportzfan.io">hello@sportzfan.io</a>
            </MDTypography>
      </MDBox>
    </DashboardLayout>
  );
}

export default Help;
