import { SponsorActivationTypes } from "layouts/common/constants";
import http from "../apis/http-common";

const retrive = (search, page, rowsPerPage) => {
  const skip = page * rowsPerPage;
  return http.get(`/sponsor?skip=${skip}&take=${rowsPerPage}&search=${search}`);
};

const retriveActivations = (id, filter, page, rowsPerPage) => {
  const skip = page * rowsPerPage;
  return http.get(`/sponsor/detail/${id}?skip=${skip}&take=${rowsPerPage}&filter=${filter}`);
};

const retriveAllActivations = (id) => http.get(`/sponsor/detail/${id}`);

const getAll = () => http.get(`/sponsor`);

const get = (id) => http.get(`/sponsor/${id}`);

const create = (data) => http.post("/sponsor", data);

const update = (id, data) => http.put(`/sponsor/${id}`, data);

const remove = (id) => http.delete(`/sponsor/${id}`);

const removeActivation = (type, id) => {
  if (type === SponsorActivationTypes.Trivia) {
    return http.delete(`/game/trivia/${id}`);
  }
  if (type === SponsorActivationTypes.Prediction) {
    return http.delete(`/game/prediction/${id}`);
  }
  if (type === SponsorActivationTypes.Milestone) {
    return http.delete(`/milestone/milestone/${id}`);
  }
  if (type === SponsorActivationTypes.Poll) {
    return http.delete(`/poll/${id}`);
  }
  if (type === SponsorActivationTypes.CheckIn) {
    return http.delete(`/challenge/check-in/${id}`);
  }
  if (type === SponsorActivationTypes.Survey) {
    return http.delete(`/challenge/survey/${id}`);
  }
  return null;
};
const upload = (formData) => http.post("/upload/sponsor", formData);

const SponsorService = {
  retrive,
  retriveActivations,
  getAll,
  get,
  create,
  update,
  remove,
  removeActivation,
  retriveAllActivations,
  upload,
};

export default SponsorService;
