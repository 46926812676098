import { EventTabs } from "layouts/common/constants";
import MatchesService from "services/MatchService";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  filter: EventTabs.Ongoing,
  totalCount: 0,
  matches: [],
};

export const retriveMatches = createAsyncThunk(
  "/match/retrive",
  async ({ filter, search, page, rowsPerPage, sortFilter, sortDirection }) => {
    const res = await MatchesService.retrive(
      filter,
      search,
      page,
      rowsPerPage,
      sortFilter,
      sortDirection,
    );
    return res.data;
  },
);

export const createMatch = createAsyncThunk("/match/create", async (data) => {
  const res = await MatchesService.create(data);
  return res.data;
});

export const updateMatch = createAsyncThunk("/match/update", async ({ id, data }) => {
  const res = await MatchesService.update(id, data);
  return res.data;
});

export const deleteMatch = createAsyncThunk("/match/delete", async (id) => {
  await MatchesService.remove(id);
  return { id };
});

const matchSlice = createSlice({
  name: "match",
  initialState,
  extraReducers: {
    [retriveMatches.fulfilled]: (state, action) => {
      state.totalCount = action.payload.count;
      state.matches = [...action.payload.data];
    },
    [updateMatch.fulfilled]: (state, action) => {
      const index = state.matches.findIndex((match) => match.id === action.payload.id);
      state.matches[index] = {
        ...state.matches[index],
        ...action.payload,
      };
    },
    [deleteMatch.fulfilled]: (state, action) => {
      const index = state.matches.findIndex(({ id }) => id === action.payload.id);
      state.matches.splice(index, 1);
      state.totalCount -= 1;
    },
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
});
export const { setFilter } = matchSlice.actions;

const { reducer } = matchSlice;
export default reducer;
