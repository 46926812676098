import { GameTabs } from "layouts/common/constants";
import GameService from "services/GamesService";
import ToastrService from "services/ToastrService";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  filter: GameTabs.Upcoming,
  totalCount: 0,
  games: [],
};

export const retriveGames = createAsyncThunk(
  "/game/retrive",
  async ({ filter, search, page, rowsPerPage, sortFilter, sortDirection }) => {
    const res = await GameService.retrive(
      filter,
      search,
      page,
      rowsPerPage,
      sortFilter,
      sortDirection,
    );
    return res.data;
  },
);

export const createGame = createAsyncThunk("/game/create", async ({ type, data }) => {
  try {
    const res = await GameService.create(type, data);
    return res.data;
  } catch (e) {
    ToastrService.error(e.response?.data?.message?.message || "Failed to add game");
    throw e;
  }
});

export const updateGame = createAsyncThunk("/game/update", async ({ type, id, data }) => {
  const res = await GameService.update(type, id, data);
  return res.data;
});

export const deleteGame = createAsyncThunk("/game/delete", async ({ type, id }) => {
  await GameService.remove(type, id);
  return { id };
});

const gameSlice = createSlice({
  name: "game",
  initialState,
  extraReducers: {
    [retriveGames.fulfilled]: (state, action) => {
      state.totalCount = action.payload.count;
      state.games = [...action.payload.data];
    },
    [updateGame.fulfilled]: (state, action) => {
      const index = state.games.findIndex((game) => game.id === action.payload.id);
      state.games[index] = {
        ...state.games[index],
        ...action.payload,
      };
    },
    [deleteGame.fulfilled]: (state, action) => {
      const index = state.games.findIndex(({ id }) => id === action.payload.id);
      state.games.splice(index, 1);
      state.totalCount -= 1;
    },
  },
  reducers: {
    setFilterGame: (state, action) => {
      state.filter = action.payload;
    },
  },
});
export const { setFilterGame } = gameSlice.actions;

const { reducer } = gameSlice;
export default reducer;
