import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import Table from "@mui/material/Table";
import { TableHead } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

import { FaArrowDown, FaArrowUp, FaCopy, FaEdit, FaEye, FaTrash } from "react-icons/fa";

import ConfirmSnackBarContent from "layouts/common/components/ConfirmSnackbarContent";
import { dateToDateAndTime } from "utils/converter";

import MatchesService from "services/MatchService";
import { setLoading } from "store/appSlice";
import { createMatch } from "store/slices/matchSlice";

import ToastrService from "services/ToastrService";
import {
  customTableHeader,
  StyledTableCell,
  StyledTableCellContent,
  StyledTableButtonGroups,
  StyledTableSortButton,
} from "layouts/common/styles";

import { SortFilter } from "layouts/common/constants";

const tableHeads = [
  {
    label: "Title",
    alignment: "left",
    sort: true,
    sortFilter: SortFilter.Title,
  },
  {
    label: "Team",
    alignment: "left",
    sort: true,
    sortFilter: SortFilter.Team,
  },
  {
    label: "Sponsor/Team",
    alignment: "left",
    sort: true,
    sortFilter: SortFilter.Type,
  },
  {
    label: "Start Date",
    alignment: "right",
    sort: true,
    sortFilter: SortFilter.Start,
  },
  {
    label: "Action",
    alignment: "right",
    sort: false,
  },
];

function EventsTable({ data, deleteHandler, sortHandler }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [events, setEvents] = useState([]);
  const [sortFilter, setSortFilter] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);

  const [deleteId, setDeleteId] = useState("");

  useEffect(() => {
    const tempData = data.map((d) => {
      const temp = { ...d };
      const startDateTime = dateToDateAndTime(new Date(d.start));
      temp.start = `${startDateTime.date}  ${startDateTime.time}`;
      return temp;
    });

    setEvents(tempData);
  }, [data]);

  const handleSort = (sort, direction) => () => {
    setSortFilter(sort);
    setSortDirection(direction);
    sortHandler(sort, direction);
  };

  const handleCopy = (_id) => () => {
    dispatch(setLoading(true));
    MatchesService.get(_id)
      .then((response) => {
        const temp = { ...response.data.match };
        temp.teamId = temp.team.id;
        temp.homeTeamId = temp.homeTeam.id;
        temp.awayTeamId = temp.awayTeam.id;
        temp.title = `${temp.title}  (Duplicated)`;
        temp.isDraft = true;
        ["id", "team", "homeTeam", "awayTeam", "createdAt", "updatedAt"].forEach((key) => {
          delete temp[key];
        });
        dispatch(createMatch(temp))
          .unwrap()
          .then((res) => {
            dispatch(setLoading(false));
            ToastrService.success("Successfully duplicated match");
            navigate(`/match/view/${res.id}`);
          })
          .catch(() => {
            dispatch(setLoading(false));
            ToastrService.error("Failed to duplicated match");
          });
      })
      .catch(() => {
        dispatch(setLoading(false));
        ToastrService.error("Failed to duplicated match");
      });
  };

  const handleView = (_id) => () => {
    navigate(`/match/view/${_id}`);
  };

  const handleEdit = (_id) => () => {
    navigate(`/match/edit/${_id}`);
  };

  const handleDelete = (_id) => () => {
    setShow(true);
    setDeleteId(_id);
  };

  const confirmDelete = () => {
    if (deleteId !== "") {
      setShow(false);
      deleteHandler(deleteId);
      setDeleteId("");
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead sx={(theme) => customTableHeader(theme)}>
          <TableRow>
            {tableHeads.map((headerItem) => (
              <StyledTableCell align={`${headerItem.alignment}`}>
                <StyledTableCellContent
                  style={
                    headerItem.alignment === "right"
                      ? { justifyContent: "flex-end" }
                      : { justifyContent: "flex-start" }
                  }
                >
                  {headerItem.label}
                  {headerItem.sort && (
                    <StyledTableButtonGroups>
                      <StyledTableSortButton
                        type="button"
                        className={
                          sortFilter === headerItem.sortFilter && sortDirection === "ASC"
                            ? "active"
                            : ""
                        }
                        onClick={handleSort(headerItem.sortFilter, "ASC")}
                      >
                        <FaArrowUp />
                      </StyledTableSortButton>
                      <StyledTableSortButton
                        type="button"
                        className={
                          sortFilter === headerItem.sortFilter && sortDirection === "DESC"
                            ? "active"
                            : ""
                        }
                        onClick={handleSort(headerItem.sortFilter, "DESC")}
                      >
                        <FaArrowDown />
                      </StyledTableSortButton>
                    </StyledTableButtonGroups>
                  )}
                </StyledTableCellContent>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((row) => (
            <TableRow key={`event-tr-${row.id}`}>
              <StyledTableCell
                align="left"
                sx={{ color: "#0076B2", cursor: "pointer" }}
                onClick={handleView(row.id)}
              >
                {row?.title}
              </StyledTableCell>
              <StyledTableCell align="left">{row?.team?.name}</StyledTableCell>
              <StyledTableCell align="left">{row?.type}</StyledTableCell>
              <StyledTableCell align="right">{row?.start}</StyledTableCell>
              <StyledTableCell align="right">
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={handleCopy(row.id)}
                >
                  <FaCopy />
                </MDButton>
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={handleView(row.id)}
                >
                  <FaEye />
                </MDButton>
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={handleEdit(row.id)}
                >
                  <FaEdit />
                </MDButton>
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={handleDelete(row.id)}
                >
                  <FaTrash />
                </MDButton>
              </StyledTableCell>
            </TableRow>
          ))}
          {data.length === 0 && (
            <TableRow>
              <StyledTableCell colSpan={6} align="center" color="dark">
                There is no content.
              </StyledTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <MDSnackbar
        icon="notificaiton"
        color="error"
        dateTime=""
        title="Delete Match/Event"
        content={
          <ConfirmSnackBarContent
            title="Are you sure to delete this data?"
            confirmHandler={confirmDelete}
          />
        }
        open={show}
        close={toggleSnackbar}
      />
    </TableContainer>
  );
}

EventsTable.defaultProps = {
  data: [],
  deleteHandler: {},
  sortHandler: {},
};

// Typechecking props for the DashboardNavbar
EventsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  deleteHandler: PropTypes.func,
  sortHandler: PropTypes.func,
};

export default EventsTable;
