const sponsorTeamOptions = [
  {
    label: "Team Asset",
    value: "Team Asset",
  },
  {
    label: "Sponsor Asset",
    value: "Sponsor Asset",
  },
];

export default sponsorTeamOptions;
