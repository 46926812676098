import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { customTableHeader } from "layouts/common/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontSize: "13px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "13px",
  },
}));

function WinnerTableMiniGame({ data, isWeekly }) {
  return (
    <TableContainer component={Paper} sx={{ background: "transparent", boxShadow: "none" }}>
      <Table aria-label="customized table">
        <TableHead sx={(theme) => customTableHeader(theme)}>
          <TableRow>
            <StyledTableCell align="left">Name</StyledTableCell>
            <StyledTableCell align="left">Email</StyledTableCell>
            <StyledTableCell align="left">Rank</StyledTableCell>
            {isWeekly && <StyledTableCell align="left">Week</StyledTableCell>}
            <StyledTableCell align="left">Score</StyledTableCell>
            <StyledTableCell align="left">Tokens Won</StyledTableCell>
            <StyledTableCell align="left">Kudos Won</StyledTableCell>
            <StyledTableCell align="left">Reward Sent</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <TableRow key={row.user.id}>
              <StyledTableCell align="left" component="th" scope="row">
                {row?.user?.firstName}&nbsp;{row?.user?.lastName}
              </StyledTableCell>
              <StyledTableCell align="left" component="th" scope="row">
                {row.user.email}
              </StyledTableCell>
              <StyledTableCell align="left">{row.rank}</StyledTableCell>
              {isWeekly && <StyledTableCell align="left">{row.week}</StyledTableCell>}
              <StyledTableCell align="left">{row.score}</StyledTableCell>
              <StyledTableCell align="left">{row.rewardToken}</StyledTableCell>
              <StyledTableCell align="left">{row.rewardKudos}</StyledTableCell>
              <StyledTableCell align="left">{row.isSent ? "Yes" : ""}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

WinnerTableMiniGame.defaultProps = {
  data: [],
  isWeekly: false,
};

// Typechecking props for the DashboardNavbar
WinnerTableMiniGame.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  isWeekly: PropTypes.bool,
};

export default WinnerTableMiniGame;
