import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";
import { GameTypes } from "layouts/common/constants";

import ToastrService from "services/ToastrService";
import GameService from "services/GamesService";
import { setLoading } from "store/appSlice";

import { dateToDateAndTime } from "utils/converter";

import WinnerTableMilestone from "../WinnerTableMilestone";

function ViewMilestoneResult() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState(null);
  const [winners, setWinners] = useState([]);

  const getResult = (_id) => {
    GameService.getResult(GameTypes.Milestone, _id).then((response) => {
      const tempWinners = response.data.filter((d) => d.balanceFlag && d.checkInFlag);
      setWinners(tempWinners);
    });
  };

  const getGame = (_id) => {
    dispatch(setLoading(true));
    GameService.get(_id, GameTypes.Milestone)
      .then((response) => {
        const temp = { ...response.data };

        const startDateTime = dateToDateAndTime(new Date(temp.start));
        const endDateTime = dateToDateAndTime(new Date(temp.end));

        temp.start = `${startDateTime.date}  ${startDateTime.time}`;
        temp.end = `${endDateTime.date}  ${endDateTime.time}`;

        setData(temp);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(
          e.response?.data?.message?.message || "Failed to load milestone result.",
        );
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      getGame(id);
      getResult(id);
    }
  }, [id]);

  const handleBack = () => {
    navigate(`/game/view-milestone/${id}`);
  };

  return (
    <DashboardLayout>
      <MDBox my={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "250px" }}>
          Milestone - Results
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handleBack()}>
            Back
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox>
        <MDBox sx={(theme) => customFormContainer(theme)}>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Details
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.team?.name}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Sponsor
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.sponsor?.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Milestone Title
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Milestone Description
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.description}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Start Date & Time
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={8.5}>
              <MDTypography fontSize="15px">{data?.start}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Expiration Date & Time
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={8.5}>
              <MDTypography fontSize="15px">{data?.end}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Results
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={4}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Number of times the Milstone occured
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">{data?.occurCount}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={4}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Number of winners
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">{winners.length}</MDTypography>
            </Grid>
          </Grid>
          <MDBox mb={3}>
            <MDTypography
              fontSize="15px"
              fontWeight="bold"
              mb={3}
              sx={{ textDecoration: "underline" }}
            >
              Winners
            </MDTypography>
            <WinnerTableMilestone milestone={data} data={winners} />
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default ViewMilestoneResult;
