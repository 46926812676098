import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CSVLink } from "react-csv";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import ToastrService from "services/ToastrService";

import {
  customTableContainer,
  flexContainer,
  pageHeaderContainer,
  searchInput,
} from "layouts/common/styles";

import { deleteTeam, activateTeam } from "store/slices/teamSlice";
import { UserRole } from "layouts/common/constants";

import TeamsService from "services/TeamService";
import TeamsTable from "./components/TeamsTable";

function Teams() {
  const adminProfile = useSelector((state) => state.users.profile);
  const teams = useSelector((state) => state.teams);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchedTeams, setSearchedTeams] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const retriveTeamdetails = useCallback(() => {
    TeamsService.retrive(page, rowsPerPage, searchText).then((res) => {
      setSearchedTeams(
        res.data.data.map((d) => ({
          ...d.team,
          adminFirstName: d.admin?.firstName,
          adminLastName: d.admin?.lastName,
          adminPhoneNumber: d.admin?.phone,
        })),
      );
      setTotalCount(res.data.count);
    });
  }, [searchText, page, rowsPerPage]);

  useEffect(() => {
    retriveTeamdetails();
  }, [retriveTeamdetails]);

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };

  const changePageNum = (direct) => {
    const maxPageNum = Math.floor((totalCount - 1) / rowsPerPage);
    if (page === 0 && direct === -1) return;
    if (page === maxPageNum && direct === 1) return;

    setPage((prevState) => prevState + direct);
  };

  useEffect(() => {
    if (adminProfile.role === UserRole.TeamAdmin) {
      navigate(`/dashboards/teams/view/${adminProfile.teamId}`);
    }
  }, [adminProfile]);

  const deleteHandler = (id) => {
    dispatch(deleteTeam(id))
      .unwrap()
      .then(() => {
        ToastrService.success("Successfully deleted team data");
        retriveTeamdetails();
      })
      .catch(() => {
        ToastrService.error("Failed to delete team data");
      });
  };
  const activeHandler = (id, activate) => {
    dispatch(activateTeam({ id, active: activate }))
      .unwrap()
      .then(() => {
        ToastrService.success("Successfully changed active state");
        retriveTeamdetails();
      })
      .catch(() => {
        ToastrService.error("Failed to change active state");
      });
  };

  const searchHandler = (v) => {
    setSearchText(v);
  };

  const handleAdd = () => {
    navigate("/dashboards/teams/add");
  };

  // Export to CSV
  const headers = [
    { label: "Team Name", key: "name" },
    { label: "Description", key: "description" },
    { label: "Sub Domain", key: "subDomain" },
    { label: "Admin First Name", key: "adminFirstName" },
    { label: "Admin Last Name", key: "adminLastName" },
    { label: "Email", key: "adminEmail" },
    { label: "Phone", key: "adminPhoneNumber" },
    { label: "Token Sale to Fan", key: "tokenSaleToFan" },
    { label: "Token Sale to Sponsor", key: "tokenSaleToSponsors" },
    { label: "Price", key: "price" },
    { label: "Vault Token Balance", key: "vaultTokenBalance" },
    { label: "Team Wallet Balance", key: "teamWalletBalance" },
    { label: "Kudos To Tire1", key: "kudosToTire1" },
    { label: "Kudos To Tire2", key: "kudosToTire2" },
    { label: "Kudos To Tire3", key: "kudosToTire3" },
    { label: "Kudos To Tire3", key: "kudosToTire4" },
    { label: "Member Level Name1", key: "memberLevelName1" },
    { label: "Member Level Name2", key: "memberLevelName2" },
    { label: "Member Level Name3", key: "memberLevelName3" },
    { label: "Member Level Name4", key: "memberLevelName4" },
    { label: "Active", key: "isActived" },
    { label: "Registered", key: "createdAt" },
  ];

  const csvReport = {
    data: teams.map((v) => {
      const temp = { ...v };
      ["id", "logo"].forEach((e) => delete temp[e]);

      return temp;
    }),
    headers,
    filename: "SuperAdminPanel_Teams.csv",
  };

  return (
    <DashboardLayout>
      <MDBox py={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDBox sx={(theme) => flexContainer(theme)}>
            <MDTypography fontSize="12px" fontWeight="bold">
              Search By:
            </MDTypography>
            <MDInput
              type="search"
              placeholder="Team...Name...Email...Phone"
              onChange={(e) => searchHandler(e.target.value)}
              sx={(theme) => searchInput(theme)}
            />
          </MDBox>
        </MDBox>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold">
            Teams
          </MDTypography>
          {adminProfile.role === UserRole.SuperAdmin && (
            <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
              <MDButton size="small" color="secondary" onClick={() => handleAdd()}>
                Add Team +
              </MDButton>

              <MDButton size="small" color="secondary">
                <CSVLink {...csvReport} style={{ color: "white" }}>
                  Export
                </CSVLink>
              </MDButton>
            </MDBox>
          )}
        </MDBox>
        <MDBox
          sx={(theme) => {
            customTableContainer(theme);
          }}
        >
          <TeamsTable
            data={searchedTeams}
            deleteHandler={deleteHandler}
            activeHandler={activeHandler}
          />
        </MDBox>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end" my={3}>
          <FormControl>
            <InputLabel id="rows_per_page">Rows per page</InputLabel>
            <Select
              labelId="rows_per_page"
              id="rows_per_page_select"
              value={rowsPerPage}
              label="Rows per page"
              onChange={handleChangeRowsPerPage}
              sx={{ height: "38px", width: "150px", minWidth: "50px" }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
              <MenuItem value={20}>20</MenuItem>
            </Select>
          </FormControl>
          <MDTypography fontSize="12px" fontWeight="bold">
            {totalCount > 0 ? page * rowsPerPage + 1 : "0"}
            &nbsp;~&nbsp;
            {totalCount > page * rowsPerPage + rowsPerPage
              ? page * rowsPerPage + rowsPerPage
              : totalCount}
            &nbsp;of&nbsp;
            {totalCount}
          </MDTypography>

          <MDButton icon="text" onClick={() => changePageNum(-1)}>
            <FaArrowLeft />
          </MDButton>
          <MDButton icon="text" onClick={() => changePageNum(1)}>
            <FaArrowRight />
          </MDButton>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Teams;
