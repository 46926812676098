import { Fragment, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { customFormContainer } from "layouts/common/styles";
import claimTypes from "layouts/assets/data/claimTypes";

function BonusAssetReview({ formData, prevHandler, submitHandler }) {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handlePrev = () => {
    prevHandler();
  };

  const handleSave = useCallback(
    (isDraft = false) =>
      () => {
        if (isSubmitted) return;

        setIsSubmitted(true);
        submitHandler(formData, isDraft);

        setTimeout(() => {
          setIsSubmitted(false);
        }, 5000);
      },
    [submitHandler, formData, isSubmitted],
  );

  return (
    <MDBox>
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Sponsor / Team
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{formData.type}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{formData.team.name}</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Asset Category
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{formData.category}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Asset Title
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{formData.title}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Add Image
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {formData.logo !== null && formData.logo !== "" && (
              <MDBox
                component="img"
                src={formData.logo}
                alt="asset image"
                shadow="md"
                width="250px"
                height="250px"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Start Date & Time
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={8.5}>
            <MDTypography fontSize="15px">
              {" "}
              {`${formData.startDate} ${formData.startTime}`}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Expiration Date & Time
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={8.5}>
            <MDTypography fontSize="15px">
              {" "}
              {`${formData.endDate} ${formData.endTime}`}{" "}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Total Asset Count
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{formData.totalCount}</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Claim details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Claim type
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{formData.claimType}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Claim description
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{formData.claimDescription}</MDTypography>
          </Grid>
        </Grid>
        {formData.claimType === claimTypes[0] &&
          (formData.category === "Tickets" || formData.category === "Merchandise") && (
            <>
              <MDTypography
                fontSize="15px"
                fontWeight="bold"
                mb={3}
                sx={{ textDecoration: "underline" }}
              >
                Coupon details
              </MDTypography>
              <Grid container spacing={2} mb={4}>
                <Grid item xs={12} sm={3} md={2.5}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Total asset count
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <MDTypography fontSize="15px">{formData.totalCount}</MDTypography>
                </Grid>
                {formData.coupons
                  .sort((a, b) => {
                    if (a.order > b.order) return 1;
                    if (a.order < b.order) return -1;
                    return 0;
                  })
                  .map((coupon, idx) => (
                    <Fragment key={`coupon-${idx + 1}`}>
                      <Grid item xs={12} sm={3} md={2.5}>
                        <MDTypography fontSize="15px" fontWeight="bold">
                          Coupon code #{idx + 1}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <MDTypography fontSize="15px">{coupon.code}</MDTypography>
                      </Grid>
                    </Fragment>
                  ))}
              </Grid>
              <MDTypography
                fontSize="15px"
                fontWeight="bold"
                mb={3}
                sx={{ textDecoration: "underline" }}
              >
                URL Link to Claim
              </MDTypography>
              <Grid container spacing={2} mb={4}>
                <Grid item xs={12} sm={9}>
                  <MDTypography fontSize="15px">{formData.claimUrl}</MDTypography>
                </Grid>
              </Grid>
            </>
          )}
      </MDBox>

      <Grid container justifyContent="flex-end" gap={2}>
        <Grid item>
          <MDButton color="secondary" size="large" onClick={handlePrev}>
            Prev
          </MDButton>
        </Grid>
        <Grid item>
          <MDButton
            disabled={isSubmitted}
            color="secondary"
            size="large"
            onClick={() => handleSave(true)}
          >
            Save as Draft
          </MDButton>
        </Grid>
        <Grid item>
          <MDButton disabled={isSubmitted} color="secondary" size="large" onClick={handleSave()}>
            {formData?.id ? "Save" : "Publish"}
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

BonusAssetReview.defaultProps = {
  prevHandler: {},
  submitHandler: {},
  formData: null,
};

BonusAssetReview.propTypes = {
  prevHandler: PropTypes.func,
  submitHandler: PropTypes.func,
  formData: PropTypes.objectOf(PropTypes.any),
};

export default BonusAssetReview;
