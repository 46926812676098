import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { Autocomplete, Checkbox, Divider, Grid, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { pageHeaderContainer, customFormContainer, flexContainer } from "layouts/common/styles";

import FormField from "components/FormField";
import GameService from "services/GamesService";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .MuiOutlinedInput-root .MuiAutocomplete-input`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
    padding: 0,
    width: "35px",
  },
  [`& .MuiOutlinedInput-root`]: {
    padding: "0px",
    paddingLeft: "10px",
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

function TeamRewardForm({ item, initialState, prevHandler, nextHandler, changeHandler }) {
  const resetState = {
    referralSignupTokenReward: 0,
    referralSignupKudosReward: 0,
    referralPlayTokenReward: 0,
    referralPlayKudosReward: 0,
    enableEarlySignupReward: false,
    earlySignupLimitCount: 0,
    earlySignupTokenReward: 0,
    earlySignupKudosReward: 0,
    earlySignupAsset: null,
    enableWeeklyReward: false,
    weeklyRewardLimitCount: 0,
    weeklyTokenReward: 0,
    weeklyKudosReward: 0,
    weeklyAsset: null,
  };

  const [team, setTeam] = useState(resetState);
  const [validationMsg, setValidationMsg] = useState(resetState);

  const [bonusAssets, setBonusAssets] = useState([]);

  const getBonusAssets = useCallback(() => {
    let search = null;
    if (item?.id) {
      search = item.id;
    } else {
      setBonusAssets([]);
      return;
    }
    GameService.getBonusAssets(search, 0, 9999).then((response) => {
      setBonusAssets(response.data.data);
    });
  }, [item]);

  useEffect(() => {
    getBonusAssets();
  }, []);

  useEffect(() => {
    if (item) {
      const newItem = { ...item };
      setTeam(newItem);
    }
  }, [item]);

  const handleStatusChange = (name, value) => {
    setTeam({ ...team, [name]: value });
  };

  const handleSubmitChangeNumber = (field, value) => {
    setTeam({ ...team, [field]: parseFloat(value) });
  };

  const validate = () => {
    let validated = true;
    let errorMsg = validationMsg;
    const keys = Object.keys(resetState);
    for (let i = 0; i < keys.length; i += 1) {
      let msg = "";
      if (
        (team[keys[i]] === "" || team[keys[i]] === null) &&
        ![
          "earlySignupLimitCount",
          "earlySignupTokenReward",
          "earlySignupKudosReward",
          "earlySignupAsset",
          "weeklyRewardLimitCount",
          "weeklyTokenReward",
          "weeklyKudosReward",
          "weeklyAsset",
        ].includes(keys[i])
      ) {
        msg = "Field should not be left empty";
        validated = false;
      }
      errorMsg = { ...errorMsg, [keys[i]]: msg };
    }
    setValidationMsg(errorMsg);

    return validated;
  };

  const handleNext = () => {
    if (!validate()) return;

    if (changeHandler(team)) {
      nextHandler();
    }
  };

  const handleReset = () => {
    let temp = { ...team };
    const keys = Object.keys(resetState);
    for (let i = 0; i < keys.length; i += 1) {
      temp = { ...temp, [keys[i]]: initialState[keys[i]] };
    }
    setTeam(temp);
    setValidationMsg(resetState);
  };

  const handleBack = () => {
    prevHandler();
  };

  return (
    <MDBox my={3}>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "100%" }}>
          {team?.id ? "Edit Team" : "Add Team"} - Reward Settings
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handleBack()}>
            Back
          </MDButton>
          <MDButton size="small" onClick={() => handleReset()}>
            Reset
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox>
        <MDBox sx={(theme) => customFormContainer(theme)}>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team Name
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{team?.name}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Refer a Friend
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">
                Successful referral + sign up reward - Tokens - Referrer
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormField
                type="number"
                name="referralSignupTokenReward"
                value={team.referralSignupTokenReward}
                onChange={(e) => {
                  handleSubmitChangeNumber("referralSignupTokenReward", e.target.value);
                }}
                error={
                  validationMsg.referralSignupTokenReward !== "" &&
                  validationMsg.referralSignupTokenReward !== 0
                }
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">
                Successful referral + sign up reward - KUDOS - Referrer
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormField
                type="number"
                name="referralSignupKudosReward"
                value={team.referralSignupKudosReward}
                onChange={(e) => {
                  handleSubmitChangeNumber("referralSignupKudosReward", e.target.value);
                }}
                error={
                  validationMsg.referralSignupKudosReward !== "" &&
                  validationMsg.referralSignupKudosReward !== 0
                }
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">
                Successful referral + PLAY GAME reward - Tokens - Referrer
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormField
                type="number"
                name="referralPlayTokenReward"
                value={team.referralPlayTokenReward}
                onChange={(e) => {
                  handleSubmitChangeNumber("referralPlayTokenReward", e.target.value);
                }}
                error={
                  validationMsg.referralPlayTokenReward !== "" &&
                  validationMsg.referralPlayTokenReward !== 0
                }
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">
                Successful referral + PLAY GAME reward - KUDOS - Referrer
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormField
                type="number"
                name="referralPlayKudosReward"
                value={team.referralPlayKudosReward}
                onChange={(e) => {
                  handleSubmitChangeNumber("referralPlayKudosReward", e.target.value);
                }}
                error={
                  validationMsg.referralPlayKudosReward !== "" &&
                  validationMsg.referralPlayKudosReward !== 0
                }
              />
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Bonus Events
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={6}>
              <MDBox>
                <Grid container spacing={2} mb={4}>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">Reward first users registered?</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Checkbox
                      checked={team.enableEarlySignupReward}
                      onChange={(e) => {
                        handleStatusChange("enableEarlySignupReward", e.target.checked);
                      }}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox>
                <Grid container spacing={2} mb={4}>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">No of users to reward</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      disabled={!team.enableEarlySignupReward}
                      type="number"
                      name="earlySignupLimitCount"
                      value={team.earlySignupLimitCount}
                      onChange={(e) => {
                        handleSubmitChangeNumber("earlySignupLimitCount", e.target.value);
                      }}
                      error={
                        validationMsg.earlySignupLimitCount !== "" &&
                        validationMsg.earlySignupLimitCount !== 0
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">Reward sent(Tokens)</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      disabled={!team.enableEarlySignupReward}
                      type="number"
                      name="earlySignupTokenReward"
                      value={team.earlySignupTokenReward}
                      onChange={(e) => {
                        handleSubmitChangeNumber("earlySignupTokenReward", e.target.value);
                      }}
                      error={
                        validationMsg.earlySignupTokenReward !== "" &&
                        validationMsg.earlySignupTokenReward !== 0
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">Reward sent(KUDOS)</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      disabled={!team.enableEarlySignupReward}
                      type="number"
                      name="earlySignupKudosReward"
                      value={team.earlySignupKudosReward}
                      onChange={(e) => {
                        handleSubmitChangeNumber("earlySignupKudosReward", e.target.value);
                      }}
                      error={
                        validationMsg.earlySignupKudosReward !== "" &&
                        validationMsg.earlySignupKudosReward !== 0
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">Bonus asset to send</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledAutocomplete
                      value={team.earlySignupAsset}
                      disabled={!team.enableEarlySignupReward}
                      options={bonusAssets}
                      getOptionLabel={(option) => option?.title}
                      onChange={(e, v) => {
                        handleStatusChange("earlySignupAsset", v);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} InputLabelProps={{ shrink: true }} />
                      )}
                      placeholder="Choose Asset"
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={6}>
              <MDBox>
                <Grid container spacing={2} mb={4}>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">
                      Reward weekly top ranked (leaderboard)?
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Checkbox
                      checked={team.enableWeeklyReward}
                      onChange={(e) => {
                        handleStatusChange("enableWeeklyReward", e.target.checked);
                      }}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox>
                <Grid container spacing={2} mb={4}>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">Rank of users to send rewards</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      disabled={!team.enableWeeklyReward}
                      type="number"
                      name="weeklyRewardLimitCount"
                      value={team.weeklyRewardLimitCount}
                      onChange={(e) => {
                        handleSubmitChangeNumber("weeklyRewardLimitCount", e.target.value);
                      }}
                      error={
                        validationMsg.weeklyRewardLimitCount !== "" &&
                        validationMsg.weeklyRewardLimitCount !== 0
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">Reward sent(Tokens)</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      disabled={!team.enableWeeklyReward}
                      type="number"
                      name="weeklyTokenReward"
                      value={team.weeklyTokenReward}
                      onChange={(e) => {
                        handleSubmitChangeNumber("weeklyTokenReward", e.target.value);
                      }}
                      error={
                        validationMsg.weeklyTokenReward !== "" &&
                        validationMsg.weeklyTokenReward !== 0
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">Reward sent(KUDOS)</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      disabled={!team.enableWeeklyReward}
                      type="number"
                      name="weeklyKudosReward"
                      value={team.weeklyKudosReward}
                      onChange={(e) => {
                        handleSubmitChangeNumber("weeklyKudosReward", e.target.value);
                      }}
                      error={
                        validationMsg.weeklyKudosReward !== "" &&
                        validationMsg.weeklyKudosReward !== 0
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">Bonus asset to send</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledAutocomplete
                      value={team.weeklyAsset}
                      disabled={!team.enableWeeklyReward}
                      options={bonusAssets}
                      getOptionLabel={(option) => option?.title}
                      onChange={(e, v) => {
                        handleStatusChange("weeklyAsset", v);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} InputLabelProps={{ shrink: true }} />
                      )}
                      placeholder="Choose Asset"
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container justifyContent="flex-end">
          <MDButton color="secondary" size="large" onClick={handleNext}>
            Next
          </MDButton>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

TeamRewardForm.defaultProps = {
  item: null,
  initialState: null,
  nextHandler: {},
  prevHandler: {},
  changeHandler: {},
};

TeamRewardForm.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  initialState: PropTypes.objectOf(PropTypes.any),
  nextHandler: PropTypes.func,
  prevHandler: PropTypes.func,
  changeHandler: PropTypes.func,
};

export default TeamRewardForm;
