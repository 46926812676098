import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { customTableHeader } from "layouts/common/styles";
import { dateToDateAndTime } from "utils/converter";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontSize: "13px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "13px",
  },
}));

function PlayerCheckInTable({ checkInData, result }) {
  const [checkIns, setCheckIns] = useState([]);
  useEffect(() => {
    const tempData = result.map((d) => {
      const temp = { ...d };
      const checkInDateTime = dateToDateAndTime(new Date(d.createdAt));
      temp.checkInDate = checkInDateTime.date;
      temp.checkInTime = checkInDateTime.time;
      return temp;
    });

    setCheckIns(tempData);
  }, [checkInData, result]);

  return (
    <TableContainer component={Paper} sx={{ background: "transparent", boxShadow: "none" }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead sx={(theme) => customTableHeader(theme)}>
          <TableRow>
            <StyledTableCell align="left">Name</StyledTableCell>
            <StyledTableCell align="left">Check in date</StyledTableCell>
            <StyledTableCell align="left">Check in time</StyledTableCell>
            <StyledTableCell align="left">Check in method</StyledTableCell>
            <StyledTableCell align="left">Tokens Won</StyledTableCell>
            <StyledTableCell align="left">Kudos Won</StyledTableCell>
            <StyledTableCell align="center">Bonus Asset</StyledTableCell>
            <StyledTableCell align="center">Rewards Sent</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {checkIns?.map((row) => (
            <TableRow key={row.id}>
              <StyledTableCell align="left" component="th" scope="row">
                {row?.user?.firstName} {row?.user?.lastName}
              </StyledTableCell>
              <StyledTableCell align="left">{row.checkInDate}</StyledTableCell>
              <StyledTableCell align="left">{row.checkInTime}</StyledTableCell>
              <StyledTableCell align="left">
                Check In {row.location === 0 ? "(inside)" : "(outside)"}
              </StyledTableCell>
              <StyledTableCell align="left">
                {row.location === 0 ? checkInData?.kudosReward : checkInData?.outKudosReward}
              </StyledTableCell>
              <StyledTableCell align="left">
                {row.location === 0 ? checkInData?.tokenReward : checkInData?.outTokenReward}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row?.receiveBonus ? checkInData?.asset?.title : "-"}
              </StyledTableCell>
              <StyledTableCell align="left">Yes</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

PlayerCheckInTable.defaultProps = {
  checkInData: {},
  result: [],
};

// Typechecking props for the DashboardNavbar
PlayerCheckInTable.propTypes = {
  checkInData: PropTypes.objectOf(PropTypes.any),
  result: PropTypes.arrayOf(PropTypes.any),
};

export default PlayerCheckInTable;
