import PropTypes from "prop-types";
import { Checkbox, Grid } from "@mui/material";

import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

import { customFormContainer } from "layouts/common/styles";

function RewardDetail({ item }) {
  return (
    <MDBox>
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team Name
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.name}</MDTypography>
          </Grid>
        </Grid>

        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Reward Settings
        </MDTypography>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
          Refer a Friend
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px">
              Successful referral + sign up reward - Tokens - Referrer
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <MDTypography fontSize="15px">{item?.referralSignupTokenReward}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px">
              Successful referral + sign up reward - KUDOS - Referrer
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <MDTypography fontSize="15px">{item?.referralSignupKudosReward}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px">
              Successful referral + PLAY GAME reward - Tokens - Referrer
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <MDTypography fontSize="15px">{item?.referralPlayTokenReward}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px">
              Successful referral + PLAY GAME reward - KUDOS - Referrer
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <MDTypography fontSize="15px">{item?.referralPlayKudosReward}</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
          Bonus Events
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={6}>
            <MDBox>
              <Grid container spacing={2} mb={4}>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px">Reward first users registered?</MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Checkbox disabled checked={item?.enableEarlySignupReward} />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDBox>
              <Grid container spacing={2} mb={4}>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px">No of users to reward</MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px">{item?.earlySignupLimitCount}</MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px">Reward sent(Tokens)</MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px">{item?.earlySignupTokenReward}</MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px">Reward sent(KUDOS)</MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px">{item?.earlySignupKudosReward}</MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px">Bonus asset to send</MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px">{item?.earlySignupAsset?.title}</MDTypography>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={6}>
            <MDBox>
              <Grid container spacing={2} mb={4}>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px">
                    Reward weekly top ranked (leaderboard)?
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Checkbox disabled checked={item?.enableWeeklyReward} />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDBox>
              <Grid container spacing={2} mb={4}>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px">Rank of users to send rewards</MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px">{item?.weeklyRewardLimitCount}</MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px">Reward sent(Tokens)</MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px">{item?.weeklyTokenReward}</MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px">Reward sent(KUDOS)</MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px">{item?.weeklyKudosReward}</MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px">Bonus asset to send</MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px">{item?.weeklyAsset?.title || ""}</MDTypography>{" "}
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

RewardDetail.defaultProps = {
  item: null,
};

RewardDetail.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
};

export default RewardDetail;
