import { useState, useEffect, useCallback } from "react";

import PropTypes from "prop-types";
import { Checkbox, Divider, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { customFormContainer, flexContainer, pageHeaderContainer } from "layouts/common/styles";
import MDBox from "components/MDBox";

import { dateToDateAndTime } from "utils/converter";

function SurveyReview({ typeForm, item, prevHandler, saveHandler }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    const temp = { ...item };

    const startDateTime = dateToDateAndTime(new Date(temp.start));
    const endDateTime = dateToDateAndTime(new Date(temp.end));

    temp.start = `${startDateTime.date}  ${startDateTime.time}`;
    temp.end = `${endDateTime.date}  ${endDateTime.time}`;

    setData(temp);
  }, [item]);

  const handlePrev = () => {
    prevHandler();
  };

  let debounceTimeout;

  const handleSave = useCallback(
    (isDraft = false) =>
      () => {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
          saveHandler(isDraft);
        }, 1000);
      },
    [saveHandler],
  );

  return (
    <MDBox>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "200px" }}>
          Survey - Review
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handlePrev()}>
            Back
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{typeForm?.team?.name}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Challenge Type
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">Survey (Multi Choice)</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Challenge Title
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9} mb={3}>
            <MDTypography fontSize="15px">{data?.title}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Challenge Description
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{data?.description}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Match / Event
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{data?.match?.title}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} my={6}>
            {data?.surveyQuestions.map((surveyQuestion, idx) => (
              <MDBox key={`surveyQuestion-${idx.toString()}`} mb={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3} md={2.5}>
                    <MDTypography fontSize="15px" fontWeight="bold">
                      Question {idx + 1}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <MDTypography fontSize="15px">{surveyQuestion.question}</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <MDBox>
                      <Grid container spacing={2}>
                        {surveyQuestion.options.map((option, optionIdx) => (
                          <Grid item xs={12} sm={6} key={`survey-option-${optionIdx.toString()}`}>
                            <MDBox mb={2}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={5}>
                                  <MDTypography fontSize="15px" fontWeight="bold">
                                    Option {optionIdx + 1}
                                  </MDTypography>
                                </Grid>
                                <Grid item xs={12} sm={6} display="flex" alignItems="center">
                                  <MDTypography fontSize="15px">{option.optionText}</MDTypography>
                                </Grid>
                              </Grid>
                            </MDBox>
                          </Grid>
                        ))}
                      </Grid>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            ))}
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Start Date & Time
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <MDTypography fontSize="15px">{data?.start}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Expiration Date & Time
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <MDTypography fontSize="15px">{data?.end}</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Eligibility
        </MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Kudos points
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <MDTypography fontSize="15px">{data?.eligbleKudos}</MDTypography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Tokens
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <MDTypography fontSize="15px">{data?.eligbleToken}</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Rewards
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  No of Kudos Points
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography fontSize="15px">{data?.kudosReward}</MDTypography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  No of Tokens
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography fontSize="15px">{data?.tokenReward} </MDTypography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Send asset as reward?
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox disabled checked={data?.enableAssetReward || false} />
          </Grid>
          {data?.enableAssetReward && (
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                select asset
              </MDTypography>
            </Grid>
          )}
          {data?.enableAssetReward && (
            <Grid item xs={12} sm={4} md={5}>
              <MDTypography fontSize="15px">{data?.asset?.title}</MDTypography>
            </Grid>
          )}
        </Grid>
        {data?.enableAssetReward && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Number of top ranked scores to send to
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{data?.winnerLimit}</MDTypography>
            </Grid>
          </Grid>
        )}
        {data?.enableAssetReward && (
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of Bonus Asset to send per winner
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{data?.rewardAssetCount}</MDTypography>
            </Grid>
          </Grid>
        )}
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Sponsors
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Game Sponsor
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px">{data?.sponsor?.title}</MDTypography>
          </Grid>
        </Grid>
      </MDBox>
      <Grid container justifyContent="flex-end" gap={2}>
        <MDButton color="secondary" size="large" onClick={handleSave(true)}>
          Save as Draft
        </MDButton>
        <MDButton color="secondary" size="large" onClick={handleSave()}>
          Publish
        </MDButton>
      </Grid>
    </MDBox>
  );
}

SurveyReview.defaultProps = {
  typeForm: null,
  item: null,
  prevHandler: {},
  saveHandler: {},
};

SurveyReview.propTypes = {
  typeForm: PropTypes.objectOf(PropTypes.any),
  item: PropTypes.objectOf(PropTypes.any),
  prevHandler: PropTypes.func,
  saveHandler: PropTypes.func,
};

export default SurveyReview;
