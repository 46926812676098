import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MDBox from "components/MDBox";

import ToastrService from "services/ToastrService";
import GameService from "services/GamesService";
import { createGame, updateGame } from "store/slices/gameSlice";
import { setLoading } from "store/appSlice";
import { GameTypes, UserRole } from "layouts/common/constants";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import PredictionBasicForm from "./forms/PredictionBasicForm";
import PredictSummaryForm from "./forms/PredictSummaryForm";

const formSwitcher = (
  screen,
  prevHandler,
  nextHandler,
  handleSave,
  handleChange,
  newPrediction,
) => {
  switch (screen) {
    case 1:
      return (
        <PredictionBasicForm
          item={newPrediction}
          nextHandler={nextHandler}
          changeHandler={handleChange}
        />
      );
    case 2:
      return (
        <PredictSummaryForm
          item={newPrediction}
          prevHandler={prevHandler}
          saveHandler={handleSave}
        />
      );
    default:
      return <PredictionBasicForm nextHandler={nextHandler} />;
  }
};

function AddPrediction() {
  const { id } = useParams();

  const adminProfile = useSelector((state) => state.users.profile);

  const [screen, setScreen] = useState(1);
  const [newPrediction, setPrediction] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getGame = (_id) => {
    dispatch(setLoading(true));
    GameService.get(_id, GameTypes.Prediction)
      .then((response) => {
        const temp = response.data;
        setPrediction(temp);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(
          e.response?.data?.message?.message || "Failed to load prediction data.",
        );
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      getGame(id);
    } else {
      setPrediction(null);
    }
  }, [id]);

  const handleChange = (data) => {
    setPrediction(data);
    return true;
  };

  const handleSave = (isDraft = false) => {
    const newData = { ...newPrediction };
    const dataId = newData.id;

    if (adminProfile.role === UserRole.TeamAdmin) {
      newData.teamId = adminProfile.teamId;
    } else {
      newData.teamId = newData.team.id;
    }
    newData.sponsorId = newData.sponsor.id;
    newData.matchId = newData.match.id;

    if (!newData.participants) newData.participants = 0;

    newData.isDraft = isDraft;

    ["id", "team", "match", "sponsor"].forEach((key) => {
      delete newData[key];
    });

    if (dataId === null) {
      dispatch(setLoading(true));
      dispatch(createGame({ type: GameTypes.Prediction, data: newData }))
        .unwrap()
        .then((data) => {
          ToastrService.success("Successfully added new Game Prediction");
          dispatch(setLoading(false));
          navigate(`/game/view-prediction/${data.id}`);
        })
        .catch((e) => {
          ToastrService.error(
            e.response?.data?.message?.message || "Failed to add new Game Prediction",
          );
          dispatch(setLoading(false));
        });
    } else {
      // remove keys
      [
        "createdAt",
        "updatedAt",
        "isEnded",
        "isResult",
        "playPrediction",
        "resultMain",
        "resultOpposition",
      ].forEach((key) => {
        delete newData[key];
      });

      newData.predictionType = "Score";

      dispatch(setLoading(true));
      dispatch(updateGame({ type: GameTypes.Prediction, id: dataId, data: newData }))
        .unwrap()
        .then((data) => {
          if (data.success) {
            ToastrService.success("Successfully updated Game prediction");
            dispatch(setLoading(false));
            navigate(`/game/view-prediction/${dataId}`);
          } else {
            ToastrService.error(data.message);
            dispatch(setLoading(false));
          }
        })
        .catch((e) => {
          ToastrService.error(
            e.response?.data?.message?.message || "Failed to update Game Prediction",
          );
          dispatch(setLoading(false));
        });
    }
  };

  const nextScreen = () => {
    setScreen(screen + 1);
  };

  const prevScreen = () => {
    setScreen(screen - 1);
  };

  return (
    <DashboardLayout>
      <MDBox my={3}>
        {formSwitcher(screen, prevScreen, nextScreen, handleSave, handleChange, newPrediction)}
      </MDBox>
    </DashboardLayout>
  );
}

export default AddPrediction;
