import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import MDButton from "components/MDButton";

import { FaArrowDown, FaArrowUp, FaEye } from "react-icons/fa";

import { dateToDateAndTime } from "utils/converter";

import {
  customTableHeader,
  StyledTableCell,
  StyledTableCellContent,
  StyledTableButtonGroups,
  StyledTableSortButton,
} from "layouts/common/styles";

const tableHeads = [
  {
    label: "Notification",
    alignment: "left",
    sort: true,
    sortFilter: "title",
  },
  {
    label: "Platform/Team",
    alignment: "left",
    sort: true,
    sortFilter: "team",
  },
  {
    label: "Sent By",
    alignment: "left",
    sort: true,
    sortFilter: "sentBy",
  },
  {
    label: "Time sent / scheduled",
    alignment: "left",
    sort: true,
    sortFilter: "timeSent",
  },
  {
    label: "Status",
    alignment: "left",
    sort: true,
    sortFilter: "status",
  },
  {
    label: "Action",
    alignment: "right",
    sort: false,
  },
];

function NotificationTable({ sortHandler, data }) {
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [sortFilter, setSortFilter] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  useEffect(() => {
    const tempData = data.map((d) => {
      const temp = { ...d };
      const createdAtDateTime = dateToDateAndTime(new Date(d.createdAt));
      const scheduledDateTime = dateToDateAndTime(new Date(d.scheduleDate));
      temp.createdAt = `${createdAtDateTime.date}  ${createdAtDateTime.time}`;
      temp.scheduleDate = `${scheduledDateTime.date}  ${scheduledDateTime.time}`;
      return temp;
    });

    setItems(tempData);
  }, [data]);

  const handleSort = (sort, direction) => () => {
    setSortFilter(sort);
    setSortDirection(direction);
    sortHandler(sort, direction);
  };

  const handleView = (id) => () => {
    if (id) {
      navigate(`/notification/view/${id}`);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table" variant="">
        <TableHead sx={(theme) => customTableHeader(theme)}>
          <TableRow>
            {tableHeads.map((headerItem) => (
              <StyledTableCell align={`${headerItem.alignment}`}>
                <StyledTableCellContent
                  style={
                    headerItem.alignment === "right"
                      ? { justifyContent: "flex-end" }
                      : { justifyContent: "flex-start" }
                  }
                >
                  {headerItem.label}
                  {headerItem.sort && (
                    <StyledTableButtonGroups>
                      <StyledTableSortButton
                        type="button"
                        className={
                          sortFilter === headerItem.sortFilter && sortDirection === "ASC"
                            ? "active"
                            : ""
                        }
                        onClick={handleSort(headerItem.sortFilter, "ASC")}
                      >
                        <FaArrowUp />
                      </StyledTableSortButton>
                      <StyledTableSortButton
                        type="button"
                        className={
                          sortFilter === headerItem.sortFilter && sortDirection === "DESC"
                            ? "active"
                            : ""
                        }
                        onClick={handleSort(headerItem.sortFilter, "DESC")}
                      >
                        <FaArrowDown />
                      </StyledTableSortButton>
                    </StyledTableButtonGroups>
                  )}
                </StyledTableCellContent>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow key={row.id}>
              <StyledTableCell
                align="left"
                component="th"
                scope="row"
                sx={{ color: "#0076B2", cursor: "pointer" }}
                onClick={handleView(row.id)}
              >
                {row?.title}
              </StyledTableCell>
              <StyledTableCell align="left">{row?.team?.name}</StyledTableCell>
              <StyledTableCell align="left">
                {row?.user?.firstName}&nbsp;{row?.user?.lastName}
              </StyledTableCell>
              <StyledTableCell align="left">
                {row?.isSchedule ? row?.scheduleDate : row?.createdAt}
              </StyledTableCell>
              <StyledTableCell align="left">{row?.isEnded ? "Sent" : "Scheduled"}</StyledTableCell>
              <StyledTableCell align="right">
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={handleView(row.id)}
                >
                  <FaEye />
                </MDButton>
              </StyledTableCell>
            </TableRow>
          ))}
          {items.length === 0 && (
            <TableRow>
              <StyledTableCell colSpan={6} align="center" color="dark">
                There is no content.
              </StyledTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

NotificationTable.defaultProps = {
  sortHandler: {},
  data: [],
};

NotificationTable.propTypes = {
  sortHandler: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.any),
};

export default NotificationTable;
