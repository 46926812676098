import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";

import PropTypes from "prop-types";

function ColorPicker({ initialColor, active, changeHandler }) {
  const [color, setColor] = useState("#000000");
  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    setColor(initialColor);
  }, [initialColor]);

  const handleClick = () => {
    if (!active) return;
    setShowPicker((prevState) => !prevState);
  };

  const handleClose = () => {
    setShowPicker(false);
  };

  const handleChange = (_color) => {
    setColor(_color.hex);
    changeHandler(_color.hex);
  };

  return (
    <div>
      <div
        style={{
          width: "100px",
          padding: "5px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "flex",
          gap: "5px",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <div
          style={{
            width: "36px",
            height: "14px",
            borderRadius: "2px",
            background: `${color}`,
          }}
        />
        <span style={{ fontSize: "11px" }}>{color}</span>
      </div>
      {showPicker ? (
        <div
          style={{
            position: "absolute",
            zIndex: "2",
          }}
        >
          <div
            style={{
              position: "fixed",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
            }}
            onClick={handleClose}
          />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
}

ColorPicker.defaultProps = {
  initialColor: "",
  active: true,
  changeHandler: {},
};

ColorPicker.propTypes = {
  initialColor: PropTypes.string,
  active: PropTypes.bool,
  changeHandler: PropTypes.func,
};

export default ColorPicker;
