import PropTypes from "prop-types";
import { Checkbox, Divider, Grid } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";
import ColorPicker from "components/ColorPicker";
import fontList from "../data/fonts";

function TeamReview({ item, prevHandler, saveHandler }) {
  const handlePrev = () => {
    prevHandler();
  };

  const handlePublish = () => {
    saveHandler(false);
  };

  return (
    <MDBox my={3}>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ minWidth: "150px" }}>
          {item?.name}
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={handlePrev}>
            Back
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox>
        <MDBox sx={(theme) => customFormContainer(theme)}>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Team Details
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team Name
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.name}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team Description
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.description}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team Logo
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">
                {item?.logo && item?.logo !== "" && (
                  <MDAvatar
                    src={item?.logo}
                    alt="Avatar"
                    sx={{ width: "250px", height: "250px" }}
                  />
                )}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Platform URL
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.platformUrl}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Token name
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.tokenSymbol}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Token Logo
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">
                {item?.tokenImage && item?.tokenImage !== "" && (
                  <MDAvatar
                    src={item?.tokenImage}
                    alt="Avatar"
                    sx={{ width: "250px", height: "250px" }}
                  />
                )}
              </MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Admin Details
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                First Name
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.adminFirstName}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Last Name
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.adminLastName}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Email ID
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.adminEmail}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Token Details
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Token sale to fan (commission%)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <MDTypography fontSize="15px">{item?.tokenSaleToFan}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Token sale to sponsors (commission%)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <MDTypography fontSize="15px">{item?.tokenSaleToSponsors}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Price per token
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <MDTypography fontSize="15px">{item?.price}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Vault token balance
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <MDTypography fontSize="15px">{item?.vaultTokenBalance}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team token wallet balance
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <MDTypography fontSize="15px">{item?.teamWalletBalance}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Membership Tier Details
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Kudos required to hit Tier 1
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <MDTypography fontSize="15px">{item?.kudosToTire1}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Kudos required to hit Tier 2
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <MDTypography fontSize="15px">{item?.kudosToTire2}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Kudos required to hit Tier 3
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <MDTypography fontSize="15px">{item?.kudosToTire3}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Kudos required to hit Tier 4
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <MDTypography fontSize="15px">{item?.kudosToTire4}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Member tier level 1 (Name)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <MDTypography fontSize="15px">{item?.memberLevelName1}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Member tier level 2 (Name)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <MDTypography fontSize="15px">{item?.memberLevelName2}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Member tier level 3 (Name)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <MDTypography fontSize="15px">{item?.memberLevelName3}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Member tier level 4 (Name)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <MDTypography fontSize="15px">{item?.memberLevelName4}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Player Details
          </MDTypography>
          {item.players?.map((player, idx) => (
            <Grid container spacing={2} key={`team-player-${idx.toString()}`} mb={2}>
              <Grid item xs={12} sm={3} sx={{ display: "flex", alignItems: "center" }}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Player {idx + 1}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ display: "flex", alignItems: "center" }}>
                <MDTypography fontSize="15px">{player?.name}</MDTypography>
              </Grid>
              <Grid item xs={12} sm={5}>
                {player.avatar && player.avatar !== "" && (
                  <MDAvatar
                    src={player.avatar}
                    alt="Avatar"
                    sx={{ width: "100px", height: "100px" }}
                  />
                )}
              </Grid>
            </Grid>
          ))}
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mt={4}
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Theme Details
          </MDTypography>
          <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
            Colours
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Background theme</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">{item?.backgroundTheme}</MDTypography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Landing Page Background Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.landingPageBackgroundColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Landing Page Backgroud Top Layer Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.landingPageBackgroundTopLayerColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">
                Landing Page Background Bottom Layer Color
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker
                initialColor={item.landingPageBackgroundBottomLayerColor}
                active={false}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Landing Page Text Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.landingPageTextColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Auth Input Background Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.authInputBackgroundColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Primary Text Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.primaryTextColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Heading Text Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.headingTextColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Menu Text Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.menuTextColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Secondary Text Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.secondaryTextColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Primary Button Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.primaryButtonColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Secondary Button Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.secondaryButtonColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Tab Hightlight Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.tabHighlightColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Button Text Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.buttonTextColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Token Text Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.tokenTextColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Kudos Text Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.kudosTextColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Kudos Icon Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.kudosIconColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Date Text Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.dateTextColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Success Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.successColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Error Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.errorColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Warning Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.warningColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Icon Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.iconColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Landing Page Card Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.landingPageCardColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Main Page Card Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.mainPageCardColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Main Page Background Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.mainPageBackgroundColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Main Page Header Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.mainPageHeaderColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Main Page Sidebar Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.mainPageSidebarColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Modal Background Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.modalBackgroundColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Memebership Tier One Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.membershipTierOneColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Membership Tier Two Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.membershipTierTwoColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Membership Tier Three Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.membershipTierThreeColor} active={false} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Membership Tier Four Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ColorPicker initialColor={item.membershipTierFourColor} active={false} />
            </Grid>
          </Grid>
          <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
            Fonts
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Primary font (Headings)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{fontList[item?.primaryFont || 0]?.label}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Secondary font (Sub headings, Main text)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">
                <MDTypography fontSize="15px">
                  {fontList[item?.secondaryFont || 0]?.label}
                </MDTypography>
              </MDTypography>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Landing page hero image
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">
                {item?.landingPageHeroImage && item?.landingPageHeroImage !== "" && (
                  <MDAvatar
                    src={item?.landingPageHeroImage}
                    alt="Avatar"
                    sx={{ width: "250px", height: "250px" }}
                  />
                )}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                App Logo
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">
                {item?.appLogoImage && item?.appLogoImage !== "" && (
                  <MDAvatar
                    src={item?.appLogoImage}
                    alt="Avatar"
                    sx={{ width: "250px", height: "250px" }}
                  />
                )}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                PWA icon
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">
                {item?.pwaIcon && item?.pwaIcon !== "" && (
                  <MDAvatar
                    src={item?.pwaIcon}
                    alt="Avatar"
                    sx={{ width: "250px", height: "250px" }}
                  />
                )}
              </MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Reward Settings
          </MDTypography>
          <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
            Refer a Friend
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">
                Successful referral + sign up reward - Tokens - Referrer
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <MDTypography fontSize="15px">{item.referralSignupTokenReward}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">
                Successful referral + sign up reward - KUDOS - Referrer
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <MDTypography fontSize="15px">{item.referralSignupKudosReward}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">
                Successful referral + PLAY GAME reward - Tokens - Referrer
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <MDTypography fontSize="15px">{item.referralPlayTokenReward}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">
                Successful referral + PLAY GAME reward - KUDOS - Referrer
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <MDTypography fontSize="15px">{item.referralPlayKudosReward}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
            Bonus Events
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={6}>
              <MDBox>
                <Grid container spacing={2} mb={4}>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">Reward first users registered?</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Checkbox disabled checked={item.enableEarlySignupReward} />
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox>
                <Grid container spacing={2} mb={4}>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">No of users to reward</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">{item.earlySignupLimitCount}</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">Reward sent(Tokens)</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">{item.earlySignupTokenReward}</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">Reward sent(KUDOS)</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">{item.earlySignupKudosReward}</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">Bonus asset to send</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">{item.earlySignupAsset?.title}</MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={6}>
              <MDBox>
                <Grid container spacing={2} mb={4}>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">
                      Reward weekly top ranked (leaderboard)?
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Checkbox disabled checked={item.enableWeeklyReward} />
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox>
                <Grid container spacing={2} mb={4}>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">Rank of users to send rewards</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">{item.weeklyRewardLimitCount}</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">Reward sent(Tokens)</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">{item.weeklyTokenReward}</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">Reward sent(KUDOS)</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">{item.weeklyKudosReward}</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">Bonus asset to send</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography fontSize="15px">{item.weeklyAsset?.title || ""}</MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Grid container justifyContent="flex-end" gap={2}>
        <MDButton color="secondary" size="large" onClick={handlePublish}>
          Publish
        </MDButton>
      </Grid>
    </MDBox>
  );
}

TeamReview.defaultProps = {
  item: null,
  prevHandler: {},
  saveHandler: {},
};

TeamReview.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  prevHandler: PropTypes.func,
  saveHandler: PropTypes.func,
};

export default TeamReview;
