import { AssetTabs } from "layouts/common/constants";
import AssetService from "services/AssetService";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  filter: AssetTabs.Live,
  totalCount: 0,
  assets: [],
};

export const retriveAsset = createAsyncThunk(
  "/asset/retrive",
  async ({ filter, search, page, rowsPerPage, sortFilter, sortDirection }) => {
    const res = await AssetService.retrive(
      filter,
      search,
      page,
      rowsPerPage,
      sortFilter,
      sortDirection,
    );
    return res.data;
  },
);

export const createAsset = createAsyncThunk("/asset/create", async ({ data }) => {
  const res = await AssetService.create(data);
  return res.data;
});

export const updateAsset = createAsyncThunk("/asset/update", async ({ id, data }) => {
  const res = await AssetService.update(id, data);
  return res.data;
});

export const deleteAsset = createAsyncThunk("/asset/delete", async (id) => {
  await AssetService.remove(id);
  return { id };
});

const assetSlice = createSlice({
  name: "asset",
  initialState,
  extraReducers: {
    [retriveAsset.fulfilled]: (state, action) => {
      state.totalCount = action.payload.count;
      state.assets = [...action.payload.data];
    },
    [updateAsset.fulfilled]: (state, action) => {
      const index = state.assets.findIndex((asset) => asset.id === action.payload.id);
      state.assets[index] = {
        ...state.assets[index],
        ...action.payload,
      };
    },
    [deleteAsset.fulfilled]: (state, action) => {
      const index = state.assets.findIndex(({ id }) => id === action.payload.id);
      state.assets.splice(index, 1);
      state.totalCount -= 1;
    },
  },
  reducers: {
    setFilterAsset: (state, action) => {
      state.filter = action.payload;
    },
  },
});
export const { setFilterAsset } = assetSlice.actions;

const { reducer } = assetSlice;
export default reducer;
