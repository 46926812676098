import { AuthContext } from "context/AuthContext";
import { useContext } from "react";

const useAuth = () => {
  const authContext = useContext(AuthContext);

  if (authContext === undefined) {
    throw new Error("Auth context undefined");
  }

  return authContext;
};

export default useAuth;
