import { useEffect, useState, useCallback, useRef } from "react";
// import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { CSVLink } from "react-csv";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import { pageHeaderContainer, flexContainer, searchInput } from "layouts/common/styles";

import UserService from "services/UserService";
import ToastrService from "services/ToastrService";

// slices
import { activateUser, deleteUser, retriveUsers } from "store/slices/userSlice";
import { setLoading } from "store/appSlice";

import { UserRole } from "layouts/common/constants";

import UserTable from "./components/UserTable";

// Export to CSV
const headers = [
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Email", key: "email" },
  { label: "Registration Date", key: "createdAt" },
  { label: "Last login date", key: "lastLoginAt" },
  { label: "Kudos Balance", key: "kudosAmount" },
  { label: "Tokens Balance", key: "tokenAmount" },
  { label: "City", key: "locationCity" },
  { label: "Country", key: "locationCountry" },
];

function Users() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const adminProfile = useSelector((state) => state.users.profile);

  const totalCount = useSelector((state) => state.users.totalCount);
  const users = useSelector((state) => state.users.data);
  const teams = useSelector((state) => state.teams);

  const [dataToDownload, setDataToDownload] = useState([]);
  const csvRef = useRef();

  // Search options.
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [sortFilter, setSortFilter] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const fetchBySearchOptions = useCallback(() => {
    let searchTeam = "";
    if (adminProfile.role === UserRole.SuperAdmin && selectedTeams.length > 0) {
      searchTeam = selectedTeams.map((d) => d.id).join(",");
    } else if (adminProfile.role === UserRole.TeamAdmin) {
      searchTeam = adminProfile.teamId;
    }
    dispatch(
      retriveUsers({ searchTeam, searchText, page, rowsPerPage, sortFilter, sortDirection }),
    );
  }, [
    dispatch,
    selectedTeams,
    searchText,
    page,
    rowsPerPage,
    sortFilter,
    sortDirection,
    adminProfile,
  ]);

  useEffect(() => {
    fetchBySearchOptions();
  }, [fetchBySearchOptions]);

  const sortHandler = (sort, direction) => {
    setSortFilter(sort);
    setSortDirection(direction);
  };

  const deleteHandler = (_id) => {
    dispatch(deleteUser(_id))
      .unwrap()
      .then(() => {
        ToastrService.success("Successfully deleted user data");
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to delete user");
      });
  };

  const activeHandler = (id, activate) => {
    dispatch(activateUser({ id, active: activate }));
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };

  const changePageNum = (direct) => {
    const maxPageNum = Math.floor((totalCount - 1) / rowsPerPage);
    if (page === 0 && direct === -1) return;
    if (page === maxPageNum && direct === 1) return;

    setPage((prevState) => prevState + direct);
  };

  const exportUsers = () => {
    let searchTeam = "";
    if (adminProfile.role === UserRole.SuperAdmin && selectedTeams.length > 0) {
      searchTeam = selectedTeams.map((d) => d.id).join(",");
    } else if (adminProfile.role === UserRole.TeamAdmin) {
      searchTeam = adminProfile.teamId;
    }

    dispatch(setLoading(true));
    UserService.getAll(searchTeam, searchText, 0, totalCount, sortFilter, sortDirection)
      .then(({ data }) => {
        dispatch(setLoading(false));
        setDataToDownload(data.data);
        csvRef.current.link.click();
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load user");
      });
  };

  return (
    <DashboardLayout>
      <MDBox py={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDBox sx={(theme) => flexContainer(theme)}>
            <MDTypography fontSize="12px" fontWeight="bold">
              Search By:
            </MDTypography>
            <MDInput
              type="search"
              placeholder="Team...Name...Email...Phone"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              sx={(theme) => searchInput(theme)}
            />
          </MDBox>
        </MDBox>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "150px" }}>
            Users
          </MDTypography>
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDButton size="small" color="secondary" onClick={exportUsers}>
              Export
            </MDButton>
            <CSVLink
              data={dataToDownload}
              headers={headers}
              filename="superadmin(users).csv"
              className="hidden"
              ref={csvRef}
              target="_blank"
            />
          </MDBox>
        </MDBox>
        {adminProfile.role === UserRole.SuperAdmin && (
          <MDBox mb={2}>
            <MDTypography fontSize="12px" fontWeight="bold">
              Select Teams:{" "}
            </MDTypography>
            <Autocomplete
              multiple
              value={selectedTeams}
              options={teams}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => setSelectedTeams(v)}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              sx={{ maxWidth: "568px", width: "100%" }}
              placeholder="Select Teams"
            />
          </MDBox>
        )}
        <MDBox>
          <UserTable
            data={users}
            deleteHandler={deleteHandler}
            activeHandler={activeHandler}
            sortHandler={sortHandler}
          />
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end" my={3}>
            <FormControl>
              <InputLabel id="rows_per_page">Rows per page</InputLabel>
              <Select
                labelId="rows_per_page"
                id="rows_per_page_select"
                value={rowsPerPage}
                label="Rows per page"
                onChange={handleChangeRowsPerPage}
                sx={{ height: "38px", width: "150px", minWidth: "50px" }}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
              </Select>
            </FormControl>
            <MDTypography fontSize="12px" fontWeight="bold">
              {totalCount > 0 ? page * rowsPerPage + 1 : "0"}
              &nbsp;~&nbsp;
              {totalCount > page * rowsPerPage + rowsPerPage
                ? page * rowsPerPage + rowsPerPage
                : totalCount}
              &nbsp;of&nbsp;
              {totalCount}
            </MDTypography>

            <MDButton icon="text" onClick={() => changePageNum(-1)}>
              <FaArrowLeft />
            </MDButton>
            <MDButton icon="text" onClick={() => changePageNum(1)}>
              <FaArrowRight />
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Users;
