import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Autocomplete, Grid, TextField } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { customFormContainer } from "layouts/common/styles";
import { UserRole } from "layouts/common/constants";

import sponsorTeamOptions from "layouts/assets/data/sponsorTeamOptions";
import assetCategories from "layouts/assets/data/assetCategories";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .MuiOutlinedInput-root .MuiAutocomplete-input`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
    padding: 0,
    width: "35px",
  },
  [`& .MuiOutlinedInput-root`]: {
    padding: "0px",
    paddingLeft: "10px",
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

function SelectAssetCategory({ formData, nextHandler }) {
  const adminProfile = useSelector((state) => state.users.profile);
  const teams = useSelector((state) => state.teams);
  const [type, setType] = useState(formData.type ? formData.type : "");
  const [team, setTeam] = useState(formData.team ? formData.team : "");
  const [category, setCategory] = useState(formData.category ? formData.category : "");
  const [validationMsg, setValidationMsg] = useState({
    type: "",
    category: "",
    team: "",
  });
  const validate = () => {
    let validated = true;
    const temp = { ...validationMsg };
    if (type === "") {
      temp.type = "Invalid type";
      validated = false;
    } else {
      temp.type = "";
    }
    if (team === "") {
      temp.team = "Invalid team";
      validated = false;
    } else {
      temp.team = "";
    }

    if (category === "") {
      temp.category = "Invalid category";
      validated = false;
    } else {
      temp.category = "";
    }
    setValidationMsg({ ...temp });
    return validated;
  };

  const handleNext = () => {
    if (!validate()) {
      return;
    }
    nextHandler({ ...formData, type, category, team });
  };

  useEffect(() => {
    setType(formData.type ? formData.type : "");
    if (adminProfile.role === UserRole.SuperAdmin) {
      setTeam(formData.team ? formData.team : "");
    } else {
      const tmp = teams.find((d) => d.id === adminProfile.teamId);
      setTeam(tmp);
    }

    setCategory(formData.category ? formData.category : "");
  }, [formData, teams]);

  return (
    <MDBox>
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <Grid container spacing={2} mb={4} alignItems="center">
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Sponsor / Team</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <StyledAutocomplete
              options={sponsorTeamOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  error={validationMsg.type !== ""}
                />
              )}
              placeholder="Type"
              onChange={(e, v) => setType(v.value)}
              value={type}
              disableClearable
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Select Team</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {adminProfile.role === UserRole.SuperAdmin ? (
              <StyledAutocomplete
                value={team.id || ""}
                options={teams.map((item) => item.id)}
                getOptionLabel={(option) => teams.find((item) => item.id === option)?.name || ""}
                onChange={(e, v) => setTeam(teams.find((item) => item.id === v))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    error={validationMsg.team !== ""}
                  />
                )}
                placeholder="Choose Team"
                error={validationMsg.team !== ""}
              />
            ) : (
              <MDTypography fontSize="15px">{team?.name}</MDTypography>
            )}
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Asset Category</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <StyledAutocomplete
              options={assetCategories}
              value={category}
              onChange={(e, v) => setCategory(v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  error={validationMsg.category !== ""}
                />
              )}
              placeholder="Category"
              disableClearable
            />
          </Grid>
        </Grid>
      </MDBox>
      <Grid container justifyContent="flex-end">
        <MDButton color="secondary" size="large" onClick={handleNext}>
          Next
        </MDButton>
      </Grid>
    </MDBox>
  );
}

SelectAssetCategory.defaultProps = {
  nextHandler: {},
  formData: null,
};

SelectAssetCategory.propTypes = {
  nextHandler: PropTypes.func,
  formData: PropTypes.objectOf(PropTypes.any),
};

export default SelectAssetCategory;
