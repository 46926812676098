import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Divider, FormControl, Grid, InputLabel, MenuItem, Select, Switch } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";
import { UserRole } from "layouts/common/constants";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { setLoading } from "store/appSlice";
import { activateUser } from "store/slices/userSlice";

import UserService from "services/UserService";
import PlatformService from "services/PlatformService";
import ToastrService from "services/ToastrService";
import TransactionService from "services/TransactionService";

import { dateToDateAndTime } from "utils/converter";

import TransferUserForm from "../TransferUser/TransferUserForm";
import UserActivityTable from "../UserActivityTable";

function ViewUser() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { teamId, email } = useParams();

  // Search options.
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [item, setItem] = useState(null);
  const [usage, setUsage] = useState(null);
  const [analysisInfo, setAnalysisInfo] = useState(null);
  const [activities, setActivities] = useState({ activities: [] });

  const [uiMode, setUIMode] = useState(0);

  const getUser = (_teamId, _email) => {
    dispatch(setLoading(true));
    UserService.get(_teamId, _email)
      .then(async (response) => {
        setItem(response.data);
        await PlatformService.getPlatformUsage(response.data.id)
          .then(({ data }) => {
            setUsage(data);
          })
          .catch((e) => {
            ToastrService.error(
              e.response?.data?.message?.message || "Failed to load platform usage.",
            );
          });
        UserService.getAnalysisInfo(response.data.id)
          .then(({ data }) => {
            setAnalysisInfo(data);
            dispatch(setLoading(false));
          })
          .catch((e) => {
            ToastrService.error(
              e.response?.data?.message?.message || "Failed to load analysis data.",
            );
            dispatch(setLoading(false));
          });
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load user data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (item) {
      TransactionService.getUserActivities(item.id, page, rowsPerPage).then(({ data }) => {
        setActivities({ activities: [...data] });
      });
    }
  }, [item, page, rowsPerPage]);

  useEffect(() => {
    if (teamId && email) getUser(teamId, email);
  }, [teamId, email]);

  const handleBack = () => {
    if (uiMode === 0) {
      navigate("/users");
    } else {
      setUIMode(0);
    }
  };

  const handleViewTransfer = () => {
    setUIMode(1);
  };

  const handleActivate = (id, active) => () => {
    dispatch(activateUser({ id, active }))
      .unwrap()
      .then((data) => {
        setItem({ ...item, ...data });
      });
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };

  const changePageNum = (direct) => () => {
    if (page === 0 && direct === -1) return;
    if (activities.activities.length < rowsPerPage && direct === 1) return;

    setPage((prevState) => prevState + direct);
  };

  return (
    <DashboardLayout>
      <MDBox my={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "100%", maxWidth: "500px" }}>
            {uiMode === 0 ? "Users - Single User View" : "Transfer Tokens/Kudos - Single User"}
          </MDTypography>
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDButton size="small" onClick={() => handleBack()}>
              Back
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        {uiMode === 0 ? (
          <MDBox>
            {item?.role === UserRole.Fan && (
              <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
                <MDButton size="small" color="secondary" onClick={handleViewTransfer}>
                  Send Tokens/ Kudos +
                </MDButton>
              </MDBox>
            )}

            <MDBox sx={(theme) => customFormContainer(theme)}>
              <MDTypography
                fontSize="15px"
                fontWeight="bold"
                mb={3}
                sx={{ textDecoration: "underline" }}
              >
                Profile Details
              </MDTypography>
              <Grid container spacing={2} mb={4}>
                <Grid item xs={12} sm={2.5}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    User Name
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={3.5}>
                  <MDTypography fontSize="15px">{item?.username}</MDTypography>
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Date of Bith
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={3.5}>
                  <MDTypography fontSize="15px">{item?.birthday}</MDTypography>
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Registration date
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={3.5}>
                  <MDTypography fontSize="15px">
                    {item?.createdAt ? dateToDateAndTime(new Date(item?.createdAt)).date : ""}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Gender
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={3.5}>
                  <MDTypography fontSize="15px">{item?.gender}</MDTypography>
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Name
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={3.5}>
                  <MDTypography fontSize="15px">
                    {item?.firstName} {item?.lastName}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Country
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={3.5}>
                  <MDTypography fontSize="15px">{item?.locationCountry}</MDTypography>
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Phone
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={3.5}>
                  <MDTypography fontSize="15px">{item?.phone}</MDTypography>
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    City
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={3.5}>
                  <MDTypography fontSize="15px">{item?.locationCity}</MDTypography>
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Active/Inactive
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={3.5}>
                  <Switch
                    size="small"
                    checked={item?.isActivated}
                    onChange={handleActivate(item?.id, !item?.isActivated)}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Postcode
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={3.5}>
                  <MDTypography fontSize="15px">{item?.locationPostcode}</MDTypography>
                </Grid>
              </Grid>
              <MDTypography
                fontSize="15px"
                fontWeight="bold"
                mb={3}
                sx={{ textDecoration: "underline" }}
              >
                Fan Details
              </MDTypography>
              <Grid container spacing={2} mb={4}>
                <Grid item xs={12} sm={3}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Team`s favourite player
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <MDTypography fontSize="15px">{item?.favPlayer}</MDTypography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Fan Level
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <MDTypography fontSize="15px">{item?.fanType}</MDTypography>
                </Grid>
              </Grid>
              <MDTypography
                fontSize="15px"
                fontWeight="bold"
                mb={3}
                sx={{ textDecoration: "underline" }}
              >
                Usage Data
              </MDTypography>
              <Grid container spacing={2} mb={4}>
                <Grid item xs={12} sm={6}>
                  <MDBox>
                    <Grid container spacing={2} mb={4}>
                      <Grid item xs={12} sm={5}>
                        <MDTypography fontSize="15px" fontWeight="bold">
                          No of visits to platform (total)
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <MDTypography fontSize="15px">{usage?.totalLoginCount || 0}</MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <MDTypography fontSize="15px" fontWeight="bold">
                          Time on platform (total mins)
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <MDTypography fontSize="15px">
                          {usage?.totalUsageMinutes || 0} mins
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <MDTypography fontSize="15px" fontWeight="bold">
                          No of visits to platform (month)
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <MDTypography fontSize="15px">
                          {usage?.lastMonthLoginCount || 0}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <MDTypography fontSize="15px" fontWeight="bold">
                          Time on platform (month)
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <MDTypography fontSize="15px">
                          {usage?.lastMonthUsageMinutes || 0} mins
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <MDTypography fontSize="15px" fontWeight="bold">
                          Last login date
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <MDTypography fontSize="15px">
                          {usage?.lastLoginAt
                            ? dateToDateAndTime(new Date(usage?.lastLoginAt)).date
                            : ""}
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDBox>
                    <Grid container spacing={2} mb={4}>
                      <Grid item xs={12} sm={5}>
                        <MDTypography fontSize="15px" fontWeight="bold">
                          Kudos earned (total)
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <MDTypography fontSize="15px">50</MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <MDTypography fontSize="15px" fontWeight="bold">
                          Token earned (total)
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <MDTypography fontSize="15px">202</MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <MDTypography fontSize="15px" fontWeight="bold">
                          Kudos balance
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <MDTypography fontSize="15px">{item?.kudosAmount || 0}</MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <MDTypography fontSize="15px" fontWeight="bold">
                          Token balance
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <MDTypography fontSize="15px">{item?.tokenAmount || 0}</MDTypography>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              </Grid>
              <MDTypography
                fontSize="15px"
                fontWeight="bold"
                mb={3}
                sx={{ textDecoration: "underline" }}
              >
                Game Data
              </MDTypography>
              <Grid container spacing={2} mb={4}>
                <Grid item xs={12} sm={3}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Number of games played
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <MDTypography fontSize="15px">{analysisInfo?.gamePlayed || 0}</MDTypography>
                </Grid>
              </Grid>
              <MDTypography
                fontSize="15px"
                fontWeight="bold"
                mb={3}
                sx={{ textDecoration: "underline" }}
              >
                Challenge Data
              </MDTypography>
              <Grid container spacing={2} mb={4}>
                <Grid item xs={12} sm={3}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Number of challenges played
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <MDTypography fontSize="15px">{analysisInfo?.challengePlayed || 0}</MDTypography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Number of challenges completed
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <MDTypography fontSize="15px">
                    {analysisInfo?.challengeCompleted || 0}
                  </MDTypography>
                </Grid>
              </Grid>
              <MDTypography
                fontSize="15px"
                fontWeight="bold"
                mb={3}
                sx={{ textDecoration: "underline" }}
              >
                Poll Data
              </MDTypography>
              <Grid container spacing={2} mb={4}>
                <Grid item xs={12} sm={3}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Number of polls completed
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <MDTypography fontSize="15px">{analysisInfo?.pollCompleted || 0}</MDTypography>
                </Grid>
              </Grid>
              <MDTypography
                fontSize="15px"
                fontWeight="bold"
                mb={3}
                sx={{ textDecoration: "underline" }}
              >
                Assets Data
              </MDTypography>
              <Grid container spacing={2} mb={4}>
                <Grid item xs={12} sm={3}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    No of assets redeemed
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <MDTypography fontSize="15px">{analysisInfo?.redeemedAsset || 0}</MDTypography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    No of assets claimed
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <MDTypography fontSize="15px">{analysisInfo?.claimedAsset || 0}</MDTypography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Tokens spent on redemptions
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <MDTypography fontSize="15px">
                    {analysisInfo?.spentRedemptionToken || 0}
                  </MDTypography>
                </Grid>
              </Grid>

              <MDTypography
                fontSize="15px"
                fontWeight="bold"
                mb={3}
                sx={{ textDecoration: "underline" }}
              >
                Activity
              </MDTypography>
              <UserActivityTable data={activities.activities} />
              <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end" my={3}>
                <FormControl>
                  <InputLabel id="rows_per_page">Rows per page</InputLabel>
                  <Select
                    labelId="rows_per_page"
                    id="rows_per_page_select"
                    value={rowsPerPage}
                    label="Rows per page"
                    onChange={handleChangeRowsPerPage}
                    sx={{ height: "38px", width: "150px", minWidth: "50px" }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
                <MDTypography fontSize="12px" fontWeight="bold">
                  {page * rowsPerPage + 1}
                  &nbsp;~&nbsp;
                  {activities.activities.length === rowsPerPage
                    ? page * rowsPerPage + rowsPerPage
                    : page * rowsPerPage + activities.activities.length}
                </MDTypography>

                <MDButton icon="text" onClick={changePageNum(-1)}>
                  <FaArrowLeft />
                </MDButton>
                <MDButton icon="text" onClick={changePageNum(1)}>
                  <FaArrowRight />
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        ) : (
          <TransferUserForm user={item} updateUserInfo={getUser} teamId={teamId} />
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default ViewUser;
