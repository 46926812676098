import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Switch } from "@mui/material";

import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

import ConfirmSnackBarContent from "layouts/common/components/ConfirmSnackbarContent";

import { FaArrowDown, FaArrowUp, FaEye, FaTrash } from "react-icons/fa";

import { dateToDateAndTime } from "utils/converter";

import { UserRole } from "layouts/common/constants";
import ToastrService from "services/ToastrService";

import {
  customTableHeader,
  StyledTableCell,
  StyledTableCellContent,
  StyledTableButtonGroups,
  StyledTableSortButton,
} from "layouts/common/styles";

const tableHeads = [
  {
    label: "User Name",
    alignment: "left",
    sort: true,
    sortFilter: "userName",
  },
  {
    label: "Registration Date",
    alignment: "left",
    sort: true,
    sortFilter: "createdAt",
  },
  {
    label: "Name",
    alignment: "left",
    sort: true,
    sortFilter: "name",
  },
  {
    label: "Email",
    alignment: "left",
    sort: true,
    sortFilter: "email",
  },
  {
    label: "Phone",
    alignment: "left",
    sort: true,
    sortFilter: "phone",
  },
  {
    label: "Activate",
    alignment: "right",
    sort: true,
    sortFilter: "isActivated",
  },
  {
    label: "Action",
    alignment: "right",
    sort: false,
  },
];

function UserTable({ deleteHandler, activeHandler, sortHandler, data }) {
  const adminProfile = useSelector((state) => state.users.profile);
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);

  const [deleteIdx, setDeleteIdx] = useState("");

  const [items, setItems] = useState([]);
  const [sortFilter, setSortFilter] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  useEffect(() => {
    const tempData = data.map((d) => {
      const temp = { ...d };
      const createdAtDateTime = dateToDateAndTime(new Date(d.createdAt));
      temp.createdAt = `${createdAtDateTime.date}  ${createdAtDateTime.time}`;
      return temp;
    });

    setItems(tempData);
  }, [data]);

  const handleSort = (sort, direction) => () => {
    setSortFilter(sort);
    setSortDirection(direction);
    sortHandler(sort, direction);
  };

  const handleView = (teamId, email) => () => {
    if (teamId && email) {
      navigate(`/user/view/${teamId}/${email}`);
    }
  };

  const handleDelete = (id) => () => {
    if (adminProfile.role === UserRole.TeamAdmin) {
      ToastrService.warning("Team Admin cannot delete user data");
      return;
    }
    setShow(true);
    setDeleteIdx(id);
  };

  const handleActivate = (id, active) => () => {
    activeHandler(id, active);
  };

  const confirmDelete = () => {
    if (deleteIdx !== "") {
      setShow(false);
      deleteHandler(deleteIdx);
      setDeleteIdx("");
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table" variant="">
        <TableHead sx={(theme) => customTableHeader(theme)}>
          <TableRow>
            {tableHeads.map((headerItem) => (
              <StyledTableCell align={`${headerItem.alignment}`}>
                <StyledTableCellContent
                  style={
                    headerItem.alignment === "right"
                      ? { justifyContent: "flex-end" }
                      : { justifyContent: "flex-start" }
                  }
                >
                  {headerItem.label}
                  {headerItem.sort && (
                    <StyledTableButtonGroups>
                      <StyledTableSortButton
                        type="button"
                        className={
                          sortFilter === headerItem.sortFilter && sortDirection === "ASC"
                            ? "active"
                            : ""
                        }
                        onClick={handleSort(headerItem.sortFilter, "ASC")}
                      >
                        <FaArrowUp />
                      </StyledTableSortButton>
                      <StyledTableSortButton
                        type="button"
                        className={
                          sortFilter === headerItem.sortFilter && sortDirection === "DESC"
                            ? "active"
                            : ""
                        }
                        onClick={handleSort(headerItem.sortFilter, "DESC")}
                      >
                        <FaArrowDown />
                      </StyledTableSortButton>
                    </StyledTableButtonGroups>
                  )}
                </StyledTableCellContent>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow key={row.id}>
              <StyledTableCell
                align="left"
                component="th"
                scope="row"
                sx={{ color: "#0076B2", cursor: "pointer" }}
                onClick={handleView(row.team?.id, row.email)}
              >
                {row.userName}
              </StyledTableCell>
              <StyledTableCell align="left">{row.createdAt}</StyledTableCell>
              <StyledTableCell align="left">
                {row.firstName} {row.lastName}
              </StyledTableCell>
              <StyledTableCell align="left">{row.email}</StyledTableCell>
              <StyledTableCell align="left">{row.phone}</StyledTableCell>
              <StyledTableCell align="center">
                <Switch
                  size="small"
                  checked={row.isActivated}
                  onChange={handleActivate(row.id, !row.isActivated)}
                  inputProps={{ "aria-label": "ant design" }}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={handleView(row.team?.id, row.email)}
                >
                  <FaEye />
                </MDButton>
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={handleDelete(row.id)}
                >
                  <FaTrash />
                </MDButton>
              </StyledTableCell>
            </TableRow>
          ))}
          {items.length === 0 && (
            <TableRow>
              <StyledTableCell colSpan={6} align="center" color="dark">
                There is no content.
              </StyledTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <MDSnackbar
        color="error"
        icon="notifications"
        title="Delete User"
        dateTime=""
        content={
          <ConfirmSnackBarContent
            title="Are you sure to delete this data?"
            confirmHandler={confirmDelete}
          />
        }
        open={show}
        close={toggleSnackbar}
      />
    </TableContainer>
  );
}

UserTable.defaultProps = {
  deleteHandler: {},
  activeHandler: {},
  sortHandler: {},
  data: [],
};

UserTable.propTypes = {
  deleteHandler: PropTypes.func,
  activeHandler: PropTypes.func,
  sortHandler: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.any),
};

export default UserTable;
