import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { pageHeaderContainer, flexContainer } from "layouts/common/styles";
import { Divider } from "@mui/material";
import AdminTable from "./AdminTable";

function ManageAdmins({ item, backHandler }) {
  return (
    <MDBox my={3}>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDBox sx={{ maxWidth: "768px", width: "100%" }}>
          <MDTypography fontSize="20px" fontWeight="bold">
            Team - Manage Admin Members
          </MDTypography>
          <MDTypography fontSize="12px" fontWeight="bold">
            {item?.name}
          </MDTypography>
        </MDBox>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={backHandler}>
            Back
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end" mb={1}>
        <MDButton size="small" color="secondary">
          Add member +
        </MDButton>
      </MDBox>
      <AdminTable data={[]} />
    </MDBox>
  );
}

ManageAdmins.defaultProps = {
  item: null,
  backHandler: {},
};

ManageAdmins.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  backHandler: PropTypes.func,
};

export default ManageAdmins;
