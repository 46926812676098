import http from "../apis/http-common";

const retrive = (
  filter,
  search,
  page,
  rowsPerPage,
  sortFilter = "",
  sortDirection = "",
  onlyFilter = false,
) => {
  const skip = page * rowsPerPage;

  const sortParams =
    sortFilter && sortFilter !== "" && sortDirection && sortDirection !== ""
      ? `&sort=${sortFilter}&direction=${sortDirection}`
      : "";

  if (!onlyFilter) {
    return http.get(
      `/match?skip=${skip}&take=${rowsPerPage}&search=${search}&filter=${filter}${sortParams}`,
    );
  }

  let searchParam = "";
  if (search !== "" && search) {
    searchParam = `&search=${search}`;
  }

  return http.get(`/match?filter=${filter}&take=9999&skip=0${searchParam}${sortParams}`);
};

const get = (id) => http.get(`/match/${id}`);

const create = (data) => http.post("/match", data);

const update = (id, data) => http.put(`/match/${id}`, data);

const remove = (id) => http.delete(`/match/${id}`);

const MatchesService = {
  retrive,
  get,
  create,
  update,
  remove,
};

export default MatchesService;
