import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Switch } from "@mui/material";

import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

import { customTableHeader } from "layouts/common/styles";
import ConfirmSnackBarContent from "layouts/common/components/ConfirmSnackbarContent";

import { FaEdit, FaEye, FaTrash } from "react-icons/fa";

import { dateToDateAndTime } from "utils/converter";

import { UserRole } from "layouts/common/constants";
import ToastrService from "services/ToastrService";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontSize: "13px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "13px",
    fontWeight: "bold",
  },
}));

function TeamsTable({ deleteHandler, activeHandler, data }) {
  const adminProfile = useSelector((state) => state.users.profile);
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);

  const [deleteIdx, setDeleteIdx] = useState("");

  const [items, setItems] = useState([]);

  useEffect(() => {
    const tempData = data.map((d) => {
      const temp = { ...d };
      const createdAtDateTime = dateToDateAndTime(new Date(d.createdAt));
      temp.createdAt = `${createdAtDateTime.date}  ${createdAtDateTime.time}`;
      return temp;
    });

    setItems(tempData);
  }, [data]);

  const handleView = (id) => {
    navigate(`/dashboards/teams/view/${id}`);
  };

  const handleEdit = (id) => {
    navigate(`/dashboards/teams/edit/${id}`);
  };

  const handleDelete = (id) => {
    if (adminProfile.role === UserRole.TeamAdmin) {
      ToastrService.warning("Team Admin cannot delete team data");
      return;
    }
    setShow(true);
    setDeleteIdx(id);
  };

  const handleActivate = (id, active) => {
    activeHandler(id, active);
  };

  const confirmDelete = () => {
    if (deleteIdx !== "") {
      setShow(false);
      deleteHandler(deleteIdx);
      setDeleteIdx("");
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table" variant="">
        <TableHead sx={(theme) => customTableHeader(theme)}>
          <TableRow>
            <StyledTableCell align="left">Team</StyledTableCell>
            <StyledTableCell align="left">Owner</StyledTableCell>
            <StyledTableCell align="left">Phone</StyledTableCell>
            <StyledTableCell align="left">Registration Date</StyledTableCell>
            <StyledTableCell align="center">Activate/Inactive</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow key={row.id}>
              <StyledTableCell
                align="left"
                component="th"
                scope="row"
                sx={{ color: "#0076B2", cursor: "pointer" }}
                onClick={() => handleView(row.id)}
              >
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="left">
                {row.adminFirstName}&nbsp;{row.adminLastName}
              </StyledTableCell>
              <StyledTableCell align="left">{row.adminPhoneNumber}</StyledTableCell>
              <StyledTableCell align="left">{row.createdAt}</StyledTableCell>
              <StyledTableCell align="center">
                <Switch
                  size="small"
                  checked={row.isActivated}
                  onChange={() => {
                    handleActivate(row.id, !row.isActivated);
                  }}
                  inputProps={{ "aria-label": "ant design" }}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={() => handleView(row.id)}
                >
                  <FaEye />
                </MDButton>
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={() => handleEdit(row.id)}
                >
                  <FaEdit />
                </MDButton>
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={() => handleDelete(row.id)}
                >
                  <FaTrash />
                </MDButton>
              </StyledTableCell>
            </TableRow>
          ))}
          {items.length === 0 && (
            <TableRow>
              <StyledTableCell colSpan={6} align="center" color="dark">
                There is no content.
              </StyledTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <MDSnackbar
        color="error"
        icon="notifications"
        title="Delete Team"
        dateTime=""
        content={
          <ConfirmSnackBarContent
            title="Are you sure to delete this data?"
            confirmHandler={confirmDelete}
          />
        }
        open={show}
        close={toggleSnackbar}
      />
    </TableContainer>
  );
}

TeamsTable.defaultProps = {
  deleteHandler: {},
  activeHandler: {},
  data: [],
};

TeamsTable.propTypes = {
  deleteHandler: PropTypes.func,
  activeHandler: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.any),
};

export default TeamsTable;
