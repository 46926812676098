import { useState, useEffect, useCallback } from "react";

import PropTypes from "prop-types";

import { Checkbox, Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";
import { dateToDateAndTime } from "utils/converter";

function NotificationSummary({ item, prevHandler, saveHandler }) {
  const [notification, setNotification] = useState({});

  useEffect(() => {
    const temp = { ...item };
    const scheduleDateTime = dateToDateAndTime(new Date(temp.scheduleDate));
    temp.scheduleDate = `${scheduleDateTime.date}  ${scheduleDateTime.time}`;
    setNotification(temp);
  }, [item]);

  const handlePrev = () => {
    prevHandler();
  };

  let debounceTimeout;

  const handleSave = useCallback(
    () => () => {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => {
        saveHandler();
      }, 1000);
    },
    [saveHandler],
  );

  return (
    <MDBox my={3}>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "150px" }}>
          Event Review
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handlePrev()}>
            Back
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <Divider />
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <Grid container spacing={2} mb={4} alignItems="center">
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Team</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{notification?.team?.name}</MDTypography>
          </Grid>
        </Grid>

        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Notification Title</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{notification?.title}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Notification Mesage</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{notification?.message}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2.5}>
            <MDTypography fontSize="15px">Link to page?</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox checked={notification?.enableLinkToPage || false} />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Page to link to</MDTypography>
          </Grid>
          <Grid item xs={12} sm={4} md={4.5}>
            {notification?.enableLinkToPage && (
              <MDTypography fontSize="15px">{notification?.linkPage?.label}</MDTypography>
            )}
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Button for link</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{notification?.buttonLabel}</MDTypography>
          </Grid>
        </Grid>
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Schedule
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Send Now?</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox checked={!notification?.isSchedule || false} />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Schedule for later?</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox checked={notification?.isSchedule || false} />
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <MDTypography fontSize="15px">Send On</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px">{notification?.scheduleDate}</MDTypography>
          </Grid>
        </Grid>
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Select Recipients
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Send to All Users</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox checked={notification?.sendAll || false} />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Send to specific users</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox checked={!notification?.sendAll || false} />
          </Grid>
        </Grid>
        {!notification?.sendAll && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Criteria</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4} md={4.5}>
              <MDTypography fontSize="15px">{notification?.criteria?.label}</MDTypography>
            </Grid>
          </Grid>
        )}
        {notification?.criteria?.value === 0 && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Select a challenge</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4} md={4.5}>
              <MDTypography fontSize="15px">{notification?.criteriaValue?.title}</MDTypography>
            </Grid>
          </Grid>
        )}
        {notification?.criteria?.value === 1 && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Select a poll</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4} md={4.5}>
              <MDTypography fontSize="15px">{notification?.criteriaValue?.title}</MDTypography>
            </Grid>
          </Grid>
        )}
        {notification?.criteria?.value === 2 && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Time since last activity</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4} md={4.5}>
              <MDTypography fontSize="15px">{notification?.criteriaValue}</MDTypography>
            </Grid>
          </Grid>
        )}
        {notification?.criteria?.value === 3 && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Favorite fan players</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4} md={4.5}>
              <MDTypography fontSize="15px">{notification?.criteriaValue}</MDTypography>
            </Grid>
          </Grid>
        )}
        {notification?.criteria?.value === 4 && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Users minimum token balance</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4} md={4.5}>
              <MDTypography fontSize="15px">{notification?.criteriaValue}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography fontSize="15px">Tokens</MDTypography>
            </Grid>
          </Grid>
        )}
        {notification?.criteria?.value === 5 && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Select a game</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4} md={4.5}>
              <MDTypography fontSize="15px">{notification?.criteriaValue?.title}</MDTypography>
            </Grid>
          </Grid>
        )}
      </MDBox>
      <Grid container justifyContent="flex-end" gap={2}>
        <MDButton color="secondary" size="large" onClick={handleSave()}>
          Save
        </MDButton>
      </Grid>
    </MDBox>
  );
}

NotificationSummary.defaultProps = {
  item: null,
  prevHandler: {},
  saveHandler: {},
};

NotificationSummary.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  prevHandler: PropTypes.func,
  saveHandler: PropTypes.func,
};

export default NotificationSummary;
