import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MDBox from "components/MDBox";

import ToastrService from "services/ToastrService";
import GameService from "services/GamesService";

import { setLoading } from "store/appSlice";
import { createGame, updateGame } from "store/slices/gameSlice";

import { GameTypes, UserRole } from "layouts/common/constants";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import MiniGameDetailForm from "./forms/MiniGameDetailForm";
import MiniGameThemeForm from "./forms/MiniGameThemeForm";
import MiniGameRewardForm from "./forms/MiniGameRewardForm";
import MiniGameSummaryForm from "./forms/MiniGameSummaryForm";

const formSwitcher = (screen, prevHandler, nextHandler, handleSave, handleChange, newMiniGame) => {
  switch (screen) {
    case 1:
      return (
        <MiniGameDetailForm
          item={newMiniGame}
          nextHandler={nextHandler}
          changeHandler={handleChange}
        />
      );
    case 2:
      return (
        <MiniGameThemeForm
          item={newMiniGame}
          prevHandler={prevHandler}
          nextHandler={nextHandler}
          changeHandler={handleChange}
        />
      );
    case 3:
      return (
        <MiniGameRewardForm
          item={newMiniGame}
          prevHandler={prevHandler}
          nextHandler={nextHandler}
          changeHandler={handleChange}
        />
      );
    case 4:
      return (
        <MiniGameSummaryForm
          item={newMiniGame}
          prevHandler={prevHandler}
          saveHandler={handleSave}
        />
      );
    default:
      return (
        <MiniGameDetailForm
          item={newMiniGame}
          nextHandler={nextHandler}
          changeHandler={handleChange}
        />
      );
  }
};

function AddMiniGame() {
  const { id } = useParams();

  const adminProfile = useSelector((state) => state.users.profile);

  const [screen, setScreen] = useState(1);
  const [newMiniGame, setNewMiniGame] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getGame = (_id) => {
    dispatch(setLoading(true));
    GameService.get(_id, GameTypes.MiniGame)
      .then((response) => {
        const temp = response.data;
        setNewMiniGame(temp);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load mini game data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      getGame(id);
    } else {
      setNewMiniGame(null);
    }
  }, [id]);

  const handleChange = (data) => {
    setNewMiniGame(data);
    return true;
  };

  const handleSave = async (isDraft = false) => {
    dispatch(setLoading(true));
    const newData = { ...newMiniGame };
    const dataId = newData.id;

    if (adminProfile.role === UserRole.TeamAdmin) {
      newData.teamId = adminProfile.teamId;
    } else {
      newData.teamId = newData.team.id;
    }

    if (newData.sponsor) {
      newData.sponsorId = newData.sponsor.id;
    }
    if (newData.match) {
      newData.matchId = newData.match.id;
    } else {
      newData.matchId = "";
    }
    newData.type = parseFloat(newData.type);
    if (newData.asset) {
      newData.assetId = newData.asset.id;
    }

    // Uploading all images before creating mini game.
    let isSuccessUploading = true;

    // logo file
    if (newData.logoFile) {
      const formData = new FormData();
      formData.append("file", newData.logoFile);
      await GameService.upload(formData)
        .then((res) => {
          newData.logo = res.data.url;
        })
        .catch((e) => {
          ToastrService.error(
            e.response?.data?.message?.message || "Failed to upload mini game logo",
          );
          isSuccessUploading = false;
        });
    }

    // background file
    if (newData.backgroundFile) {
      const formData = new FormData();
      formData.append("file", newData.backgroundFile);
      await GameService.upload(formData)
        .then((res) => {
          newData.background = res.data.url;
        })
        .catch((e) => {
          ToastrService.error(
            e.response?.data?.message?.message || "Failed to upload background image",
          );
          isSuccessUploading = false;
        });
    }

    // sponsor logo file
    if (newData.sponsorLogoFile) {
      const formData = new FormData();
      formData.append("file", newData.sponsorLogoFile);
      await GameService.upload(formData)
        .then((res) => {
          newData.sponsorLogo = res.data.url;
        })
        .catch((e) => {
          ToastrService.error(
            e.response?.data?.message?.message || "Failed to upload sponsor logo",
          );
          isSuccessUploading = false;
        });
    }

    // booster logo file
    if (newData.boosterLogoFile) {
      const formData = new FormData();
      formData.append("file", newData.boosterLogoFile);
      await GameService.upload(formData)
        .then((res) => {
          newData.boosterLogo = res.data.url;
        })
        .catch((e) => {
          ToastrService.error(
            e.response?.data?.message?.message || "Failed to upload booster logo",
          );
          isSuccessUploading = false;
        });
    }

    if (!isSuccessUploading) {
      dispatch(setLoading(false));
      return;
    }

    newData.isDraft = isDraft;

    [
      "id",
      "team",
      "match",
      "sponsor",
      "asset",
      "logoFile",
      "backgroundFile",
      "sponsorLogoFile",
      "boosterLogoFile",
    ].forEach((key) => {
      delete newData[key];
    });

    if (!dataId) {
      dispatch(createGame({ type: GameTypes.MiniGame, data: newData }))
        .unwrap()
        .then((data) => {
          ToastrService.success("Successfully added new Mini Game");
          dispatch(setLoading(false));
          navigate(`/game/view-minigame/${data.id}`);
        })
        .catch(() => {
          dispatch(setLoading(false));
        });
    } else {
      // remove keys
      ["createdAt", "updatedAt"].forEach((key) => {
        delete newData[key];
      });

      dispatch(updateGame({ type: GameTypes.MiniGame, id: dataId, data: newData }))
        .unwrap()
        .then((data) => {
          if (data.success) {
            ToastrService.success("Successfully updated Mini Game");
            dispatch(setLoading(false));
            navigate(`/game/view-minigame/${dataId}`);
          } else {
            ToastrService.error(data.message);
            dispatch(setLoading(false));
          }
        })
        .catch((e) => {
          ToastrService.error(e.response?.data?.message?.message || "Failed to update Mini Game");
          dispatch(setLoading(false));
        });
    }
  };

  const nextScreen = () => {
    setScreen(screen + 1);
  };

  const prevScreen = () => {
    setScreen(screen - 1);
  };

  return (
    <DashboardLayout>
      <MDBox my={3}>
        {formSwitcher(screen, prevScreen, nextScreen, handleSave, handleChange, newMiniGame)}
      </MDBox>
    </DashboardLayout>
  );
}

export default AddMiniGame;
