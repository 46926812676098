import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import Table from "@mui/material/Table";
import { FormControl, InputLabel, MenuItem, Select, TableHead } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import FormField from "components/FormField";

import { dateToDateAndTime } from "utils/converter";
import { customTableHeader, flexContainer, StyledTableCell } from "layouts/common/styles";
import { GameTypes } from "layouts/common/constants";

import GameService from "services/GamesService";
import ToastrService from "services/ToastrService";

import { setLoading } from "store/appSlice";

function QuickVerifyPredictionTable({ selectedTeams }) {
  const dispatch = useDispatch();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [items, setItems] = useState([]);

  const fetchData = useCallback(() => {
    GameService.retrivePrediction(page, rowsPerPage, false, selectedTeams).then(({ data }) => {
      const tempData = data.data.map((d) => {
        const temp = { ...d };
        const endDateTime = dateToDateAndTime(new Date(d.end));
        temp.endedAt = `${endDateTime.date}  ${endDateTime.time}`;
        temp.homeScore = 0;
        temp.awayScore = 0;
        return temp;
      });

      setItems(tempData);
      setTotalCount(data.count);
    });
  }, [page, rowsPerPage, selectedTeams]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleRemove = (idx) => {
    const temp = [...items];
    temp.splice(idx, 1);
    setItems(temp);
    setTotalCount((prevVal) => prevVal - 1);
  };

  const handleChangeScore = (idx, name, value) => {
    const temp = [...items];
    items[idx][name] = parseFloat(value);
    setItems(temp);
  };

  const handleFinish = async (id) => {
    dispatch(setLoading(true));
    GameService.finish(GameTypes.Prediction, id)
      .then((response) => {
        const temp = { ...response.data };
        if (temp.success) {
          ToastrService.success("Successfully finished prediction game.");
          dispatch(setLoading(false));
        } else {
          ToastrService.error(temp.message);
          dispatch(setLoading(false));
        }
      })
      .catch((e) => {
        ToastrService.error(
          e.response?.data?.message?.message || "Failed to finish prediction game.",
        );
        dispatch(setLoading(false));
      });
  };

  const handleSubmit = (idx) => () => {
    const temp = items[idx];
    dispatch(setLoading(true));
    GameService.updateResult(GameTypes.Prediction, temp.id, {
      resultMain: temp.homeScore,
      resultOpposition: temp.awayScore,
    })
      .then(({ data }) => {
        if (data.success) {
          ToastrService.success("Successfully saved the final score");
          dispatch(setLoading(false));
          handleFinish(temp.id);
          handleRemove(idx);
        } else {
          ToastrService.error(data.message);
        }
      })
      .catch((e) => {
        ToastrService.error(
          e.response?.data?.message?.message || "Failed to save new final score.",
        );
        dispatch(setLoading(false));
      });
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };

  const changePageNum = (direct) => () => {
    const maxPageNum = Math.floor((totalCount - 1) / rowsPerPage);
    if (page === 0 && direct === -1) return;
    if (page === maxPageNum && direct === 1) return;

    setPage((prevState) => prevState + direct);
  };

  return (
    <MDBox>
      <MDTypography fontSize="16px" fontWeight="bold">
        Predictor games(To verify)
      </MDTypography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table" variant="">
          <TableHead sx={(theme) => customTableHeader(theme)}>
            <TableRow>
              <StyledTableCell align="left">Game</StyledTableCell>
              <StyledTableCell align="left">Team</StyledTableCell>
              <StyledTableCell align="left">Match/Evemt</StyledTableCell>
              <StyledTableCell align="left">Date of Expiry</StyledTableCell>
              <StyledTableCell align="center">Home Score</StyledTableCell>
              <StyledTableCell align="center">Away Score</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row, idx) => (
              <TableRow key={row.id}>
                <StyledTableCell
                  align="left"
                  component="th"
                  scope="row"
                  sx={{ color: "#0076B2", cursor: "pointer" }}
                >
                  {row.title}
                </StyledTableCell>
                <StyledTableCell align="left">{row.team?.name}</StyledTableCell>
                <StyledTableCell align="left">{row.match?.title}</StyledTableCell>
                <StyledTableCell align="left">{row.endedAt}</StyledTableCell>
                <StyledTableCell align="center">
                  <FormField
                    type="number"
                    sx={{ width: "70px" }}
                    value={items[idx].homeScore}
                    onChange={(e) => handleChangeScore(idx, "homeScore", e.target.value)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormField
                    type="number"
                    sx={{ width: "70px" }}
                    value={items[idx].awayScore}
                    onChange={(e) => handleChangeScore(idx, "awayScore", e.target.value)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <MDButton
                    color="secondary"
                    sx={{ minWidth: "10px", width: "60px", padding: "0px" }}
                    onClick={handleSubmit(idx)}
                  >
                    Verify
                  </MDButton>
                </StyledTableCell>
              </TableRow>
            ))}
            {items.length === 0 && (
              <TableRow>
                <StyledTableCell colSpan={6} align="center" color="dark">
                  There is no content.
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end" my={3}>
        <FormControl>
          <InputLabel id="rows_per_page">Rows per page</InputLabel>
          <Select
            labelId="rows_per_page"
            id="rows_per_page_select"
            value={rowsPerPage}
            label="Rows per page"
            onChange={handleChangeRowsPerPage}
            sx={{ height: "38px", width: "150px", minWidth: "50px" }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </FormControl>
        <MDTypography fontSize="12px" fontWeight="bold">
          {totalCount > 0 ? page * rowsPerPage + 1 : "0"}
          &nbsp;~&nbsp;
          {totalCount > page * rowsPerPage + rowsPerPage
            ? page * rowsPerPage + rowsPerPage
            : totalCount}
          &nbsp;of&nbsp;
          {totalCount}
        </MDTypography>

        <MDButton icon="text" onClick={changePageNum(-1)}>
          <FaArrowLeft />
        </MDButton>
        <MDButton icon="text" onClick={changePageNum(1)}>
          <FaArrowRight />
        </MDButton>
      </MDBox>
    </MDBox>
  );
}

QuickVerifyPredictionTable.defaultProps = {
  selectedTeams: "",
};

// Typechecking props for the DashboardNavbar
QuickVerifyPredictionTable.propTypes = {
  selectedTeams: PropTypes.string,
};

export default QuickVerifyPredictionTable;
