import { ChallengeTypes, ChallengeTabs } from "layouts/common/constants";
import http from "../apis/http-common";

const retrive = (filter, search, page, rowsPerPage, sortFilter = "", sortDirection = "") => {
  const skip = page * rowsPerPage;
  const sortParams =
    sortFilter !== "" && sortDirection !== ""
      ? `&sort=${sortFilter}&direction=${sortDirection}`
      : "";
  if (filter === ChallengeTabs.Draft) {
    return http.get(
      `/challenge/list?skip=${skip}&take=${rowsPerPage}&search=${search}&isDraft=true${sortParams}`,
    );
  }
  return http.get(
    `/challenge/list?skip=${skip}&take=${rowsPerPage}&search=${search}&filter=${filter}&isDraft=false${sortParams}`,
  );
};

const get = (id, type) => {
  if (type === ChallengeTypes.CheckIn) {
    return http.get(`/challenge/check-in/${id}`);
  }
  if (
    type === ChallengeTypes.Survey ||
    type === ChallengeTypes.SurveyFreeText ||
    type === ChallengeTypes.SurveyMultichoice
  ) {
    return http.get(`/challenge/survey/${id}`);
  }
  return null;
};

const create = (type, data) => {
  if (type === ChallengeTypes.CheckIn) {
    return http.post("/challenge/check-in", data);
  }
  if (
    type === ChallengeTypes.SurveyMultichoice ||
    type === ChallengeTypes.SurveyFreeText ||
    type === ChallengeTypes.Survey
  ) {
    return http.post("/challenge/survey", data);
  }
  return null;
};

const update = (type, id, data) => {
  if (type === ChallengeTypes.CheckIn) {
    return http.put(`/challenge/check-in/${id}`, data);
  }
  if (type === ChallengeTypes.SurveyMultichoice || type === ChallengeTypes.SurveyFreeText) {
    return http.put(`/challenge/survey/${id}`, data);
  }
  return null;
};

const remove = (type, id) => {
  if (type === ChallengeTypes.CheckIn) {
    return http.delete(`/challenge/check-in/${id}`);
  }
  if (type === ChallengeTypes.Survey || type === ChallengeTypes.SurveyFreeText) {
    return http.delete(`/challenge/survey/${id}`);
  }
  return null;
};

const getResult = (type, id) => {
  if (type === ChallengeTypes.CheckIn) {
    return http.get(`/challenge/check-in/result/${id}`);
  }
  if (
    type === ChallengeTypes.Survey ||
    type === ChallengeTypes.SurveyFreeText ||
    type === ChallengeTypes.SurveyMultichoice
  ) {
    return http.get(`/challenge/survey/result/${id}`);
  }
  return null;
};

const duplicate = (type, data) => {
  if (type === ChallengeTypes.CheckIn) {
    return http.post("/challenge/check-in/duplicate", data);
  }

  return null;
};

const ChallengeService = {
  retrive,
  get,
  create,
  update,
  remove,
  getResult,
  duplicate,
};

export default ChallengeService;
