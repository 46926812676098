import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Checkbox, Divider, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";
import { ChallengeTypes } from "layouts/common/constants";

import { setLoading } from "store/appSlice";

import ToastrService from "services/ToastrService";
import ChallengeService from "services/ChallengeService";

import { dateToDateAndTime } from "utils/converter";

function SurveyView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [item, setItem] = useState(null);

  const getChallenge = (_id) => {
    dispatch(setLoading(true));
    ChallengeService.get(_id, ChallengeTypes.Survey)
      .then((response) => {
        const temp = { ...response.data };

        const startDateTime = dateToDateAndTime(new Date(temp.start));
        const endDateTime = dateToDateAndTime(new Date(temp.end));

        temp.start = `${startDateTime.date}  ${startDateTime.time}`;
        temp.end = `${endDateTime.date}  ${endDateTime.time}`;
        setItem(temp);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load challenge data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) getChallenge(id);
  }, [id]);

  const handleViewResult = () => {
    navigate(`/challenge/result/survey/${id}`);
  };

  const handleEdit = () => {
    navigate(`/challenge/edit/${ChallengeTypes.Survey}/${id}`);
  };

  const handleBack = () => {
    navigate(`/challenges`);
  };

  return (
    <DashboardLayout>
      <MDBox my={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "300px" }}>
            {item?.isOptional ? "Survey - Result" : "Survey (Free Text) - Result"}
          </MDTypography>
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDButton size="small" onClick={() => handleBack()}>
              Back
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox sx={(theme) => customFormContainer(theme)}>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Details
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.team?.name}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Challenge Type
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">
                {item?.isOptional ? "Survey (Multi choice)" : "Survey (Free text)"}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Challenge Title
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9} mb={3}>
              <MDTypography fontSize="15px">{item?.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Challenge Description
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.description}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Match / Event
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.match?.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} my={6}>
              {item?.surveyQuestions.map((surveyQuestion, idx) => (
                <MDBox key={`surveyQuestion-${idx.toString()}`} mb={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} md={2.5}>
                      <MDTypography fontSize="15px" fontWeight="bold">
                        Question {idx + 1}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <MDTypography fontSize="15px">{surveyQuestion.question}</MDTypography>
                    </Grid>
                    {item?.isOptional && (
                      <Grid item xs={12} sm={12}>
                        <MDBox>
                          <Grid container spacing={2}>
                            {surveyQuestion.options.map((option, optionIdx) => (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                key={`survey-option-${optionIdx.toString()}`}
                              >
                                <MDBox mb={2}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={5}>
                                      <MDTypography fontSize="15px" fontWeight="bold">
                                        Option {optionIdx + 1}
                                      </MDTypography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} display="flex" alignItems="center">
                                      <MDTypography fontSize="15px">
                                        {option.optionText}
                                      </MDTypography>
                                    </Grid>
                                  </Grid>
                                </MDBox>
                              </Grid>
                            ))}
                          </Grid>
                        </MDBox>
                      </Grid>
                    )}
                  </Grid>
                </MDBox>
              ))}
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Start Date & Time
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <MDTypography fontSize="15px">{item?.start}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Expiration Date & Time
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <MDTypography fontSize="15px">{item?.end}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Participants
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9} md={8.5}>
              <MDTypography fontSize="15px">
                {item?.participants === 0 ? "All" : "User"}
              </MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Eligibility
          </MDTypography>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Kudos points
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{item?.eligbleKudos}</MDTypography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Tokens
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <MDTypography fontSize="15px">{item?.eligbleToken}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Rewards
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    No of Kudos Points
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography fontSize="15px">{item?.kudosReward}</MDTypography>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    No of Tokens
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography fontSize="15px">{item?.tokenReward}</MDTypography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Send asset as reward?
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <Checkbox disabled checked={item?.enableAssetReward || false} />
            </Grid>
            {item?.enableAssetReward && (
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  select asset
                </MDTypography>
              </Grid>
            )}
            {item?.enableAssetReward && (
              <Grid item xs={12} sm={4} md={5}>
                <MDTypography fontSize="15px">{item?.asset?.title}</MDTypography>
              </Grid>
            )}
          </Grid>
          {item?.enableAssetReward && (
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Number of top ranked scores to send to
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={2} md={1.5}>
                <MDTypography fontSize="15px">{item?.winnerLimit}</MDTypography>
              </Grid>
            </Grid>
          )}
          {item?.enableAssetReward && (
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={3}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  No of Bonus Asset to send per winner
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={2} md={1.5}>
                <MDTypography fontSize="15px">{item?.rewardAssetCount}</MDTypography>
              </Grid>
            </Grid>
          )}
          <Divider />
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Sponsors
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Game Sponsor
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">{item?.sponsor?.title}</MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container justifyContent="flex-end" gap={2}>
          <Grid item>
            <MDButton color="secondary" size="large" onClick={() => handleViewResult()}>
              View Results
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton color="secondary" size="large" onClick={() => handleEdit()}>
              Edit
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default SurveyView;
