/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts

// Sidebar icons
import { SvgIcon } from "@mui/material";
import { ReactComponent as DashboardIcon } from "assets/images/icons/sidebar/dashboard.svg";
import { ReactComponent as UserIcon } from "assets/images/icons/sidebar/user.svg";
import { ReactComponent as GameIcon } from "assets/images/icons/sidebar/game.svg";
import { ReactComponent as ChallengeIcon } from "assets/images/icons/sidebar/challenge.svg";
import { ReactComponent as PollIcon } from "assets/images/icons/sidebar/poll.svg";
import { ReactComponent as AssetIcon } from "assets/images/icons/sidebar/asset.svg";
import { ReactComponent as SponsorIcon } from "assets/images/icons/sidebar/sponsor.svg";
import { ReactComponent as ContentIcon } from "assets/images/icons/sidebar/content.svg";
import { ReactComponent as MatchIcon } from "assets/images/icons/sidebar/match.svg";

// Components
import Teams from "layouts/dashboards/teams";
import Tokenmanagement from "layouts/dashboards/token-management";
import Overview from "layouts/dashboards/overview";
import Analytics from "layouts/dashboards/analytics";
import Games from "layouts/games";
import Polls from "layouts/polls";
import Assets from "layouts/assets";
import Events from "layouts/events";
import Challenges from "layouts/challenges";
import Users from "layouts/users";
import ViewUser from "layouts/users/components/ViewUser";
import Sponsors from "layouts/sponsors";
import AddAsset from "layouts/assets/components/AddAsset";
import ViewAsset from "layouts/assets/components/ViewAsset";
import InventoryAsset from "layouts/assets/components/InventoryAsset";
import AddBonusAsset from "layouts/assets/components/bonus/AddBonusAsset";
import ViewBonusAsset from "layouts/assets/components/bonus/ViewBonusAsset";
import SendBonusAsset from "layouts/assets/components/bonus/SendBonusAsset";
import InventoryBonusAsset from "layouts/assets/components/bonus/InventoryBonusAsset";
import AddEvent from "layouts/events/components/AddEvent";
import ViewEvent from "layouts/events/components/ViewEvent";
import AddTeam from "layouts/dashboards/teams/components/AddTeam";
import ViewTeam from "layouts/dashboards/teams/components/ViewTeam";
import AddChallenge from "layouts/challenges/components/AddChallenge";
import CheckInView from "layouts/challenges/components/ViewChallenge/CheckInView";
import CheckInResult from "layouts/challenges/components/ResultChallenge/CheckInResult";
import SurveyView from "layouts/challenges/components/ViewChallenge/SurveryView";
import SurveyResult from "layouts/challenges/components/ResultChallenge/SurveyResult";
import AddTrivia from "layouts/games/components/AddTrivia";
import ViewTrivia from "layouts/games/components/ViewTrivia";
import ViewTriviaResult from "layouts/games/components/ViewTriviaResult";
import AddPrediction from "layouts/games/components/AddPrediction";
import ViewPrediction from "layouts/games/components/ViewPrediction";
import ViewPredictionResult from "layouts/games/components/ViewPredictionResult";
import AddMilestone from "layouts/games/components/AddMilestone";
import ViewMilestone from "layouts/games/components/ViewMilestone";
import ViewMilestoneResult from "layouts/games/components/ViewMilestoneResult";
import AddSponsor from "layouts/sponsors/components/AddSponsor";
import ViewSponsor from "layouts/sponsors/components/ViewSponsor";
import ViewActivation from "layouts/sponsors/components/ViewActivation";
import AddPoll from "layouts/polls/components/AddPoll";
import PollImageView from "layouts/polls/components/ViewPoll/PollImageView";
import PollImageResult from "layouts/polls/components/ResultPoll/PollImageResult";
import ViewMiniGame from "layouts/games/components/ViewMiniGame";
import ViewMiniGameResult from "layouts/games/components/ViewMiniGameResult";
import AddMiniGame from "layouts/games/components/AddMiniGame";
import Notification from "layouts/notifications";
import Help from 'layouts/help'
import ViewNotification from "layouts/notifications/components/ViewNotification";
import AddNotification from "layouts/notifications/components/AddNotification";

const routes = [
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: (
      <SvgIcon>
        <DashboardIcon />
      </SvgIcon>
    ),
    collapse: [
      {
        name: "Overview",
        key: "overview",
        route: "/dashboards/overview",
        component: <Overview />,
        requireAuth: true,
      },
      {
        name: "Token Management",
        key: "token-management",
        route: "/dashboards/token-management",
        component: <Tokenmanagement />,
        requireAuth: true,
      },
      {
        name: "Teams",
        key: "teams",
        route: "/dashboards/teams",
        component: <Teams />,
        requireAuth: true,
      },
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />,
        requireAuth: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    route: "/users",
    icon: (
      <SvgIcon>
        <UserIcon />
      </SvgIcon>
    ),
    component: <Users />,
    noCollapse: true,
    requireAuth: true,
  },
  {
    key: "users/view",
    route: "/user/view/:teamId/:email",
    component: <ViewUser />,
    requireAuth: true,
  },
  {
    type: "collapse",
    name: "Games",
    key: "games",
    route: "/games",
    icon: (
      <SvgIcon>
        <GameIcon />
      </SvgIcon>
    ),
    component: <Games />,
    noCollapse: true,
    requireAuth: true,
  },
  {
    type: "collapse",
    name: "Challenges",
    key: "challenges",
    route: "/challenges",
    icon: (
      <SvgIcon>
        <ChallengeIcon />
      </SvgIcon>
    ),
    component: <Challenges />,
    noCollapse: true,
    requireAuth: true,
  },
  {
    type: "collapse",
    name: "Polls",
    key: "polls",
    route: "/polls",
    icon: (
      <SvgIcon>
        <PollIcon />
      </SvgIcon>
    ),
    component: <Polls />,
    noCollapse: true,
    requireAuth: true,
  },
  {
    type: "collapse",
    name: "Assets",
    key: "assets",
    route: "/assets",
    icon: (
      <SvgIcon>
        <AssetIcon />
      </SvgIcon>
    ),
    component: <Assets />,
    noCollapse: true,
    requireAuth: true,
  },
  {
    key: "assets/add",
    route: "/assets/add",
    component: <AddAsset />,
    requireAuth: true,
  },
  {
    key: "assets/view",
    route: "/assets/view/:id",
    component: <ViewAsset />,
    requireAuth: true,
  },
  {
    key: "assets/inventory",
    route: "/assets/inventory/:id",
    component: <InventoryAsset />,
    requireAuth: true,
  },
  {
    key: "assets/edit",
    route: "/assets/edit/:id",
    component: <AddAsset />,
    requireAuth: true,
  },
  {
    key: "assets/add-bonus",
    route: "/assets/add-bonus",
    component: <AddBonusAsset />,
    requireAuth: true,
  },
  {
    key: "assets/view-bonus",
    route: "/assets/view-bonus/:id",
    component: <ViewBonusAsset />,
    requireAuth: true,
  },
  {
    key: "assets/inventory-bonus",
    route: "/assets/inventory-bonus/:id",
    component: <InventoryBonusAsset />,
    requireAuth: true,
  },
  {
    key: "assets/send-bonus",
    route: "/assets/send-bonus/:id",
    component: <SendBonusAsset />,
    requireAuth: true,
  },
  {
    key: "assets/edit-bonus",
    route: "/assets/edit-bonus/:id",
    component: <AddBonusAsset />,
    requireAuth: true,
  },
  {
    type: "collapse",
    name: "Contents",
    key: "contents",
    icon: (
      <SvgIcon>
        <ContentIcon />
      </SvgIcon>
    ),
  },
  {
    type: "collapse",
    name: "Matches/Events",
    key: "matches",
    route: "/matches",
    icon: (
      <SvgIcon>
        <MatchIcon />
      </SvgIcon>
    ),
    component: <Events />,
    noCollapse: true,
    requireAuth: true,
  },
  {
    type: "collapse",
    name: "Sponsors",
    key: "sponsors",
    route: "/sponsors",
    icon: (
      <SvgIcon>
        <SponsorIcon />
      </SvgIcon>
    ),
    component: <Sponsors />,
    noCollapse: true,
    requireAuth: true,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    route: "/notifications",
    component: <Notification />,
    noCollapse: true,
    requireAuth: true,
  },
  {
    type: "collapse",
    name: "Help & Support",
    key: "help",
    route: "/help",
    component: <Help />,
    noCollapse: true,
    requireAuth: true,
  },
  {
    key: "teams/add",
    route: "/dashboards/teams/add",
    component: <AddTeam />,
    requireAuth: true,
  },
  {
    key: "teams/view",
    route: "/dashboards/teams/view/:id",
    component: <ViewTeam />,
    requireAuth: true,
  },
  {
    key: "teams/edit",
    route: "/dashboards/teams/edit/:id",
    component: <AddTeam />,
    requireAuth: true,
  },
  {
    key: "match/add",
    route: "/match/add",
    component: <AddEvent />,
    requireAuth: true,
  },
  {
    key: "match/view",
    route: "/match/view/:id",
    component: <ViewEvent />,
    requireAuth: true,
  },
  {
    key: "match/edit",
    route: "/match/edit/:id",
    component: <AddEvent />,
    requireAuth: true,
  },
  {
    key: "challenge/add",
    route: "/challenge/add",
    component: <AddChallenge />,
    requireAuth: true,
  },
  {
    key: "challenge/edit",
    route: "/challenge/edit/:type/:id",
    component: <AddChallenge />,
    requireAuth: true,
  },
  {
    key: "challenge/view/check-in",
    route: "/challenge/view/check-in/:id",
    component: <CheckInView />,
    requireAuth: true,
  },
  {
    key: "challenge/result/check-in",
    route: "/challenge/result/check-in/:id",
    component: <CheckInResult />,
    requireAuth: true,
  },
  {
    key: "challenge/view/survey",
    route: "/challenge/view/survey/:id",
    component: <SurveyView />,
    requireAuth: true,
  },
  {
    key: "challenge/result/survey",
    route: "/challenge/result/survey/:id",
    component: <SurveyResult />,
    requireAuth: true,
  },
  {
    key: "trivia/add",
    route: "/game/add-trivia",
    component: <AddTrivia />,
    requireAuth: true,
  },
  {
    key: "trivia/edit",
    route: "/game/edit-trivia/:id",
    component: <AddTrivia />,
    requireAuth: true,
  },
  {
    key: "trivia/view",
    route: "/game/view-trivia/:id",
    component: <ViewTrivia />,
    requireAuth: true,
  },
  {
    key: "trivia/result",
    route: "/game/result-trivia/:id",
    component: <ViewTriviaResult />,
    requireAuth: true,
  },
  {
    key: "prediction/add",
    route: "/game/add-prediction",
    component: <AddPrediction />,
    requireAuth: true,
  },
  {
    key: "game/edit",
    route: "/game/edit-prediction/:id",
    component: <AddPrediction />,
    requireAuth: true,
  },
  {
    key: "prediction/view",
    route: "/game/view-prediction/:id",
    component: <ViewPrediction />,
    requireAuth: true,
  },
  {
    key: "prediction/result",
    route: "/game/result-prediction/:id",
    component: <ViewPredictionResult />,
    requireAuth: true,
  },
  {
    key: "milestone/add",
    route: "/game/add-milestone",
    component: <AddMilestone />,
    requireAuth: true,
  },
  {
    key: "milestone/edit",
    route: "/game/edit-milestone/:id",
    component: <AddMilestone />,
    requireAuth: true,
  },
  {
    key: "milestone/view",
    route: "/game/view-milestone/:id",
    component: <ViewMilestone />,
    requireAuth: true,
  },
  {
    key: "milestone/result",
    route: "/game/result-milestone/:id",
    component: <ViewMilestoneResult />,
    requireAuth: true,
  },
  {
    key: "minigame/add",
    route: "/game/add-minigame",
    component: <AddMiniGame />,
    requireAuth: true,
  },
  {
    key: "minigame/edit",
    route: "/game/edit-minigame/:id",
    component: <AddMiniGame />,
    requireAuth: true,
  },
  {
    key: "minigame/view",
    route: "/game/view-minigame/:id",
    component: <ViewMiniGame />,
    requireAuth: true,
  },
  {
    key: "minigame/result",
    route: "/game/result-minigame/:id",
    component: <ViewMiniGameResult />,
    requireAuth: true,
  },
  {
    key: "sponsor/add",
    route: "/sponsor/add",
    component: <AddSponsor />,
    requireAuth: true,
  },
  {
    key: "sponsor/edit",
    route: "/sponsor/edit/:id",
    component: <AddSponsor />,
    requireAuth: true,
  },
  {
    key: "sponsor/view",
    route: "/sponsor/view/:id",
    component: <ViewSponsor />,
    requireAuth: true,
  },
  {
    key: "polls/add",
    route: "/polls/add",
    component: <AddPoll />,
    requireAuth: true,
  },
  {
    key: "polls/edit",
    route: "/polls/edit/:id",
    component: <AddPoll />,
    requireAuth: true,
  },
  {
    key: "polls/view/image",
    route: "/polls/view/image/:id",
    component: <PollImageView />,
    requireAuth: true,
  },
  {
    key: "polls/result/image",
    route: "/polls/result/image/:id",
    component: <PollImageResult />,
    requireAuth: true,
  },
  {
    key: "sponsor/activation",
    route: "/sponsor/activation/:id",
    component: <ViewActivation />,
    requireAuth: true,
  },
  {
    key: "notification/add",
    route: "/notification/add",
    component: <AddNotification />,
    requireAuth: true,
  },
  {
    key: "notification/view",
    route: "/notification/view/:id",
    component: <ViewNotification />,
    requireAuth: true,
  },
];

export default routes;
