const sponsorOptions = [
  {
    label: "Zambrero",
    value: "Zambrero",
  },
  {
    label: "Nike",
    value: "Nike",
  },
];

export default sponsorOptions;
