import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Divider, Grid, Checkbox } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { setLoading } from "store/appSlice";
import NotificationService from "services/NotificationService";
import ToastrService from "services/ToastrService";
import { dateToDateAndTime } from "utils/converter";
import { CriteriaTypes } from "layouts/common/constants";

function ViewNotification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [item, setItem] = useState(null);
  const [criteriaLabel, setCriteriaLabel] = useState("");

  const getNotification = (_id) => {
    dispatch(setLoading(true));
    NotificationService.get(_id)
      .then((response) => {
        const temp = { ...response.data };
        const scheduleDateTime = dateToDateAndTime(new Date(temp.scheduleDate));
        temp.scheduleDate = `${scheduleDateTime.date}  ${scheduleDateTime.time}`;
        if (!temp.sendAll) {
          const criteriaType = CriteriaTypes.find((d) => d.value === temp.criteria);
          if (criteriaType) {
            setCriteriaLabel(criteriaType.label);
          }
        }

        setItem(temp);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load match data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) getNotification(id);
  }, [id]);

  const handleBack = () => {
    navigate("/notifications");
  };

  return (
    <DashboardLayout>
      <MDBox my={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "150px" }}>
            Notification
          </MDTypography>
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDButton size="small" onClick={() => handleBack()}>
              Back
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox sx={(theme) => customFormContainer(theme)}>
          <Grid container spacing={2} mb={4} alignItems="center">
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">Team</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.team?.name}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Details
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">Notification Title</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">Notification Mesage</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.message}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <MDTypography fontSize="15px">Link to page?</MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <Checkbox checked={(item?.linkPage && item.linkPage !== "") || false} />
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">Page to link to</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4} md={4.5}>
              <MDTypography fontSize="15px">{item?.linkPage}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">Button for link</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{item?.buttonLabel}</MDTypography>
            </Grid>
          </Grid>
          <Divider />
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Schedule
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Send Now?</MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <Checkbox checked={!item?.isSchedule || false} />
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Schedule for later?</MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <Checkbox checked={item?.isSchedule || false} />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <MDTypography fontSize="15px">Send On</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
              <MDTypography fontSize="15px">{item?.scheduleDate}</MDTypography>
            </Grid>
          </Grid>
          <Divider />
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Select Recipients
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Send to All Users</MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <Checkbox checked={item?.sendAll || false} />
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Send to specific users</MDTypography>
            </Grid>
            <Grid item xs={12} sm={2} md={1.5}>
              <Checkbox checked={!item?.sendAll || false} />
            </Grid>
          </Grid>
          {!item?.sendAll && (
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3}>
                <MDTypography fontSize="15px">Criteria</MDTypography>
              </Grid>
              <Grid item xs={12} sm={4} md={4.5}>
                <MDTypography fontSize="15px">{criteriaLabel}</MDTypography>
              </Grid>
            </Grid>
          )}
          {item?.criteria === 0 && (
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3}>
                <MDTypography fontSize="15px">Selected challenge</MDTypography>
              </Grid>
              <Grid item xs={12} sm={4} md={4.5}>
                <MDTypography fontSize="15px">{item?.criteriaValue}</MDTypography>
              </Grid>
            </Grid>
          )}
          {item?.criteria === 1 && (
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3}>
                <MDTypography fontSize="15px">Selected poll</MDTypography>
              </Grid>
              <Grid item xs={12} sm={4} md={4.5}>
                <MDTypography fontSize="15px">{item?.criteriaValue}</MDTypography>
              </Grid>
            </Grid>
          )}
          {item?.criteria === 2 && (
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3}>
                <MDTypography fontSize="15px">Time since last activity</MDTypography>
              </Grid>
              <Grid item xs={12} sm={4} md={4.5}>
                <MDTypography fontSize="15px">{item?.criteriaValue}</MDTypography>
              </Grid>
            </Grid>
          )}
          {item?.criteria === 3 && (
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3}>
                <MDTypography fontSize="15px">Favorite fan players</MDTypography>
              </Grid>
              <Grid item xs={12} sm={4} md={4.5}>
                <MDTypography fontSize="15px">{item?.criteriaValue}</MDTypography>
              </Grid>
            </Grid>
          )}
          {item?.criteria === 4 && (
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3}>
                <MDTypography fontSize="15px">Users minimum token balance</MDTypography>
              </Grid>
              <Grid item xs={12} sm={4} md={4.5}>
                <MDTypography fontSize="15px">{item?.criteriaValue}</MDTypography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <MDTypography fontSize="15px">Tokens</MDTypography>
              </Grid>
            </Grid>
          )}
          {item?.criteria === 5 && (
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3}>
                <MDTypography fontSize="15px">Selected game</MDTypography>
              </Grid>
              <Grid item xs={12} sm={4} md={4.5}>
                <MDTypography fontSize="15px">{item?.criteriaValue}</MDTypography>
              </Grid>
            </Grid>
          )}
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default ViewNotification;
