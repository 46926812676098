const validateEmail = (email) =>
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

const validateCoordinates = (coordinates) => {
  const coordsArray = coordinates.split(",");
  if (coordsArray.length !== 2) return false;

  return (
    !Number.isNaN(coordsArray[0]) &&
    !Number.isNaN(parseFloat(coordsArray[0])) &&
    !Number.isNaN(coordsArray[1]) &&
    !Number.isNaN(parseFloat(coordsArray[1]))
  );
};

const validatePassword = (password) =>
  password.match(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\!\@\#\$\%\^\&\*\(\)\-\=\~\_\+\[\]\\\{\}\|\;\'\:\"\,\.\/\<\>\?])[A-Za-z\d\!\@\#\$\%\^\&\*\(\)\-\=\~\_\+\[\]\\\{\}\|\;\'\:\"\,\.\/\<\>\?]{8,}$/,
  );

const validateFileSize = (num) => num / 1048576 < 1;

module.exports = {
  validateEmail,
  validateCoordinates,
  validateFileSize,
  validatePassword,
};
