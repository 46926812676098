import { deleteLocalStorageValue } from "hooks/useLocalStorage";
import http from "../apis/http-common";

const login = (data) =>
  new Promise((resolve, reject) => {
    http
      .post("/auth/login", data)
      .then((res) => {
        if (res.status === 201) {
          resolve(res.data);
        } else {
          reject(res);
        }
      })
      .catch((err) => reject(err));
  });

const logout = () => {
  deleteLocalStorageValue("admin-token");
  return true;
};

const requestForgotPassword = (email) => {
  const teamId = "fe226b0e-f9b8-11ed-be56-0242ac120002";
  return new Promise((resolve, reject) => {
    http
      .get(`/user/forgot-password-pre-request/${teamId}/${email}`)
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const resetPassword = (code, password) =>
  new Promise((resolve, reject) => {
    http
      .get(`/user/forgot-password-request/${code}/${password}`)
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });

const AuthService = {
  login,
  logout,
  requestForgotPassword,
  resetPassword,
};

export default AuthService;
