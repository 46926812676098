import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import ToastrService from "services/ToastrService";
import TeamsService from "services/TeamService";
import SportsService from "services/SportsService";
import { setLoading } from "store/appSlice";

import TeamOverview from "./TeamOverview";
import ResetPassword from "./ResetPassword";
import ManageAdmins from "./ManageAdmins";
import { ViewMode } from "./types";

function ViewTeam() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [uiMode, setUIMode] = useState(ViewMode.VIEW);
  const [item, setItem] = useState(null);

  const getTeam = (_id) => {
    dispatch(setLoading(true));
    TeamsService.get(_id)
      .then(async (response) => {
        const tmp = { ...response.data };
        // load token of team
        try {
          if (tmp?.team?.tokenId) {
            const resToken = await TeamsService.getTeamToken(tmp.team.tokenId);

            if (resToken?.data) {
              tmp.team.tokenImage = resToken?.data.logo;
              tmp.team.tokenSymbol = resToken?.data.symbol;
              tmp.team.tokenBalance = Number(resToken?.data.totalBalance);
              tmp.team.price = Number(resToken?.data.price);
              tmp.team.tokenSaleToFan = Number(resToken?.data.fanSaleFee);
              tmp.team.tokenSaleToSponsors = Number(resToken?.data.sponsorSaleFee);
            } else {
              tmp.team.tokenImage = "";
              tmp.team.tokenSymbol = "";
              tmp.team.tokenBalance = 0;
              tmp.team.price = 0;
              tmp.team.tokenSaleToFan = 0;
              tmp.team.tokenSaleToSponsors = 0;
              ToastrService.error("Failed to load token data");
            }
          }
        } catch {
          tmp.team.tokenImage = "";
          tmp.team.tokenSymbol = "";
          tmp.team.tokenBalance = 0;
          tmp.team.price = 0;
          tmp.team.tokenSaleToFan = 0;
          tmp.team.tokenSaleToSponsors = 0;
          ToastrService.error("Failed to ;pad token data");
        }

        // load fan players of team
        try {
          const resPlayers = await TeamsService.getTeamPlayers(_id);
          if (resPlayers.data.count > 0) {
            const tempPlayers = resPlayers.data.data
              .sort((a, b) => {
                if (a.createdAt > b.createdAt) return 1;
                if (a.createdAt < b.createdAt) return -1;
                return 0;
              })
              .map((d) => ({
                id: d.id,
                name: d.name,
                avatar: d.avatar,
              }));

            tmp.team.players = [...tempPlayers];
          } else {
            tmp.team.players = [];
          }
        } catch {
          tmp.team.players = [];
        }

        // load admin of team
        if (tmp?.admin) {
          tmp.team.adminFirstName = tmp?.admin.firstName;
          tmp.team.adminLastName = tmp?.admin.lastName;
          tmp.team.adminEmail = tmp?.admin.email;
        }
        // load team sport
        if (tmp?.team.sportId) {
          try {
            const resSport = await SportsService.get(tmp?.team.sportId);
            if (resSport?.data) {
              tmp.team.teamSport = resSport?.data.name;
            } else {
              tmp.team.teamSport = "";
              ToastrService.error("Failed to admin data");
            }
          } catch {
            tmp.team.teamSport = "";
            ToastrService.error("Failed to admin data");
          }
        }
        setItem(tmp.team);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load team data");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) getTeam(id);
  }, [id]);

  const backToMain = () => {
    navigate("/dashboards/teams");
  };

  const backToView = () => {
    setUIMode(ViewMode.VIEW);
  };

  const switchUITo = (to) => {
    setUIMode(to);
  };

  return (
    <DashboardLayout>
      {uiMode === ViewMode.MANAGE_ADMIN && <ManageAdmins item={item} backHandler={backToView} />}
      {uiMode === ViewMode.UPDATE_USER && <ResetPassword item={item} backHandler={backToView} />}
      {uiMode === ViewMode.VIEW && (
        <TeamOverview item={item} backHandler={backToMain} switchUIHandler={switchUITo} />
      )}
    </DashboardLayout>
  );
}

export default ViewTeam;
