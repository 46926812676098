import http from "../apis/http-common";

const retrive = (isSuperAdmin = false, start, end, teams) => {
  let url = "";
  if (isSuperAdmin) {
    url = `/analytics/admin?teamIDs=${teams}&start=${start}&end=${end}`;
  } else {
    url = `/analytics/team?start=${start}&end=${end}`;
  }

  return http.get(url);
};

const AnalyticsService = {
  retrive,
};

export default AnalyticsService;
