import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Autocomplete, Checkbox, Divider, Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";

import SponsorService from "services/SponsorService";
import ChallengeService from "services/ChallengeService";
import MatchesService from "services/MatchService";
import GameService from "services/GamesService";
import ToastrService from "services/ToastrService";
import { setLoading } from "store/appSlice";

import { dateToDateAndTime, dateAndTimeToDate } from "utils/converter";
import { ChallengeTypes, EventTabs, UserRole } from "layouts/common/constants";

import FormField from "../../FormField";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .MuiOutlinedInput-root .MuiAutocomplete-input`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
    padding: 0,
    width: "35px",
  },
  [`& .MuiOutlinedInput-root`]: {
    padding: "0px",
    paddingLeft: "10px",
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

function SurveyFreeTextDetail({ prevHandler, nextHandler, changeHandler, typeForm, item }) {
  const initialState = {
    id: null,
    team: null,
    eligbleKudos: 0,
    eligbleToken: 0,
    kudosReward: 0,
    tokenReward: 0,
    sponsor: null,
    match: null,
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    title: "",
    description: "",
    isOptional: false,
    surveyQuestions: [{ question: "" }],
    asset: null,
    enableAssetReward: false,
    rewardAssetCount: 0,
    winnerLimit: 0,
  };

  const validationState = {
    team: null,
    eligbleKudos: 0,
    eligbleToken: 0,
    kudosReward: 0,
    tokenReward: 0,
    sponsor: null,
    match: null,
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    title: "",
    description: "",
    surveyQuestions: [{ question: "" }],
  };

  const adminProfile = useSelector((state) => state.users.profile);
  const [challenge, setChallenge] = useState(initialState);
  const [validationMsg, setValidationMsg] = useState(validationState);
  const [sponsors, setSponsors] = useState([]);
  const [bonusAssets, setBonusAssets] = useState([]);
  const [matches, setMatches] = useState([]);

  const dispatch = useDispatch();

  const getBonusAssets = useCallback(() => {
    let search = null;
    if (adminProfile.role === UserRole.TeamAdmin) search = adminProfile.teamId;
    else if (challenge.team) {
      search = challenge.team.id;
    } else {
      setBonusAssets([]);
      return;
    }
    GameService.getBonusAssets(search, 0, 9999).then((response) => {
      setBonusAssets(response.data.data);
    });
  }, [challenge?.team, adminProfile]);

  const getSponsors = useCallback(() => {
    let search = null;
    if (adminProfile.role === UserRole.TeamAdmin) search = adminProfile.teamId;
    else if (challenge.team) {
      search = challenge.team.id;
    } else {
      setSponsors([]);
      return;
    }
    SponsorService.retrive(search, 0, 9999).then((response) => {
      setSponsors(response.data.data);
    });
  }, [challenge?.team, adminProfile]);

  const getMatches = useCallback(async () => {
    let temp = [];
    let search = null;
    if (adminProfile.role === UserRole.TeamAdmin) search = adminProfile.teamId;
    else if (challenge.team) {
      search = challenge.team.id;
    } else {
      setMatches([]);
      return;
    }
    const promises = await Promise.all([
      new Promise((resolve) => {
        let data = [];
        MatchesService.retrive(EventTabs.Ongoing, search, null, null, null, null, true).then(
          (res) => {
            data = res.data.data;
            resolve({ data });
          },
        );
      }),
      new Promise((resolve) => {
        let data = [];
        MatchesService.retrive(EventTabs.Upcoming, search, null, null, null, null, true).then(
          (res) => {
            data = res.data.data;
            resolve({ data });
          },
        );
      }),
    ]);

    promises.forEach(({ data }) => {
      temp = [...temp].concat(data);
    });

    temp = temp.sort((a, b) => {
      if (a.start < b.start) return 1;
      if (a.start > b.start) return -1;
      return 0;
    });

    setMatches(temp);
  }, [challenge?.team, adminProfile]);

  useEffect(() => {
    getBonusAssets();
    getMatches();
    getSponsors();
  }, [getBonusAssets, getMatches, getSponsors]);

  useEffect(() => {
    if (!item) {
      const temp = { ...initialState };
      temp.team = typeForm.team;
      setChallenge(temp);
    } else {
      // Divide Datetime to Date and Time.
      const newItem = { ...item };
      const startDateTime = dateToDateAndTime(new Date(newItem.start));
      const endDateTime = dateToDateAndTime(new Date(newItem.end));
      newItem.startDate = startDateTime.date;
      newItem.startTime = startDateTime.time;
      newItem.endDate = endDateTime.date;
      newItem.endTime = endDateTime.time;

      delete newItem.start;
      delete newItem.end;

      setChallenge(newItem);

      // Add questions to validation
      const validationSurveyQuestions = newItem.surveyQuestions.map((d) => {
        const options = d.options.map(() => ({ optionText: "" }));
        return { question: "", options };
      });
      setValidationMsg((prevState) => ({
        ...prevState,
        surveyQuestions: validationSurveyQuestions,
      }));
    }
  }, [typeForm, item]);

  const validate = () => {
    let validated = true;
    let errorMsg = { ...validationMsg };
    const keys = Object.keys(challenge);
    for (let i = 0; i < keys.length; i += 1) {
      let msg = "";
      if (
        (challenge[keys[i]] === "" || challenge[keys[i]] === null) &&
        !["asset", "rewardAssetCount", "winnerLimit", "id"].includes(keys[i])
      ) {
        msg = "Field should not be left empty";
        validated = false;
      }
      errorMsg = { ...errorMsg, [keys[i]]: msg };
    }

    const errorSurveyQuestions = [...validationMsg.surveyQuestions];

    for (let i = 0; i < challenge.surveyQuestions.length; i += 1) {
      if (challenge.surveyQuestions[i].question === "") {
        errorSurveyQuestions[i].question = "Field should not be left empty";
        validated = false;
      } else {
        errorSurveyQuestions[i].question = "";
      }
    }

    if (challenge.enableAssetReward) {
      if (!challenge.asset) {
        validated = false;
        ToastrService.warning("Please select asset.");
      }
      if (challenge.rewardAssetCount < 1) {
        validated = false;
        ToastrService.warning("Reward Asset Count should be right value.");
      }
      if (challenge.winnerLimit < 1) {
        validated = false;
        ToastrService.warning("Winner Ranks should be right value");
      }
    }

    errorMsg = { ...errorMsg, surveyQuestions: errorSurveyQuestions };

    setValidationMsg(errorMsg);

    return validated;
  };

  const handleSubmitChange = (field, value) => {
    setChallenge({ ...challenge, [field]: value });
  };

  const handleSubmitChangeNumber = (field, value) => {
    setChallenge({ ...challenge, [field]: parseFloat(value) });
  };

  const handleSubmitChangeQuestion = (idx, value) => {
    const temp = [...challenge.surveyQuestions];
    temp[idx].question = value;
    setChallenge((prevState) => ({ ...prevState, surveyQuestions: temp }));
  };

  const handleAddQuestion = () => {
    const temp = [...challenge.surveyQuestions, { question: "" }];
    setChallenge((prevState) => ({ ...prevState, surveyQuestions: temp }));
    const tempValidation = [...validationMsg.surveyQuestions, { question: "" }];
    setValidationMsg((prevState) => ({ ...prevState, surveyQuestions: tempValidation }));
  };

  const handleRemoveQuestion = () => {
    const temp = [...challenge.surveyQuestions];
    const tempValidation = [...validationMsg.surveyQuestions];
    if (temp.length > 1) {
      temp.pop();
      tempValidation.pop();
      setChallenge((prevState) => ({ ...prevState, surveyQuestions: temp }));
      setValidationMsg((prevState) => ({ ...prevState, surveyQuestions: tempValidation }));
    }
  };

  async function getChallenge(_id) {
    let temp = null;
    dispatch(setLoading(true));
    await ChallengeService.get(_id, ChallengeTypes.SurveyFreeText)
      .then((response) => {
        temp = response.data;
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
    return temp;
  }

  const handleReset = async () => {
    if (!challenge.id) {
      const temp = { ...initialState };
      temp.team = typeForm.team;
      setChallenge(temp);
      setValidationMsg(validationState);
    } else {
      const data = await getChallenge(challenge.id);

      const newItem = { ...data };
      const startDateTime = dateToDateAndTime(new Date(newItem.start));
      const endDateTime = dateToDateAndTime(new Date(newItem.end));
      newItem.startDate = startDateTime.date;
      newItem.startTime = startDateTime.time;
      newItem.endDate = endDateTime.date;
      newItem.endTime = endDateTime.time;

      delete newItem.start;
      delete newItem.end;

      setChallenge(newItem);

      // Add questions to validation
      const validationSurveyQuestions = newItem.surveyQuestions.map(() => ({ question: "" }));
      setValidationMsg((prevState) => ({
        ...prevState,
        surveyQuestions: validationSurveyQuestions,
      }));
    }
  };

  const handleNext = () => {
    if (!validate()) {
      return;
    }
    const temp = challenge;

    temp.start = dateAndTimeToDate(temp.startDate, temp.startTime).toISOString();
    temp.end = dateAndTimeToDate(temp.endDate, temp.endTime).toISOString();
    ["startDate", "startTime", "endDate", "endTime"].forEach((d) => {
      delete temp[d];
    });

    if (changeHandler(temp)) {
      nextHandler();
    }
  };

  const handleBack = () => {
    prevHandler();
  };

  return (
    <MDBox>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "300px" }}>
          Survey(Free Text) - Detail
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handleBack()}>
            Back
          </MDButton>
          <MDButton size="small" onClick={() => handleReset()}>
            Reset
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <Grid container spacing={2} mb={4} alignItems="center">
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{typeForm.team.name}</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Challenge Type
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">Survey(Free Text)</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Challenge Title</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9} mb={2}>
            <FormField
              value={challenge.title}
              onChange={(e) => {
                handleSubmitChange("title", e.target.value);
              }}
              error={validationMsg.title !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Challenge Description</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormField
              value={challenge.description}
              onChange={(e) => {
                handleSubmitChange("description", e.target.value);
              }}
              error={validationMsg.description !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Match / Event</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <StyledAutocomplete
              value={challenge.match}
              options={matches}
              getOptionLabel={(option) => `${option?.title}`}
              onChange={(e, v) => {
                handleSubmitChange("match", v);
              }}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose type"
            />
          </Grid>
          <Grid item xs={12} sm={12} my={3}>
            <MDTypography
              fontSize="15px"
              fontWeight="bold"
              mb={3}
              sx={{ textDecoration: "underline" }}
            >
              Questions
            </MDTypography>
            {challenge.surveyQuestions.map((surveyQuestion, idx) => (
              <MDBox key={`surveyQuestion-free-${idx.toString()}`} mb={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3} md={2.5}>
                    <MDTypography fontSize="15px">Question {idx + 1}</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <FormField
                      value={surveyQuestion.question}
                      onChange={(e) => {
                        handleSubmitChangeQuestion(idx, e.target.value);
                      }}
                      error={validationMsg.surveyQuestions[idx].question !== ""}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            ))}
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDBox mb={2} display="flex" flexDirection="row" alignItems="center" gap="10px">
              <MDButton size="small" color="secondary" onClick={() => handleAddQuestion()}>
                Question +
              </MDButton>
              {challenge.surveyQuestions.length > 1 && (
                <MDButton size="small" color="secondary" onClick={() => handleRemoveQuestion()}>
                  Question -
                </MDButton>
              )}
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px">Start Date & Time</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="date"
              label=""
              value={challenge.startDate}
              onChange={(e) => handleSubmitChange("startDate", e.target.value)}
              error={validationMsg.startDate !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="time"
              label=""
              value={challenge.startTime}
              onChange={(e) => handleSubmitChange("startTime", e.target.value)}
              error={validationMsg.startTime !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px">Expiration Date & Time</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="date"
              label=""
              value={challenge.endDate}
              onChange={(e) => handleSubmitChange("endDate", e.target.value)}
              error={validationMsg.endDate !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="time"
              label=""
              value={challenge.endTime}
              onChange={(e) => handleSubmitChange("endTime", e.target.value)}
              error={validationMsg.endTime !== ""}
            />
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Eligibility
        </MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Kudos points</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              name="eligbleKudos"
              value={challenge.eligbleKudos}
              onChange={(e) => {
                handleSubmitChangeNumber("eligbleKudos", e.target.value);
              }}
              error={validationMsg.eligbleKudos !== "" && validationMsg.eligbleKudos !== 0}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Tokens</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              name="eligbleToken"
              value={challenge.eligbleToken}
              onChange={(e) => {
                handleSubmitChangeNumber("eligbleToken", e.target.value);
              }}
              error={validationMsg.eligbleToken !== "" && validationMsg.eligbleToken !== 0}
            />
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Rewards
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px">No of Kudos Points</MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="number"
                  name="kudosReward"
                  value={challenge.kudosReward}
                  onChange={(e) => {
                    handleSubmitChangeNumber("kudosReward", e.target.value);
                  }}
                  error={validationMsg.kudosReward !== "" && validationMsg.kudosReward !== 0}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px">No of Tokens</MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="number"
                  name="tokenReward"
                  value={challenge.tokenReward}
                  onChange={(e) => {
                    handleSubmitChangeNumber("tokenReward", e.target.value);
                  }}
                  error={validationMsg.tokenReward !== "" && validationMsg.tokenReward !== 0}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Send asset as reward?</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox
              checked={challenge.enableAssetReward}
              onChange={(e) => {
                handleSubmitChange("enableAssetReward", e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">select asset</MDTypography>
          </Grid>
          <Grid item xs={12} sm={4} md={5}>
            <StyledAutocomplete
              value={challenge.asset}
              disabled={!challenge.enableAssetReward}
              options={bonusAssets}
              getOptionLabel={(option) => option?.title}
              onChange={(e, v) => {
                handleSubmitChange("asset", v);
              }}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose Asset"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Number of top ranked scores to send to</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              disabled={!challenge.enableAssetReward}
              type="number"
              min="0"
              value={Math.abs(challenge.winnerLimit)}
              onChange={(e) => handleSubmitChangeNumber("winnerLimit", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">No of Bonus Asset to send per winner</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              disabled={!challenge.enableAssetReward}
              type="number"
              min="0"
              value={Math.abs(challenge.rewardAssetCount)}
              onChange={(e) => handleSubmitChangeNumber("rewardAssetCount", e.target.value)}
            />
          </Grid>
        </Grid>
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Sponsors
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Game Sponsor</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <StyledAutocomplete
              value={challenge.sponsor}
              options={sponsors}
              getOptionLabel={(option) => option.title}
              onChange={(e, v) => {
                handleSubmitChange("sponsor", v);
              }}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose Sponsor"
            />
          </Grid>
        </Grid>
      </MDBox>
      <Grid container justifyContent="flex-end" gap={2}>
        <Grid item>
          <MDButton color="secondary" size="large" onClick={() => handleNext()}>
            Next
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

SurveyFreeTextDetail.defaultProps = {
  typeForm: null,
  item: null,
  prevHandler: {},
  nextHandler: {},
  changeHandler: {},
};

SurveyFreeTextDetail.propTypes = {
  typeForm: PropTypes.objectOf(PropTypes.any),
  item: PropTypes.objectOf(PropTypes.any),
  prevHandler: PropTypes.func,
  nextHandler: PropTypes.func,
  changeHandler: PropTypes.func,
};

export default SurveyFreeTextDetail;
