import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import GameService from "services/GamesService";
import ToastrService from "services/ToastrService";

import { setLoading } from "store/appSlice";
import { createGame, updateGame } from "store/slices/gameSlice";
import { GameTypes, UserRole } from "layouts/common/constants";

import TriviaBasicForm from "./forms/TriviaBasicForm";
import TriviaQuestionsForm from "./forms/TriviaQuestionsForm";
import TriviaSummaryForm from "./forms/TriviaSummaryForm";

const formSwitcher = (screen, prevHandler, nextHandler, handleChange, handleSave, newTrivia) => {
  switch (screen) {
    case 1:
      return (
        <TriviaBasicForm item={newTrivia} nextHandler={nextHandler} changeHandler={handleChange} />
      );
    case 2:
      return (
        <TriviaQuestionsForm
          item={newTrivia}
          prevHandler={prevHandler}
          nextHandler={nextHandler}
          changeHandler={handleChange}
        />
      );
    case 3:
      return (
        <TriviaSummaryForm item={newTrivia} prevHandler={prevHandler} saveHandler={handleSave} />
      );
    default:
      return (
        <TriviaBasicForm item={newTrivia} nextHandler={nextHandler} changeHandler={handleChange} />
      );
  }
};

function AddTrivia() {
  const { id } = useParams();

  const adminProfile = useSelector((state) => state.users.profile);

  const [screen, setScreen] = useState(1);
  const [newTrivia, setTrivia] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getGame = (_id) => {
    dispatch(setLoading(true));
    GameService.get(_id, GameTypes.Trivia)
      .then((response) => {
        const temp = response.data;
        setTrivia(temp);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load trivia data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      getGame(id);
    } else {
      setTrivia(null);
    }
  }, [id]);

  const handleChange = (data) => {
    setTrivia(data);
    return true;
  };

  const handleSave = (isDraft = false) => {
    const newData = { ...newTrivia };
    const dataId = newData.id;

    if (adminProfile.role === UserRole.TeamAdmin) {
      newData.teamId = adminProfile.teamId;
    } else {
      newData.teamId = newData.team.id;
    }

    if (newData?.asset) {
      newData.assetId = newData?.asset?.id;
    }

    newData.sponsorId = newData.sponsor.id;
    newData.matchId = newData.match.id;

    if (!newData.participants) newData.participants = 0;

    newData.isDraft = isDraft;

    ["id", "team", "match", "sponsor", "asset"].forEach((key) => {
      delete newData[key];
    });

    if (dataId === null) {
      dispatch(setLoading(true));
      dispatch(createGame({ type: GameTypes.Trivia, data: newData }))
        .unwrap()
        .then((data) => {
          ToastrService.success("Successfully added new Game Trivia");
          dispatch(setLoading(false));
          navigate(`/game/view-trivia/${data.id}`);
        })
        .catch((e) => {
          ToastrService.error(
            e.response?.data?.message?.message || "Failed to add new Game Trivia",
          );
          dispatch(setLoading(false));
        });
    } else {
      // remove keys
      ["createdAt", "updatedAt"].forEach((key) => {
        delete newData[key];
      });

      dispatch(setLoading(true));
      dispatch(updateGame({ type: GameTypes.Trivia, id: dataId, data: newData }))
        .unwrap()
        .then((data) => {
          if (data.success) {
            ToastrService.success("Successfully updated Game Trivia");
            dispatch(setLoading(false));
            navigate(`/game/view-trivia/${dataId}`);
          } else {
            ToastrService.error(data.message);
            dispatch(setLoading(false));
          }
        })
        .catch((e) => {
          ToastrService.error(e.response?.data?.message?.message || "Failed to update Game Trivia");
          dispatch(setLoading(false));
        });
    }
  };

  const nextScreen = () => {
    setScreen(screen + 1);
  };

  const prevScreen = () => {
    setScreen(screen - 1);
  };

  return (
    <DashboardLayout>
      <MDBox my={3}>
        {formSwitcher(screen, prevScreen, nextScreen, handleChange, handleSave, newTrivia)}
      </MDBox>
    </DashboardLayout>
  );
}

export default AddTrivia;
