import { ChallengeTabs } from "layouts/common/constants";
import ChallengeService from "services/ChallengeService";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  filter: ChallengeTabs.Upcoming,
  totalCount: 0,
  challenges: [],
};

export const retriveChallenges = createAsyncThunk(
  "/challenge/retrive",
  async ({ filter, search, page, rowsPerPage, sortFilter, sortDirection }) => {
    const res = await ChallengeService.retrive(
      filter,
      search,
      page,
      rowsPerPage,
      sortFilter,
      sortDirection,
    );
    return res.data;
  },
);

export const createChallenge = createAsyncThunk("/challenge/create", async ({ type, data }) => {
  const res = await ChallengeService.create(type, data);
  return res.data;
});

export const updateChallenge = createAsyncThunk("/challenge/update", async ({ type, id, data }) => {
  const res = await ChallengeService.update(type, id, data);
  return res.data;
});

export const deleteChallenge = createAsyncThunk("/challenge/delete", async ({ type, id }) => {
  await ChallengeService.remove(type, id);
  return { id };
});

const challengeSlice = createSlice({
  name: "challenge",
  initialState,
  extraReducers: {
    [retriveChallenges.fulfilled]: (state, action) => {
      state.totalCount = action.payload.count;
      state.challenges = [...action.payload.data];
    },
    [updateChallenge.fulfilled]: (state, action) => {
      const index = state.challenges.findIndex((challenge) => challenge.id === action.payload.id);
      state.challenges[index] = {
        ...state.challenges[index],
        ...action.payload,
      };
    },
    [deleteChallenge.fulfilled]: (state, action) => {
      const index = state.challenges.findIndex(({ id }) => id === action.payload.id);
      state.challenges.splice(index, 1);
      state.totalCount -= 1;
    },
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
});
export const { setFilter } = challengeSlice.actions;

const { reducer } = challengeSlice;
export default reducer;
