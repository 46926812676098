import http from "../apis/http-common";

const getUserList = (searchText = "", teamId = "") => {
  let params = "";
  if (searchText !== "" && teamId !== "") {
    params = `?search=${searchText}&teams=${teamId}`;
  } else if (searchText !== "") {
    params = `?search=${searchText}`;
  } else if (teamId !== "") {
    params = `?teams=${teamId}`;
  }
  return http.get(`/user${params}`);
};

const CommonService = {
  getUserList,
};

export default CommonService;
