import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";

import { Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";
import { UserRole } from "layouts/common/constants";

import { dateToDateAndTime } from "utils/converter";

function EventReview({ item, prevHandler, publishHandler }) {
  const adminProfile = useSelector((state) => state.users.profile);
  const [event, setEvent] = useState({});
  useEffect(() => {
    const temp = { ...item };

    const startDateTime = dateToDateAndTime(new Date(temp.start));
    const endDateTime = dateToDateAndTime(new Date(temp.end));

    temp.start = `${startDateTime.date}  ${startDateTime.time}`;
    temp.end = `${endDateTime.date}  ${endDateTime.time}`;

    setEvent(temp);
  }, [item]);

  const handlePrev = () => {
    prevHandler();
  };

  let debounceTimeout;

  const handleSave = useCallback(
    (isDraft = false) =>
      () => {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
          publishHandler(isDraft);
        }, 1000);
      },
    [publishHandler],
  );
  // console.log(
  //   "gallery------>",
  //   typeof event?.gallaryImage === "string" &&
  //     event?.gallaryImage?.split(",")?.map((e) => {
  //       console.log("e", e);
  //     }),
  // );
  // console.log("------>", event?.gallaryImage);
  // console.log("type------>", typeof event?.gallaryImage);
  let gallayList = [];
  if (event?.gallaryImage) {
    if (typeof event?.gallaryImage === "string") {
      gallayList = event?.gallaryImage?.split(",");
    } else {
      Object.values(event?.gallaryImage)?.forEach((o) => {
        const fileLoadUrl = URL.createObjectURL(o);
        gallayList.push(fileLoadUrl);
      });
    }
  }

  return (
    <MDBox my={3}>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "150px" }}>
          Event Review
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handlePrev()}>
            Back
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox sx={(theme) => customFormContainer(theme)}>
        {adminProfile.role === UserRole.SuperAdmin && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{event?.team?.name}</MDTypography>
            </Grid>
          </Grid>
        )}
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Sponsor/Team
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{event?.type}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Match title
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{event?.title}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team / Player #1
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{event?.teamOne}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team / Player #1 image
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {event?.teamOneImage && (
              <MDBox
                component="img"
                src={
                  typeof event?.teamOneImage === "string"
                    ? event?.teamOneImage
                    : URL.createObjectURL(event?.teamOneImage)
                }
                alt="asset image"
                shadow="md"
                width="150px"
                height="100px"
              />
            )}
            {/* <MDTypography fontSize="15px">{event?.teamOneImage?.name}</MDTypography> */}
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team / Player #2
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{event?.teamTwo}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team / Player #2 image
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {event?.teamTwoImage && (
              <MDBox
                component="img"
                src={
                  typeof event?.teamTwoImage === "string"
                    ? event?.teamTwoImage
                    : URL.createObjectURL(event?.teamTwoImage)
                }
                alt="asset image"
                shadow="md"
                width="150px"
                height="100px"
              />
            )}
            {/* <MDTypography fontSize="15px">{event?.teamTwoImage?.name}</MDTypography> */}
          </Grid>

          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Home team
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{event?.homeTeam?.name}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Away Team
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{event?.awayTeam?.name}</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          {" "}
          Add features Image
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Recommended Image
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {event?.recommendedImage && (
              <MDBox
                component="img"
                src={
                  typeof event?.recommendedImage === "string"
                    ? event?.recommendedImage
                    : URL.createObjectURL(event?.recommendedImage)
                }
                alt="asset image"
                shadow="md"
                width="150px"
                height="100px"
              />
            )}
            {/* <MDTypography fontSize="15px">{event?.recommendedImage?.name}</MDTypography> */}
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Add Image gallery
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              gallery title
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {gallayList.length > 0 &&
              gallayList.map((elm) => (
                <MDBox
                  component="img"
                  src={elm}
                  alt="asset image"
                  shadow="md"
                  width="150px"
                  height="100px"
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                />
              ))}
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Location
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Venue title
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{event?.venueTitle}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Venue address
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{event?.venueAddress}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Venue Google coordinates
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{event?.venueGoogleCoordinates}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Start Date & Time
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{event?.start}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Expiration Date & Time
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{event?.end}</MDTypography>
          </Grid>
        </Grid>
      </MDBox>
      <Grid container justifyContent="flex-end" gap={2}>
        {event?.isDraft !== false && (
          <MDButton color="secondary" size="large" onClick={handleSave(true)}>
            Save as Draft
          </MDButton>
        )}
        <MDButton color="secondary" size="large" onClick={handleSave()}>
          Publish
        </MDButton>
      </Grid>
    </MDBox>
  );
}

EventReview.defaultProps = {
  item: null,
  prevHandler: {},
  publishHandler: {},
};

EventReview.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  prevHandler: PropTypes.func,
  publishHandler: PropTypes.func,
};

export default EventReview;
