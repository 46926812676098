const fontList = [
  {
    label: "Goldman",
    value: 0,
  },
  {
    label: "Poppins",
    value: 1,
  },
  {
    label: "Roboto",
    value: 2,
  },
  {
    label: "Open sans",
    value: 3,
  },
  {
    label: "Montserrat",
    value: 4,
  },
  {
    label: "Lato",
    value: 5,
  },
  {
    label: "Source sans pro",
    value: 6,
  },
  {
    label: "Oswald",
    value: 7,
  },
  {
    label: "Kanit",
    value: 8,
  },
  {
    label: "Play",
    value: 9,
  },
];

export default fontList;
