import useAuth from "hooks/useAuth";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

function ProtectedRoute({ children }) {
  const { loaded, token } = useAuth();
  const location = useLocation();

  if (!loaded) return <div>loading...</div>;

  if (loaded && token) {
    return children;
  }

  const url = location.pathname + location.search + location.hash;
  return <Navigate to="/sign-in" state={{ next: url }} />;
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
