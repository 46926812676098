import React from "react";
import Spinner from "react-spinners/MoonLoader";
import MDBox from "./MDBox";

function Loader() {
  return (
    <MDBox
      sx={{
        position: "fixed",
        top: "0",
        left: "0",
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#97979759",
      }}
    >
      <Spinner size={60} color="blue" />
    </MDBox>
  );
}

export default Loader;
