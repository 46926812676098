import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import { styled } from "@mui/material/styles";
import MDInput from "components/MDInput";

const StyledInput = styled(MDInput)(({ theme }) => ({
  [`& .MuiOutlinedInput-root`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

function FormField({ ...rest }) {
  return <StyledInput label="" {...rest} fullWidth />;
}

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

export default FormField;
