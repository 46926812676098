import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import Table from "@mui/material/Table";
import { TableHead } from "@mui/material";
import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import MDButton from "components/MDButton";

import { FaCopy, FaEye, FaEdit, FaTrash, FaArrowUp, FaArrowDown } from "react-icons/fa";

import MDSnackbar from "components/MDSnackbar";
import ConfirmSnackBarContent from "layouts/common/components/ConfirmSnackbarContent";
import { GameTypes, SortFilter } from "layouts/common/constants";
import { dateToDateAndTime } from "utils/converter";

import GameService from "services/GamesService";
import { createGame } from "store/slices/gameSlice";

import { setLoading } from "store/appSlice";
import ToastrService from "services/ToastrService";
import {
  customTableHeader,
  StyledTableCell,
  StyledTableCellContent,
  StyledTableButtonGroups,
  StyledTableSortButton,
  StyledTableButton,
} from "layouts/common/styles";

const tableHeads = [
  {
    label: "Game Type",
    alignment: "left",
    sort: false,
  },
  {
    label: "Title",
    alignment: "left",
    sort: true,
    sortFilter: SortFilter.Title,
  },
  {
    label: "Team",
    alignment: "left",
    sort: true,
    sortFilter: SortFilter.Team,
  },
  {
    label: "Start Date",
    alignment: "right",
    sort: true,
    sortFilter: SortFilter.Start,
  },
  {
    label: "Action",
    alignment: "right",
    sort: false,
  },
];

function GamesTable({ data, deleteHandler, sortHandler }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);

  const [deleteId, setDeleteId] = useState("");
  const [deleteType, setDeleteType] = useState("");
  const [items, setItems] = useState([]);
  const [sortFilter, setSortFilter] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  useEffect(() => {
    const tempData = data.map((d) => {
      const temp = { ...d };
      const startDateTime = dateToDateAndTime(new Date(d.start));
      temp.start = `${startDateTime.date}  ${startDateTime.time}`;
      return temp;
    });

    setItems(tempData);
  }, [data]);

  const handleSort = (sort, direction) => () => {
    setSortFilter(sort);
    setSortDirection(direction);
    sortHandler(sort, direction);
  };

  const handleView = (_id, _type) => {
    if (_type === GameTypes.Trivia) navigate(`/game/view-trivia/${_id}`);
    if (_type === GameTypes.Prediction) navigate(`/game/view-prediction/${_id}`);
    if (_type === GameTypes.Milestone) navigate(`/game/view-milestone/${_id}`);
    if (_type === GameTypes.MiniGame) navigate(`/game/view-minigame/${_id}`);
  };

  const handleEdit = (_id, _type) => {
    if (_type === GameTypes.Trivia) navigate(`/game/edit-trivia/${_id}`);
    if (_type === GameTypes.Prediction) navigate(`/game/edit-prediction/${_id}`);
    if (_type === GameTypes.Milestone) navigate(`/game/edit-milestone/${_id}`);
    if (_type === GameTypes.MiniGame) navigate(`/game/edit-minigame/${_id}`);
  };

  const handleDelete = (_id, _type) => {
    setShow(true);
    setDeleteId(_id);
    setDeleteType(_type);
  };

  const confirmDelete = () => {
    if (deleteId !== "") {
      setShow(false);
      deleteHandler(deleteId, deleteType);
      setDeleteId("");
      setDeleteType("");
    }
  };

  const handleCopy = (_id, _type) => {
    dispatch(setLoading(true));
    GameService.get(_id, _type)
      .then((response) => {
        const temp = { ...response.data };
        temp.teamId = temp.team.id;
        temp.matchId = temp.match.id;
        temp.sponsorId = temp.sponsor.id;
        temp.title = `${temp.title}  (Duplicated)`;
        temp.isDraft = true;

        if (_type === GameTypes.Milestone) {
          temp.occurCount = 0;
        }

        [
          "id",
          "match",
          "team",
          "sponsor",
          "createdAt",
          "updatedAt",
          "playPrediction",
          "playTrivia",
          "playMilestone",
          "resultMain",
          "resultOpposition",
          "isEnded",
          "isResult",
          "isVerified",
        ].forEach((key) => {
          delete temp[key];
        });

        if (temp.rewardDistribution) {
          temp.rewardDistribution = temp.rewardDistribution.map((d) => ({
            winnerOrder: d.winnerOrder,
            rewardKudos: d.rewardKudos,
            rewardToken: d.rewardToken,
          }));
        }

        if (temp.triviaQuestions) {
          temp.triviaQuestions = temp.triviaQuestions.map((item) => ({
            question: item.question,
            options: item.options.map((option) => ({
              optionText: option.optionText,
              isCorrect: option.isCorrect,
            })),
          }));
        }

        dispatch(createGame({ type: _type, data: temp }))
          .unwrap()
          .then((res) => {
            dispatch(setLoading(false));
            ToastrService.success("Successfully duplicated game");
            handleView(res.id, _type);
          })
          .catch(() => {
            dispatch(setLoading(false));
            ToastrService.error("Failed to duplicated game");
          });
      })
      .catch(() => {
        dispatch(setLoading(false));
        ToastrService.error("Failed to duplicated game");
      });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead sx={(theme) => customTableHeader(theme)}>
          <TableRow>
            {tableHeads.map((headerItem) => (
              <StyledTableCell align={headerItem.alignment}>
                <StyledTableCellContent
                  style={
                    headerItem.alignment === "right"
                      ? { justifyContent: "flex-end" }
                      : { justifyContent: "flex-start" }
                  }
                >
                  {headerItem.label}
                  {headerItem.sort && (
                    <StyledTableButtonGroups>
                      <StyledTableSortButton
                        type="button"
                        className={
                          sortFilter === headerItem.sortFilter && sortDirection === "ASC"
                            ? "active"
                            : ""
                        }
                        onClick={handleSort(headerItem.sortFilter, "ASC")}
                      >
                        <FaArrowUp />
                      </StyledTableSortButton>
                      <StyledTableSortButton
                        type="button"
                        className={
                          sortFilter === headerItem.sortFilter && sortDirection === "DESC"
                            ? "active"
                            : ""
                        }
                        onClick={handleSort(headerItem.sortFilter, "DESC")}
                      >
                        <FaArrowDown />
                      </StyledTableSortButton>
                    </StyledTableButtonGroups>
                  )}
                </StyledTableCellContent>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow key={row.id}>
              <StyledTableCell align="left" component="th" scope="row">
                <StyledTableButton color="secondary">{row.type}</StyledTableButton>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{ color: "#0076B2", cursor: "pointer" }}
                onClick={() => handleView(row.id, row.type)}
              >
                {row.title}
              </StyledTableCell>
              <StyledTableCell align="left">{row.team_name}</StyledTableCell>
              <StyledTableCell align="right">{row.start}</StyledTableCell>
              <StyledTableCell align="right">
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={() => handleCopy(row.id, row.type)}
                >
                  <FaCopy />
                </MDButton>
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={() => handleView(row.id, row.type)}
                >
                  <FaEye />
                </MDButton>
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={() => handleEdit(row.id, row.type)}
                >
                  <FaEdit />
                </MDButton>
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={() => handleDelete(row.id, row.type)}
                >
                  <FaTrash />
                </MDButton>
              </StyledTableCell>
            </TableRow>
          ))}
          {items.length === 0 && (
            <TableRow>
              <StyledTableCell align="center" color="dark" colSpan={5}>
                There is no content
              </StyledTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <MDSnackbar
        icon="notification"
        color="error"
        dateTime=""
        title="Delete Game"
        content={
          <ConfirmSnackBarContent
            title="Are you sure to delete this data?"
            confirmHandler={confirmDelete}
          />
        }
        open={show}
        close={toggleSnackbar}
      />
    </TableContainer>
  );
}

GamesTable.defaultProps = {
  data: [],
  deleteHandler: {},
  sortHandler: {},
};

// Typechecking props for the DashboardNavbar
GamesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  deleteHandler: PropTypes.func,
  sortHandler: PropTypes.func,
};

export default GamesTable;
