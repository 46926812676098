import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Autocomplete, Grid, TextField } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { customFormContainer } from "layouts/common/styles";
import claimTypes from "layouts/assets/data/claimTypes";
import FormField from "layouts/assets/components/FormField";

import ToastrService from "services/ToastrService";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .MuiOutlinedInput-root .MuiAutocomplete-input`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
    padding: 0,
    width: "35px",
  },
  [`& .MuiOutlinedInput-root`]: {
    padding: "0px",
    paddingLeft: "10px",
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

function AssetClaimDetail({ formData, prevHandler, nextHandler }) {
  const [data, setData] = useState(
    formData.coupons ? formData : { ...formData, coupons: [{ code: "", order: 1 }] },
  );
  const [validationMsg, setValidationMsg] = useState({
    claimType: "",
    claimDescription: "",
    claimUrl: "",
  });
  const [couponValidation, setCouponValidation] = useState(
    formData.coupons ? formData.coupons.map(() => "") : [""],
  );

  const validate = () => {
    let validated = true;
    let temp = { ...validationMsg };
    Object.keys(validationMsg).map((key) => {
      if (
        key === "claimUrl" &&
        (data.claimType !== claimTypes[0] ||
          (data.category !== "Tickets" && data.category !== "Merchandise"))
      ) {
        temp = { ...temp, [key]: "" };
      } else if (!data[key] || data[key] === "" || data[key] === null) {
        validated = false;
        temp = { ...temp, [key]: `Invalid ${key}` };
      } else {
        temp = { ...temp, [key]: "" };
      }
      return "";
    });

    const tempCoupon = [...couponValidation];
    if (
      data.claimType === claimTypes[0] &&
      (data.category === "Tickets" || data.category === "Merchandise")
    ) {
      couponValidation.map((couponMsg, idx) => {
        if (
          !data.coupons[idx] ||
          data.coupons[idx].code === "" ||
          data.coupons[idx].code === null
        ) {
          validated = false;
          tempCoupon[idx] = `Invalid coupon ${idx}`;
        } else {
          tempCoupon[idx] = "";
        }
        return "";
      });
    } else {
      couponValidation.map((couponMsg, idx) => {
        tempCoupon[idx] = "";
        return "";
      });
    }
    setCouponValidation(tempCoupon);

    setValidationMsg(temp);
    return validated;
  };

  const handlePrev = () => {
    prevHandler();
  };

  const handleNext = () => {
    if (validate()) {
      if (
        !(
          data.claimType === claimTypes[0] &&
          (data.category === "Tickets" || data.category === "Merchandise")
        )
      ) {
        data.coupons = [];
      }
      nextHandler({ ...data });
    } else {
      ToastrService.error(`Invalid input`);
    }
  };

  const handleSubmitChange = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleCouponAdd = (increment) => {
    if (increment === 1) {
      const maxOrder = data.coupons.sort((a, b) => {
        if (a.order < b.order) return 1;
        if (a.order > b.order) return -1;
        return 0;
      })[0].order;
      setData({ ...data, coupons: [...data.coupons, { code: "", order: maxOrder + 1 }] });
      setCouponValidation((prev) => [...prev, ""]);
    } else if (increment === -1) {
      setData({ ...data, coupons: [...data.coupons.slice(0, -1)] });
      setCouponValidation((prev) => prev.slice(0, -1));
    }
  };

  useEffect(() => {
    setData(formData);
  }, [formData]);

  return (
    <MDBox>
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Claim Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Claim Type</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <StyledAutocomplete
              options={claimTypes}
              value={data.claimType}
              onChange={(e, v) => handleSubmitChange("claimType", v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  error={validationMsg.claimType !== ""}
                />
              )}
              placeholder="Choose type"
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Claim Description</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormField
              type="text"
              value={data?.claimDescription}
              onChange={(e) => {
                handleSubmitChange("claimDescription", e.target.value);
              }}
              error={validationMsg.claimDescription !== ""}
            />
          </Grid>
        </Grid>
        {data.claimType === claimTypes[0] &&
          (data.category === "Tickets" || data.category === "Merchandise") && (
            <MDBox>
              <MDTypography
                fontSize="15px"
                fontWeight="bold"
                mb={3}
                sx={{ textDecoration: "underline" }}
              >
                Coupon details
              </MDTypography>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={3} md={2.5}>
                  <MDTypography fontSize="15px">Total asset count</MDTypography>
                </Grid>
                <Grid item xs={12} sm={2} md={2.5}>
                  <FormField type="number" value={data.totalCount} />
                </Grid>
              </Grid>
              {data.coupons
                .sort((a, b) => {
                  if (a.order > b.order) return 1;
                  if (a.order < b.order) return -1;
                  return 0;
                })
                .map((coupon, idx) => (
                  <Grid key={`coupon-${idx + 1}`} container spacing={2} mb={2}>
                    <Grid item xs={12} sm={3} md={2.5}>
                      <MDTypography fontSize="15px">{`Coupon code #${idx + 1}`}</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2.5}>
                      <FormField
                        value={coupon.code}
                        onChange={(e) => {
                          setData((prev) => {
                            const temp = [...prev.coupons];
                            temp[idx].code = e.target.value;
                            return { ...data, coupons: temp };
                          });
                        }}
                        error={couponValidation[idx] !== ""}
                      />
                    </Grid>
                  </Grid>
                ))}
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={3}>
                  <MDButton color="secondary" size="small" onClick={() => handleCouponAdd(1)}>
                    Coupon +
                  </MDButton>
                </Grid>
                {data.coupons.length > 1 && (
                  <Grid item xs={12} sm={3}>
                    <MDButton color="secondary" size="small" onClick={() => handleCouponAdd(-1)}>
                      Coupon -
                    </MDButton>
                  </Grid>
                )}
              </Grid>
              <MDTypography
                fontSize="15px"
                fontWeight="bold"
                mb={3}
                sx={{ textDecoration: "underline" }}
              >
                URL Link to Claim
              </MDTypography>
              <Grid container spacing={2} mb={4}>
                <Grid item xs={12} sm={9}>
                  <FormField
                    type="text"
                    value={data?.claimUrl}
                    onChange={(e) => {
                      handleSubmitChange("claimUrl", e.target.value);
                    }}
                    error={validationMsg.claimUrl !== ""}
                  />
                </Grid>
              </Grid>
            </MDBox>
          )}
      </MDBox>
      <Grid container justifyContent="flex-end" gap={2}>
        <Grid item>
          <MDButton color="secondary" size="large" onClick={handlePrev}>
            Prev
          </MDButton>
        </Grid>
        <Grid item>
          <MDButton color="secondary" size="large" onClick={handleNext}>
            Next
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

AssetClaimDetail.defaultProps = {
  prevHandler: {},
  nextHandler: {},
  formData: null,
};

AssetClaimDetail.propTypes = {
  prevHandler: PropTypes.func,
  nextHandler: PropTypes.func,
  formData: PropTypes.objectOf(PropTypes.any),
};

export default AssetClaimDetail;
