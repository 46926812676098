import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { FaLongArrowAltRight } from "react-icons/fa";

function OverviewCard({ title, value, icon, actionText, action, onClickHandler }) {
  return (
    <Card>
      <MDBox
        p={2}
        style={{
          minHeight: "200px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Grid container>
          <Grid item xs={7}>
            <MDBox mb={0.5} lineHeight={1}>
              <MDTypography
                variant="button"
                fontWeight="medium"
                color="text"
                textTransform="capitalize"
              >
                {title}
              </MDTypography>
            </MDBox>
            <MDBox lineHeight={1}>
              <MDTypography variant="h3" fontWeight="bold">
                {value}
              </MDTypography>
              {icon !== "" && (
                <img
                  src={icon}
                  alt="icon"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                />
              )}
            </MDBox>
          </Grid>
        </Grid>
        <MDBox sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {action !== "" ? (
            <Link to={action}>
              <MDButton>
                {actionText}&nbsp;
                <FaLongArrowAltRight />
              </MDButton>
            </Link>
          ) : (
            <MDButton onClick={onClickHandler}>
              {actionText}&nbsp;
              <FaLongArrowAltRight />
            </MDButton>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of OverviewCard
OverviewCard.defaultProps = {
  icon: "",
  action: "",
  onClickHandler: {},
};

// Typechecking props for the OverviewCard
OverviewCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  actionText: PropTypes.string.isRequired,
  action: PropTypes.string,
  icon: PropTypes.string,
  onClickHandler: PropTypes.func,
};

export default OverviewCard;
