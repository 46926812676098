import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import { Autocomplete, Checkbox, Divider, Grid, TextField } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { dateAndTimeToDate, dateToDateAndTime } from "utils/converter";
import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";

import ToastrService from "services/ToastrService";

import {
  UserRole,
  GameTabs,
  PollTabs,
  ChallengeTabs,
  CriteriaTypes,
} from "layouts/common/constants";

import FormField from "components/FormField";
import GameService from "services/GamesService";
import ChallengeService from "services/ChallengeService";
import PollService from "services/PollService";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .MuiOutlinedInput-root .MuiAutocomplete-input`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
    padding: 0,
    width: "35px",
  },
  [`& .MuiOutlinedInput-root`]: {
    padding: "0px",
    paddingLeft: "10px",
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

const pageLinks = [
  {
    label: "Home",
    value: "Home",
  },
  {
    label: "Marketplace",
    value: "Marketplace",
  },
  {
    label: "My Rewards",
    value: "MyRewards",
  },
  {
    label: "Trivia Game:Bonus Cricket 2",
    value: "TriviaGameBonus",
  },
  {
    label: "Check In Challenge (Match1 | Round 2)",
    value: "ChceckInChallenge",
  },
];

function NotificationDetail({ item, nextHandler, changeHandler }) {
  const initialState = {
    team: null,
    title: "",
    message: "",
    enableLinkToPage: false,
    linkPage: "",
    buttonLabel: "",
    isSchedule: true,
    scheduledDate: "",
    scheduledTime: "",
    sendAll: true,
    criteria: 0,
    criteriaValue: "",
  };

  const navigate = useNavigate();
  const adminProfile = useSelector((state) => state.users.profile);
  const teams = useSelector((state) => state.teams);

  const [games, setGames] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [polls, setPolls] = useState([]);

  const [notification, setNotification] = useState(initialState);
  const [validationMsg, setValidationMsg] = useState(initialState);

  const handleSubmitChange = (field, value) => {
    setNotification({ ...notification, [field]: value });
  };

  const getGames = useCallback(async () => {
    let temp = [];
    let search = null;
    if (adminProfile.role === UserRole.TeamAdmin) search = adminProfile.teamId;
    else if (notification.team) {
      search = notification.team.id;
    } else {
      setGames([]);
      return;
    }
    const promises = await Promise.all([
      new Promise((resolve) => {
        let data = [];
        GameService.retrive(GameTabs.Ongoing, search, 0, 9999, "", "").then((res) => {
          data = res.data.data;
          resolve({ data });
        });
      }),
      new Promise((resolve) => {
        let data = [];
        GameService.retrive(GameTabs.Past, search, 0, 9999, "", "").then((res) => {
          data = res.data.data;
          resolve({ data });
        });
      }),
    ]);

    promises.forEach(({ data }) => {
      temp = [...temp].concat(data);
    });

    temp = temp.sort((a, b) => {
      if (a.start < b.start) return 1;
      if (a.start > b.start) return -1;
      return 0;
    });

    setGames(temp);
  }, [notification?.team, adminProfile]);

  const getChallenges = useCallback(async () => {
    let temp = [];
    let search = null;
    if (adminProfile.role === UserRole.TeamAdmin) search = adminProfile.teamId;
    else if (notification.team) {
      search = notification.team.id;
    } else {
      setChallenges([]);
      return;
    }
    const promises = await Promise.all([
      new Promise((resolve) => {
        let data = [];
        ChallengeService.retrive(ChallengeTabs.Ongoing, search, 0, 9999, "", "").then((res) => {
          data = res.data.data;
          resolve({ data });
        });
      }),
      new Promise((resolve) => {
        let data = [];
        ChallengeService.retrive(ChallengeTabs.Past, search, 0, 9999, "", "").then((res) => {
          data = res.data.data;
          resolve({ data });
        });
      }),
    ]);

    promises.forEach(({ data }) => {
      temp = [...temp].concat(data);
    });

    temp = temp.sort((a, b) => {
      if (a.start < b.start) return 1;
      if (a.start > b.start) return -1;
      return 0;
    });

    setChallenges(temp);
  }, [notification?.team, adminProfile]);
  const getPolls = useCallback(async () => {
    let temp = [];
    let search = null;
    if (adminProfile.role === UserRole.TeamAdmin) search = adminProfile.teamId;
    else if (notification.team) {
      search = notification.team.id;
    } else {
      setPolls([]);
      return;
    }
    const promises = await Promise.all([
      new Promise((resolve) => {
        let data = [];
        PollService.retrive(PollTabs.Ongoing, search, 0, 9999, "", "").then((res) => {
          data = res.data.data;
          resolve({ data });
        });
      }),
      new Promise((resolve) => {
        let data = [];
        PollService.retrive(PollTabs.Past, search, 0, 9999, "", "").then((res) => {
          data = res.data.data;
          resolve({ data });
        });
      }),
    ]);

    promises.forEach(({ data }) => {
      temp = [...temp].concat(data);
    });

    temp = temp.sort((a, b) => {
      if (a.start < b.start) return 1;
      if (a.start > b.start) return -1;
      return 0;
    });

    setPolls(temp);
  }, [notification?.team, adminProfile]);

  useEffect(() => {
    if (item === null) {
      setNotification(initialState);
    } else {
      // Divide Datetime to Date and Time.
      const newItem = { ...item };

      if (newItem.scheduleDate && newItem.scheduleDate !== "") {
        const scheduledDateTime = dateToDateAndTime(new Date(newItem.scheduleDate));
        newItem.scheduledDate = scheduledDateTime.date;
        newItem.scheduledTime = scheduledDateTime.time;
      } else {
        newItem.scheduledDate = "";
        newItem.scheduledTime = "";
      }

      delete newItem.scheduled;
      setNotification(newItem);
    }
  }, [item]);

  useEffect(() => {
    if (adminProfile.role === UserRole.TeamAdmin) {
      const tmp = teams.find((d) => d.id === adminProfile.teamId);
      if (tmp) {
        setNotification({ ...notification, team: tmp });
      }
    }
  }, [adminProfile]);

  useEffect(() => {
    getGames();
  }, [getGames]);
  useEffect(() => {
    getChallenges();
  }, [getChallenges]);
  useEffect(() => {
    getPolls();
  }, [getPolls]);

  const validate = () => {
    let validated = true;
    let errorMsg = validationMsg;
    const keys = Object.keys(initialState);
    for (let i = 0; i < keys.length; i += 1) {
      let msg = "";
      if (
        (notification[keys[i]] === "" || notification[keys[i]] === null) &&
        ![
          "team",
          "linkPage",
          "scheduledDate",
          "scheduledTime",
          "criteria",
          "criteriaValue",
        ].includes(keys[i])
      ) {
        msg = "Field should not be left empty";
        validated = false;
      }

      if (
        (notification[keys[i]] === "" || notification[keys[i]] === null) &&
        keys[i] === "team" &&
        adminProfile.role === UserRole.SuperAdmin
      ) {
        msg = "Field should not be left empty";
        validated = false;
        ToastrService.warning("Team should be right value.");
      }

      errorMsg = { ...errorMsg, [keys[i]]: msg };
    }
    setValidationMsg(errorMsg);
    return validated;
  };

  const handleNext = () => {
    if (!validate()) {
      return;
    }
    const temp = { ...notification };
    if (temp.scheduledDate !== "" && temp.scheduledTime) {
      temp.scheduleDate = dateAndTimeToDate(temp.scheduledDate, temp.scheduledTime).toISOString();
    }

    ["scheduledDate", "scheduledTime"].forEach((d) => {
      delete temp[d];
    });

    if (changeHandler(temp)) {
      nextHandler();
    }
  };

  const handleBack = () => {
    navigate("/notifications");
  };

  const handleReset = async () => {
    let temp = { ...notification };
    const keys = Object.keys(initialState);
    for (let i = 0; i < keys.length; i += 1) {
      temp = { ...temp, [keys[i]]: initialState[keys[i]] };
    }
    setNotification(temp);
  };

  return (
    <MDBox>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "200px" }}>
          {notification.id ? "Edit notification" : "Add notification"}
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handleBack()}>
            Back
          </MDButton>
          <MDButton size="small" onClick={() => handleReset()}>
            Reset
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox sx={(theme) => customFormContainer(theme)}>
        {adminProfile.role === UserRole.SuperAdmin && (
          <Grid container spacing={2} mb={4} alignItems="center">
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">Select Team</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <StyledAutocomplete
                value={notification.team}
                options={teams}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => handleSubmitChange("team", v)}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
                placeholder="Choose Team"
                error={validationMsg.team !== ""}
              />
            </Grid>
          </Grid>
        )}
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Notification Title</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormField
              value={notification.title}
              onChange={(e) => handleSubmitChange("title", e.target.value)}
              error={validationMsg.title !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Notification Message</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormField
              value={notification.message}
              onChange={(e) => handleSubmitChange("message", e.target.value)}
              error={validationMsg.message !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Link to page?</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox
              checked={notification.enableLinkToPage}
              onChange={(e) => {
                handleSubmitChange("enableLinkToPage", e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Page to link to</MDTypography>
          </Grid>
          <Grid item xs={12} sm={4} md={4.5}>
            <StyledAutocomplete
              value={notification.linkPage}
              disabled={!notification.enableLinkToPage}
              options={pageLinks}
              getOptionLabel={(option) => option?.label || ""}
              onChange={(e, v) => {
                handleSubmitChange("linkPage", v);
              }}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Button for link</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormField
              value={notification.buttonLabel}
              onChange={(e) => handleSubmitChange("buttonLabel", e.target.value)}
              error={validationMsg.buttonLabel !== ""}
            />
          </Grid>
        </Grid>
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Schedule
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Send Now?</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox
              checked={!notification.isSchedule}
              onChange={(e) => {
                handleSubmitChange("isSchedule", !e.target.checked);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Schedule for later?</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox
              checked={notification.isSchedule}
              onChange={(e) => {
                handleSubmitChange("isSchedule", e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <MDTypography fontSize="15px">Send On</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <FormField
              disabled={!notification.isSchedule}
              type="date"
              label=""
              value={notification.scheduledDate}
              onChange={(e) => handleSubmitChange("scheduledDate", e.target.value)}
              error={validationMsg.scheduledDate !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <FormField
              disabled={!notification.isSchedule}
              type="time"
              label=""
              value={notification.scheduledTime}
              onChange={(e) => handleSubmitChange("scheduledTime", e.target.value)}
              error={validationMsg.scheduledTime !== ""}
            />
          </Grid>
        </Grid>
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Select Recipients
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Send to All Users</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox
              checked={notification.sendAll}
              onChange={(e) => {
                handleSubmitChange("sendAll", e.target.checked);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Send to specific users</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox
              checked={!notification.sendAll}
              onChange={(e) => {
                handleSubmitChange("sendAll", !e.target.checked);
              }}
            />
          </Grid>
        </Grid>
        {!notification.sendAll && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Criteria</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4} md={4.5}>
              <StyledAutocomplete
                value={notification.criteria}
                options={CriteriaTypes}
                getOptionLabel={(option) => option?.label || ""}
                onChange={(e, v) => {
                  handleSubmitChange("criteria", v);
                }}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
              />
            </Grid>
          </Grid>
        )}
        {notification.criteria?.value === 0 && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Select a challenge</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4} md={4.5}>
              <StyledAutocomplete
                value={notification.criteriaValue}
                options={challenges}
                getOptionLabel={(option) => option?.title || ""}
                onChange={(e, v) => {
                  handleSubmitChange("criteriaValue", v);
                }}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
              />
            </Grid>
          </Grid>
        )}
        {notification.criteria?.value === 1 && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Select a poll</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4} md={4.5}>
              <StyledAutocomplete
                value={notification.criteriaValue}
                options={polls}
                getOptionLabel={(option) => option?.title || ""}
                onChange={(e, v) => {
                  handleSubmitChange("criteriaValue", v);
                }}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
              />
            </Grid>
          </Grid>
        )}
        {notification.criteria?.value === 2 && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Time since last activity</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4} md={4.5}>
              <FormField
                label=""
                value={notification.criteriaValue}
                onChange={(e) => handleSubmitChange("criteriaValue", e.target.value)}
                error={validationMsg.criteriaValue !== ""}
              />
            </Grid>
          </Grid>
        )}
        {notification.criteria?.value === 3 && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Favorite fan players</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4} md={4.5}>
              <FormField
                label=""
                value={notification.criteriaValue}
                onChange={(e) => handleSubmitChange("criteriaValue", e.target.value)}
                error={validationMsg.criteriaValue !== ""}
              />
            </Grid>
          </Grid>
        )}
        {notification.criteria?.value === 4 && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Users minimum token balance</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4} md={4.5}>
              <FormField
                label=""
                value={notification.criteriaValue}
                onChange={(e) => handleSubmitChange("criteriaValue", e.target.value)}
                error={validationMsg.criteriaValue !== ""}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography fontSize="15px">Tokens</MDTypography>
            </Grid>
          </Grid>
        )}
        {notification.criteria?.value === 5 && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3}>
              <MDTypography fontSize="15px">Select a game</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4} md={4.5}>
              <StyledAutocomplete
                value={notification.criteriaValue}
                options={games}
                getOptionLabel={(option) => option?.title || ""}
                onChange={(e, v) => {
                  handleSubmitChange("criteriaValue", v);
                }}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
              />
            </Grid>
          </Grid>
        )}
      </MDBox>
      <Grid container justifyContent="flex-end">
        <MDButton color="secondary" size="large" onClick={() => handleNext()}>
          Next
        </MDButton>
      </Grid>
    </MDBox>
  );
}

NotificationDetail.defaultProps = {
  item: null,
  nextHandler: {},
  changeHandler: {},
};

NotificationDetail.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  nextHandler: PropTypes.func,
  changeHandler: PropTypes.func,
};

export default NotificationDetail;
