import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import MDButton from "components/MDButton";

const pageHeaderContainer = ({ breakpoints }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 2,
  pt: 0.5,
  pb: 0.5,

  [breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    paddingTop: "0",
    paddingBottom: "0",
  },
});

const flexContainer = ({ breakpoints }, mdColumn) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",

  gap: 1,
  pt: 0.5,
  pb: 0.5,

  [breakpoints.down("md")]: {
    flexDirection: mdColumn ? "column" : "row",
    alignItems: mdColumn ? "flex-start" : "center",
    paddingTop: "0",
    paddingBottom: "0",
  },
});

const searchInput = ({ breakpoints }) => ({
  "& .MuiOutlinedInput-root": {
    height: "35px",
  },
  "& .MuiInputBase-input ": {
    width: "304px",
    height: "31px",
    borderRadius: 0,
    color: "#14264B",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    height: "38px",
    borderRadius: 0,
  },
  [breakpoints.down("md")]: {
    "& .MuiInputBase-input ": {
      width: "200px",
    },
  },
});

const customTableContainer = ({ breakpoints }) => ({
  fontSize: "12px",
  width: "100%",
  "& .MuiPaper-root.MuiTableContainer-root": {
    borderRadius: "0px !important",
  },
  "& .MuiTable-root .MuiTableHead-root": {
    display: "table-header-group !important",
  },

  [breakpoints.up("md")]: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: "0",
    paddingBottom: "0",
  },
});

const customTableHeader = ({ breakpoints }) => ({
  display: "table-header-group",

  [breakpoints.up("md")]: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: "0",
    paddingBottom: "0",
  },
});

const customFormContainer = ({ breakpoints }) => ({
  width: "900px",
  [breakpoints.down("xl")]: {
    width: "100%",
  },
});

const tabButton = ({ breakpoints }) => ({
  width: "100%",
  height: "61px",
  borderRadius: "0px",
  color: "#14264B",
  [breakpoints.down("md")]: {
    width: "100%",
  },
});

const hiddenOnMobile = ({ breakpoints }) => ({
  display: "block",
  [breakpoints.down("sm")]: {
    display: "none",
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontSize: 13,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    fontWeight: "bold",
  },
}));

const StyledTableCellContent = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  gap: "5px",
}));

const StyledTableButtonGroups = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

const StyledTableSortButton = styled("button")(() => ({
  background: "transparent",
  border: 0,
  opacity: 0.3,
  filter: "alpha((opacity = 30))",
  [`&.active`]: {
    opacity: 1,
  },
  [`&:hover`]: {
    opacity: 1,
    cursor: "pointer",
  },
}));

const StyledTableButton = styled(MDButton)(({ theme }) => ({
  [`&.MuiButton-root`]: {
    width: "100px",
    color: theme.palette.background.sidenav,
    background: "rgba(204, 204, 204, 0.25)",
  },
}));

export {
  pageHeaderContainer,
  flexContainer,
  searchInput,
  customTableContainer,
  customTableHeader,
  customFormContainer,
  tabButton,
  hiddenOnMobile,
  StyledTableCell,
  StyledTableCellContent,
  StyledTableSortButton,
  StyledTableButtonGroups,
  StyledTableButton,
};
