import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Autocomplete, Divider, Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";

import { ChallengeTypes, UserRole } from "layouts/common/constants";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .MuiOutlinedInput-root .MuiAutocomplete-input`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
    padding: 0,
    width: "35px",
  },
  [`& .MuiOutlinedInput-root`]: {
    padding: "0px",
    paddingLeft: "10px",
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

function SelectCategory({ typeForm, nextHandler }) {
  const navigate = useNavigate();
  const adminProfile = useSelector((state) => state.users.profile);
  const teams = useSelector((state) => state.teams);

  const initialState = {
    type: "",
    team: null,
  };

  const [data, setData] = useState(initialState);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    const temp = { ...ChallengeTypes };
    delete temp.Survey;
    setTypes(Object.values(temp));
  }, []);

  useEffect(() => {
    if (typeForm) {
      setData(typeForm);
    } else {
      setData(initialState);
    }
  }, [typeForm]);

  const handleNext = () => {
    if (data.type !== "" && data.team) {
      nextHandler(data);
    }
  };

  const handleBack = () => {
    navigate("/challenges");
  };

  const handleChange = (field, value) => {
    setData((prevState) => ({ ...prevState, [field]: value }));
  };

  return (
    <MDBox>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "200px" }}>
          Add Challenge
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handleBack()}>
            Back
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox sx={(theme) => customFormContainer(theme)}>
        {adminProfile.role === UserRole.SuperAdmin && (
          <Grid container spacing={2} mb={4} alignItems="center">
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">Team</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <StyledAutocomplete
                options={teams}
                value={data.team}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => handleChange("team", v)}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
                placeholder="Type"
              />
            </Grid>
          </Grid>
        )}

        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Select Challenge Type
        </MDTypography>
        <Grid container spacing={2} mb={4} alignItems="center">
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Challenge Type</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <StyledAutocomplete
              options={Object.values(types)}
              value={data.type}
              onChange={(e, v) => handleChange("type", v)}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Type"
            />
          </Grid>
        </Grid>
      </MDBox>
      <Grid container justifyContent="flex-end">
        <MDButton color="secondary" size="large" onClick={() => handleNext()}>
          Next
        </MDButton>
      </Grid>
    </MDBox>
  );
}

SelectCategory.defaultProps = {
  typeForm: null,
  nextHandler: {},
};

SelectCategory.propTypes = {
  typeForm: PropTypes.objectOf(PropTypes.any),
  nextHandler: PropTypes.func,
};

export default SelectCategory;
