const dateToDateAndTime = (newDate) => {
  const year = newDate.getFullYear();
  let month = newDate.getMonth() + 1;
  let dt = newDate.getDate();
  let hr = newDate.getHours();
  let min = newDate.getMinutes();

  if (dt < 10) {
    dt = `0${dt}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  if (hr < 10) {
    hr = `0${hr}`;
  }

  if (min < 10) {
    min = `0${min}`;
  }

  const date = `${year}-${month}-${dt}`;
  const time = `${hr}:${min}`;

  return { date, time };
};

const dateAndTimeToDate = (_date, _time) => {
  const date = _date.split("-");
  const time = _time.split(" ");
  let hr = time[0].split(":")[0];
  const min = time[0].split(":")[1];
  if (time[1] === "PM") hr = Number(hr) + 12;
  const dateObj = new Date(date[0], date[1] - 1, date[2], hr, min);
  return dateObj;
};

const getTimeString = (num) => {
  let hours = Math.floor(num / 3600);
  let minutes = Math.floor((num - hours * 3600) / 60);
  let seconds = num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  if (num < 3600) {
    return `${minutes}:${seconds}`;
  }

  return `${hours}:${minutes}:${seconds}`;
};

module.exports = {
  dateToDateAndTime,
  dateAndTimeToDate,
  getTimeString,
};
