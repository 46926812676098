import NotificationService from "services/NotificationService";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  totalCount: 0,
  data: [],
};

export const retriveNotifications = createAsyncThunk(
  "/notification/retrive",
  async ({ searchTeam, searchText, page, rowsPerPage, sortFilter, sortDirection }) => {
    const res = await NotificationService.getAll(
      searchTeam,
      searchText,
      page,
      rowsPerPage,
      sortFilter,
      sortDirection,
    );
    return res.data;
  },
);

export const createNotification = createAsyncThunk("/notification/create", async ({ data }) => {
  const res = await NotificationService.create(data);
  return res.data;
});

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  extraReducers: {
    [retriveNotifications.fulfilled]: (state, action) => {
      state.totalCount = action.payload.count;
      state.data = [...action.payload.data];
    },
  },
});

const { reducer } = notificationSlice;
export default reducer;
