import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import ConfirmSnackBarContent from "layouts/common/components/ConfirmSnackbarContent";

import { FaEdit, FaEye, FaTrash } from "react-icons/fa";

import { dateToDateAndTime } from "utils/converter";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    fontWeight: "bold",
  },
}));

function SponsorsTable({ data, deleteHandler }) {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);
  const [deleteIdx, setDeleteIdx] = useState("");

  const [items, setItems] = useState([]);

  useEffect(() => {
    const tempData = data.map((d) => {
      const temp = { ...d };
      const dateTime = dateToDateAndTime(new Date(d.createdAt));
      temp.createdAt = `${dateTime.date}  ${dateTime.time}`;
      return temp;
    });

    setItems(tempData);
  }, [data]);

  const handleView = (id) => {
    navigate(`/sponsor/view/${id}`);
  };

  const handleEdit = (id) => {
    navigate(`/sponsor/edit/${id}`);
  };

  const handleDelete = (id) => {
    setShow(true);
    setDeleteIdx(id);
  };

  const confirmDelete = () => {
    if (deleteIdx !== "") {
      setShow(false);
      deleteHandler(deleteIdx);
      setDeleteIdx("");
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableBody>
          {items.map((row) => (
            <TableRow key={row.id}>
              <StyledTableCell
                align="left"
                sx={{ color: "#0076B2", cursor: "pointer" }}
                onClick={() => handleView(row.id)}
              >
                {row.title}
              </StyledTableCell>
              <StyledTableCell align="left">{row.category}</StyledTableCell>
              <StyledTableCell align="right">{row.createdAt}</StyledTableCell>
              <StyledTableCell align="right">
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={() => handleView(row.id)}
                >
                  <FaEye />
                </MDButton>
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={() => handleEdit(row.id)}
                >
                  <FaEdit />
                </MDButton>
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={() => handleDelete(row.id)}
                >
                  <FaTrash />
                </MDButton>
              </StyledTableCell>
            </TableRow>
          ))}
          {items.length === 0 && (
            <TableRow>
              <StyledTableCell colSpan={6} align="center" color="dark">
                There is no content.
              </StyledTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <MDSnackbar
        color="error"
        icon="notifications"
        title="Delete Sponsor"
        dateTime=""
        content={
          <ConfirmSnackBarContent
            title="Are you sure to delete this data?"
            confirmHandler={confirmDelete}
          />
        }
        open={show}
        close={toggleSnackbar}
      />
    </TableContainer>
  );
}

SponsorsTable.defaultProps = {
  data: [],
  deleteHandler: {},
};

// Typechecking props for the DashboardNavbar
SponsorsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  deleteHandler: PropTypes.func,
};

export default SponsorsTable;
