import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import { Autocomplete, Checkbox, Divider, Grid, TextField } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { dateAndTimeToDate, dateToDateAndTime } from "utils/converter";
import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";

import SponsorService from "services/SponsorService";
import MatchesService from "services/MatchService";
import GameService from "services/GamesService";
import ToastrService from "services/ToastrService";
import { setLoading } from "store/appSlice";

import { EventTabs, GameTypes, UserRole } from "layouts/common/constants";

import FormField from "components/FormField";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .MuiOutlinedInput-root .MuiAutocomplete-input`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
    padding: 0,
    width: "35px",
  },
  [`& .MuiOutlinedInput-root`]: {
    padding: "0px",
    paddingLeft: "10px",
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

function MilestoneBasicForm({ item, nextHandler, changeHandler }) {
  const initialState = {
    id: null,
    team: null,
    match: null,
    asset: null,
    title: "",
    description: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    sponsor: null,
    eligbleKudos: 0,
    eligbleToken: 0,
    rewardKudosPerPlayer: 0,
    rewardTokenPerPlayer: 0,
    anyWithVenue: true,
    onlyInVenue: true,
    enableAssetReward: false,
    rewardAssetCount: 0,
    winnerLimit: 0,
  };

  const resetState = {
    team: null,
    match: null,
    asset: null,
    title: "",
    description: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    sponsor: null,
    eligbleKudos: 0,
    eligbleToken: 0,
    rewardKudosPerPlayer: 0,
    rewardTokenPerPlayer: 0,
    anyWithVenue: true,
    onlyInVenue: true,
    enableAssetReward: false,
    rewardAssetCount: 0,
    winnerLimit: 0,
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminProfile = useSelector((state) => state.users.profile);
  const teams = useSelector((state) => state.teams);

  const [milestone, setMilestone] = useState(initialState);
  const [validationMsg, setValidationMsg] = useState(resetState);
  const [bonusAssets, setBonusAssets] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [matches, setMatches] = useState([]);

  const handleSubmitChange = (field, value) => {
    setMilestone({ ...milestone, [field]: value });
  };

  const handleSubmitChangeNumber = (field, value) => {
    setMilestone({ ...milestone, [field]: parseFloat(value) });
  };

  const getBonusAssets = useCallback(() => {
    let search = null;
    if (adminProfile.role === UserRole.TeamAdmin) search = adminProfile.teamId;
    else if (milestone.team) {
      search = milestone.team.id;
    } else {
      setBonusAssets([]);
      return;
    }
    GameService.getBonusAssets(search, 0, 9999).then((response) => {
      setBonusAssets(response.data.data);
    });
  }, [milestone?.team, adminProfile]);

  const getSponsors = useCallback(() => {
    let search = null;
    if (adminProfile.role === UserRole.TeamAdmin) search = adminProfile.teamId;
    else if (milestone.team) {
      search = milestone.team.id;
    } else {
      setSponsors([]);
      return;
    }
    SponsorService.retrive(search, 0, 9999).then((response) => {
      setSponsors(response.data.data);
    });
  }, [milestone?.team, adminProfile]);

  const getMatches = useCallback(async () => {
    let temp = [];
    let search = null;
    if (adminProfile.role === UserRole.TeamAdmin) search = adminProfile.teamId;
    else if (milestone.team) {
      search = milestone.team.id;
    } else {
      setMatches([]);
      return;
    }
    const promises = await Promise.all([
      new Promise((resolve) => {
        let data = [];
        MatchesService.retrive(EventTabs.Ongoing, search, null, null, null, null, true).then(
          (res) => {
            data = res.data.data;
            resolve({ data });
          },
        );
      }),
      new Promise((resolve) => {
        let data = [];
        MatchesService.retrive(EventTabs.Upcoming, search, null, null, null, null, true).then(
          (res) => {
            data = res.data.data;
            resolve({ data });
          },
        );
      }),
    ]);

    promises.forEach(({ data }) => {
      temp = [...temp].concat(data);
    });

    temp = temp.sort((a, b) => {
      if (a.start < b.start) return 1;
      if (a.start > b.start) return -1;
      return 0;
    });

    setMatches(temp);
  }, [milestone?.team, adminProfile]);

  useEffect(() => {
    getBonusAssets();
    getMatches();
    getSponsors();
  }, [getBonusAssets, getMatches, getSponsors]);

  useEffect(() => {
    if (item === null) {
      setMilestone(initialState);
    } else {
      // Divide Datetime to Date and Time.
      const newItem = { ...item };

      const startDateTime = dateToDateAndTime(new Date(newItem.start));
      const endDateTime = dateToDateAndTime(new Date(newItem.end));
      newItem.startDate = startDateTime.date;
      newItem.startTime = startDateTime.time;
      newItem.endDate = endDateTime.date;
      newItem.endTime = endDateTime.time;

      delete newItem.start;
      delete newItem.end;

      setMilestone(newItem);
    }
  }, [item]);

  const validate = () => {
    let validated = true;
    let errorMsg = validationMsg;
    const keys = Object.keys(resetState);
    for (let i = 0; i < keys.length; i += 1) {
      let msg = "";
      if (
        (milestone[keys[i]] === "" || milestone[keys[i]] === null) &&
        !["asset", "rewardAssetCount", "winnerLimit", "team"].includes(keys[i])
      ) {
        msg = "Field should not be left empty";
        validated = false;
      }

      if (
        (milestone[keys[i]] === "" || milestone[keys[i]] === null) &&
        keys[i] === "team" &&
        adminProfile.role === UserRole.SuperAdmin
      ) {
        msg = "Field should not be left empty";
        validated = false;
      }

      if (milestone.enableAssetReward) {
        if (!milestone.asset) {
          validated = false;
          ToastrService.warning("Please select asset.");
        }
        if (milestone.rewardAssetCount < 1) {
          validated = false;
          ToastrService.warning("Reward Asset Count should be right value.");
        }
        if (milestone.winnerLimit < 1) {
          validated = false;
          ToastrService.warning("Winner Ranks should be right value");
        }
      }
      errorMsg = { ...errorMsg, [keys[i]]: msg };
    }
    setValidationMsg(errorMsg);

    return validated;
  };

  const handleNext = () => {
    if (!validate()) {
      return;
    }
    const temp = { ...milestone };
    temp.start = dateAndTimeToDate(temp.startDate, temp.startTime).toISOString();
    temp.end = dateAndTimeToDate(temp.endDate, temp.endTime).toISOString();

    ["startDate", "startTime", "endDate", "endTime"].forEach((d) => {
      delete temp[d];
    });

    if (changeHandler(temp)) {
      nextHandler();
    }
  };

  const handleBack = () => {
    navigate("/games");
  };

  async function getMilestone(_id) {
    let temp = null;
    dispatch(setLoading(true));
    await GameService.get(_id, GameTypes.Milestone)
      .then((response) => {
        temp = response.data;
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
    return temp;
  }

  const handleReset = async () => {
    if (!milestone.id) {
      let temp = { ...milestone };
      const keys = Object.keys(resetState);
      for (let i = 0; i < keys.length; i += 1) {
        temp = { ...temp, [keys[i]]: initialState[keys[i]] };
      }
      setMilestone(temp);
    } else {
      const data = await getMilestone(milestone.id);
      const newItem = { ...data };

      const startDateTime = dateToDateAndTime(new Date(newItem.start));
      const endDateTime = dateToDateAndTime(new Date(newItem.end));
      newItem.startDate = startDateTime.date;
      newItem.startTime = startDateTime.time;
      newItem.endDate = endDateTime.date;
      newItem.endTime = endDateTime.time;

      let temp = { ...milestone };
      const keys = Object.keys(resetState);
      for (let i = 0; i < keys.length; i += 1) {
        temp = { ...temp, [keys[i]]: newItem[keys[i]] };
      }
      setMilestone(temp);
    }
  };

  return (
    <MDBox>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "200px" }}>
          {milestone.id ? "Edit Milestone" : "Add Milestone"}
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handleBack()}>
            Back
          </MDButton>
          <MDButton size="small" onClick={() => handleReset()}>
            Reset
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox sx={(theme) => customFormContainer(theme)}>
        {adminProfile.role === UserRole.SuperAdmin && (
          <Grid container spacing={2} mb={4} alignItems="center">
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">Select Team</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <StyledAutocomplete
                value={milestone.team}
                options={teams}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => handleSubmitChange("team", v)}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
                placeholder="Choose Team"
                error={validationMsg.team !== ""}
              />
            </Grid>
          </Grid>
        )}
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Match / Event</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <StyledAutocomplete
              value={milestone.match}
              options={matches}
              getOptionLabel={(option) => `${option?.title}`}
              onChange={(e, v) => {
                handleSubmitChange("match", v);
              }}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose type"
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Milestone Title</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormField
              value={milestone.title}
              onChange={(e) => handleSubmitChange("title", e.target.value)}
              error={validationMsg.title !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Milestone Description</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormField
              value={milestone.description}
              onChange={(e) => handleSubmitChange("description", e.target.value)}
              error={validationMsg.description !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px">Start Date & Time</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="date"
              label=""
              value={milestone.startDate}
              onChange={(e) => handleSubmitChange("startDate", e.target.value)}
              error={validationMsg.startDate !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="time"
              label=""
              value={milestone.startTime}
              onChange={(e) => handleSubmitChange("startTime", e.target.value)}
              error={validationMsg.startTime !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px">Expiration Date & Time</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="date"
              label=""
              value={milestone.endDate}
              onChange={(e) => handleSubmitChange("endDate", e.target.value)}
              error={validationMsg.endDate !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="time"
              label=""
              value={milestone.endTime}
              onChange={(e) => handleSubmitChange("endTime", e.target.value)}
              error={validationMsg.endTime !== ""}
            />
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Eligibility
        </MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">No of Kudos Points</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              min="0"
              value={Math.abs(milestone.eligbleKudos)}
              onChange={(e) => handleSubmitChangeNumber("eligbleKudos", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">No of Tokens</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              min="0"
              value={Math.abs(milestone.eligbleToken)}
              onChange={(e) => handleSubmitChangeNumber("eligbleToken", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">
              User must be checked in to match (in venue or out of venue)
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Checkbox
              checked={milestone.anyWithVenue}
              onChange={(e) => {
                handleSubmitChange("anyWithVenue", e.target.checked);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">
              User must be checked in to match (IN VENUE only)
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox
              checked={Math.abs(milestone.onlyInVenue)}
              onChange={(e) => {
                handleSubmitChange("onlyInVenue", e.target.checked);
              }}
            />
          </Grid>
        </Grid>
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Rewards
        </MDTypography>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
          For each time the event occurs
        </MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">No of Kudos Points(per player)</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              min="0"
              value={Math.abs(milestone.rewardKudosPerPlayer)}
              onChange={(e) => handleSubmitChangeNumber("rewardKudosPerPlayer", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">No of Tokens(per player)</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              min="0"
              value={Math.abs(milestone.rewardTokenPerPlayer)}
              onChange={(e) => handleSubmitChangeNumber("rewardTokenPerPlayer", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Send asset as reward?</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox
              checked={milestone.enableAssetReward}
              onChange={(e) => {
                handleSubmitChange("enableAssetReward", e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">select asset</MDTypography>
          </Grid>
          <Grid item xs={12} sm={4} md={5}>
            <StyledAutocomplete
              value={milestone.asset}
              disabled={!milestone.enableAssetReward}
              options={bonusAssets}
              getOptionLabel={(option) => option?.title}
              onChange={(e, v) => {
                handleSubmitChange("asset", v);
              }}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose Asset"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Number of top ranked scores to send to</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              disabled={!milestone.enableAssetReward}
              type="number"
              min="0"
              value={Math.abs(milestone.winnerLimit)}
              onChange={(e) => handleSubmitChangeNumber("winnerLimit", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">No of Bonus Asset to send per winner</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              disabled={!milestone.enableAssetReward}
              type="number"
              min="0"
              value={Math.abs(milestone.rewardAssetCount)}
              onChange={(e) => handleSubmitChangeNumber("rewardAssetCount", e.target.value)}
            />
          </Grid>
        </Grid>
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Sponsors
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Game Sponsor</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <StyledAutocomplete
              value={milestone.sponsor}
              options={sponsors}
              getOptionLabel={(option) => option.title}
              onChange={(e, v) => {
                handleSubmitChange("sponsor", v);
              }}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose Sponsor"
            />
          </Grid>
        </Grid>
      </MDBox>
      <Grid container justifyContent="flex-end">
        <MDButton color="secondary" size="large" onClick={() => handleNext()}>
          Next
        </MDButton>
      </Grid>
    </MDBox>
  );
}

MilestoneBasicForm.defaultProps = {
  item: null,
  nextHandler: {},
  changeHandler: {},
};

MilestoneBasicForm.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  nextHandler: PropTypes.func,
  changeHandler: PropTypes.func,
};

export default MilestoneBasicForm;
