import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";
import { GameTypes } from "layouts/common/constants";

import ToastrService from "services/ToastrService";
import GameService from "services/GamesService";
import { setLoading } from "store/appSlice";

import { dateToDateAndTime } from "utils/converter";
import WinnerTableTrivia from "../WinnerTableTrivia";

function ViewTriviaResult() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState(null);
  const [trivia, setTrivia] = useState(null);

  const getTrivia = (_id) => {
    dispatch(setLoading(true));
    GameService.get(_id, GameTypes.Trivia)
      .then((response) => {
        const temp = { ...response.data };

        const startDateTime = dateToDateAndTime(new Date(temp.start));
        const endDateTime = dateToDateAndTime(new Date(temp.end));

        temp.start = `${startDateTime.date}  ${startDateTime.time}`;
        temp.end = `${endDateTime.date}  ${endDateTime.time}`;

        setTrivia(temp);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load trivia data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) getTrivia(id);
  }, [id]);

  const getResult = (_id) => {
    dispatch(setLoading(true));
    GameService.getResult(GameTypes.Trivia, _id)
      .then((response) => {
        const temp = { ...response.data };

        const startDateTime = dateToDateAndTime(new Date(temp.start));
        const endDateTime = dateToDateAndTime(new Date(temp.end));

        temp.start = `${startDateTime.date}  ${startDateTime.time}`;
        temp.end = `${endDateTime.date}  ${endDateTime.time}`;

        // calculation avg score of players.
        let avgScore = temp.playTrivia
          .map((d) => d.score)
          .reduce((prevVal, curVal) => prevVal + curVal, 0);

        if (temp.playTrivia.length > 0) {
          avgScore = Number(avgScore) / Number(temp.playTrivia.length);
        } else {
          avgScore = 0;
        }

        temp.avgScore = avgScore.toFixed(1);

        setData(temp);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response.data.message.message || "Failed to load trivia result.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      getResult(id);
    }
  }, [id]);

  const handleBack = () => {
    navigate(`/game/view-trivia/${id}`);
  };

  return (
    <DashboardLayout>
      <MDBox my={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "200px" }}>
          Trivia - Results
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handleBack()}>
            Back
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox>
        <MDBox sx={(theme) => customFormContainer(theme)}>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Details
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.team?.name}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Trivia Type
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.triviaType}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Trivia Title
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Trivia Description
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.description}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Start Date & Time
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={8.5}>
              <MDTypography fontSize="15px">{data?.start}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Expiration Date & Time
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={8.5}>
              <MDTypography fontSize="15px">{data?.end}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Results
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Player Count
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.playTrivia?.length}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Average Score
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.avgScore}</MDTypography>
            </Grid>
          </Grid>
          <MDBox mb={3}>
            <MDTypography
              fontSize="15px"
              fontWeight="bold"
              mb={3}
              sx={{ textDecoration: "underline" }}
            >
              Winners
            </MDTypography>
            <WinnerTableTrivia data={data?.playTrivia || []} trivia={trivia} />
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default ViewTriviaResult;
