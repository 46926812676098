import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Divider, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import MDProgress from "components/MDProgress";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";
import { ChallengeTypes } from "layouts/common/constants";

import ToastrService from "services/ToastrService";
import ChallengeService from "services/ChallengeService";

import { setLoading } from "store/appSlice";

import { dateToDateAndTime } from "utils/converter";
import UserResponseTable from "./UserResponseTable";

function SurveyResult() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [result, setResult] = useState(null);
  const [item, setItem] = useState(null);
  const [isUserResponse, setIsUserResponse] = useState(false);
  const [userResponses, setUserResponses] = useState([]);

  const getResult = (_id) => {
    ChallengeService.getResult(ChallengeTypes.Survey, _id)
      .then((response) => {
        const temp = response.data;
        setResult(temp);
      })
      .catch((e) => {
        ToastrService.error(
          e.response?.data?.message?.message || "Failed to load challenge result.",
        );
      });
  };

  const getChallenge = (_id) => {
    dispatch(setLoading(true));
    ChallengeService.get(_id, ChallengeTypes.Survey)
      .then((response) => {
        const temp = { ...response.data };

        const startDateTime = dateToDateAndTime(new Date(temp.start));
        const endDateTime = dateToDateAndTime(new Date(temp.end));

        temp.start = `${startDateTime.date}  ${startDateTime.time}`;
        temp.end = `${endDateTime.date}  ${endDateTime.time}`;

        setItem(temp);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load challenge data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(async () => {
    if (id) {
      getResult(id);
      getChallenge(id);
    }
  }, [id]);

  const getPercent = (questionId, optionId) => {
    if (!result) {
      return 0;
    }

    const optionPlayerCount = result.result.find((d) => d.surveyOptions_id === optionId).count;
    if (Number(optionPlayerCount) === 0) return 0;
    const totalQPlayerCount = result.result
      .filter((d) => d.surveyQuestion_id === questionId)
      .map((d) => d.count)
      .reduce((prevVal, curVal) => Number(prevVal) + Number(curVal), 0);
    const percent = (optionPlayerCount / totalQPlayerCount) * 100;
    return Math.ceil(percent);
  };

  const handleViewResponse = (_id) => {
    setIsUserResponse(true);
    const responses = result.survey.playSurvey.find((d) => d.id === _id);

    if (responses) {
      let tmpAnswers = [...responses.answer];
      tmpAnswers = tmpAnswers.map((d) => {
        const tmpAnswer = { ...d };
        const questionData = result.survey.surveyQuestions.find(
          (qItem) => qItem.id === d.questionId,
        );
        if (questionData) tmpAnswer.question = questionData.question;
        else tmpAnswer.question = "";

        return tmpAnswer;
      });
      setUserResponses({
        username: `${responses?.user?.firstName} ${responses?.user?.lastName}`,
        responses: tmpAnswers,
      });
    }
  };

  const handleBack = () => {
    if (!isUserResponse) {
      navigate(`/challenge/view/survey/${id}`);
    } else {
      setIsUserResponse(false);
      setUserResponses({});
    }
  };

  return (
    <DashboardLayout>
      <MDBox my={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "300px" }}>
            {item?.isOptional ? "Survey - Result" : "Survey (Free Text) - Result"}
            {isUserResponse ? "- User Response" : ""}
          </MDTypography>
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDButton size="small" onClick={() => handleBack()}>
              Back
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        {isUserResponse ? (
          <MDBox sx={(theme) => customFormContainer(theme)}>
            <Grid container spacing={2} mb={4} alignItems="center">
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  UserName
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{userResponses.username}</MDTypography>
              </Grid>
            </Grid>
            {userResponses.responses.map((d, idx) => (
              <Grid
                key={`user-response-${idx.toString()}`}
                container
                spacing={2}
                mb={4}
                alignItems="center"
              >
                <Grid item xs={12} sm={3} md={2.5}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Question {idx + 1}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <MDTypography fontSize="15px">{d.question}</MDTypography>
                </Grid>
                <Grid item xs={12} sm={3} md={2.5}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Response {idx + 1}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <MDTypography fontSize="15px">{d.freeText}</MDTypography>
                </Grid>
              </Grid>
            ))}
          </MDBox>
        ) : (
          <MDBox sx={(theme) => customFormContainer(theme)}>
            <Grid container spacing={2} mb={4} alignItems="center">
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Team
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{item?.team?.name}</MDTypography>
              </Grid>
            </Grid>
            <MDTypography
              fontSize="15px"
              fontWeight="bold"
              mb={3}
              sx={{ textDecoration: "underline" }}
            >
              Details
            </MDTypography>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Challenge Type
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">
                  {item?.isOptional ? "Survey (Multi choice)" : "Survey (Free text)"}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Challenge Title
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{item?.title}</MDTypography>
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Challenge Description
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{item?.description}</MDTypography>
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Match / Event
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <MDTypography fontSize="15px">{item?.match?.title}</MDTypography>
              </Grid>
              <Grid item xs={12} sm={12} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Start Date & Time
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12} md={9}>
                <MDTypography fontSize="15px">{item?.start}</MDTypography>
              </Grid>
              <Grid item xs={12} sm={12} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Expiration Date & Time
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12} md={9}>
                <MDTypography fontSize="15px">{item?.end}</MDTypography>
              </Grid>
            </Grid>
            <MDTypography
              fontSize="15px"
              fontWeight="bold"
              mb={3}
              sx={{ textDecoration: "underline" }}
            >
              Results
            </MDTypography>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  User completed
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={2} md={1.5}>
                <MDTypography fontSize="15px">{result?.totalPlayers}</MDTypography>
              </Grid>
            </Grid>
            <Divider />
            {item?.isOptional ? (
              <Grid item xs={12} sm={12} my={6}>
                {item?.surveyQuestions.map((surveyQuestion, idx) => (
                  <MDBox key={`surveyQuestion-${idx.toString()}`} mb={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3} md={2.5}>
                        <MDTypography fontSize="15px" fontWeight="bold">
                          Question {idx + 1}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <MDTypography fontSize="15px">{surveyQuestion.question}</MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <MDBox mb={3}>
                          <Grid container spacing={2}>
                            {surveyQuestion.options.map((option, optionIdx) => (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                key={`survey-option-${optionIdx.toString()}`}
                              >
                                <MDBox mb={2}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={5}>
                                      <MDTypography fontSize="15px" fontWeight="bold">
                                        Option {optionIdx + 1}
                                      </MDTypography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} display="flex" alignItems="center">
                                      <MDTypography fontSize="15px">
                                        {option.optionText}
                                      </MDTypography>
                                    </Grid>
                                  </Grid>
                                </MDBox>
                              </Grid>
                            ))}
                          </Grid>
                        </MDBox>
                        <MDBox>
                          <Grid container spacing={2} mb={2}>
                            {surveyQuestion.options.map((option, optionIdx) => (
                              <Grid key={`result-${option.id}`} item xs={12} sm={6}>
                                <MDBox>
                                  <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} sm={4}>
                                      <MDTypography fontSize="15px">
                                        Option {optionIdx + 1}:
                                      </MDTypography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                      <MDProgress
                                        value={getPercent(option.questionId, option.id)}
                                        variant="gradient"
                                        label
                                      />
                                    </Grid>
                                  </Grid>
                                </MDBox>
                              </Grid>
                            ))}
                          </Grid>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                ))}
              </Grid>
            ) : (
              <MDBox>
                <MDTypography
                  fontSize="15px"
                  fontWeight="bold"
                  mb={3}
                  sx={{ textDecoration: "underline" }}
                >
                  User Responses
                </MDTypography>
                <UserResponseTable
                  data={result?.survey?.playSurvey}
                  viewResponse={handleViewResponse}
                />
              </MDBox>
            )}
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default SurveyResult;
