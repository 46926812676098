import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import Paper from "@mui/material/Paper";

import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import { TableHead } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";

import Badge from "@mui/material/Badge";

import MDSnackbar from "components/MDSnackbar";
import MDButton from "components/MDButton";

import { FaArrowDown, FaArrowUp, FaEdit, FaEye, FaTrash } from "react-icons/fa";

import ConfirmSnackBarContent from "layouts/common/components/ConfirmSnackbarContent";
import { dateToDateAndTime } from "utils/converter";

import { SortFilter } from "layouts/common/constants";

import {
  customTableHeader,
  StyledTableCell,
  StyledTableCellContent,
  StyledTableButtonGroups,
  StyledTableSortButton,
  StyledTableButton,
} from "layouts/common/styles";

const tableHeads = [
  {
    label: "Asset Type",
    alignment: "left",
    sort: false,
  },
  {
    label: "Title",
    alignment: "left",
    sort: true,
    sortFilter: SortFilter.Title,
  },
  {
    label: "Team",
    alignment: "left",
    sort: true,
    sortFilter: SortFilter.Team,
  },
  {
    label: "Sponsor/Team",
    alignment: "left",
    sort: true,
    sortFilter: SortFilter.Type,
  },
  {
    label: "Start Date",
    alignment: "right",
    sort: true,
    sortFilter: "",
  },
  {
    label: "Purchase count",
    alignment: "right",
    sort: false,
  },
  {
    label: "Claim count",
    alignment: "right",
    sort: false,
  },
  {
    label: "On hand",
    alignment: "right",
    sort: false,
  },
  {
    label: "Action",
    alignment: "right",
    sort: false,
  },
];
function AssetsTable({ data, deleteHandler, sortHandler }) {
  const navigate = useNavigate();

  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [items, setItems] = useState([]);
  const [sortFilter, setSortFilter] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const toggleSnackbar = () => {
    setConfirmModalShow((prev) => !prev);
  };

  useEffect(() => {
    const tempData = data.map((d) => {
      const temp = { ...d };
      const createdAtDateTime = dateToDateAndTime(new Date(d.createdAt));
      temp.createdAt = `${createdAtDateTime.date}  ${createdAtDateTime.time}`;
      return temp;
    });

    setItems(tempData);
  }, [data]);

  const handleSort = (sort, direction) => () => {
    setSortFilter(sort);
    setSortDirection(direction);
    sortHandler(sort, direction);
  };

  const handleView = (_id, isBonus) => {
    if (!isBonus) {
      navigate(`/assets/view/${_id}`);
    } else {
      navigate(`/assets/view-bonus/${_id}`);
    }
  };

  const handleEdit = (_id, isBonus) => {
    if (!isBonus) {
      navigate(`/assets/edit/${_id}`);
    } else {
      navigate(`/assets/edit-bonus/${_id}`);
    }
  };

  const handleDelete = (_id) => {
    setDeleteId(_id);
    setConfirmModalShow(true);
  };

  const confirmDelete = () => {
    if (deleteId !== "") {
      setConfirmModalShow(false);
      deleteHandler(deleteId);
      setDeleteId("");
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead sx={(theme) => customTableHeader(theme)}>
          <TableRow>
            {tableHeads.map((headerItem) => (
              <StyledTableCell align={headerItem.alignment}>
                <StyledTableCellContent
                  style={
                    headerItem.alignment === "right"
                      ? { justifyContent: "flex-end" }
                      : { justifyContent: "flex-start" }
                  }
                >
                  {headerItem.label}
                  {headerItem.sort && (
                    <StyledTableButtonGroups>
                      <StyledTableSortButton
                        type="button"
                        className={
                          sortFilter === headerItem.sortFilter && sortDirection === "ASC"
                            ? "active"
                            : ""
                        }
                        onClick={handleSort(headerItem.sortFilter, "ASC")}
                      >
                        <FaArrowUp />
                      </StyledTableSortButton>
                      <StyledTableSortButton
                        type="button"
                        className={
                          sortFilter === headerItem.sortFilter && sortDirection === "DESC"
                            ? "active"
                            : ""
                        }
                        onClick={handleSort(headerItem.sortFilter, "DESC")}
                      >
                        <FaArrowDown />
                      </StyledTableSortButton>
                    </StyledTableButtonGroups>
                  )}
                </StyledTableCellContent>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow key={row.id}>
              <StyledTableCell
                align="left"
                component="th"
                scope="row"
                onClick={() => handleView(row.id, row.isBonus)}
              >
                {row.isBonus ? (
                  <Badge color="secondary" badgeContent="bonus">
                    <StyledTableButton color="secondary">{row.category}</StyledTableButton>
                  </Badge>
                ) : (
                  <StyledTableButton color="secondary">{row.category}</StyledTableButton>
                )}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{ color: "#0076B2" }}
                onClick={() => handleView(row.id, row.isBonus)}
              >
                {row.title}
              </StyledTableCell>
              <StyledTableCell align="left">{row.team?.name}</StyledTableCell>
              <StyledTableCell align="left">{row.type}</StyledTableCell>
              <StyledTableCell align="left">{row.createdAt}</StyledTableCell>
              <StyledTableCell align="right">{row.purchaseCount}</StyledTableCell>
              <StyledTableCell align="right">{row.claimCount}</StyledTableCell>
              <StyledTableCell align="right">{row.totalCount - row.purchaseCount}</StyledTableCell>
              <StyledTableCell align="right">
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={() => handleView(row.id, row.isBonus)}
                >
                  <FaEye />
                </MDButton>
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={() => handleEdit(row.id, row.isBonus)}
                >
                  <FaEdit />
                </MDButton>
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={() => handleDelete(row.id)}
                >
                  <FaTrash />
                </MDButton>
              </StyledTableCell>
            </TableRow>
          ))}
          {data.length === 0 && (
            <TableRow>
              <StyledTableCell align="center" color="dark">
                No Content
              </StyledTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <MDSnackbar
        color="error"
        icon="notifications"
        title="Delete Team"
        dateTime=""
        content={
          <ConfirmSnackBarContent
            title="Are you sure to delete this data?"
            confirmHandler={confirmDelete}
          />
        }
        open={confirmModalShow}
        close={toggleSnackbar}
      />
    </TableContainer>
  );
}

AssetsTable.defaultProps = {
  data: [],
  deleteHandler: {},
  sortHandler: {},
};

// Typechecking props for the DashboardNavbar
AssetsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  deleteHandler: PropTypes.func,
  sortHandler: PropTypes.func,
};

export default AssetsTable;
