import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import MDBox from "components/MDBox";

import { setLoading } from "store/appSlice";
import { createPoll, updatePoll } from "store/slices/pollSlice";

import PollService from "services/PollService";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { PollType, UserRole } from "layouts/common/constants";

import SelectCategory from "./forms/SelectCategory";
import ImageDetailForm from "./forms/ImageDetailForm";
import ImageSummaryForm from "./forms/ImageSummaryForm";
import ToastrService from "../../../../services/ToastrService";

const formSwitcher = (
  screen,
  prevHandler,
  nextHandler,
  saveHandler,
  handleCategorySelection,
  changeHandler,
  typeForm,
  newPoll,
) => {
  switch (screen) {
    case 1:
      return <SelectCategory nextHandler={handleCategorySelection} typeForm={typeForm} />;
    case 2:
      if (typeForm.type === PollType.Image)
        return (
          <ImageDetailForm
            prevHandler={prevHandler}
            nextHandler={nextHandler}
            changeHandler={changeHandler}
            typeForm={typeForm}
            item={newPoll}
          />
        );

      return <div>&nbsp;</div>;

    case 3:
      if (typeForm.type === PollType.Image)
        return (
          <ImageSummaryForm
            prevHandler={prevHandler}
            saveHandler={saveHandler}
            typeForm={typeForm}
            item={newPoll}
          />
        );

      return <div>&nbsp;</div>;

    default:
      return <SelectCategory nextHandler={handleCategorySelection} />;
  }
};

function AddPoll() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminProfile = useSelector((state) => state.users.profile);
  const teams = useSelector((state) => state.teams);

  const { id } = useParams();

  const [screen, setScreen] = useState(1);
  const [typeForm, setTypeForm] = useState(null);
  const [newPoll, setNewPoll] = useState(null);

  const getPoll = (_id) => {
    dispatch(setLoading(true));
    PollService.get(_id)
      .then((response) => {
        setNewPoll(response.data);
        setTypeForm({
          team: response.data.team,
          title: response.data.title,
          description: response.data.description,
          type: response.data.optionType,
          match: response.data.match,
        });
        dispatch(setLoading(false));
        setScreen(screen);
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load poll data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      getPoll(id);
    } else {
      setNewPoll(null);
    }
  }, [id]);

  useEffect(() => {
    if (adminProfile.role === UserRole.TeamAdmin) {
      const tmp = teams.find((t) => t.id === adminProfile.teamId);
      setTypeForm({ ...typeForm, team: tmp });
    }
  }, [adminProfile]);

  const handleCategorySelection = (_typeForm) => {
    setTypeForm(_typeForm);
    setScreen(screen + 1);
  };

  const handleChange = (data) => {
    setNewPoll(data);
    return true;
  };

  const handleSave = async (isDraft = false) => {
    const newData = { ...newPoll };
    const dataId = newData.id;
    newData.teamId = typeForm.team.id;
    newData.title = typeForm.title;
    newData.description = typeForm.description;
    newData.optionType = typeForm.type;
    newData.isDraft = isDraft;
    if (newData.asset) {
      newData.assetId = newData.asset?.id;
    }
    if (newData.sponsor) {
      newData.sponsorId = newData.sponsor?.id;
    }

    const newOptions = await Promise.all(
      newPoll.options.map(async (option) => {
        const temp = { ...option };
        if (option.file) {
          const formData = new FormData();
          formData.append("file", option.file);
          await PollService.upload(formData)
            .then((res) => {
              temp.details = res.data.url;
            })
            .catch(() => {
              ToastrService.error("Failed to upload new poll option");
            });
        }
        delete temp.file;
        return temp;
      }),
    );

    newData.matchId = typeForm.match.id;
    if (!newData.participantType)
      // When create new data;
      newData.participantType = 0;

    newData.options = newOptions;

    ["id", "team", "match", "sponsor", "asset", "participants"].forEach((key) => {
      delete newData[key];
    });

    dispatch(setLoading(true));
    if (dataId === null) {
      dispatch(createPoll({ data: newData }))
        .unwrap()
        .then((data) => {
          ToastrService.success("Successfully added new poll");
          dispatch(setLoading(false));
          if (data.optionType === PollType.Image) navigate(`/polls/view/image/${data.id}`);
        })
        .catch((e) => {
          ToastrService.error(e.response?.data?.message?.message || "Failed to add poll");
          dispatch(setLoading(false));
        });
    } else {
      // remove keys
      ["createdAt", "updatedAt", "type"].forEach((key) => {
        delete newData[key];
      });

      dispatch(updatePoll({ id: dataId, data: newData }))
        .unwrap()
        .then((data) => {
          ToastrService.success("Successfully updated poll");
          dispatch(setLoading(false));
          if (data.success === true) navigate("/polls");
        })
        .catch((e) => {
          ToastrService.error(e.response?.data?.message?.message || "Failed to update poll");
          dispatch(setLoading(false));
        });
    }
  };

  const nextScreen = () => {
    setScreen(screen + 1);
  };

  const prevScreen = () => {
    setScreen(screen - 1);
  };

  return (
    <DashboardLayout>
      <MDBox my={3}>
        {formSwitcher(
          screen,
          prevScreen,
          nextScreen,
          handleSave,
          handleCategorySelection,
          handleChange,
          typeForm,
          newPoll,
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default AddPoll;
