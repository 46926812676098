import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Switch } from "@mui/material";

import MDButton from "components/MDButton";
// import MDSnackbar from "components/MDSnackbar";

import { customTableHeader } from "layouts/common/styles";
// import ConfirmSnackBarContent from "layouts/common/components/ConfirmSnackbarContent";

import { FaEye, FaTrash } from "react-icons/fa";

import { dateToDateAndTime } from "utils/converter";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontSize: "13px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "13px",
    fontWeight: "bold",
  },
}));

function AdminTable({ data }) {
  //   const [show, setShow] = useState(false);
  //   const toggleSnackbar = () => setShow(!show);

  const [items, setItems] = useState([]);

  useEffect(() => {
    const tempData = data.map((d) => {
      const temp = { ...d };
      const createdAtDateTime = dateToDateAndTime(new Date(d.createdAt));
      temp.createdAt = `${createdAtDateTime.date}  ${createdAtDateTime.time}`;
      return temp;
    });

    setItems(tempData);
  }, [data]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table" variant="">
        <TableHead sx={(theme) => customTableHeader(theme)}>
          <TableRow>
            <StyledTableCell align="left">Name</StyledTableCell>
            <StyledTableCell align="left">Level</StyledTableCell>
            <StyledTableCell align="left">Email</StyledTableCell>
            <StyledTableCell align="left">Registration Date</StyledTableCell>
            <StyledTableCell align="center">Activate</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow key={row.id}>
              <StyledTableCell
                align="left"
                component="th"
                scope="row"
                sx={{ color: "#0076B2", cursor: "pointer" }}
              >
                {row.adminFirstName}&nbsp;{row.adminLastName}
              </StyledTableCell>
              <StyledTableCell align="left">{row.role}</StyledTableCell>
              <StyledTableCell align="left">{row.email}</StyledTableCell>
              <StyledTableCell align="left">{row.createdAt}</StyledTableCell>
              <StyledTableCell align="center">
                <Switch
                  size="small"
                  checked={row.isActivated}
                  inputProps={{ "aria-label": "ant design" }}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                >
                  <FaEye />
                </MDButton>
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                >
                  <FaTrash />
                </MDButton>
              </StyledTableCell>
            </TableRow>
          ))}
          {items.length === 0 && (
            <TableRow>
              <StyledTableCell colSpan={6} align="center" color="dark">
                There is no content.
              </StyledTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {/* <MDSnackbar
        color="error"
        icon="notifications"
        title="Delete Team"
        dateTime=""
        content={
          <ConfirmSnackBarContent
            title="Are you sure to delete this data?"
            confirmHandler={confirmDelete}
          />
        }
        open={show}
        close={toggleSnackbar}
      /> */}
    </TableContainer>
  );
}

AdminTable.defaultProps = {
  data: [],
};

AdminTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
};

export default AdminTable;
