const GameTabs = {
  Upcoming: "Upcoming",
  Ongoing: "Ongoing",
  Past: "Past",
  Draft: "Draft",
};

const PollTabs = {
  Upcoming: "Upcoming",
  Ongoing: "Ongoing",
  Past: "Past",
  Draft: "Draft",
};

const ChallengeTabs = {
  Upcoming: "Upcoming",
  Ongoing: "Ongoing",
  Past: "Past",
  Draft: "Draft",
};

const AssetTabs = {
  Live: "Live",
  Upcoming: "Upcoming",
  Draft: "Draft",
  Expired: "Expired",
};

const EventTabs = {
  Upcoming: "Upcoming",
  Ongoing: "Ongoing",
  Draft: "Draft",
  Expired: "Expired",
};

const ActivationTabs = {
  Upcoming: "Upcoming",
  Ongoing: "Ongoing",
  Past: "Past",
};

const PollsState = {
  MAIN: "MAIN",
  ADD: "ADD",
};

const SponsorsState = {
  MAIN: "MAIN",
  ADD: "ADD",
  ACTIVATION: "ACTIVATION",
  VIEW: "VIEW",
};

const ChallengeTypes = {
  CheckIn: "CheckIn",
  Survey: "Survey",
  MultiReferrer: "MultiReferrer",
  MultiCheckIn: "MultiCheckIn",
  SurveyMultichoice: "Survey(MultiChoice)",
  SurveyFreeText: "Survey(FreeText)",
};

const GameTypes = {
  Trivia: "Trivia",
  Prediction: "Prediction",
  Milestone: "Milestone",
  MiniGame: "MiniGame",
};

const PredictionType = {
  Score: "Score",
  WinningOutCome: "WinningOutCome",
};

const TriviaType = {
  MultiChoiceSingleAnswer: "MultiChoiceSingleAnswer",
  WhoAmI: "WhoAmI",
};

const SponsorCategory = {
  FoodDining: "FoodDining",
  Fashion: "Fashion",
  Retail: "Retail",
  Auto: "Auto",
  Insurance: "Insurance",
  HealthWellness: "Health & Wellness",
};

const PollType = {
  // Audio: "Audio",
  Image: "Image",
  // Text: "Text",
};

const AssetClaimType = {
  Digital: "Digital-claim online",
  Physical: "Physical - claim in store / at merchant",
};

const SponsorActivationTypes = {
  Trivia: "Trivia",
  Prediction: "Prediction",
  Milestone: "Milestone",
  CheckIn: "CheckIn",
  Survey: "Survey",
  Poll: "Poll",
};

const SortFilter = {
  Title: "title",
  Team: "team",
  Team_Sponsor: "team_sponsor",
  Type: "type",
  Start: "start",
};

const UserRole = {
  SuperAdmin: "SuperAdmin",
  TeamAdmin: "TeamAdmin",
  Fan: "Fan",
};

const MiniGameType = {
  0: "Baseball Throw",
  1: "Cricket Throw",
  2: "Basketball Throw",
  3: "Tennis Throw",
  4: "Shell Tyre Throw",
  5: "BEIN Soccer Kick",
  6: "Baseball Boy",
  7: "Flyball (Basketball)",
  8: "Flyball (Baseball)",
  9: "Flyball (Cricket)",
  10: "Flyball (Netball)",
  11: "Flyball (Soccer)",
  12: "Flyball (Footy)",
  13: "Flyball (Ice Hockey)",
};

const BoosterType = {
  0: "Team",
  1: "Sponsor",
};

const UnlockRewardType = {
  0: "GameCoins",
  1: "GameLives",
  2: "GameBooster",
};

const RewardActionType = {
  0: "Watch video",
  1: "Complete survey",
};
const RewardActionRewardItemType = {
  0: "Game Lives",
  1: "In Game Coins",
};
const MarketplaceItemType = {
  0: "Team",
  1: "Sponsor",
};
const MarketplaceItemSubType = {
  0: "Tokens",
  1: "Kudos",
  2: "Game Lives",
};

const MiniGameSportType = {
  CricketBall: "CricketBall",
  BaseBall: "BaseBall",
};

const CriteriaTypes = [
  {
    label: "User who have completed a specific challenge",
    value: 0,
  },
  {
    label: "User who have completed a specific poll",
    value: 1,
  },
  {
    label: "Users who have been inactive",
    value: 2,
  },
  {
    label: "User who have a certain favourite player.",
    value: 3,
  },
  {
    label: "Users with tokens in balance",
    value: 4,
  },
  {
    label: "User who have played specific game",
    value: 5,
  },
];

export {
  AssetClaimType,
  GameTabs,
  ChallengeTabs,
  PollTabs,
  AssetTabs,
  EventTabs,
  ActivationTabs,
  PollsState,
  SponsorsState,
  ChallengeTypes,
  GameTypes,
  PredictionType,
  TriviaType,
  SponsorCategory,
  PollType,
  SponsorActivationTypes,
  SortFilter,
  UserRole,
  MiniGameType,
  MiniGameSportType,
  CriteriaTypes,
  UnlockRewardType,
  BoosterType,
  RewardActionType,
  RewardActionRewardItemType,
  MarketplaceItemType,
  MarketplaceItemSubType,
};
