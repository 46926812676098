import { PollTabs } from "layouts/common/constants";
import http from "../apis/http-common";

const retrive = (filter, search, page, rowsPerPage, sortFilter = "", sortDirection = "") => {
  const skip = page * rowsPerPage;
  const sortParams =
    sortFilter !== "" && sortDirection !== ""
      ? `&sort=${sortFilter}&direction=${sortDirection}`
      : "";
  if (filter === PollTabs.Draft) {
    return http.get(
      `/poll/list?skip=${skip}&take=${rowsPerPage}&search=${search}&isDraft=true${sortParams}`,
    );
  }
  return http.get(
    `/poll/list?skip=${skip}&take=${rowsPerPage}&search=${search}&filter=${filter}&isDraft=false${sortParams}`,
  );
};

const get = (id) => http.get(`/poll/${id}`);

const getResult = (id) => http.get(`/poll/result/${id}`);

const create = (data) => http.post("/poll", data);

const update = (id, data) => http.put(`/poll/${id}`, data);

const remove = (id) => http.delete(`/poll/${id}`);

const upload = (formData) => http.post("/upload/poll", formData);

const PollService = {
  retrive,
  get,
  getResult,
  create,
  update,
  remove,
  upload,
};

export default PollService;
