import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import { Autocomplete, Divider, Grid, TextField, Typography } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";

import MatchesService from "services/MatchService";
import { setLoading } from "store/appSlice";
import AddIcon from "@mui/icons-material/Add";
import { dateToDateAndTime, dateAndTimeToDate } from "utils/converter";

import eventTypeDummies from "dummies/eventTypeDummies";
import { validateCoordinates } from "utils/validation";
import ToastrService from "services/ToastrService";
import CloseIcon from "@mui/icons-material/Close";
import { UserRole } from "layouts/common/constants";
import FormField from "../../FormField";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .MuiOutlinedInput-root .MuiAutocomplete-input`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
    padding: 0,
    width: "35px",
  },
  [`& .MuiOutlinedInput-root`]: {
    padding: "0px",
    paddingLeft: "10px",
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

function EventDetail({ item, nextHandler, changeHandler }) {
  const initialEventState = {
    id: null,
    team: null,
    type: "",
    title: "",
    homeTeam: null,
    awayTeam: null,
    venueTitle: "",
    venueAddress: "",
    venueGoogleCoordinates: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    teamOne: "",
    teamOneImage: "",
    teamTwo: "",
    teamTwoImage: "",
    recommendedImage: "",
    siteName: "",
    gallaryImage: [],
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const adminProfile = useSelector((state) => state.users.profile);
  const teams = useSelector((state) => state.teams);

  const [event, setEvent] = useState(initialEventState);
  const [validationMsg, setValidationMsg] = useState(initialEventState);
  const teamOneImageRef = useRef();
  const teamTwoImageRef = useRef();
  const recommendedImageRef = useRef();
  const gallaryImageRef = useRef();

  useEffect(() => {
    if (item === null) {
      setEvent(initialEventState);
    } else {
      // Divide Datetime to Date and Time.

      const newItem = { ...item };
      const startDateTime = dateToDateAndTime(new Date(newItem.start));
      const endDateTime = dateToDateAndTime(new Date(newItem.end));
      newItem.startDate = startDateTime.date;
      newItem.startTime = startDateTime.time;
      newItem.endDate = endDateTime.date;
      newItem.endTime = endDateTime.time;

      delete newItem.start;
      delete newItem.end;
      setEvent(newItem);
    }
  }, [item]);
  const validate = () => {
    let validated = true;
    let errorMsg = validationMsg;
    const keys = Object.keys(initialEventState);
    for (let i = 0; i < keys.length; i += 1) {
      let msg = "";
      if ((event[keys[i]] === "" || !event[keys[i]]) && keys[i] !== "id" && keys[i] !== "team") {
        msg = "Field should not be left empty";
        validated = false;
      }
      if (
        (event[keys[i]] === "" || !event[keys[i]]) &&
        keys[i] === "team" &&
        adminProfile.role === UserRole.SuperAdmin
      ) {
        msg = "Field should not be left empty";
        validated = false;
      }

      if (keys[i] === "venueGoogleCoordinates" && !validateCoordinates(event[keys[i]])) {
        msg = "Field should not be right format";
        validated = false;
      }

      errorMsg = { ...errorMsg, [keys[i]]: msg };
    }
    setValidationMsg(errorMsg);

    return validated;
  };

  const handleSubmitChange = (field, value) => {
    setEvent({ ...event, [field]: value });
  };
  const handleImageOneFileChange = (events) => {
    const [file] = events.target.files;
    setEvent({ ...event, teamOneImage: file });
  };
  const handleImageTwoFileChange = (events) => {
    const [file] = events.target.files;
    setEvent({ ...event, teamTwoImage: file });
  };
  const handlerecommendedFileChange = (events) => {
    const [file] = events.target.files;
    setEvent({ ...event, recommendedImage: file });
  };

  const handleGallaryFileChange = (events) => {
    let updatedEvent = [];
    const files = events?.target?.files;
    if (typeof event?.gallaryImage === "string") {
      Object.values(files)?.forEach((o) => {
        updatedEvent.push(o);
      });
    } else {
      updatedEvent = [...event?.gallaryImage];
      Object.values(files)?.forEach((o) => {
        updatedEvent.push(o);
      });
    }
    setEvent((prev) => ({
      ...prev,
      gallaryImage: updatedEvent,
    }));
  };

  async function getEvent(_id) {
    let temp = null;
    dispatch(setLoading(true));
    await MatchesService.get(_id)
      .then((response) => {
        temp = response.data.match;
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
    return temp;
  }

  const handleReset = async () => {
    if (!event.id) {
      setEvent(initialEventState);
    } else {
      const data = await getEvent(event.id);
      const newItem = { ...data };

      const startDateTime = dateToDateAndTime(new Date(newItem.start));
      const endDateTime = dateToDateAndTime(new Date(newItem.end));
      newItem.startDate = startDateTime.date;
      newItem.startTime = startDateTime.time;
      newItem.endDate = endDateTime.date;
      newItem.endTime = endDateTime.time;

      delete newItem.start;
      delete newItem.end;
      setEvent(newItem);
    }
  };

  const handlerNext = () => {
    if (!validate()) {
      ToastrService.warning("Please input fields correctly");
      return;
    }
    const temp = event;

    temp.start = dateAndTimeToDate(temp.startDate, temp.startTime).toISOString();
    temp.end = dateAndTimeToDate(temp.endDate, temp.endTime).toISOString();
    ["startDate", "startTime", "endDate", "endTime"].forEach((d) => {
      delete temp[d];
    });

    if (changeHandler(temp)) {
      nextHandler();
    }
  };

  const handleBack = () => {
    navigate("/matches");
  };

  const handleRemoveImage = (index) => {
    const updatedGalleryImage = [...event.gallaryImage];
    updatedGalleryImage.splice(index, 1);
    setEvent((prevEvent) => ({
      ...prevEvent,
      gallaryImage: updatedGalleryImage,
    }));
  };

  const gallery =
    typeof event?.gallaryImage === "string" &&
    event?.gallaryImage.split(",")?.map((elm) => {
      const elem = elm.split("/").pop();
      return elem;
    });

  let gallayList = [];
  let isRemove = false;
  if (event?.gallaryImage) {
    if (typeof event?.gallaryImage === "string") {
      gallayList = event?.gallaryImage?.split(",");
    } else {
      isRemove = event?.gallaryImage?.length > 1;
      event?.gallaryImage?.forEach((o) => {
        const fileLoadUrl = URL.createObjectURL(o);
        gallayList.push(fileLoadUrl);
      });
    }
  }

  return (
    <MDBox my={3}>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "150px" }}>
          {event.id !== null ? "Edit Event" : "Add Event"}
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handleBack()}>
            Back
          </MDButton>
          <MDButton size="small" onClick={() => handleReset()}>
            Reset
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox sx={(theme) => customFormContainer(theme)}>
        {adminProfile.role === UserRole.SuperAdmin && (
          <Grid container spacing={2} mb={4} alignItems="center">
            <Grid item xs={12} sm={3} md={3}>
              <MDTypography fontSize="15px">Select Team</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <StyledAutocomplete
                value={event.team}
                options={teams}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => handleSubmitChange("team", v)}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
                placeholder="Choose Team"
                error={validationMsg.team !== ""}
              />
            </Grid>
          </Grid>
        )}
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Sponsor/Team</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <StyledAutocomplete
              value={event.type}
              options={eventTypeDummies}
              onChange={(e, v) => handleSubmitChange("type", v)}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose Anyone"
              error={validationMsg.type !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Match title</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormField
              value={event.title}
              onChange={(e) => handleSubmitChange("title", e.target.value)}
              error={validationMsg.title !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Team / Player #1</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {/* <StyledAutocomplete
              value={event.homeTeam}
              options={teams}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => handleSubmitChange("homeTeam", v)}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose Team"
              error={validationMsg.homeTeam !== "" && validationMsg.homeTeam !== null}
            /> */}
            <FormField
              value={event.teamOne}
              onChange={(e) => handleSubmitChange("teamOne", e.target.value)}
              error={validationMsg.teamOne !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Team / Player #1 image</MDTypography>
          </Grid>
          <Grid item xs={8}>
            <MDButton
              // variant="contained"
              component="label"
              color="white"
              onClick={() => {
                teamOneImageRef.current.value = null;
              }}
              sx={{ marginRight: "20px" }}
              error="true"
            >
              <Grid item xs spacing={2}>
                <AddIcon style={{ width: "50px", height: "50px" }} />
                <Typography
                  fontSize="15px"
                  color="#2196f3"
                  style={{
                    display: "flex",
                    width: "135px",
                    wordBreak: "break-word",
                    justifyContent: "center",
                  }}
                >
                  {event?.teamOneImage === ""
                    ? "Upload"
                    : typeof event?.teamOneImage === "object"
                    ? event?.teamOneImage?.name
                    : event?.teamOneImage?.split("/")?.pop()}
                </Typography>
              </Grid>

              <input
                ref={teamOneImageRef}
                type="file"
                hidden
                name="teamOneImage"
                onChange={handleImageOneFileChange}
                accept=".jpg,.jpeg,.png"
              />
            </MDButton>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Team / Player 2</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {/* <StyledAutocomplete
              value={event.awayTeam}
              options={teams}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => handleSubmitChange("awayTeam", v)}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose Team"
              error={validationMsg.awayTeam !== "" && validationMsg.awayTeam !== null}
            /> */}
            <FormField
              value={event.teamTwo}
              onChange={(e) => handleSubmitChange("teamTwo", e.target.value)}
              error={validationMsg.teamTwo !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Team / Player #2 image</MDTypography>
          </Grid>{" "}
          <Grid item xs={8}>
            <MDButton
              variant="contained"
              component="label"
              color="white"
              onClick={() => {
                teamTwoImageRef.current.value = null;
              }}
              sx={{ marginRight: "20px" }}
              error="true"
            >
              <Grid item xs spacing={2}>
                <AddIcon style={{ width: "50px", height: "50px" }} />
                <Typography
                  fontSize="15px"
                  color="#2196f3"
                  style={{
                    display: "flex",
                    width: "135px",
                    wordBreak: "break-word",
                    justifyContent: "center",
                  }}
                >
                  {event?.teamTwoImage === ""
                    ? "Upload"
                    : typeof event?.teamTwoImage === "object"
                    ? event?.teamTwoImage?.name
                    : event?.teamTwoImage?.split("/")?.pop()}
                  {/* {event?.teamTwoImage ? event?.teamTwoImage?.name : "Upload"} */}
                </Typography>
              </Grid>
              <input
                ref={teamTwoImageRef}
                type="file"
                hidden
                name="logo"
                onChange={handleImageTwoFileChange}
                accept=".jpg,.jpeg,.png"
              />
            </MDButton>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Home team</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <StyledAutocomplete
              value={event.homeTeam}
              options={teams}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => handleSubmitChange("homeTeam", v)}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose Team"
              error={validationMsg.homeTeam !== "" && validationMsg.homeTeam !== null}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Away Team</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <StyledAutocomplete
              value={event.awayTeam}
              options={teams}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => handleSubmitChange("awayTeam", v)}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose Team"
              error={validationMsg.awayTeam !== "" && validationMsg.awayTeam !== null}
            />
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
          Add features Image
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px"> Recommended Image </MDTypography>
          </Grid>{" "}
          <Grid item xs={8}>
            <MDButton
              component="label"
              color="white"
              onClick={() => {
                recommendedImageRef.current.value = null;
              }}
              sx={{ marginRight: "20px" }}
              error="true"
            >
              <Grid item xs spacing={2}>
                <AddIcon style={{ width: "50px", height: "50px" }} />
                <Typography
                  fontSize="15px"
                  color="#2196f3"
                  style={{
                    display: "flex",
                    width: "135px",
                    wordBreak: "break-word",
                    justifyContent: "center",
                  }}
                >
                  {event?.recommendedImage === ""
                    ? "Upload"
                    : typeof event?.recommendedImage === "object"
                    ? event?.recommendedImage?.name
                    : event?.recommendedImage?.split("/")?.pop()}
                  {/* {event?.recommendedImage ? event?.recommendedImage?.name : "Upload"} */}
                </Typography>
              </Grid>
              <input
                ref={recommendedImageRef}
                type="file"
                hidden
                name="recommendedImage"
                onChange={handlerecommendedFileChange}
                accept=".jpg,.jpeg,.png"
              />
            </MDButton>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
          Add Image gallery
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px"> Gallery title</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormField
              value={event.siteName}
              onChange={(e) => handleSubmitChange("siteName", e.target.value)}
              error={validationMsg.siteName !== ""}
            />
          </Grid>
          <Grid item xs={8} sm={3}>
            <MDButton
              component="label"
              color="white"
              onClick={() => {
                gallaryImageRef.current.value = null;
              }}
              sx={{ marginRight: "20px" }}
              error="true"
            >
              <Grid item xs spacing={2}>
                <AddIcon style={{ width: "50px", height: "50px" }} />
                <Typography
                  fontSize="15px"
                  color="#2196f3"
                  style={{
                    display: "flex",
                    width: "135px",
                    justifyContent: "center",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {typeof event?.gallaryImage === "string"
                    ? gallery.map((ele) => ele)
                    : event?.gallaryImage?.[0]
                    ? Object.values(event?.gallaryImage)
                        .map((file) => file.name)
                        .join(", ")
                    : "Upload"}
                </Typography>
              </Grid>
              <input
                ref={gallaryImageRef}
                type="file"
                hidden
                name="gallaryImage"
                multiple
                onChange={handleGallaryFileChange}
                accept=".jpg,.jpeg,.png"
              />
            </MDButton>
          </Grid>
          <Grid item xs={12} md={8} sm={12} display="flex" flexWrap="wrap">
            {event?.gallaryImage &&
              gallayList.map((elm, index) => (
                <div
                  key={index}
                  style={{
                    position: "relative",
                    width: "90px",
                    height: "50px",
                    marginRight: "14px",
                    marginBottom: "14px",
                  }}
                >
                  <MDBox
                    component="img"
                    src={elm}
                    alt="asset image"
                    shadow="md"
                    width="90px"
                    height="50px"
                    // style={{ marginRight: "10px", marginBottom: "10px" }}
                  />

                  {isRemove && (
                    <CloseIcon
                      color="error"
                      fontSize="small"
                      style={{
                        position: "absolute",
                        top: "-9px",
                        right: "-9px",
                        backgroundColor: "white",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleRemoveImage(index)}
                    />
                  )}
                </div>
              ))}
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Location
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Venue title</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormField
              value={event.venueTitle}
              onChange={(e) => handleSubmitChange("venueTitle", e.target.value)}
              error={validationMsg.venueTitle !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Venue address</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormField
              value={event.venueAddress}
              onChange={(e) => handleSubmitChange("venueAddress", e.target.value)}
              error={validationMsg.venueAddress !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">
              Venue Google coordinates
              <br />
              (lat, lng) ex: 24,45
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormField
              value={event.venueGoogleCoordinates}
              onChange={(e) => handleSubmitChange("venueGoogleCoordinates", e.target.value)}
              error={validationMsg.venueGoogleCoordinates !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <MDTypography fontSize="15px">Start Date & Time</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="date"
              label=""
              value={event.startDate}
              onChange={(e) => handleSubmitChange("startDate", e.target.value)}
              error={validationMsg.startDate !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="time"
              label=""
              value={event.startTime}
              onChange={(e) => handleSubmitChange("startTime", e.target.value)}
              error={validationMsg.startTime !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <MDTypography fontSize="15px">Expiration Date & Time</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="date"
              label=""
              value={event.endDate}
              onChange={(e) => handleSubmitChange("endDate", e.target.value)}
              error={validationMsg.endDate !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="time"
              label=""
              value={event.endTime}
              onChange={(e) => handleSubmitChange("endTime", e.target.value)}
              error={validationMsg.endTime !== ""}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Grid container justifyContent="flex-end">
        <MDButton color="secondary" size="large" onClick={() => handlerNext()}>
          Next
        </MDButton>
      </Grid>
    </MDBox>
  );
}

EventDetail.defaultProps = {
  item: null,
  nextHandler: {},
  changeHandler: {},
};

EventDetail.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  nextHandler: PropTypes.func,
  changeHandler: PropTypes.func,
};

export default EventDetail;
