import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDProgress from "components/MDProgress";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";

import { GameTypes } from "layouts/common/constants";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import ToastrService from "services/ToastrService";
import GameService from "services/GamesService";
import { setLoading } from "store/appSlice";

import { dateToDateAndTime } from "utils/converter";

import WinnerTablePrediction from "../WinnerTablePrediction";

function ViewPredictionResult() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [result, setResult] = useState(null);
  const [data, setData] = useState(null);

  const getResult = (_id) => {
    dispatch(setLoading(true));
    GameService.getResult(GameTypes.Prediction, _id)
      .then((response) => {
        const temp = { ...response.data };
        // calcualtion popular predictions.
        const predictions = temp.playPrediction.map(
          (d) => `${d.mainPredictScore}-${d.oppositionPredictScore}`,
        );
        const predictFrequency = {};
        let popularPredictions = [];
        predictions.forEach((d) => {
          if (predictFrequency[d]) {
            predictFrequency[d] += 1;
          } else {
            predictFrequency[d] = 1;
          }
        });

        const keys = Object.keys(predictFrequency);
        const values = Object.values(predictFrequency);

        for (let i = 0; i < keys.length; i += 1) {
          popularPredictions.push({ predict: keys[i], count: values[i] });
        }

        popularPredictions = popularPredictions
          .sort((a, b) => parseFloat(b.count) - parseFloat(a.count))
          .slice(0, 5)
          .map((d) => {
            const predictData = { ...d };
            predictData.percent = Math.ceil((predictData.count / predictions.length) * 100);
            return predictData;
          });

        temp.popularPredictions = popularPredictions;

        setResult(temp);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load predict result.");
        dispatch(setLoading(false));
      });
  };

  const getGame = (_id) => {
    dispatch(setLoading(true));
    GameService.get(_id, GameTypes.Prediction)
      .then((response) => {
        const temp = { ...response.data };

        const startDateTime = dateToDateAndTime(new Date(temp.start));
        const endDateTime = dateToDateAndTime(new Date(temp.end));

        temp.start = `${startDateTime.date}  ${startDateTime.time}`;
        temp.end = `${endDateTime.date}  ${endDateTime.time}`;

        setData(temp);
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      getResult(id);
      getGame(id);
    }
  }, [id]);

  const handleBack = () => {
    navigate(`/game/view-prediction/${id}`);
  };
  return (
    <DashboardLayout>
      <MDBox my={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "250px" }}>
            Prediction - Results
          </MDTypography>
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDButton size="small" onClick={() => handleBack()}>
              Back
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox sx={(theme) => customFormContainer(theme)}>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Details
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.team?.name}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Prediction Type
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.predictionType}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Prediction Title
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Prediction Description
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.description}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Start Date & Time
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={8.5}>
              <MDTypography fontSize="15px">{data?.start}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Expiration Date & Time
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={8.5}>
              <MDTypography fontSize="15px">{data?.end}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Results
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Prediction Count
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{result?.playPrediction?.length}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Final Score
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">
                {result?.resultMain}-{result?.resultOpposition}
              </MDTypography>
            </Grid>
          </Grid>
          <MDBox mb={3}>
            <MDTypography
              fontSize="15px"
              fontWeight="bold"
              mb={3}
              sx={{ textDecoration: "underline" }}
            >
              Popular Predictions
            </MDTypography>

            {result?.popularPredictions?.map((item, idx) => (
              <Grid
                key={`prediction-result-${idx.toString()}`}
                container
                spacing={1}
                mb={1}
                alignItems="center"
              >
                <Grid item xs={2} sm={1}>
                  <MDTypography fontSize="15px">{item.predict}:</MDTypography>
                </Grid>
                <Grid item xs={10} sm={11}>
                  <MDProgress value={Number(item.percent)} variant="gradient" label />
                </Grid>
              </Grid>
            ))}
          </MDBox>
          <MDBox mb={3}>
            <MDTypography
              fontSize="15px"
              fontWeight="bold"
              mb={3}
              sx={{ textDecoration: "underline" }}
            >
              Winners
            </MDTypography>
            <WinnerTablePrediction data={result?.playPrediction.sort((a, b) => a.rank - b.rank)} />
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default ViewPredictionResult;
