import TeamsService from "services/TeamService";
import ToastrService from "services/ToastrService";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = [];

export const retriveTeams = createAsyncThunk("/team/retrive", async () => {
  const res = await TeamsService.getAll();
  return res.data;
});

export const createTeam = createAsyncThunk("/team/create", async (data) => {
  try {
    const res = await TeamsService.create(data);
    return res.data;
  } catch (e) {
    ToastrService.error(e.response?.data?.message?.message || "Failed to create new team");
    throw e;
  }
});

export const updateTeam = createAsyncThunk("/team/update", async ({ id, data }) => {
  try {
    const res = await TeamsService.update(id, data);
    return res.data;
  } catch (e) {
    ToastrService.error(e.response?.data?.message?.message || "Failed to update new team");
    throw e;
  }
});

export const deleteTeam = createAsyncThunk("/team/delete", async (id) => {
  await TeamsService.remove(id);
  return { id };
});

export const activateTeam = createAsyncThunk("/team/activate", async (data) => {
  const res = await TeamsService.activate(data.id, data.active);
  return res.data;
});

export const createTeamToken = createAsyncThunk("/team/create/token", async (data) => {
  const res = await TeamsService.createToken(data);
  return res.data;
});

export const updateTeamToken = createAsyncThunk("/team/update/token", async ({ id, data }) => {
  const res = await TeamsService.updateToken(id, data);
  return res.data;
});

export const deleteTeamToken = createAsyncThunk("/team/delete/token", async (id) => {
  await TeamsService.removeToken(id);
  return { id };
});

const teamSlice = createSlice({
  name: "team",
  initialState,
  extraReducers: {
    [createTeam.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
    [retriveTeams.fulfilled]: (state, action) => [...action.payload],
    [updateTeam.fulfilled]: (state, action) => {
      const index = state.findIndex((team) => team.id === action.payload.id);
      state[index] = {
        ...state[index],
        ...action.payload,
      };
    },
    [deleteTeam.fulfilled]: (state, action) => {
      const index = state.findIndex(({ id }) => id === action.payload.id);
      state.splice(index, 1);
    },
    [activateTeam.fulfilled]: (state, action) => {
      const index = state.findIndex((team) => team.id === action.payload.id);
      state[index] = {
        ...state[index],
        ...action.payload,
      };
    },
  },
});
const { reducer } = teamSlice;
export default reducer;
