import http from "../apis/http-common";

const retrive = (page, rowsPerPage, search) => {
  const skip = page * rowsPerPage;

  return http.get(`/sports?skip=${skip}&take=${rowsPerPage}&search=${search}`);
};

const get = (id) => http.get(`/sports/${id}`);

const create = (data) => http.post("/sports", data);

const update = (id, data) => http.put(`/sports/${id}`, data);

const remove = (id) => http.delete(`/sports/${id}`);

const SportsService = {
  retrive,
  get,
  create,
  update,
  remove,
};

export default SportsService;
