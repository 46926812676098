import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";

import { Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";
import { UserRole } from "layouts/common/constants";
import { dateToDateAndTime } from "utils/converter";

function PredictionSummaryForm({ item, prevHandler, saveHandler }) {
  const adminProfile = useSelector((state) => state.users.profile);
  const [data, setData] = useState(null);

  useEffect(() => {
    const temp = { ...item };

    const startDateTime = dateToDateAndTime(new Date(temp.start));
    const endDateTime = dateToDateAndTime(new Date(temp.end));

    temp.start = `${startDateTime.date}  ${startDateTime.time}`;
    temp.end = `${endDateTime.date}  ${endDateTime.time}`;

    setData(temp);
  }, [item]);

  const handlePrev = () => {
    prevHandler();
  };

  let debounceTimeout;

  const handleSave = useCallback(
    (isDraft = false) =>
      () => {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
          saveHandler(isDraft);
        }, 1000);
      },
    [saveHandler],
  );

  return (
    <MDBox>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "250px" }}>
          Prediction - Summary
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handlePrev()}>
            Back
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        {adminProfile.role === UserRole.SuperAdmin && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.team?.name}</MDTypography>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Prediction Type
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{data?.predictionType}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Match/Event
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{data?.match?.title}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Prediction Title
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{data?.title}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Prediction Description
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{data?.description}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Start Date & Time
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={8.5}>
            <MDTypography fontSize="15px">{data?.start}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Expiration Date & Time
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={8.5}>
            <MDTypography fontSize="15px">{data?.end}</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Eligibility
        </MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              No of Kudos Points
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <MDTypography fontSize="15px">{data?.eligbleKudos}</MDTypography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              No of Tokens
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <MDTypography fontSize="15px">{data?.eligbleToken}</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Rewards
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} md={6}>
            <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
              For participation
            </MDTypography>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  No of Kudos Points(Per player)
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography fontSize="15px">{data?.rewardKudosAll}</MDTypography>
              </Grid>
            </Grid>
            <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
              For Winners
            </MDTypography>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  No of Kudos Points(Total)
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography fontSize="15px">{data?.rewardKudosWinnerTotal}</MDTypography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  No of Tokens(Total)
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography fontSize="15px">{data?.rewardTokenWinnerTotal}</MDTypography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  No of Winners
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography fontSize="15px">{data?.rewardDistribution.length}</MDTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
              Reward distribution
            </MDTypography>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={4} sm={4}>
                &nbsp;
              </Grid>
              <Grid item xs={4} sm={4}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  KUDOS
                </MDTypography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Tokens
                </MDTypography>
              </Grid>
            </Grid>
            {data?.rewardDistribution.map((d) => (
              <Grid key={`winner-${d.winnerOrder}`} container spacing={2} mb={2}>
                <Grid item xs={4} sm={4}>
                  <MDTypography fontSize="15px" fontWeight="bold">
                    Winner #{d.winnerOrder}
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <MDTypography fontSize="15px">{d.rewardKudos}</MDTypography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <MDTypography fontSize="15px">{d.rewardToken}</MDTypography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Sponsors
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Game Sponsor</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px">{data?.sponsor?.title}</MDTypography>
          </Grid>
        </Grid>
      </MDBox>

      <Grid container justifyContent="flex-end" gap={2} mt={6}>
        {data?.isDraft !== false && (
          <MDButton color="secondary" size="large" onClick={handleSave(true)}>
            Save as Draft
          </MDButton>
        )}
        <MDButton color="secondary" size="large" onClick={handleSave()}>
          Publish
        </MDButton>
      </Grid>
    </MDBox>
  );
}

PredictionSummaryForm.defaultProps = {
  item: null,
  prevHandler: {},
  saveHandler: {},
};

PredictionSummaryForm.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  prevHandler: PropTypes.func,
  saveHandler: PropTypes.func,
};

export default PredictionSummaryForm;
