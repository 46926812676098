import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import { Autocomplete, Checkbox, Divider, Grid, TextField } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { dateAndTimeToDate, dateToDateAndTime } from "utils/converter";
import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";

import SponsorService from "services/SponsorService";
import MatchesService from "services/MatchService";
import GameService from "services/GamesService";
import { setLoading } from "store/appSlice";

import { EventTabs, TriviaType, GameTypes, UserRole } from "layouts/common/constants";

import FormField from "components/FormField";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .MuiOutlinedInput-root .MuiAutocomplete-input`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
    padding: 0,
    width: "35px",
  },
  [`& .MuiOutlinedInput-root`]: {
    padding: "0px",
    paddingLeft: "10px",
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

function TriviaBasicForm({ item, nextHandler, changeHandler }) {
  const initialState = {
    id: null,
    team: null,
    triviaType: "",
    match: null,
    title: "",
    description: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    sponsor: null,
    eligbleKudos: 0,
    eligbleToken: 0,
    rewardKudosAll: 0,
    rewardKudosWinnerTotal: 0,
    rewardTokenWinnerTotal: 0,
    rewardDistribution: [
      {
        winnerOrder: 1,
        rewardKudos: 0,
        rewardToken: 0,
      },
    ],

    triviaQuestions: [],
    asset: null,
    enableAssetReward: false,
    rewardAssetCount: 0,
    winnerLimit: 0,
  };

  const resetState = {
    team: "",
    triviaType: "",
    match: "",
    title: "",
    description: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    sponsor: "",
    eligbleKudos: 0,
    eligbleToken: 0,
    rewardKudosAll: 0,
    rewardKudosWinnerTotal: 0,
    rewardTokenWinnerTotal: 0,
    rewardDistribution: [
      {
        winnerOrder: 1,
        rewardKudos: 0,
        rewardToken: 0,
      },
    ],
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminProfile = useSelector((state) => state.users.profile);
  const teams = useSelector((state) => state.teams);

  const [trivia, setTrivia] = useState(initialState);
  const [validationMsg, setValidationMsg] = useState(resetState);
  const [sponsors, setSponsors] = useState([]);
  const [bonusAssets, setBonusAssets] = useState([]);
  const [matches, setMatches] = useState([]);

  const getBonusAssets = useCallback(() => {
    let search = null;
    if (adminProfile.role === UserRole.TeamAdmin) search = adminProfile.teamId;
    else if (trivia.team) {
      search = trivia.team.id;
    } else {
      setBonusAssets([]);
      return;
    }
    GameService.getBonusAssets(search, 0, 9999).then((response) => {
      setBonusAssets(response.data.data);
    });
  }, [trivia?.team, adminProfile]);

  const getSponsors = useCallback(() => {
    let search = null;
    if (adminProfile.role === UserRole.TeamAdmin) search = adminProfile.teamId;
    else if (trivia.team) {
      search = trivia.team.id;
    } else {
      setSponsors([]);
      return;
    }
    SponsorService.retrive(search, 0, 9999).then((response) => {
      setSponsors(response.data.data);
    });
  }, [trivia?.team, adminProfile]);

  const getMatches = useCallback(async () => {
    let temp = [];
    let search = null;
    if (adminProfile.role === UserRole.TeamAdmin) search = adminProfile.teamId;
    else if (trivia.team) {
      search = trivia.team.id;
    } else {
      setMatches([]);
      return;
    }
    const promises = await Promise.all([
      new Promise((resolve) => {
        let data = [];
        MatchesService.retrive(EventTabs.Ongoing, search, null, null, null, null, true).then(
          (res) => {
            data = res.data.data;
            resolve({ data });
          },
        );
      }),
      new Promise((resolve) => {
        let data = [];
        MatchesService.retrive(EventTabs.Upcoming, search, null, null, null, null, true).then(
          (res) => {
            data = res.data.data;
            resolve({ data });
          },
        );
      }),
    ]);

    promises.forEach(({ data }) => {
      temp = [...temp].concat(data);
    });

    temp = temp.sort((a, b) => {
      if (a.start < b.start) return 1;
      if (a.start > b.start) return -1;
      return 0;
    });

    setMatches(temp);
  }, [trivia?.team, adminProfile]);

  useEffect(() => {
    getBonusAssets();
    getMatches();
    getSponsors();
  }, [getBonusAssets, getMatches, getSponsors]);

  useEffect(() => {
    if (item === null) {
      setTrivia(initialState);
    } else {
      // Divide Datetime to Date and Time.
      const newItem = { ...item };

      const startDateTime = dateToDateAndTime(new Date(newItem.start));
      const endDateTime = dateToDateAndTime(new Date(newItem.end));
      newItem.startDate = startDateTime.date;
      newItem.startTime = startDateTime.time;
      newItem.endDate = endDateTime.date;
      newItem.endTime = endDateTime.time;

      delete newItem.start;
      delete newItem.end;

      setTrivia(newItem);
    }
  }, [item]);

  const validate = () => {
    let validated = true;
    let errorMsg = validationMsg;
    const keys = Object.keys(resetState);
    for (let i = 0; i < keys.length; i += 1) {
      let msg = "";
      if ((trivia[keys[i]] === "" || trivia[keys[i]] === null) && keys[i] !== "team") {
        msg = "Field should not be left empty";
        validated = false;
      }
      if (
        (trivia[keys[i]] === "" || !trivia[keys[i]]) &&
        keys[i] === "team" &&
        adminProfile.role === UserRole.SuperAdmin
      ) {
        msg = "Field should not be left empty";
        validated = false;
      }
      errorMsg = { ...errorMsg, [keys[i]]: msg };
    }
    setValidationMsg(errorMsg);

    return validated;
  };

  const handleSubmitChange = (field, value) => {
    setTrivia({ ...trivia, [field]: value });
  };

  const handleSubmitChangeNumber = (field, value) => {
    setTrivia({ ...trivia, [field]: parseFloat(value) });
  };

  const handleUpdateDestribution = (_order, _field, _value) => {
    const idx = trivia.rewardDistribution.findIndex((d) => d.winnerOrder === _order);
    const temp = [...trivia.rewardDistribution];
    temp[idx] = { ...temp[idx], [_field]: parseFloat(_value) };

    const totalWinnerRewardKudos = temp.reduce(
      (n, { rewardKudos }) => Number(n) + Number(rewardKudos),
      0,
    );
    const totalWinnerRewardTokens = temp.reduce(
      (n, { rewardToken }) => Number(n) + Number(rewardToken),
      0,
    );

    setTrivia((data) => ({
      ...data,
      rewardDistribution: temp,
      rewardKudosWinnerTotal: totalWinnerRewardKudos,
      rewardTokenWinnerTotal: totalWinnerRewardTokens,
    }));
  };

  const handleAddWinner = () => {
    const newOrder = trivia.rewardDistribution.length + 1;
    const temp = [
      ...trivia.rewardDistribution,
      {
        winnerOrder: newOrder,
        rewardKudos: 0,
        rewardToken: 0,
      },
    ];

    setTrivia({ ...trivia, rewardDistribution: temp });
  };

  const handleRemoveWinner = () => {
    const temp = [...trivia.rewardDistribution];
    temp.pop();
    setTrivia({ ...trivia, rewardDistribution: temp });
  };

  const handleNext = () => {
    if (!validate()) {
      return;
    }
    const temp = trivia;
    temp.start = dateAndTimeToDate(temp.startDate, temp.startTime).toISOString();
    temp.end = dateAndTimeToDate(temp.endDate, temp.endTime).toISOString();

    ["startDate", "startTime", "endDate", "endTime"].forEach((d) => {
      delete temp[d];
    });

    if (changeHandler(temp)) {
      nextHandler();
    }
  };

  const handleBack = () => {
    navigate("/games");
  };

  async function getTrivia(_id) {
    let temp = null;
    dispatch(setLoading(true));
    await GameService.get(_id, GameTypes.Trivia)
      .then((response) => {
        temp = response.data;
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
    return temp;
  }

  const handleReset = async () => {
    if (!trivia.id) {
      let temp = { ...trivia };
      const keys = Object.keys(resetState);
      for (let i = 0; i < keys.length; i += 1) {
        temp = { ...temp, [keys[i]]: initialState[keys[i]] };
      }
      setTrivia(temp);
    } else {
      const data = await getTrivia(trivia.id);
      const newItem = { ...data };

      const startDateTime = dateToDateAndTime(new Date(newItem.start));
      const endDateTime = dateToDateAndTime(new Date(newItem.end));
      newItem.startDate = startDateTime.date;
      newItem.startTime = startDateTime.time;
      newItem.endDate = endDateTime.date;
      newItem.endTime = endDateTime.time;

      let temp = { ...trivia };
      const keys = Object.keys(resetState);
      for (let i = 0; i < keys.length; i += 1) {
        temp = { ...temp, [keys[i]]: newItem[keys[i]] };
      }
      setTrivia(temp);
    }
  };

  return (
    <MDBox>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "200px" }}>
          {trivia.id ? "Edit Trivia" : "Add Trivia"}
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handleBack()}>
            Back
          </MDButton>
          <MDButton size="small" onClick={() => handleReset()}>
            Reset
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox sx={(theme) => customFormContainer(theme)}>
        {adminProfile.role === UserRole.SuperAdmin && (
          <Grid container spacing={2} mb={4} alignItems="center">
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">Select Team</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <StyledAutocomplete
                value={trivia.team}
                options={teams}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => handleSubmitChange("team", v)}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
                placeholder="Choose Team"
                error={validationMsg.team !== ""}
              />
            </Grid>
          </Grid>
        )}
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Trivia Type</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <StyledAutocomplete
              value={trivia.triviaType}
              options={Object.values(TriviaType)}
              onChange={(e, v) => handleSubmitChange("triviaType", v)}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose Type"
              error={validationMsg.triviaType !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Match / Event</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <StyledAutocomplete
              value={trivia.match}
              options={matches}
              getOptionLabel={(option) => `${option.title}`}
              onChange={(e, v) => {
                handleSubmitChange("match", v);
              }}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose type"
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Trivia Title</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormField
              value={trivia.title}
              onChange={(e) => handleSubmitChange("title", e.target.value)}
              error={validationMsg.title !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Trivia Description</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormField
              value={trivia.description}
              onChange={(e) => handleSubmitChange("description", e.target.value)}
              error={validationMsg.description !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px">Start Date & Time</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="date"
              label=""
              value={trivia.startDate}
              onChange={(e) => handleSubmitChange("startDate", e.target.value)}
              error={validationMsg.startDate !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="time"
              label=""
              value={trivia.startTime}
              onChange={(e) => handleSubmitChange("startTime", e.target.value)}
              error={validationMsg.startTime !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px">Expiration Date & Time</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="date"
              label=""
              value={trivia.endDate}
              onChange={(e) => handleSubmitChange("endDate", e.target.value)}
              error={validationMsg.endDate !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="time"
              label=""
              value={trivia.endTime}
              onChange={(e) => handleSubmitChange("endTime", e.target.value)}
              error={validationMsg.endTime !== ""}
            />
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Eligibility
        </MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">No of Kudos Points</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              value={trivia.eligbleKudos}
              onChange={(e) => handleSubmitChangeNumber("eligbleKudos", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">No of Tokens</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              value={trivia.eligbleToken}
              onChange={(e) => handleSubmitChangeNumber("eligbleToken", e.target.value)}
            />
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Rewards
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} md={6}>
            <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
              For participation
            </MDTypography>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px">No of Kudos Points(Per player)</MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="number"
                  value={trivia.rewardKudosAll}
                  onChange={(e) => handleSubmitChangeNumber("rewardKudosAll", e.target.value)}
                />
              </Grid>
            </Grid>
            <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
              For Winners
            </MDTypography>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px">No of Kudos Points(Total)</MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography fontSize="15px">{trivia.rewardKudosWinnerTotal}</MDTypography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px">No of Tokens(Total)</MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography fontSize="15px">{trivia.rewardTokenWinnerTotal}</MDTypography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px">No of Winners</MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography fontSize="15px">{trivia.rewardDistribution.length}</MDTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
              Reward distribution
            </MDTypography>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={4} sm={4}>
                &nbsp;
              </Grid>
              <Grid item xs={4} sm={4}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  KUDOS
                </MDTypography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Tokens
                </MDTypography>
              </Grid>
            </Grid>
            {trivia.rewardDistribution
              .sort((a, b) => {
                if (a.winnerOrder > b.winnerOrder) return 1;
                if (a.winnerOrder < b.winnerOrder) return -1;
                return 0;
              })
              .map((d) => (
                <Grid key={`winner-${d.winnerOrder}`} container spacing={2} mb={2}>
                  <Grid item xs={4} sm={4}>
                    <MDTypography fontSize="15px">Winner #{d.winnerOrder}</MDTypography>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <FormField
                      id={`winner-kudos-${d.winnerOrder}`}
                      type="number"
                      value={d.rewardKudos}
                      onChange={(e) =>
                        handleUpdateDestribution(d.winnerOrder, "rewardKudos", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <FormField
                      id={`winner-token-${d.winnerOrder}`}
                      type="number"
                      value={d.rewardToken}
                      onChange={(e) =>
                        handleUpdateDestribution(d.winnerOrder, "rewardToken", e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              ))}
            <MDBox
              sx={(theme) => {
                flexContainer(theme);
              }}
            >
              <MDButton
                size="small"
                color="secondary"
                sx={{ marginRight: "10px" }}
                onClick={() => handleAddWinner()}
              >
                Winner +
              </MDButton>
              {trivia.rewardDistribution.length > 1 && (
                <MDButton size="small" color="secondary" onClick={() => handleRemoveWinner()}>
                  Winner -
                </MDButton>
              )}
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Send asset as reward?</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox
              checked={trivia.enableAssetReward}
              onChange={(e) => {
                handleSubmitChange("enableAssetReward", e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">select asset</MDTypography>
          </Grid>
          <Grid item xs={12} sm={4} md={5}>
            <StyledAutocomplete
              value={trivia.asset}
              disabled={!trivia.enableAssetReward}
              options={bonusAssets}
              getOptionLabel={(option) => option?.title}
              onChange={(e, v) => {
                handleSubmitChange("asset", v);
              }}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose Asset"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Number of top ranked scores to send to</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              disabled={!trivia.enableAssetReward}
              type="number"
              min="0"
              value={Math.abs(trivia.winnerLimit)}
              onChange={(e) => handleSubmitChangeNumber("winnerLimit", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">No of Bonus Asset to send per winner</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              disabled={!trivia.enableAssetReward}
              type="number"
              min="0"
              value={Math.abs(trivia.rewardAssetCount)}
              onChange={(e) => handleSubmitChangeNumber("rewardAssetCount", e.target.value)}
            />
          </Grid>
        </Grid>
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Sponsors
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Game Sponsor</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <StyledAutocomplete
              value={trivia.sponsor}
              options={sponsors}
              getOptionLabel={(option) => option.title}
              onChange={(e, v) => {
                handleSubmitChange("sponsor", v);
              }}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose Sponsor"
            />
          </Grid>
        </Grid>
      </MDBox>
      <Grid container justifyContent="flex-end">
        <MDButton color="secondary" size="large" onClick={() => handleNext()}>
          Next
        </MDButton>
      </Grid>
    </MDBox>
  );
}

TriviaBasicForm.defaultProps = {
  item: null,
  nextHandler: {},
  changeHandler: {},
};

TriviaBasicForm.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  nextHandler: PropTypes.func,
  changeHandler: PropTypes.func,
};

export default TriviaBasicForm;
