import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { customFormContainer } from "layouts/common/styles";
import { UserRole, MiniGameType } from "layouts/common/constants";

function ViewMiniGameDetail({ item }) {
  const adminProfile = useSelector((state) => state.users.profile);
  return (
    <MDBox sx={(theme) => customFormContainer(theme)}>
      {adminProfile.role === UserRole.SuperAdmin && (
        <Grid container spacing={2} mb={4} alignItems="center">
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.team?.name}</MDTypography>
          </Grid>
        </Grid>
      )}
      <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
        Details
      </MDTypography>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={3} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Match / Event
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <MDTypography fontSize="15px">
            {item?.match?.title ?? "No match / event allocation"}
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={3} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Mini Game Title
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <MDTypography fontSize="15px">{item?.title}</MDTypography>
        </Grid>
        <Grid item xs={12} sm={3} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Mini Game ID
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <MDTypography fontSize="15px">{item?.id}</MDTypography>
        </Grid>
        <Grid item xs={12} sm={3} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Mini Game Description
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <MDTypography fontSize="15px">{item?.description}</MDTypography>
        </Grid>

        <Grid item xs={12} sm={3} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Select game type
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <MDTypography fontSize="15px">{MiniGameType[item?.type || 0]}</MDTypography>
        </Grid>
      </Grid>
      <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
        Game Rules
      </MDTypography>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={3} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Number of lives (start)
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={2} md={1.5}>
          <MDTypography fontSize="15px">{item?.lifeCount}</MDTypography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={3} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Refresh time (min)
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={2} md={1.5}>
          <MDTypography fontSize="15px">{item?.refreshTime}</MDTypography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={3} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Refresh amount (lives)
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={2} md={1.5}>
          <MDTypography fontSize="15px">{item?.refreshAmount}</MDTypography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={12} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Start Date & Time
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={12} md={4.5}>
          <MDTypography fontSize="15px">{item?.start}</MDTypography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={2.5}>
          <MDTypography fontSize="15px" fontWeight="bold">
            Expiration Date & Time
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={12} md={4.5}>
          <MDTypography fontSize="15px">{item?.end}</MDTypography>
        </Grid>
      </Grid>
    </MDBox>
  );
}

ViewMiniGameDetail.defaultProps = {
  item: null,
};

ViewMiniGameDetail.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
};

export default ViewMiniGameDetail;
