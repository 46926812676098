import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Autocomplete, Grid, TextField } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { pageHeaderContainer, flexContainer } from "layouts/common/styles";
import { UserRole } from "layouts/common/constants";

import AnalyticsService from "services/AnalyticsService";
import ToastrService from "services/ToastrService";

import { setLoading } from "store/appSlice";

import FormField from "components/FormField";
import AnalyticsCard from "./components/AnalyticsCard";

function Analytics() {
  // Analytics data
  const initialData = [
    {
      label: "Users (Registered)",
      value: 0,
      icon: "/images/analytics/referrer-anlaytics-icon.svg",
      action: "/users",
    },
    {
      label: "New Users",
      value: 0,
      icon: "/images/analytics/team-analytics-icon.svg",
      action: "/users",
    },
    {
      label: "Referrals (Successful)",
      value: 0,
      icon: "/images/analytics/referrer-anlaytics-icon.svg",
      action: "/users",
    },
    {
      label: "Games Played",
      value: 0,
      icon: "/images/analytics/game-analytics-icon.svg",
      action: "/games",
    },
    {
      label: "Challenges Completed",
      value: 0,
      icon: "/images/analytics/challenge-analytics-icon.svg",
      action: "/challenges",
    },
    {
      label: "Polls Completed",
      value: 0,
      icon: "/images/analytics/poll-analytics-icon.svg",
      action: "/polls",
    },
    { label: "Check-Ins", value: 0, icon: "", action: "/challenges" },
    {
      label: "Trivia Games (Complete)",
      value: 0,
      icon: "/images/analytics/game-analytics-icon.svg",
      action: "/games",
    },
    {
      label: "Predictor Games (Complete)",
      value: 0,
      icon: "/images/analytics/game-analytics-icon.svg",
      action: "/games",
    },
    {
      label: "Assets Redeemed",
      value: 0,
      icon: "/images/analytics/asset-analytics-icon.svg",
      action: "/assets",
    },
    {
      label: "Assets Claimed",
      value: 0,
      icon: "/images/analytics/asset-analytics-icon.svg",
      action: "/assets",
    },
    {
      label: "Sponsors (Active)",
      value: 0,
      icon: "/images/analytics/sponsor-analytics-icon.svg",
      action: "/sponsors",
    },
  ];

  const dispatch = useDispatch();
  const teams = useSelector((state) => state.teams);
  const adminProfile = useSelector((state) => state.users.profile);
  const [startDate, setStart] = useState("");
  const [endDate, setEnd] = useState("");
  const [data, setData] = useState(initialData);

  const [selectedTeams, setSelectedTeams] = useState([]);

  const fetchBySearchOptions = useCallback(() => {
    let search = "";
    if (selectedTeams.length > 0) {
      search = selectedTeams.map((d) => d.id).join(",");
    }
    if (!startDate) return;
    if (!endDate) return;
    const start = new Date(startDate).toISOString();
    let end = new Date(new Date(endDate).setUTCHours(23)).toISOString();
    end = new Date(new Date(end).setMinutes(59)).toISOString();
    end = new Date(new Date(end).setSeconds(59)).toISOString();

    if (start > end) {
      ToastrService.warning("Please select date correctly!");
      return;
    }
    dispatch(setLoading(true));
    AnalyticsService.retrive(adminProfile.role === UserRole.SuperAdmin, start, end, search)
      .then((res) => {
        const tmp = initialData.slice(0);
        [
          "usersRegistered",
          "newUsers",
          "referrals",
          "gamesPlayed",
          "challengesCompleted",
          "pollsCompleted",
          "checkins",
          "triviaGames",
          "predictorGames",
          "assetsRedeemed",
          "assetsClaimed",
          "sponsors",
        ].forEach((key, idx) => {
          tmp[idx].value = res.data[key];
        });
        setData(tmp);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "FFailed to lead analytics data");
        dispatch(setLoading(false));
      });
  }, [dispatch, adminProfile, selectedTeams, startDate, endDate]);

  useEffect(() => {
    if (startDate === "" || endDate === "" || !startDate || !endDate) {
      setData(initialData);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    fetchBySearchOptions();
  }, [fetchBySearchOptions]);

  return (
    <DashboardLayout>
      <MDBox py={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold">
            Dashboard Analytics
          </MDTypography>
        </MDBox>
        {adminProfile.role === UserRole.SuperAdmin && (
          <MDBox mb={3}>
            <MDTypography fontSize="12px" fontWeight="bold">
              Select Teams:{" "}
            </MDTypography>
            <Autocomplete
              multiple
              value={selectedTeams}
              options={teams}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => setSelectedTeams(v)}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              sx={{ maxWidth: "568px", width: "100%" }}
              placeholder="Select Teams"
            />
          </MDBox>
        )}
        <MDBox style={{ display: "flex", justifyContent: "end" }}>
          <MDBox
            mb={3}
            sx={(theme) => flexContainer(theme)}
            style={{
              width: "100%",
              maxWidth: "350px",
              alignItems: "start",
              justifyContent: "end",
              gap: "10px",
            }}
          >
            <MDTypography fontSize="12px" fontWeight="bold">
              Date period
            </MDTypography>
            <MDBox>
              <MDBox mb={1} style={{ display: "flex", gap: "10px" }}>
                <MDTypography fontSize="12px" fontWeight="bold" width="80px">
                  Start Date
                </MDTypography>
                <FormField
                  type="date"
                  label=""
                  onChange={(e) => {
                    setStart(e.target.value);
                  }}
                />
              </MDBox>
              <MDBox style={{ display: "flex", gap: "10px" }}>
                <MDTypography fontSize="12px" fontWeight="bold" width="80px">
                  End Date
                </MDTypography>
                <FormField
                  type="date"
                  label=""
                  onChange={(e) => {
                    setEnd(e.target.value);
                  }}
                />
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox>
          <Grid container spacing={2} mb={4}>
            {data.map((item) => (
              <Grid item xs={12} sm={4} md={3}>
                <AnalyticsCard
                  color="info"
                  title={item.label}
                  value={item.value}
                  icon={item.icon}
                  action={item.action}
                />
              </Grid>
            ))}
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Analytics;
