import { configureStore } from "@reduxjs/toolkit";

import appReducer from "./appSlice";
import teamReducer from "./slices/teamSlice";
import sportReducer from "./slices/sportSlice";
import matchReducer from "./slices/matchSlice";
import challengeReducer from "./slices/challengeSlice";
import gamesReducer from "./slices/gameSlice";
import sponsorReducer from "./slices/sponsorSlice";
import pollReducer from "./slices/pollSlice";
import assetReducer from "./slices/assetSlice";
import userReducer from "./slices/userSlice";
import notificationReducer from "./slices/notificationSlice";

const reducer = {
  app: appReducer,
  teams: teamReducer,
  sports: sportReducer,
  matches: matchReducer,
  challenges: challengeReducer,
  games: gamesReducer,
  sponsors: sponsorReducer,
  polls: pollReducer,
  assets: assetReducer,
  users: userReducer,
  notifications: notificationReducer,
};

const store = configureStore({
  reducer,
  devTools: true,
});

export default store;
