import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

import { customFormContainer } from "layouts/common/styles";

function PlayerLevels({ item }) {
  return (
    <MDBox>
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team Name
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.name}</MDTypography>
          </Grid>
        </Grid>

        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Player Levels
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Kudos required to hit Tier 1
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <MDTypography fontSize="15px">{item?.kudosToTire1}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Kudos required to hit Tier 2
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <MDTypography fontSize="15px">{item?.kudosToTire2}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Kudos required to hit Tier 3
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <MDTypography fontSize="15px">{item?.kudosToTire3}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Kudos required to hit Tier 4
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <MDTypography fontSize="15px">{item?.kudosToTire4}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Member tier level 1 (Name)
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <MDTypography fontSize="15px">{item?.memberLevelName1}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Member tier level 2 (Name)
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <MDTypography fontSize="15px">{item?.memberLevelName2}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Member tier level 3 (Name)
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <MDTypography fontSize="15px">{item?.memberLevelName3}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Member tier level 4 (Name)
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <MDTypography fontSize="15px">{item?.memberLevelName4}</MDTypography>
          </Grid>
        </Grid>
        {item?.players.length > 0 && (
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Player Details
          </MDTypography>
        )}
        {item?.players?.map((player, idx) => (
          <Grid container spacing={2} key={`team-player-${idx.toString()}`} mb={2}>
            <Grid item xs={12} sm={3} sx={{ display: "flex", alignItems: "center" }}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Player name {idx + 1}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4} sx={{ display: "flex", alignItems: "center" }}>
              <MDTypography fontSize="15px">{player?.name}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              {player.avatar && player.avatar !== "" && (
                <MDAvatar
                  src={player.avatar}
                  alt="Avatar"
                  sx={{ width: "100px", height: "100px" }}
                />
              )}
            </Grid>
          </Grid>
        ))}
      </MDBox>
    </MDBox>
  );
}

PlayerLevels.defaultProps = {
  item: null,
};

PlayerLevels.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
};

export default PlayerLevels;
