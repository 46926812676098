import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { FaLongArrowAltRight } from "react-icons/fa";

function MyTeamCard({ team }) {
  return (
    <Card>
      <MDBox
        p={2}
        style={{
          minHeight: "200px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <MDBox sx={{ display: "flex", justifyContent: "center" }}>
          {team.logo !== "" && <img src={team.logo} alt="icon" style={{ height: "100px" }} />}
        </MDBox>

        <MDBox mb={0.5} lineHeight={1} sx={{ display: "flex", justifyContent: "center" }}>
          <MDTypography
            variant="button"
            fontWeight="medium"
            color="text"
            textTransform="capitalize"
          >
            {team.name}
          </MDTypography>
        </MDBox>

        <MDBox sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Link to={`/dashboards/teams/view/${team.id}`}>
            <MDButton>
              Manage my team&nbsp;
              <FaLongArrowAltRight />
            </MDButton>
          </Link>
        </MDBox>
      </MDBox>
    </Card>
  );
}

MyTeamCard.defaultProps = {
  team: {},
};

MyTeamCard.propTypes = {
  team: PropTypes.objectOf(PropTypes.any),
};

export default MyTeamCard;
