import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MDBox from "components/MDBox";

import ToastrService from "services/ToastrService";
import GameService from "services/GamesService";
import { createGame, updateGame } from "store/slices/gameSlice";
import { setLoading } from "store/appSlice";
import { GameTypes, UserRole } from "layouts/common/constants";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import MilestoneBasicForm from "./forms/MilestoneBasicForm";
import MilestoneSummaryForm from "./forms/MilestoneSummaryForm";

const formSwitcher = (screen, prevHandler, nextHandler, handleSave, handleChange, newMilestone) => {
  switch (screen) {
    case 1:
      return (
        <MilestoneBasicForm
          item={newMilestone}
          nextHandler={nextHandler}
          changeHandler={handleChange}
        />
      );
    case 2:
      return (
        <MilestoneSummaryForm
          item={newMilestone}
          prevHandler={prevHandler}
          saveHandler={handleSave}
        />
      );
    default:
      return <MilestoneSummaryForm item={newMilestone} nextHandler={nextHandler} />;
  }
};

function AddMilestone() {
  const { id } = useParams();

  const adminProfile = useSelector((state) => state.users.profile);

  const [screen, setScreen] = useState(1);
  const [newMilestone, setMilestone] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getGame = (_id) => {
    dispatch(setLoading(true));
    GameService.get(_id, GameTypes.Milestone)
      .then((response) => {
        const temp = response.data;
        setMilestone(temp);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to load milestone data.");
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      getGame(id);
      setMilestone(null);
    } else {
      setMilestone(null);
    }
  }, [id]);

  const handleChange = (data) => {
    setMilestone(data);
    return true;
  };

  const handleSave = (isDraft = false) => {
    const newData = { ...newMilestone };
    const dataId = newData.id;

    if (adminProfile.role === UserRole.TeamAdmin) {
      newData.teamId = adminProfile.teamId;
    } else {
      newData.teamId = newData.team.id;
    }
    newData.sponsorId = newData.sponsor.id;
    newData.matchId = newData.match.id;
    if (newData.enableAssetReward) {
      newData.assetId = newData.asset.id;
    } else {
      newData.assetId = null;
      newData.rewardAssetCount = 0;
    }
    newData.isDraft = isDraft;
    ["id", "team", "match", "sponsor", "asset"].forEach((key) => {
      delete newData[key];
    });

    dispatch(setLoading(true));
    if (dataId === null) {
      dispatch(createGame({ type: GameTypes.Milestone, data: newData }))
        .unwrap()
        .then((data) => {
          ToastrService.success("Successfully added new milestone game");
          dispatch(setLoading(false));
          navigate(`/game/view-milestone/${data.id}`);
        })
        .catch((e) => {
          ToastrService.error(
            e.response?.data?.message?.message || "Failed to add new milestone game",
          );
          dispatch(setLoading(false));
        });
    } else {
      // remove keys
      ["createdAt", "updatedAt"].forEach((key) => {
        delete newData[key];
      });

      dispatch(updateGame({ type: GameTypes.Milestone, id: dataId, data: newData }))
        .unwrap()
        .then((data) => {
          ToastrService.success("Successfully updated milestone game");
          dispatch(setLoading(false));
          navigate(`/game/view-milestone/${data.id}`);
        })
        .catch((e) => {
          ToastrService.error(
            e.response?.data?.message?.message || "Failed to update milestone game",
          );
          dispatch(setLoading(false));
        });
    }
  };

  const nextScreen = () => {
    setScreen(screen + 1);
  };

  const prevScreen = () => {
    setScreen(screen - 1);
  };

  return (
    <DashboardLayout>
      <MDBox my={3}>
        {formSwitcher(screen, prevScreen, nextScreen, handleSave, handleChange, newMilestone)}
      </MDBox>
    </DashboardLayout>
  );
}

export default AddMilestone;
