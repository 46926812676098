import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Autocomplete, Divider, Grid, TextField, Button, Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { dateToDateAndTime, dateAndTimeToDate } from "utils/converter";

import SponsorService from "services/SponsorService";
import GameService from "services/GamesService";
import ToastrService from "services/ToastrService";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";
import { UserRole } from "layouts/common/constants";
import FormField from "../../FormField";
// import ToastrService from '../../../../../services/ToastrService';
import MDAvatar from "../../../../../components/MDAvatar";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .MuiOutlinedInput-root .MuiAutocomplete-input`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
    padding: 0,
    width: "35px",
  },
  [`& .MuiOutlinedInput-root`]: {
    padding: "0px",
    paddingLeft: "10px",
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

function ImageDetailForm({ prevHandler, nextHandler, changeHandler, typeForm, item }) {
  const initialState = {
    id: null,
    team: null,
    match: null,
    kudosEligible: 0,
    tokenEligible: 0,
    kudosReward: 0,
    tokenReward: 0,
    sponsor: null,
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    participants: 0,
    options: [
      { details: "", file: null, order: 1 },
      { details: "", file: null, order: 2 },
    ],
    asset: null,
    enableAssetReward: false,
    rewardAssetCount: 0,
    winnerLimit: 0,
  };

  const adminProfile = useSelector((state) => state.users.profile);

  const [poll, setPoll] = useState(initialState);
  const [validationMsg, setValidationMsg] = useState(initialState);
  const [sponsors, setSponsors] = useState([]);
  const [bonusAssets, setBonusAssets] = useState([]);

  const getBonusAssets = useCallback(() => {
    let search = null;
    if (adminProfile.role === UserRole.TeamAdmin) search = adminProfile.teamId;
    else if (poll.team) {
      search = poll.team.id;
    } else {
      setBonusAssets([]);
      return;
    }
    GameService.getBonusAssets(search, 0, 9999).then((response) => {
      setBonusAssets(response.data.data);
    });
  }, [poll?.team, adminProfile]);

  const getSponsors = useCallback(() => {
    let search = null;
    if (adminProfile.role === UserRole.TeamAdmin) search = adminProfile.teamId;
    else if (poll.team) {
      search = poll.team.id;
    } else {
      setSponsors([]);
      return;
    }
    SponsorService.retrive(search, 0, 9999).then((response) => {
      setSponsors(response.data.data);
    });
  }, [poll?.team, adminProfile]);

  useEffect(() => {
    getBonusAssets();
    getSponsors();
  }, [getBonusAssets, getSponsors]);

  const handleFilePathChange = (event, idx) => {
    const [file] = event.target.files;
    const temp = [...poll.options];
    temp[idx].details = URL.createObjectURL(file);
    temp[idx].file = file;
    setPoll({ ...poll, options: temp });
    event.target.value = null;
  };

  useEffect(async () => {
    if (item === null) {
      const temp = { ...initialState };
      temp.team = typeForm.team;
      temp.match = typeForm.match;
      setPoll(temp);
    } else {
      // Divide Datetime to Date and Time.
      const newItem = { ...item };
      const startDateTime = dateToDateAndTime(new Date(newItem.start));
      const endDateTime = dateToDateAndTime(new Date(newItem.end));
      newItem.startDate = startDateTime.date;
      newItem.startTime = startDateTime.time;
      newItem.endDate = endDateTime.date;
      newItem.endTime = endDateTime.time;
      newItem.options = newItem.options.map((opt) => {
        const temp = { ...opt };
        if (!opt.file) {
          temp.file = null;
        }
        return temp;
      });
      delete newItem.start;
      delete newItem.end;

      setPoll(newItem);
    }
  }, [typeForm, item]);

  const validate = () => {
    let validated = true;
    let errorMsg = validationMsg;
    const keys = Object.keys(poll);
    for (let i = 0; i < keys.length; i += 1) {
      let msg = "";
      if (
        (poll[keys[i]] === "" || poll[keys[i]] === null) &&
        ![
          "id",
          "options",
          "asset",
          "enableAssetReward",
          "rewardAssetCount",
          "winnerLimit",
        ].includes(keys[i])
      ) {
        msg = "Field should not be left empty";
        validated = false;
      }
      errorMsg = { ...errorMsg, [keys[i]]: msg };
    }
    for (let i = 0; i < poll.options.length; i += 1) {
      if (poll.options[i].details === "") {
        validated = false;
        break;
      }
    }
    setValidationMsg(errorMsg);

    return validated;
  };

  const handleSubmitChange = (field, value) => {
    setPoll({ ...poll, [field]: value });
  };

  const handleSubmitChangeNumber = (field, value) => {
    if (parseFloat(value) < 0) {
      return;
    }
    setPoll({ ...poll, [field]: parseFloat(value) });
  };

  const handleAddOption = () => {
    const maxOrder = poll.options.sort((a, b) => {
      if (a.order < b.order) return 1;
      if (a.order > b.order) return -1;
      return 0;
    })[0].order;
    const temp = [...poll.options, { details: "", file: null, order: maxOrder + 1 }];
    setPoll({ ...poll, options: temp });
  };

  const handleRemoveOption = () => {
    const temp = [...poll.options];
    temp.pop();
    setPoll({ ...poll, options: temp });
  };

  const handleNext = async () => {
    if (!validate()) {
      return;
    }
    const temp = poll;

    temp.start = dateAndTimeToDate(temp.startDate, temp.startTime).toISOString();
    temp.end = dateAndTimeToDate(temp.endDate, temp.endTime).toISOString();
    if (temp.start > temp.end) {
      ToastrService.warning("End date cannot not be before start date");
      return;
    }
    ["startDate", "startTime", "endDate", "endTime"].forEach((d) => {
      delete temp[d];
    });

    if (changeHandler(temp)) {
      nextHandler();
    }
  };

  const handleBack = () => {
    prevHandler();
  };

  const handleReset = async () => {
    const temp = { ...initialState };
    temp.team = typeForm.team;
    temp.match = typeForm.match;
    setPoll(temp);
  };

  return (
    <MDBox>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "200px" }}>
          Add Poll - Image
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={handleBack}>
            Back
          </MDButton>
          <MDButton size="small" onClick={handleReset}>
            Reset
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <Grid container spacing={2} mb={4} alignItems="center">
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{typeForm.team.name}</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Poll Title
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{typeForm.title}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Poll Description
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{typeForm.description}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Poll Type
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{typeForm.type}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Match / Event
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{typeForm?.match?.title}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDBox mb={4}>
              <Grid container spacing={2}>
                {poll.options.map((option, idx) => (
                  <Grid item xs={12} sm={12} md={6} key={`poll-option-${idx.toString()}`}>
                    <MDBox mt={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5} md={3}>
                          <MDTypography fontSize="15px">Option {idx + 1}</MDTypography>
                        </Grid>
                        <Grid item xs={12} sm={7} md={9}>
                          <MDBox mb={2}>
                            <Button
                              variant="contained"
                              component="label"
                              color="white"
                              sx={{ marginRight: "20px" }}
                            >
                              {option.details === "" || option.details === null
                                ? `Upload Image`
                                : "Change Image"}
                              <input
                                type="file"
                                hidden
                                name="option"
                                onChange={(e) => {
                                  handleFilePathChange(e, idx);
                                }}
                                accept=".jpg,.jpeg,.png"
                              />
                            </Button>
                          </MDBox>
                          {option.details !== null && option.details !== "" && (
                            <MDAvatar
                              src={option.details}
                              alt="Avatar"
                              sx={{ width: "100px", height: "100px" }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Grid>
                ))}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="10px"
                >
                  <MDButton size="small" color="secondary" onClick={() => handleAddOption()}>
                    Option +
                  </MDButton>
                  {poll.options.length > 2 && (
                    <MDButton size="small" color="secondary" onClick={() => handleRemoveOption()}>
                      Option -
                    </MDButton>
                  )}
                  <MDTypography fontSize="12px">Minimum 2 Options</MDTypography>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px">Start Date & Time</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="date"
              label=""
              value={poll.startDate}
              onChange={(e) => handleSubmitChange("startDate", e.target.value)}
              error={validationMsg.startDate !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="time"
              label=""
              value={poll.startTime}
              onChange={(e) => handleSubmitChange("startTime", e.target.value)}
              error={validationMsg.startTime !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px">Expiration Date & Time</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="date"
              label=""
              value={poll.endDate}
              onChange={(e) => handleSubmitChange("endDate", e.target.value)}
              error={validationMsg.endDate !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="time"
              label=""
              value={poll.endTime}
              onChange={(e) => handleSubmitChange("endTime", e.target.value)}
              error={validationMsg.endTime !== ""}
            />
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Eligibility
        </MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">No of Kudos Points</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              name="kudosEligible"
              min={0}
              value={poll.kudosEligible}
              onChange={(e) => {
                handleSubmitChangeNumber("kudosEligible", e.target.value);
              }}
              error={validationMsg.kudosEligible !== "" && validationMsg.kudosEligible !== 0}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">No of Tokens</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              name="tokenEligible"
              min={0}
              value={poll.tokenEligible}
              onChange={(e) => {
                handleSubmitChangeNumber("tokenEligible", e.target.value);
              }}
              error={validationMsg.tokenEligible !== "" && validationMsg.tokenEligible !== 0}
            />
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Rewards
        </MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">No of Kudos Points</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              name="kudosReward"
              min={0}
              value={poll.kudosReward}
              onChange={(e) => {
                handleSubmitChangeNumber("kudosReward", e.target.value);
              }}
              error={validationMsg.kudosReward !== "" && validationMsg.kudosReward !== 0}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">No of Tokens</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              name="tokenReward"
              min={0}
              value={poll.tokenReward}
              onChange={(e) => {
                handleSubmitChangeNumber("tokenReward", e.target.value);
              }}
              error={validationMsg.tokenReward !== "" && validationMsg.tokenReward !== 0}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Send asset as reward?</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox
              checked={poll.enableAssetReward}
              onChange={(e) => {
                handleSubmitChange("enableAssetReward", e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">select asset</MDTypography>
          </Grid>
          <Grid item xs={12} sm={4} md={5}>
            <StyledAutocomplete
              value={poll.asset}
              disabled={!poll.enableAssetReward}
              options={bonusAssets}
              getOptionLabel={(option) => option?.title}
              onChange={(e, v) => {
                handleSubmitChange("asset", v);
              }}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose Asset"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Users to send to (1st to complete poll)</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              disabled={!poll.enableAssetReward}
              value={poll.winnerLimit}
              onChange={(e) => handleSubmitChangeNumber("winnerLimit", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">No of Bonus Asset to send per winner</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              disabled={!poll.enableAssetReward}
              type="number"
              min="0"
              value={Math.abs(poll.rewardAssetCount)}
              onChange={(e) => handleSubmitChangeNumber("rewardAssetCount", e.target.value)}
            />
          </Grid>
        </Grid>
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Sponsors
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Game Sponsor</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <StyledAutocomplete
              value={poll.sponsor}
              options={sponsors}
              getOptionLabel={(option) => option.title}
              onChange={(e, v) => {
                handleSubmitChange("sponsor", v);
              }}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose Sponsor"
            />
          </Grid>
        </Grid>
      </MDBox>
      <Grid container justifyContent="flex-end" gap={2}>
        <Grid item>
          <MDButton color="secondary" size="large" onClick={handleBack}>
            Prev
          </MDButton>
        </Grid>
        <Grid item>
          <MDButton color="secondary" size="large" onClick={handleNext}>
            Next
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

ImageDetailForm.defaultProps = {
  typeForm: null,
  item: null,
  prevHandler: {},
  nextHandler: {},
  changeHandler: {},
};

ImageDetailForm.propTypes = {
  typeForm: PropTypes.objectOf(PropTypes.any),
  item: PropTypes.objectOf(PropTypes.any),
  prevHandler: PropTypes.func,
  nextHandler: PropTypes.func,
  changeHandler: PropTypes.func,
};

export default ImageDetailForm;
