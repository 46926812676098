import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/illustrations/SportzFan.png";

import { validateEmail } from "utils/validation";
import MDTypography from "components/MDTypography";
import { setLoading } from "store/appSlice";

import AuthService from "services/AuthService";

function RecoverPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [validation, setValidation] = useState("");
  const [success, setSuccess] = useState(false);

  const onChange = (value) => {
    setEmail(value);
    setValidation("");
  };

  const onSubmit = () => {
    setValidation("");
    if (email === "") {
      setValidation("empty");
    } else if (!validateEmail(email)) {
      setValidation("invalid");
    } else {
      dispatch(setLoading(true));
      AuthService.requestForgotPassword(email)
        .then(() => {
          setSuccess(true);
          dispatch(setLoading(false));
        })
        .catch(() => {
          setValidation("incorrect");
          dispatch(setLoading(false));
        });
    }
  };

  const handleBackLogin = () => {
    navigate("/sign-in");
  };

  return (
    <IllustrationLayout title="Reset password" illustration={bgImage}>
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            type="email"
            label="Email"
            fullWidth
            value={email}
            disabled={success}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
          {validation ? (
            <MDTypography fontSize="12px" mt={1} sx={{ color: "red" }}>
              {validation === "empty"
                ? "Please enter an email address."
                : validation === "invalid"
                ? "Please enter a valid email address."
                : "Sorry, that email does not seem to exist. Please try again"}
            </MDTypography>
          ) : null}
        </MDBox>
        <MDBox mt={4} mb={1}>
          {success ? (
            <MDTypography fontSize="12px" mt={1} sx={{ color: "green" }}>
              Thank you! Details have been sent to your email to recover your password.
            </MDTypography>
          ) : (
            <MDButton
              variant="gradient"
              color="info"
              size="large"
              fullWidth
              onClick={() => onSubmit()}
            >
              Submit
            </MDButton>
          )}
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton
            variant="text"
            color="info"
            fullWidth
            className="w-full h-50 rounded-[20px] leading-20 uppercase"
            onClick={handleBackLogin}
          >
            BACK TO LOGIN
          </MDButton>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}

export default RecoverPassword;
