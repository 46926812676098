import { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { customFormContainer } from "layouts/common/styles";
import MDBox from "components/MDBox";

function MultiCheckInReview({ nextHandler }) {
  const [submitted, setSubmitted] = useState(false);

  const handleNext = () => {
    nextHandler();
  };

  return (
    <MDBox>
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">Adelaide Giants</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Challenge Type
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">Multi Check In</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Start Date & Time
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <MDTypography fontSize="15px">05/18/2022 12:22</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Expiration Date & Time
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <MDTypography fontSize="15px">05/18/2022 12:22</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Participants
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9} md={8.5}>
            <MDTypography fontSize="15px">All Users</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Eligibility
        </MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              No of check ins required to complete challenge
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <MDTypography fontSize="15px">0</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Rewards
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} md={6}>
            <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
              If user is at venue
            </MDTypography>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  No of Kudos Points
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography fontSize="15px">0</MDTypography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  No of Tokens
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography fontSize="15px">0</MDTypography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Sponsors
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Game Sponsor
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDTypography fontSize="15px">Nike</MDTypography>
          </Grid>
        </Grid>
      </MDBox>
      {submitted ? (
        <Grid container justifyContent="flex-end" gap={2}>
          <Grid item>
            <MDButton color="secondary" size="large" onClick={() => handleNext()}>
              View Results
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton color="secondary" size="large">
              Edit
            </MDButton>
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent="flex-end" gap={2}>
          <Grid item>
            <MDButton color="secondary" size="large" onClick={() => setSubmitted(true)}>
              Publish
            </MDButton>
          </Grid>
        </Grid>
      )}
    </MDBox>
  );
}

MultiCheckInReview.defaultProps = {
  nextHandler: {},
};

MultiCheckInReview.propTypes = {
  nextHandler: PropTypes.func,
};

export default MultiCheckInReview;
