import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Table from "@mui/material/Table";
import { TableHead } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import PropTypes from "prop-types";

import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

import ConfirmSnackBarContent from "layouts/common/components/ConfirmSnackbarContent";

import { dateToDateAndTime } from "utils/converter";

import { FaArrowDown, FaArrowUp, FaCopy, FaEdit, FaEye, FaTrash } from "react-icons/fa";

import PollService from "services/PollService";
import { setLoading } from "store/appSlice";
import { createPoll } from "store/slices/pollSlice";
import ToastrService from "services/ToastrService";

import { SortFilter } from "layouts/common/constants";

import {
  customTableHeader,
  StyledTableCell,
  StyledTableCellContent,
  StyledTableButtonGroups,
  StyledTableSortButton,
} from "layouts/common/styles";

const tableHeads = [
  {
    label: "Title",
    alignment: "left",
    sort: true,
    sortFilter: SortFilter.Title,
  },
  {
    label: "Team",
    alignment: "left",
    sort: true,
    sortFilter: SortFilter.Team,
  },
  {
    label: "Start Date",
    alignment: "right",
    sort: true,
    sortFilter: SortFilter.Start,
  },
  {
    label: "Action",
    alignment: "right",
    sort: false,
  },
];

function PollsTable({ data, deleteHandler, sortHandler }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);

  const [deleteId, setDeleteId] = useState("");
  const [items, setItems] = useState([]);
  const [sortFilter, setSortFilter] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  useEffect(() => {
    const tempData = data.map((d) => {
      const temp = { ...d };
      const startDateTime = dateToDateAndTime(new Date(d.start));
      temp.createdAt = `${startDateTime.date}  ${startDateTime.time}`;
      return temp;
    });

    setItems(tempData);
  }, [data]);

  const handleSort = (sort, direction) => () => {
    setSortFilter(sort);
    setSortDirection(direction);
    sortHandler(sort, direction);
  };

  const handleEdit = (_id) => () => {
    navigate(`/polls/edit/${_id}`);
  };

  const handleView = (_id) => () => {
    navigate(`/polls/view/image/${_id}`);
  };

  const handleDelete = (_id) => () => {
    setShow(true);
    setDeleteId(_id);
  };

  const confirmDelete = () => {
    if (deleteId !== "") {
      setShow(false);
      deleteHandler(deleteId);
      setDeleteId("");
    }
  };

  const handleCopy = (_id) => () => {
    dispatch(setLoading(true));
    PollService.get(_id)
      .then((response) => {
        const temp = { ...response.data };
        temp.teamId = temp.team.id;
        temp.matchId = temp.match.id;
        temp.sponsorId = temp.sponsor.id;
        temp.isDraft = true;
        temp.title = `${temp.title}  (Duplicated)`;
        [
          "id",
          "team",
          "match",
          "sponsor",
          "participants",
          "createdAt",
          "updatedAt",
          "isEnded",
          "isPlayed",
        ].forEach((key) => {
          delete temp[key];
        });
        temp.options = temp.options.map((item) => ({ details: item.details, order: item.order }));
        dispatch(createPoll({ data: temp }))
          .unwrap()
          .then((res) => {
            dispatch(setLoading(false));
            ToastrService.success("Successfully duplicated poll");
            navigate(`/polls/view/image/${res.id}`);
          })
          .catch(() => {
            dispatch(setLoading(false));
            ToastrService.error("Failed to duplicated poll");
          });
      })
      .catch(() => {
        dispatch(setLoading(false));
        ToastrService.error("Failed to duplicated poll");
      });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead sx={(theme) => customTableHeader(theme)}>
          <TableRow>
            {tableHeads.map((headerItem) => (
              <StyledTableCell align={headerItem.alignment}>
                <StyledTableCellContent
                  style={
                    headerItem.alignment === "right"
                      ? { justifyContent: "flex-end" }
                      : { justifyContent: "flex-start" }
                  }
                >
                  {headerItem.label}
                  {headerItem.sort && (
                    <StyledTableButtonGroups>
                      <StyledTableSortButton
                        type="button"
                        className={
                          sortFilter === headerItem.sortFilter && sortDirection === "ASC"
                            ? "active"
                            : ""
                        }
                        onClick={handleSort(headerItem.sortFilter, "ASC")}
                      >
                        <FaArrowUp />
                      </StyledTableSortButton>
                      <StyledTableSortButton
                        type="button"
                        className={
                          sortFilter === headerItem.sortFilter && sortDirection === "DESC"
                            ? "active"
                            : ""
                        }
                        onClick={handleSort(headerItem.sortFilter, "DESC")}
                      >
                        <FaArrowDown />
                      </StyledTableSortButton>
                    </StyledTableButtonGroups>
                  )}
                </StyledTableCellContent>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow key={row.id}>
              <StyledTableCell align="left" sx={{ color: "#0076B2" }} onClick={handleView(row.id)}>
                {row.title}
              </StyledTableCell>
              <StyledTableCell align="left">{row.team?.name}</StyledTableCell>
              <StyledTableCell align="right" onClick={handleView(row.id)}>
                {row.createdAt}
              </StyledTableCell>
              <StyledTableCell align="right">
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={handleCopy(row.id)}
                >
                  <FaCopy />
                </MDButton>
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={handleView(row.id)}
                >
                  <FaEye />
                </MDButton>
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={handleEdit(row.id)}
                >
                  <FaEdit />
                </MDButton>
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  sx={{ minWidth: "10px", width: "20px", padding: "0px" }}
                  onClick={handleDelete(row.id)}
                >
                  <FaTrash />
                </MDButton>
              </StyledTableCell>
            </TableRow>
          ))}
          {data.length === 0 && (
            <TableRow>
              <StyledTableCell align="center" color="dark" colSpan={4}>
                There is no content.
              </StyledTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <MDSnackbar
        icon="notificaiton"
        color="error"
        dateTime=""
        title="Delete Poll"
        content={
          <ConfirmSnackBarContent
            title="Are you sure to delete this data?"
            confirmHandler={confirmDelete}
          />
        }
        open={show}
        close={toggleSnackbar}
      />
    </TableContainer>
  );
}

PollsTable.defaultProps = {
  data: [],
  deleteHandler: {},
  sortHandler: {},
};

// Typechecking props for the DashboardNavbar
PollsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  deleteHandler: PropTypes.func,
  sortHandler: PropTypes.func,
};

export default PollsTable;
