import { useEffect, useState, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { pageHeaderContainer, flexContainer } from "layouts/common/styles";
import { UserRole } from "layouts/common/constants";

import ToastrService from "services/ToastrService";
import { retriveSponsors, deleteSponsor } from "store/slices/sponsorSlice";

import SponsorsTable from "./components/SponsorsTable";

function Sponsors() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminProfile = useSelector((state) => state.users.profile);

  const totalCount = useSelector((state) => state.sponsors.totalCount);
  const sponsors = useSelector((state) => state.sponsors.sponsors);
  const teams = useSelector((state) => state.teams);

  // Search options.
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedTeams, setSelectedTeams] = useState([]);

  const fetchBySearchOptions = useCallback(() => {
    let search = "";
    if (adminProfile.role === UserRole.SuperAdmin && selectedTeams.length > 0) {
      search = selectedTeams.map((d) => d.id).join(",");
    } else if (adminProfile.role === UserRole.TeamAdmin) {
      search = adminProfile.teamId;
    }
    dispatch(retriveSponsors({ search, page, rowsPerPage }));
  }, [dispatch, selectedTeams, page, rowsPerPage]);

  useEffect(() => {
    fetchBySearchOptions();
  }, [fetchBySearchOptions]);

  const deleteHandler = (_id) => {
    dispatch(deleteSponsor(_id))
      .unwrap()
      .then(() => {
        ToastrService.success("Successfully deleted sponsor");
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to delete sponsor data.");
      });
  };

  const handleAdd = () => {
    navigate("/sponsor/add");
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };

  const changePageNum = (direct) => {
    const maxPageNum = Math.floor((totalCount - 1) / rowsPerPage);
    if (page === 0 && direct === -1) return;
    if (page === maxPageNum && direct === 1) return;

    setPage((prevState) => prevState + direct);
  };

  return (
    <DashboardLayout>
      <MDBox py={3}>
        <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
          <MDTypography fontSize="20px" fontWeight="bold">
            Sponsors
          </MDTypography>
          <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
            <MDButton size="small" color="secondary" onClick={() => handleAdd()}>
              sponsor +
            </MDButton>
          </MDBox>
        </MDBox>
        {adminProfile.role === UserRole.SuperAdmin && (
          <MDBox mb={3}>
            <MDTypography fontSize="12px" fontWeight="bold">
              Select Teams:{" "}
            </MDTypography>
            <Autocomplete
              multiple
              value={selectedTeams}
              options={teams}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => setSelectedTeams(v)}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              sx={{ maxWidth: "568px", width: "100%" }}
              placeholder="Select Teams"
            />
          </MDBox>
        )}
        <SponsorsTable data={sponsors} deleteHandler={deleteHandler} />
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end" my={3}>
          <FormControl>
            <InputLabel id="rows_per_page">Rows per page</InputLabel>
            <Select
              labelId="rows_per_page"
              id="rows_per_page_select"
              value={rowsPerPage}
              label="Rows per page"
              onChange={handleChangeRowsPerPage}
              sx={{ height: "38px", width: "150px", minWidth: "50px" }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
              <MenuItem value={20}>20</MenuItem>
            </Select>
          </FormControl>
          <MDTypography fontSize="12px" fontWeight="bold">
            {totalCount > 0 ? page * rowsPerPage + 1 : "0"}
            &nbsp;~&nbsp;
            {totalCount > page * rowsPerPage + rowsPerPage
              ? page * rowsPerPage + rowsPerPage
              : totalCount}
            &nbsp;of&nbsp;
            {totalCount}
          </MDTypography>

          <MDButton icon="text" onClick={() => changePageNum(-1)}>
            <FaArrowLeft />
          </MDButton>
          <MDButton icon="text" onClick={() => changePageNum(1)}>
            <FaArrowRight />
          </MDButton>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Sponsors;
