import http from "../apis/http-common";

const getAll = () => http.get("/team");

const retrive = (page, rowsPerPage, search) => {
  const skip = page * rowsPerPage;

  return http.get(`/team/list/detail?skip=${skip}&take=${rowsPerPage}&search=${search}`);
};

const get = (id) => http.get(`/team/${id}`);

const create = (data) => http.post("/team", data);

const update = (id, data) => http.put(`/team/${id}`, data);

const remove = (id) => http.delete(`/team/${id}`);

const activate = (id, active) => http.put(`/team/${id}/${active}`);

const upload = (formData) => http.post("/upload/team", formData);

const getTeamToken = (id) => http.get(`/token/${id}`);

const createToken = (data) => http.post("/token", data);

const updateToken = (id, data) => http.put(`/token/${id}`, data);

const removeToken = (id) => http.delete(`/token/${id}`);

const getTeamPlayers = (teamId) => http.get(`/player/list?teams=${teamId}`);

const createTeamPlayer = (data) => http.post("/player", data);

const updateTeamPlayer = (id, data) => http.put(`/player/${id}`, data);

const removeTeamPlayer = (id) => http.delete(`/player/${id}`);

const TeamsService = {
  getAll,
  retrive,
  get,
  create,
  update,
  remove,
  activate,
  upload,
  getTeamToken,
  createToken,
  updateToken,
  removeToken,
  getTeamPlayers,
  createTeamPlayer,
  updateTeamPlayer,
  removeTeamPlayer,
};

export default TeamsService;
