import axios from "axios";

import { GOOGLE_FONT_API_KEY } from "config";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  loading: false,
  fonts: [],
};

export const retriveFonts = createAsyncThunk("/app/fonts", async () => {
  const res = await axios.get(
    `https://www.googleapis.com/webfonts/v1/webfonts?key=${GOOGLE_FONT_API_KEY}`,
  );
  return res.data;
});

const appSlice = createSlice({
  name: "app",
  initialState,
  extraReducers: {
    [retriveFonts.fulfilled]: (state, action) => {
      const data = action.payload.items || [];
      state.fonts = data.map((d) => d.family);
    },
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setLoading } = appSlice.actions;
const { reducer } = appSlice;
export default reducer;
