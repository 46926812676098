import { useState } from "react";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import FormField from "components/FormField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { customFormContainer } from "layouts/common/styles";
import MDButton from "components/MDButton";
import TransactionService from "services/TransactionService";
import { setLoading } from "store/appSlice";
import ToastrService from "services/ToastrService";

function TransferUserForm({ user, teamId, updateUserInfo }) {
  const dispatch = useDispatch();
  const [showResult, setShowResult] = useState(false);
  const [data, setData] = useState({
    tokenAmount: 0,
    kudosAmount: 0,
    reason: "",
  });

  const [result, setResult] = useState({});

  const getUserInfo = () => {
    updateUserInfo(teamId, user.email);
  };

  const handleSubmitChange = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleSubmitChangeNumber = (field, value) => {
    setData({ ...data, [field]: parseFloat(value) });
  };

  const handleComplete = () => {
    if (data.tokenAmount === 0 && data.kudosAmount === 0) return;
    dispatch(setLoading(true));
    const temp = { ...data };
    temp.receiverId = user.id;

    TransactionService.transfer(temp)
      .then((res) => {
        if (res.data.status === "Success") {
          ToastrService.success("Successfully transferred to token/kudos");
          setShowResult(true);
          setResult(res.data);
          getUserInfo();
        } else {
          ToastrService.error(data.message || "Failed to transfer token/kudos");
        }
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(e.response?.data?.message?.message || "Failed to transfer token/kudos");
        dispatch(setLoading(false));
      });
  };

  const handleBack = () => {
    setShowResult(false);
    setResult({});
  };

  return (
    <MDBox sx={(theme) => customFormContainer(theme)}>
      <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
        Transfer Details
      </MDTypography>
      {showResult ? (
        <MDBox>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Tokens transferreed
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">{result?.tokenAmount}</MDTypography>
            </Grid>
          </Grid>
          {result?.tokenAmount > 0 && (
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Tokens transferred to
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <MDTypography fontSize="15px">
                  {user?.firstName} {user?.lastName}
                </MDTypography>
                <MDTypography fontSize="15px" sx={{ color: "gray" }}>
                  New token balance = {user?.tokenAmount}
                </MDTypography>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Kudos transferreed
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">{result?.kudosAmount}</MDTypography>
            </Grid>
          </Grid>
          {result?.kudosAmount > 0 && (
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={2.5}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  kudos transferred to
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <MDTypography fontSize="15px">
                  {user?.firstName} {user?.lastName}
                </MDTypography>
                <MDTypography fontSize="15px" sx={{ color: "gray" }}>
                  New kudos balance = {user?.kudosAmount}
                </MDTypography>
              </Grid>
            </Grid>
          )}
          <Grid container justifyContent="flex-end">
            <MDButton color="secondary" size="large" onClick={handleBack}>
              Back to Token Main
            </MDButton>
          </Grid>
        </MDBox>
      ) : (
        <MDBox>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Transfer To
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">
                {user.firstName} {user.lastName}
              </MDTypography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of tokens to transfer
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type="number"
                value={data.tokenAmount}
                onChange={(e) => handleSubmitChangeNumber("tokenAmount", e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                No of kudos to transfer
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type="number"
                value={data.kudosAmount}
                onChange={(e) => handleSubmitChangeNumber("kudosAmount", e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Reason to transfer
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <FormField
                value={data.reason}
                onChange={(e) => handleSubmitChange("reason", e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <MDButton color="secondary" size="large" onClick={handleComplete}>
              Complete Transfer
            </MDButton>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

TransferUserForm.defaultProps = {
  user: null,
  teamId: "",
  updateUserInfo: {},
};

TransferUserForm.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  teamId: PropTypes.string,
  updateUserInfo: PropTypes.func,
};

export default TransferUserForm;
