import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ColorPicker from "components/ColorPicker";

import { customFormContainer } from "layouts/common/styles";
import fontList from "../../../AddTeam/data/fonts";

function ThemeDetail({ item }) {
  return (
    <MDBox>
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Team Name
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{item?.name}</MDTypography>
          </Grid>
        </Grid>
        <MDTypography
          fontSize="15px"
          fontWeight="bold"
          mt={4}
          mb={3}
          sx={{ textDecoration: "underline" }}
        >
          Theme Details
        </MDTypography>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
          Colours
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Background theme</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">{item?.backgroundTheme}</MDTypography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Landing Page Background Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.landingPageBackgroundColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Landing Page Backgroud Top Layer Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.landingPageBackgroundTopLayerColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Landing Page Background Bottom Layer Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker
              initialColor={item?.landingPageBackgroundBottomLayerColor}
              active={false}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Landing Page Text Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.landingPageTextColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Auth Input Background Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.authInputBackgroundColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Primary Text Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.primaryTextColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Heading Text Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.headingTextColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Menu Text Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.menuTextColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Secondary Text Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.secondaryTextColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Primary Button Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.primaryButtonColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Secondary Button Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.secondaryButtonColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Tab Hightlight Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.tabHighlightColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Button Text Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.buttonTextColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Token Text Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.tokenTextColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Kudos Text Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.kudosTextColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Kudos Icon Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.kudosIconColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Date Text Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.dateTextColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Success Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.successColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Error Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.errorColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Warning Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.warningColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Icon Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.iconColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Landing Page Card Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.landingPageCardColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Main Page Card Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.mainPageCardColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Main Page Background Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.mainPageBackgroundColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Main Page Header Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.mainPageHeaderColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Main Page Sidebar Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.mainPageSidebarColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Modal Background Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.modalBackgroundColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Memebership Tier One Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.membershipTierOneColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Membership Tier Two Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.membershipTierTwoColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Membership Tier Three Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.membershipTierThreeColor} active={false} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Membership Tier Four Color</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ColorPicker initialColor={item?.membershipTierFourColor} active={false} />
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
          Fonts
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Primary font (Headings)
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{fontList[item?.primaryFont || 0]?.label}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Secondary font (Sub headings, Main text)
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{fontList[item?.secondaryFont || 0]?.label}</MDTypography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Landing page hero image
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">
              {item?.landingPageHeroImage && item?.landingPageHeroImage !== "" && (
                <MDAvatar
                  src={item?.landingPageHeroImage}
                  alt="Avatar"
                  sx={{ width: "250px", height: "250px" }}
                />
              )}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              App Logo
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">
              {item?.appLogoImage && item?.appLogoImage !== "" && (
                <MDAvatar
                  src={item?.appLogoImage}
                  alt="Avatar"
                  sx={{ width: "250px", height: "250px" }}
                />
              )}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px" fontWeight="bold">
              PWA Icon
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">
              {item?.pwaIcon && item?.pwaIcon !== "" && (
                <MDAvatar
                  src={item?.pwaIcon}
                  alt="Avatar"
                  sx={{ width: "250px", height: "250px" }}
                />
              )}
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

ThemeDetail.defaultProps = {
  item: null,
};

ThemeDetail.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
};

export default ThemeDetail;
