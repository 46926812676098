const assetCategories = [
  "Merchandise",
  "VIP Pass",
  "Tickets",
  "Exclusive Content",
  "In game asset",
  "Digital collectible",
];

export default assetCategories;
