import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { SvgIcon } from "@mui/material";
import MDButton from "components/MDButton";

import { customTableHeader } from "layouts/common/styles";

import { ReactComponent as DeleteIcon } from "assets/images/icons/actions/delete.svg";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontSize: "13px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "13px",
  },
}));

const StyledTableButton = styled(MDButton)(({ theme }) => ({
  [`&.MuiButton-root`]: {
    padding: "0px",
    color: theme.palette.background.sidenav,
    background: "#FFFFFF",
    border: "1px solid #C4C4C4",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    minWidth: "30px",
    marginRight: "5px",
  },
}));

function WinnerTableMilestone({ milestone, data }) {
  const handleView = () => {};
  return (
    <TableContainer component={Paper} sx={{ background: "transparent", boxShadow: "none" }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead sx={(theme) => customTableHeader(theme)}>
          <TableRow>
            <StyledTableCell align="left">Name</StyledTableCell>
            <StyledTableCell align="left">Email</StyledTableCell>
            <StyledTableCell align="left">Tokens Won</StyledTableCell>
            <StyledTableCell align="left">Kudos Won</StyledTableCell>
            <StyledTableCell align="left">Asset</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <TableRow key={row.id}>
              <StyledTableCell align="left" component="th" scope="row">
                {row.user.firstName}&nbsp;{row.user.lastName}
              </StyledTableCell>
              <StyledTableCell align="left" component="th" scope="row">
                {row.user.email}
              </StyledTableCell>
              <StyledTableCell align="left">
                {milestone && milestone.occurCount
                  ? milestone.rewardTokenPerPlayer * milestone.occurCount
                  : 0}
              </StyledTableCell>
              <StyledTableCell align="left">
                {milestone && milestone.occurCount
                  ? milestone.rewardKudosPerPlayer * milestone.occurCount
                  : 0}
              </StyledTableCell>
              <StyledTableCell align="left">{milestone?.asset?.title}</StyledTableCell>
              <StyledTableCell align="center">
                <StyledTableButton
                  size="small"
                  sx={{ width: "50px" }}
                  color="secondary"
                  onClick={() => {
                    handleView();
                  }}
                >
                  View
                </StyledTableButton>
                <MDButton
                  variant="icon"
                  size="small"
                  color="secondary"
                  sx={{ fontSize: "20px", padding: "0px", minWidth: "30px" }}
                >
                  <SvgIcon>
                    <DeleteIcon />
                  </SvgIcon>
                </MDButton>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

WinnerTableMilestone.defaultProps = {
  milestone: null,
  data: [],
};

// Typechecking props for the DashboardNavbar
WinnerTableMilestone.propTypes = {
  milestone: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.arrayOf(PropTypes.any),
};

export default WinnerTableMilestone;
