import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";

import { Autocomplete, Checkbox, Divider, Grid, TextField } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";
import {
  MiniGameType,
  UserRole,
  RewardActionType,
  RewardActionRewardItemType,
  MarketplaceItemType,
  MarketplaceItemSubType,
} from "layouts/common/constants";

import GameService from "services/GamesService";
// import ToastrService from "services/ToastrService";

import { setLoading } from "store/appSlice";

import FormField from "components/FormField";
import styled from "@emotion/styled";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .MuiOutlinedInput-root .MuiAutocomplete-input`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
    padding: 0,
    width: "35px",
  },
  [`& .MuiOutlinedInput-root`]: {
    padding: "0px",
    paddingLeft: "10px",
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

function MiniGameRewardForm({ item, prevHandler, nextHandler, changeHandler }) {
  const initialData = {
    team: {
      name: "Adelaide Giants",
    },
    match: {
      title: "No match / event allocation",
    },
    title: "Zambrero Hit Nation",
    description: "",
    type: 0,
    eligbleKudos: 0,
    eligbleToken: 0,
    rewardKudos: 0,
    rewardToken: 0,
    rewardKudosWinnerTotal: 1000,
    rewardTokenWinnerTotal: 10000,
    rewardDistribution: [
      {
        winnerOrder: 1,
        rewardKudos: 500,
        rewardToken: 5000,
      },
      {
        winnerOrder: 2,
        rewardKudos: 250,
        rewardToken: 2500,
      },
      {
        winnerOrder: 3,
        rewardKudos: 100,
        rewardToken: 1000,
      },
      {
        winnerOrder: 4,
        rewardKudos: 75,
        rewardToken: 750,
      },
      {
        winnerOrder: 5,
        rewardKudos: 75,
        rewardToken: 750,
      },
    ],
    asset: null,
    enableAssetReward: false,
    rewardAssetCount: 0,
    winnerLimit: 0,
    rewardAction: [
      {
        type: 0,
        videoUrl: "",
        rewardActionRewardItems: [
          {
            type: 0,
            count: 0,
          },
        ],
        rewardActionQuestions: [],
        maximumActionPerUserPerDay: 0,
      },
    ],
    rewardMarketplaceItem: [
      {
        type: 0,
        subType: 0,
        description: "",
        itemCount: 0,
        itemCost: 0,
      },
    ],
  };

  const initialState = {
    id: null,
    team: null,
    match: null,
    title: "",
    description: "",
    eligbleKudos: 0,
    eligbleToken: 0,
    rewardKudos: 0,
    rewardToken: 0,
    rewardKudosWinnerTotal: 0,
    rewardTokenWinnerTotal: 0,
    rewardDistribution: [
      {
        winnerOrder: 1,
        rewardKudos: 0,
        rewardToken: 0,
      },
    ],
    asset: null,
    enableAssetReward: false,
    rewardAssetCount: 0,
    winnerLimit: 0,
    rewardAction: [
      {
        type: 0,
        videoUrl: "",
        rewardActionRewardItems: [
          {
            type: 0,
            count: 0,
          },
        ],
        rewardActionQuestions: [],
        maximumActionPerUserPerDay: 0,
      },
    ],
    rewardMarketplaceItem: [
      {
        type: 0,
        subType: 0,
        description: "",
        itemCount: 0,
        itemCost: 0,
      },
    ],
  };

  const resetState = {
    eligbleKudos: 0,
    eligbleToken: 0,
    rewardKudos: 0,
    rewardToken: 0,
    rewardKudosWinnerTotal: 0,
    rewardTokenWinnerTotal: 0,
    rewardDistribution: [
      {
        winnerOrder: 1,
        rewardKudos: 0,
        rewardToken: 0,
      },
    ],
    enableAssetReward: false,
    rewardAssetCount: 0,
    winnerLimit: 0,
    rewardAction: [
      {
        type: 0,
        videoUrl: "",
        rewardActionRewardItems: [
          {
            type: 0,
            count: 0,
          },
        ],
        rewardActionQuestions: [],
        maximumActionPerUserPerDay: 0,
      },
    ],
    rewardMarketplaceItem: [
      {
        type: 0,
        subType: 0,
        description: "",
        itemCount: 0,
        itemCost: 0,
      },
    ],
  };

  const dispatch = useDispatch();
  const adminProfile = useSelector((state) => state.users.profile);

  const [minigame, setMiniGame] = useState(initialState);
  const [validationMsg, setValidationMsg] = useState(resetState);
  const [bonusAssets, setBonusAssets] = useState([]);

  useEffect(() => {
    if (item === null) {
      setMiniGame(initialState);
    } else {
      const newItem = { ...item };

      if (!newItem.id) {
        newItem.eligbleKudos = item.eligbleKudos || initialState.eligbleKudos;
        newItem.eligbleToken = item.eligbleToken || initialState.eligbleToken;
        newItem.rewardKudos = item.rewardKudos || initialState.rewardKudos;
        newItem.rewardToken = item.rewardToken || initialState.rewardToken;
        newItem.rewardKudosWinnerTotal =
          item.rewardKudosWinnerTotal || initialState.rewardKudosWinnerTotal;
        newItem.rewardTokenWinnerTotal =
          item.rewardTokenWinnerTotal || initialState.rewardTokenWinnerTotal;
        newItem.rewardDistribution = item.rewardDistribution || initialState.rewardDistribution;
        newItem.rewardAssetCount = item.rewardAssetCount || initialState.rewardAssetCount;
        newItem.winnerLimit = item.winnerLimit || initialState.winnerLimit;
        newItem.rewardAction = item.rewardAction || initialState.rewardAction;
        newItem.rewardMarketplaceItem =
          item.rewardMarketplaceItem || initialState.rewardMarketplaceItem;
        newItem.enableAssetReward = item.enableAssetReward || initialState.enableAssetReward;
      }

      setMiniGame(newItem);
    }
  }, [item]);

  const getBonusAssets = useCallback(() => {
    let search = null;
    if (adminProfile.role === UserRole.TeamAdmin) search = adminProfile.teamId;
    else if (minigame.team) {
      search = minigame.team.id;
    } else {
      setBonusAssets([]);
      return;
    }
    GameService.getBonusAssets(search, 0, 9999).then((response) => {
      setBonusAssets(response.data.data);
    });
  }, [minigame?.team, adminProfile]);

  useEffect(() => {
    getBonusAssets();
  }, [getBonusAssets]);

  const validate = () => {
    let validated = true;
    let errorMsg = validationMsg;
    const keys = Object.keys(resetState);
    for (let i = 0; i < keys.length; i += 1) {
      let msg = "";
      if (minigame[keys[i]] === "" || minigame[keys[i]] === null) {
        msg = "Field should not be left empty";
        validated = false;
      }
      errorMsg = { ...errorMsg, [keys[i]]: msg };
    }

    setValidationMsg(errorMsg);

    return validated;
  };

  const handleChangeActionType = (actionIndex, value) => {
    const temp = [...minigame.rewardAction];
    temp[actionIndex].type = value;
    if (value === 0) {
      temp[actionIndex].videoUrl = "";
      temp[actionIndex].rewardActionQuestions = [];
    } else if (value === 1) {
      temp[actionIndex].videoUrl = "";
      temp[actionIndex].rewardActionQuestions = [
        { question: "", options: [{ optionText: "" }, { optionText: "" }] },
      ];
    }

    setMiniGame({ ...minigame, rewardAction: temp });
  };

  const handleSubmitChange = (field, value) => {
    setMiniGame({ ...minigame, [field]: value });
  };

  const handleSubmitChangeNumber = (field, value) => {
    setMiniGame({ ...minigame, [field]: parseFloat(value) });
  };

  const handleChangeActionField = (actionIndex, field, value) => {
    const temp = [...minigame.rewardAction];
    temp[actionIndex] = { ...minigame.rewardAction[actionIndex], [field]: value };
    setMiniGame((prevState) => ({ ...prevState, rewardAction: temp }));
  };

  const handleChangeActionRewardField = (actionIndex, rewardIndex, field, value) => {
    const temp = [...minigame.rewardAction];
    temp[actionIndex].rewardActionRewardItems[rewardIndex] = {
      ...minigame.rewardAction[actionIndex].rewardActionRewardItems[rewardIndex],
      [field]: value,
    };
    setMiniGame((prevState) => ({ ...prevState, rewardAction: temp }));
  };

  const handleChangeActionQuestion = (actionIndex, questionIndex, value) => {
    const temp = [...minigame.rewardAction];
    temp[actionIndex].rewardActionQuestions[questionIndex].question = value;
    setMiniGame((prevState) => ({ ...prevState, rewardAction: temp }));
  };

  const handleChangeActionOption = (actionIndex, questionIndex, optionIndex, value) => {
    const temp = [...minigame.rewardAction];
    temp[actionIndex].rewardActionQuestions[questionIndex].options[optionIndex].optionText = value;
    setMiniGame((prevState) => ({ ...prevState, rewardAction: temp }));
  };

  const handleChangeGameItemField = (itemIndex, field, value) => {
    const temp = [...minigame.rewardMarketplaceItem];
    temp[itemIndex] = { ...minigame.rewardMarketplaceItem[itemIndex], [field]: value };
    setMiniGame((prevState) => ({ ...prevState, rewardMarketplaceItem: temp }));
  };

  const handleUpdateDestribution = (_order, _field, _value) => {
    const idx = minigame.rewardDistribution.findIndex((d) => d.winnerOrder === _order);
    const temp = [...minigame.rewardDistribution];
    temp[idx] = { ...temp[idx], [_field]: parseFloat(_value) };

    const totalWinnerRewardKudos = temp.reduce(
      (n, { rewardKudos }) => Number(n) + Number(rewardKudos),
      0,
    );
    const totalWinnerRewardTokens = temp.reduce(
      (n, { rewardToken }) => Number(n) + Number(rewardToken),
      0,
    );

    setMiniGame((data) => ({
      ...data,
      rewardDistribution: temp,
      rewardKudosWinnerTotal: totalWinnerRewardKudos,
      rewardTokenWinnerTotal: totalWinnerRewardTokens,
    }));
  };

  const handleAddWinner = () => {
    const newOrder = minigame.rewardDistribution.length + 1;
    const temp = [
      ...minigame.rewardDistribution,
      {
        winnerOrder: newOrder,
        rewardKudos: 0,
        rewardToken: 0,
      },
    ];

    setMiniGame({ ...minigame, rewardDistribution: temp });
  };

  const handleRemoveWinner = () => {
    const temp = [...minigame.rewardDistribution];
    temp.pop();
    setMiniGame({ ...minigame, rewardDistribution: temp });
  };

  const handleAddAction = () => {
    const temp = minigame.rewardAction
      ? [
          ...minigame.rewardAction,
          {
            type: 0,
            videoUrl: "",
            rewardActionQuestions: [],
            rewardActionRewardItems: [
              {
                type: 0,
                count: 0,
              },
            ],
            maximumActionPerUserPerDay: 0,
          },
        ]
      : [
          {
            type: 0,
            videoUrl: "",
            rewardActionQuestions: [],
            rewardActionRewardItems: [
              {
                type: 0,
                count: 0,
              },
            ],
            maximumActionPerUserPerDay: 0,
          },
        ];

    setMiniGame({ ...minigame, rewardAction: temp });
  };

  const handleRemoveAction = () => {
    const temp = [...minigame.rewardAction];
    temp.pop();
    setMiniGame({ ...minigame, rewardAction: temp });
  };

  const handleAddQuestion = (actionIndex) => {
    const temp = [...minigame.rewardAction];
    temp[actionIndex].rewardActionQuestions.push({
      question: "",
      options: [{ optionText: "" }, { optionText: "" }],
    });
    setMiniGame((prevState) => ({ ...prevState, rewardAction: temp }));
  };

  const handleRemoveQuestion = (actionIndex) => {
    const temp = [...minigame.rewardAction];
    temp[actionIndex].rewardActionQuestions.pop();
    setMiniGame((prevState) => ({ ...prevState, rewardAction: temp }));
  };

  const handleAddOption = (actionIndex, questionIdx) => {
    const temp = [...minigame.rewardAction];
    temp[actionIndex].rewardActionQuestions[questionIdx].options.push({ optionText: "" });
    setMiniGame((prevState) => ({ ...prevState, rewardAction: temp }));
  };

  const handleRemoveOption = (actionIndex, questionIdx) => {
    const temp = [...minigame.rewardAction];
    temp[actionIndex].rewardActionQuestions[questionIdx].options.pop();
    setMiniGame((prevState) => ({ ...prevState, rewardAction: temp }));
  };

  const handleAddItemReward = (actionIndex) => {
    const temp = [...minigame.rewardAction];

    temp[actionIndex].rewardActionRewardItems.push({
      type: 0,
      count: 0,
    });

    setMiniGame({ ...minigame, rewardAction: temp });
  };

  const handleRemoveItemReward = (actionIndex) => {
    const temp = [...minigame.rewardAction];
    temp[actionIndex].rewardActionRewardItems.pop();
    setMiniGame({ ...minigame, rewardAction: temp });
  };

  const handleAddGameItem = () => {
    const temp = minigame.rewardMarketplaceItem
      ? [
          ...minigame.rewardMarketplaceItem,
          {
            type: 0,
            subType: 0,
            description: "",
            itemCount: 0,
            itemCost: 0,
          },
        ]
      : [
          {
            type: 0,
            subType: 0,
            description: "",
            itemCount: 0,
            itemCost: 0,
          },
        ];

    setMiniGame({ ...minigame, rewardMarketplaceItem: temp });
  };

  const handleRemoveGameItem = () => {
    const temp = [...minigame.rewardMarketplaceItem];
    temp.pop();
    setMiniGame({ ...minigame, rewardMarketplaceItem: temp });
  };

  const handleNext = () => {
    if (!validate()) {
      return;
    }
    const temp = minigame;

    if (changeHandler(temp)) {
      nextHandler();
    }
  };

  const handleBack = () => {
    prevHandler();
  };

  async function getMiniGame(_id) {
    const temp = { ...initialData };
    dispatch(setLoading(true));
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 100);
    temp.id = _id;
    return temp;
  }

  const handleReset = async () => {
    if (!minigame.id) {
      let temp = { ...minigame };
      const keys = Object.keys(resetState);
      for (let i = 0; i < keys.length; i += 1) {
        temp = { ...temp, [keys[i]]: initialState[keys[i]] };
      }
      setMiniGame(temp);
    } else {
      const data = await getMiniGame(minigame.id);
      const newItem = { ...data };

      let temp = { ...minigame };
      const keys = Object.keys(resetState);
      for (let i = 0; i < keys.length; i += 1) {
        temp = { ...temp, [keys[i]]: newItem[keys[i]] };
      }
      setMiniGame(temp);
    }
  };

  return (
    <MDBox>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ maxWidth: "500px", width: "100%" }}>
          {minigame.id
            ? "Edit Mini Game |  Rewards + Eligibility"
            : "Add  Mini Game |  Rewards + Eligibility"}
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handleBack()}>
            Back
          </MDButton>
          <MDButton size="small" onClick={() => handleReset()}>
            Reset
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <Grid container spacing={2} mb={4} alignItems="center">
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Select Team
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{minigame?.team?.name}</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Match / Event
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{minigame?.match?.title}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Mini Game Title
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{minigame?.title}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Mini Game Description
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">{minigame?.description}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Game Type
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDTypography fontSize="15px">
              {minigame ? MiniGameType[minigame?.type] : ""}
            </MDTypography>
          </Grid>
        </Grid>
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Eligibility
        </MDTypography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">No of Kudos Points</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              value={minigame.eligbleKudos}
              onChange={(e) => handleSubmitChangeNumber("eligbleKudos", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">No of Tokens</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              value={minigame.eligbleToken}
              onChange={(e) => handleSubmitChangeNumber("eligbleToken", e.target.value)}
            />
          </Grid>
        </Grid>
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Rewards
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} md={6}>
            <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
              For participation
            </MDTypography>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  No of Kudos Points(Per player per play)
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="number"
                  value={minigame.rewardKudos}
                  onChange={(e) => handleSubmitChangeNumber("rewardKudos", e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  No of Tokens Points(Per campaign)
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="number"
                  value={minigame.rewardToken}
                  onChange={(e) => handleSubmitChangeNumber("rewardToken", e.target.value)}
                />
              </Grid>
            </Grid>
            <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
              For Winners
            </MDTypography>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px">No of Kudos Points(Total)</MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography fontSize="15px">{minigame.rewardKudosWinnerTotal}</MDTypography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px">No of Tokens(Total)</MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography fontSize="15px">{minigame.rewardTokenWinnerTotal}</MDTypography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <MDTypography fontSize="15px">No of Winners</MDTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography fontSize="15px">{minigame.rewardDistribution.length}</MDTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
              Reward distribution
            </MDTypography>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={4} sm={4}>
                &nbsp;
              </Grid>
              <Grid item xs={4} sm={4}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  KUDOS
                </MDTypography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <MDTypography fontSize="15px" fontWeight="bold">
                  Tokens
                </MDTypography>
              </Grid>
            </Grid>
            {minigame.rewardDistribution
              .sort((a, b) => {
                if (a.winnerOrder > b.winnerOrder) return 1;
                if (a.winnerOrder < b.winnerOrder) return -1;
                return 0;
              })
              .map((d) => (
                <Grid key={`winner-${d.winnerOrder}`} container spacing={2} mb={2}>
                  <Grid item xs={4} sm={4}>
                    <MDTypography fontSize="15px">Winner #{d.winnerOrder}</MDTypography>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <FormField
                      id={`winner-kudos-${d.winnerOrder}`}
                      type="number"
                      value={d.rewardKudos}
                      onChange={(e) =>
                        handleUpdateDestribution(d.winnerOrder, "rewardKudos", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <FormField
                      id={`winner-token-${d.winnerOrder}`}
                      type="number"
                      value={d.rewardToken}
                      onChange={(e) =>
                        handleUpdateDestribution(d.winnerOrder, "rewardToken", e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              ))}
            <MDBox
              sx={(theme) => {
                flexContainer(theme);
              }}
            >
              <MDButton
                size="small"
                color="secondary"
                sx={{ marginRight: "10px" }}
                onClick={() => handleAddWinner()}
              >
                Winner +
              </MDButton>
              {minigame.rewardDistribution.length > 1 && (
                <MDButton size="small" color="secondary" onClick={() => handleRemoveWinner()}>
                  Winner -
                </MDButton>
              )}
            </MDBox>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Send asset as reward?</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox
              checked={minigame.enableAssetReward}
              onChange={(e) => {
                handleSubmitChange("enableAssetReward", e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">select asset</MDTypography>
          </Grid>
          <Grid item xs={12} sm={4} md={5}>
            <StyledAutocomplete
              value={minigame.asset}
              disabled={!minigame.enableAssetReward}
              options={bonusAssets}
              getOptionLabel={(option) => option?.title}
              onChange={(e, v) => {
                handleSubmitChange("asset", v);
              }}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose Asset"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">Number of top ranked scores to send to</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              disabled={!minigame.enableAssetReward}
              type="number"
              min="0"
              value={Math.abs(minigame.winnerLimit)}
              onChange={(e) => handleSubmitChangeNumber("winnerLimit", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3}>
            <MDTypography fontSize="15px">No of Bonus Asset to send per winner</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              disabled={!minigame.enableAssetReward}
              type="number"
              min="0"
              value={Math.abs(minigame.rewardAssetCount)}
              onChange={(e) => handleSubmitChangeNumber("rewardAssetCount", e.target.value)}
            />
          </Grid>
        </Grid>
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Rewards (In Game)
        </MDTypography>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
          Reward Actions (Paywall)
        </MDTypography>
        {minigame?.rewardAction?.map((action, actionIndex) => (
          <MDBox>
            <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
              Action #{actionIndex + 1}
            </MDTypography>
            <Grid container spacing={2} mb={4} alignItems="center">
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px">Select Action</MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <StyledAutocomplete
                  value={action.type}
                  options={Object.keys(RewardActionType)}
                  getOptionLabel={(option) => `${RewardActionType[option]} `}
                  onChange={(e, v) => handleChangeActionType(actionIndex, parseFloat(v))}
                  renderInput={(params) => (
                    <TextField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                />
              </Grid>
            </Grid>
            {action.type === 0 && (
              <Grid container spacing={2} mb={4} alignItems="center">
                <Grid item xs={12} sm={3} md={2.5}>
                  <MDTypography fontSize="15px">Select URL</MDTypography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <FormField
                    value={action.videoUrl}
                    onChange={(e) => {
                      handleChangeActionField(actionIndex, "videoUrl", e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            )}
            {action.type === 1 && (
              <Grid container spacing={2} mb={4} alignItems="center">
                <Grid item xs={12} sm={12}>
                  {action.rewardActionQuestions?.map((actionQuestion, actionQuestionIndex) => (
                    <MDBox key={`actionQuestion-${actionQuestionIndex.toString()}`} mb={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3} md={2.5}>
                          <MDTypography fontSize="15px">
                            Question {actionQuestionIndex + 1}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <FormField
                            value={actionQuestion.question}
                            onChange={(e) =>
                              handleChangeActionQuestion(
                                actionIndex,
                                actionQuestionIndex,
                                e.target.value,
                              )
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          gap="10px"
                        >
                          <MDButton
                            size="small"
                            color="secondary"
                            onClick={() => handleAddOption(actionIndex, actionQuestionIndex)}
                          >
                            Option +
                          </MDButton>
                          {actionQuestion.options.length > 2 && (
                            <MDButton
                              size="small"
                              color="secondary"
                              onClick={() => handleRemoveOption(actionIndex, actionQuestionIndex)}
                            >
                              Option -
                            </MDButton>
                          )}
                          <MDTypography fontSize="12px">Minimum 2 Options</MDTypography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <MDBox>
                            <Grid container spacing={2}>
                              {actionQuestion.options.map((option, optionIdx) => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  key={`survey-option-${actionQuestionIndex.toString()}-${optionIdx.toString()}`}
                                >
                                  <MDBox mb={2}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} sm={5}>
                                        <MDTypography fontSize="15px">
                                          Option {optionIdx + 1}
                                        </MDTypography>
                                      </Grid>
                                      <Grid item xs={12} sm={6} display="flex" alignItems="center">
                                        <FormField
                                          value={option.optionText}
                                          onChange={(e) =>
                                            handleChangeActionOption(
                                              actionIndex,
                                              actionQuestionIndex,
                                              optionIdx,
                                              e.target.value,
                                            )
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  </MDBox>
                                </Grid>
                              ))}
                            </Grid>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  ))}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <MDBox mb={2} display="flex" flexDirection="row" alignItems="center" gap="10px">
                    {action.rewardActionQuestions?.length < 10 && (
                      <MDButton
                        size="small"
                        color="secondary"
                        onClick={() => handleAddQuestion(actionIndex)}
                      >
                        Question +
                      </MDButton>
                    )}
                    {action.rewardActionQuestions?.length > 1 && (
                      <MDButton
                        size="small"
                        color="secondary"
                        onClick={() => handleRemoveQuestion(actionIndex)}
                      >
                        Question -
                      </MDButton>
                    )}
                  </MDBox>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2} mb={4} alignItems="center">
              <Grid item xs={12} sm={12}>
                {action.rewardActionRewardItems?.map((reward, rewardIndex) => (
                  <MDBox>
                    <Grid container spacing={2} mb={4} alignItems="center">
                      <Grid item xs={12} sm={3} md={2.5}>
                        <MDTypography fontSize="15px">
                          Reward #{rewardIndex + 1} per{" "}
                          {action.type === 0 ? "action" : "survey answer"}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <StyledAutocomplete
                          value={reward.type}
                          options={Object.keys(RewardActionRewardItemType)}
                          getOptionLabel={(option) => `${RewardActionRewardItemType[option]} `}
                          onChange={(e, v) =>
                            handleChangeActionRewardField(
                              actionIndex,
                              rewardIndex,
                              "type",
                              parseFloat(v),
                            )
                          }
                          renderInput={(params) => (
                            <TextField {...params} InputLabelProps={{ shrink: true }} />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3} md={2.5}>
                        <MDTypography fontSize="15px">
                          No of item #{rewardIndex + 1} rewarded
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <FormField
                          type="number"
                          value={reward.count}
                          onChange={(e) =>
                            handleChangeActionRewardField(
                              actionIndex,
                              rewardIndex,
                              "count",
                              parseFloat(e.target.value),
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                ))}
              </Grid>
              <Grid item xs={12} sm={12}>
                <MDBox
                  sx={(theme) => {
                    flexContainer(theme);
                  }}
                >
                  {action.rewardActionRewardItems?.length < 2 && (
                    <MDButton
                      size="small"
                      color="secondary"
                      sx={{ marginRight: "10px" }}
                      onClick={() => handleAddItemReward(actionIndex)}
                    >
                      Add Item Reward +
                    </MDButton>
                  )}
                  {action.rewardActionRewardItems?.length > 1 && (
                    <MDButton
                      size="small"
                      color="secondary"
                      onClick={() => handleRemoveItemReward(actionIndex)}
                    >
                      Remove Item Reward -
                    </MDButton>
                  )}
                </MDBox>
              </Grid>
            </Grid>
            {action.type === 0 && (
              <Grid container spacing={2} mb={4} alignItems="center">
                <Grid item xs={12} sm={3} md={2.5}>
                  <MDTypography fontSize="15px">Max no of actions per user per day</MDTypography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <FormField
                    type="number"
                    value={action.maximumActionPerUserPerDay}
                    onChange={(e) => {
                      handleChangeActionField(
                        actionIndex,
                        "maximumActionPerUserPerDay",
                        parseFloat(e.target.value),
                      );
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </MDBox>
        ))}
        <MDBox
          sx={(theme) => {
            flexContainer(theme);
          }}
        >
          <MDButton
            size="small"
            color="dark"
            sx={{ marginRight: "10px" }}
            onClick={() => handleAddAction()}
          >
            Action +
          </MDButton>
          {minigame?.rewardAction?.length > 1 && (
            <MDButton size="small" color="dark" onClick={() => handleRemoveAction()}>
              Action -
            </MDButton>
          )}
        </MDBox>
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          In game store (marketplace)
        </MDTypography>
        {minigame?.rewardMarketplaceItem?.map((gameItem, itemIndex) => (
          <MDBox>
            <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
              Item #{itemIndex + 1}
            </MDTypography>
            <Grid container spacing={2} mb={4} alignItems="center">
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px">Team or sponsor item</MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <StyledAutocomplete
                  value={gameItem.type}
                  options={Object.keys(MarketplaceItemType)}
                  getOptionLabel={(option) => `${MarketplaceItemType[option]} `}
                  onChange={(e, v) => handleChangeGameItemField(itemIndex, "type", parseFloat(v))}
                  renderInput={(params) => (
                    <TextField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                  placeholder="Choose type"
                />
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px">Select item type</MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <StyledAutocomplete
                  value={gameItem.subType}
                  options={Object.keys(MarketplaceItemSubType)}
                  getOptionLabel={(option) => `${MarketplaceItemSubType[option]} `}
                  onChange={(e, v) =>
                    handleChangeGameItemField(itemIndex, "subType", parseFloat(v))
                  }
                  renderInput={(params) => (
                    <TextField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                  placeholder="Choose type"
                />
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px">description</MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <FormField
                  value={gameItem.description}
                  onChange={(e) =>
                    handleChangeGameItemField(itemIndex, "description", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px">No of item</MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <FormField
                  type="number"
                  value={gameItem.itemCount}
                  onChange={(e) =>
                    handleChangeGameItemField(itemIndex, "itemCount", parseFloat(e.target.value))
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} md={2.5}>
                <MDTypography fontSize="15px">Cost of item (coins)</MDTypography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <FormField
                  type="number"
                  value={gameItem.itemCost}
                  onChange={(e) =>
                    handleChangeGameItemField(itemIndex, "itemCost", parseFloat(e.target.value))
                  }
                />
              </Grid>
            </Grid>
          </MDBox>
        ))}
        <MDBox
          sx={(theme) => {
            flexContainer(theme);
          }}
        >
          <MDButton
            size="small"
            color="dark"
            sx={{ marginRight: "10px" }}
            onClick={() => handleAddGameItem()}
          >
            Add Marketplace Item +
          </MDButton>
          {minigame?.rewardMarketplaceItem?.length > 1 && (
            <MDButton size="small" color="dark" onClick={() => handleRemoveGameItem()}>
              Remove Marketplace Item -
            </MDButton>
          )}
        </MDBox>
      </MDBox>
      <Grid container justifyContent="flex-end">
        <MDButton color="secondary" size="large" onClick={() => handleNext()}>
          Next
        </MDButton>
      </Grid>
    </MDBox>
  );
}

MiniGameRewardForm.defaultProps = {
  item: null,
  prevHandler: {},
  nextHandler: {},
  changeHandler: {},
};

MiniGameRewardForm.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  prevHandler: PropTypes.func,
  nextHandler: PropTypes.func,
  changeHandler: PropTypes.func,
};

export default MiniGameRewardForm;
