import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import { Autocomplete, Button, Checkbox, Divider, Grid, TextField } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import FormField from "components/FormField";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";
import { UserRole, MiniGameType, UnlockRewardType, BoosterType } from "layouts/common/constants";

import SponsorService from "services/SponsorService";
import ToastrService from "services/ToastrService";
import { setLoading } from "store/appSlice";

import { validateFileSize } from "utils/validation";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .MuiOutlinedInput-root .MuiAutocomplete-input`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
    padding: 0,
    width: "35px",
  },
  [`& .MuiOutlinedInput-root`]: {
    padding: "0px",
    paddingLeft: "10px",
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

function MiniGameThemeForm({ item, prevHandler, nextHandler, changeHandler }) {
  const initialData = {
    team: {
      name: "Adelaide Giants",
    },
    match: {
      title: "No match / event allocation",
    },
    title: "Zambrero Hit Nation",
    description: "",
    type: 0,
    logo: "https://sportzfan.s3.ap-southeast-2.amazonaws.com/game/d1d34e53-9e0d-47fb-90aa-85a018d2e919.png",
    logoFile: null,
    background:
      "https://sportzfan.s3.ap-southeast-2.amazonaws.com/game/e0f375c1-7ea7-4f37-916e-164cefdfeae1.png",
    backgroundFile: null,
    enableSponsor: false,
    sponsor: {
      title: "Nike",
    },
    sponsorLogo: "",
    sponsorLogoFile: null,
    boosterType: 0,
    boosterLogo:
      "https://sportzfan.s3.ap-southeast-2.amazonaws.com/game/d1d34e53-9e0d-47fb-90aa-85a018d2e919.png",
    boosterLogoFile: null,
    unlockRewardNumber: 10,
    rewardSendTimeNumber: 10,
    unlockReward: 0,
  };

  const initialState = {
    id: null,
    team: null,
    match: null,
    title: "",
    description: "",
    type: 0,
    logo: "https://sportzfan.s3.ap-southeast-2.amazonaws.com/game/d1d34e53-9e0d-47fb-90aa-85a018d2e919.png",
    logoFile: null,
    background:
      "https://sportzfan.s3.ap-southeast-2.amazonaws.com/game/e0f375c1-7ea7-4f37-916e-164cefdfeae1.png",
    backgroundFile: null,
    enableSponsor: false,
    sponsor: null,
    sponsorLogo: "",
    sponsorLogoFile: null,
    boosterType: 0,
    boosterLogo:
      "https://sportzfan.s3.ap-southeast-2.amazonaws.com/game/d1d34e53-9e0d-47fb-90aa-85a018d2e919.png",
    boosterLogoFile: null,
    unlockRewardNumber: 10,
    rewardSendTimeNumber: 10,
    unlockReward: 0,
  };

  const resetState = {
    logo: "",
    background: "",
    boosterLogo: "",
    boosterType: "",
    unlockRewardNumber: "",
    rewardSendTimeNumber: "",
    unlockReward: "",
  };

  const dispatch = useDispatch();
  const adminProfile = useSelector((state) => state.users.profile);

  const [minigame, setMiniGame] = useState(initialState);
  const [validationMsg, setValidationMsg] = useState(resetState);
  const [sponsors, setSponsors] = useState([]);

  const getSponsors = useCallback(() => {
    let search = null;
    if (adminProfile.role === UserRole.TeamAdmin) search = adminProfile.teamId;
    else if (minigame.team) {
      search = minigame.team.id;
    } else {
      setSponsors([]);
      return;
    }
    SponsorService.retrive(search, 0, 9999).then((response) => {
      setSponsors(response.data.data);
    });
  }, [minigame?.team, adminProfile]);

  useEffect(() => {
    getSponsors();
  }, [getSponsors]);

  useEffect(() => {
    if (item === null) {
      setMiniGame(initialState);
    } else {
      const newItem = { ...item };
      if (!newItem.id) {
        newItem.sponsor = item.sponsor || initialState.sponsor;
        newItem.logo = item.logo || initialState.logo;
        newItem.background = item.background || initialState.background;
        newItem.sponsorLogo = item.sponsorLogo || initialState.sponsorLogo;
        newItem.boosterLogo = item.boosterLogo || initialState.boosterLogo;
        newItem.boosterType = item.boosterType || initialState.boosterType;
        newItem.unlockReward = item.unlockReward || initialState.unlockReward;
        newItem.unlockRewardNumber = item.unlockRewardNumber || initialState.unlockRewardNumber;
        newItem.rewardSendTimeNumber =
          item.rewardSendTimeNumber || initialState.rewardSendTimeNumber;
      }
      setMiniGame(newItem);
    }
  }, [item]);

  const validate = () => {
    let validated = true;
    let errorMsg = validationMsg;
    const keys = Object.keys(resetState);
    for (let i = 0; i < keys.length; i += 1) {
      let msg = "";
      if (minigame[keys[i]] === "" || minigame[keys[i]] === null) {
        msg = "Field should not be left empty";
        validated = false;
      }
      errorMsg = { ...errorMsg, [keys[i]]: msg };
    }

    if (minigame.logoFile && !validateFileSize(minigame.logoFile.size)) {
      ToastrService.warning("File size of logo should be less than 1MB");
      validated = false;
    }
    if (minigame.backgroundFile && !validateFileSize(minigame.backgroundFile.size)) {
      ToastrService.warning("File size of background image should be less than 1MB");
      validated = false;
    }
    if (minigame.sponsorLogoFile && !validateFileSize(minigame.sponsorLogoFile.size)) {
      ToastrService.warning("File size of sponsor logo should be less than 1MB");
      validated = false;
    }
    if (minigame.boosterLogoFile && !validateFileSize(minigame.boosterLogoFile.size)) {
      ToastrService.warning("File size of booster logo should be less than 1MB");
      validated = false;
    }

    setValidationMsg(errorMsg);

    return validated;
  };

  const handleFilePathChange = (event, imageType) => {
    const [file] = event.target.files;
    if (imageType === "logo") {
      setMiniGame({ ...minigame, logo: URL.createObjectURL(file), logoFile: file });
    }
    if (imageType === "background") {
      setMiniGame({
        ...minigame,
        background: URL.createObjectURL(file),
        backgroundFile: file,
      });
    }
    if (imageType === "sponsorLogo") {
      setMiniGame({ ...minigame, sponsorLogo: URL.createObjectURL(file), sponsorLogoFile: file });
    }
    if (imageType === "boosterLogo") {
      setMiniGame({ ...minigame, boosterLogo: URL.createObjectURL(file), boosterLogoFile: file });
    }

    event.target.value = null;
  };

  const handleRemoveImage = (imageType) => {
    if (imageType === "logo") {
      setMiniGame({ ...minigame, logo: "", logoFile: null });
    }
    if (imageType === "background") {
      setMiniGame({ ...minigame, background: "", backgroundFile: null });
    }
    if (imageType === "sponsorLogo") {
      setMiniGame({ ...minigame, sponsorLogo: "", sponsorLogoFile: null });
    }
    if (imageType === "boosterLogo") {
      setMiniGame({ ...minigame, boosterLogo: "", boosterLogoFile: null });
    }
  };

  const handleSubmitChange = (field, value) => {
    setMiniGame((prevState) => ({ ...prevState, [field]: value }));
    if (field === "sponsor") {
      setMiniGame((prevState) => ({ ...prevState, sponsorLogo: value.logo }));
    }
  };

  const handleSubmitChangeNumber = (field, value) => {
    setMiniGame({ ...minigame, [field]: parseFloat(value) });
  };

  const handleNext = () => {
    if (!validate()) {
      return;
    }
    const temp = minigame;

    if (changeHandler(temp)) {
      nextHandler();
    }
  };

  const handleBack = () => {
    prevHandler();
  };

  async function getMiniGame(_id) {
    const temp = { ...initialData };
    dispatch(setLoading(true));
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 100);
    temp.id = _id;
    return temp;
  }

  const handleReset = async () => {
    if (!minigame.id) {
      let temp = { ...minigame };
      const keys = Object.keys(resetState);
      for (let i = 0; i < keys.length; i += 1) {
        temp = { ...temp, [keys[i]]: initialState[keys[i]] };
      }
      setMiniGame(temp);
    } else {
      const data = await getMiniGame(minigame.id);
      const newItem = { ...data };

      let temp = { ...minigame };
      const keys = Object.keys(resetState);
      for (let i = 0; i < keys.length; i += 1) {
        temp = { ...temp, [keys[i]]: newItem[keys[i]] };
      }
      setMiniGame(temp);
    }
  };

  return (
    <MDBox>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ maxWidth: "500px", width: "100%" }}>
          {minigame.id
            ? "Edit Mini Game | Theme + Mechanics"
            : "Add  Mini Game | Theme + Mechanics"}
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handleBack()}>
            Back
          </MDButton>
          <MDButton size="small" onClick={() => handleReset()}>
            Reset
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox sx={(theme) => customFormContainer(theme)}>
        <Grid container spacing={2} mb={4} alignItems="center">
          <Grid item xs={12} sm={3.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Select Team
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <MDTypography fontSize="15px">{minigame?.team?.name}</MDTypography>
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Match / Event
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <MDTypography fontSize="15px">{minigame?.match?.title}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Mini Game Title
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <MDTypography fontSize="15px">{minigame?.title}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Mini Game Description
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <MDTypography fontSize="15px">{minigame?.description}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3.5}>
            <MDTypography fontSize="15px" fontWeight="bold">
              Game Type
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <MDTypography fontSize="15px">
              {minigame ? MiniGameType[minigame?.type] : ""}
            </MDTypography>
          </Grid>
        </Grid>
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Design
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3.5}>
            <MDTypography fontSize="15px">Mini Game Logo</MDTypography>
            <MDTypography fontSize="10px">(Recommended 500 × 500px | Max 1MB)</MDTypography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <MDBox mb={2}>
              <Button
                variant="contained"
                component="label"
                color="white"
                sx={{ marginRight: "20px" }}
              >
                Upload Image
                <input
                  type="file"
                  hidden
                  name="logo"
                  onChange={(e) => handleFilePathChange(e, "logo")}
                  accept=".jpg,.jpeg,.png"
                />
              </Button>
              {minigame.logo !== null && minigame.logo !== "" && (
                <Button
                  variant="contained"
                  component="label"
                  color="error"
                  onClick={() => handleRemoveImage("logo")}
                >
                  Remove Image
                </Button>
              )}
            </MDBox>
            {minigame.logo !== null && minigame.logo !== "" && (
              <MDAvatar
                src={minigame.logo}
                alt="Avatar"
                sx={{ width: "250px", height: "250px", borderRadius: "0px" }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={3.5}>
            <MDTypography fontSize="15px">Background Image</MDTypography>
            <MDTypography fontSize="10px">(Recommended 500 × 1000px | Max 1MB)</MDTypography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <MDBox mb={2}>
              <Button
                variant="contained"
                component="label"
                color="white"
                sx={{ marginRight: "20px" }}
              >
                Upload Image
                <input
                  type="file"
                  hidden
                  name="background"
                  onChange={(e) => handleFilePathChange(e, "background")}
                  accept=".jpg,.jpeg,.png"
                />
              </Button>
              {minigame.background !== null && minigame.background !== "" && (
                <Button
                  variant="contained"
                  component="label"
                  color="error"
                  onClick={() => handleRemoveImage("background")}
                >
                  Remove Image
                </Button>
              )}
            </MDBox>
            {minigame.background !== null && minigame.background !== "" && (
              <MDAvatar
                src={minigame.background}
                alt="background"
                sx={{ width: "250px", height: "500px", borderRadius: "0px" }}
              />
            )}
          </Grid>
        </Grid>
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Sponsors
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3.5}>
            <MDTypography fontSize="15px">Add sponsor to game?</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <Checkbox
              checked={minigame.enableSponsor}
              onChange={(e) => {
                handleSubmitChange("enableSponsor", e.target.checked);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3.5}>
            <MDTypography fontSize="15px">Game Sponsor</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <StyledAutocomplete
              value={minigame.sponsor}
              disabled={!minigame.enableSponsor}
              options={sponsors}
              getOptionLabel={(option) => option.title}
              onChange={(e, v) => {
                handleSubmitChange("sponsor", v);
              }}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose Sponsor"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3.5}>
            <MDTypography fontSize="15px">Sponsor Logo</MDTypography>
            <MDTypography fontSize="10px">(Recommended 500 × 500px | Max 1MB)</MDTypography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <MDBox mb={2}>
              <Button
                variant="contained"
                component="label"
                color="white"
                sx={{ marginRight: "20px" }}
              >
                Upload Image
                <input
                  type="file"
                  hidden
                  name="sponsorLogo"
                  onChange={(e) => handleFilePathChange(e, "sponsorLogo")}
                  accept=".jpg,.jpeg,.png"
                />
              </Button>
              {minigame.sponsorLogo !== null && minigame.sponsorLogo !== "" && (
                <Button
                  variant="contained"
                  component="label"
                  color="error"
                  onClick={() => handleRemoveImage("sponsorLogo")}
                >
                  Remove Image
                </Button>
              )}
            </MDBox>
            {minigame.sponsorLogo !== null && minigame.sponsorLogo !== "" && (
              <MDAvatar
                src={minigame.sponsorLogo}
                alt="sponsorLogo"
                sx={{ width: "250px", height: "250px", borderRadius: "0px" }}
              />
            )}
          </Grid>
        </Grid>
        <Divider />
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Game Mechanics
        </MDTypography>
        <Grid container spacing={2} mb={4} alignItems="center">
          <Grid item xs={12} sm={3.5}>
            <MDTypography fontSize="15px">Collectible booster type</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <StyledAutocomplete
              value={minigame.boosterType}
              options={Object.keys(BoosterType)}
              getOptionLabel={(option) => `${BoosterType[option]} `}
              onChange={(e, v) => {
                handleSubmitChange("boosterType", parseFloat(v));
              }}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose type"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3.5}>
            <MDTypography fontSize="15px">Collectible booster image</MDTypography>
            <MDTypography fontSize="10px">(Recommended 500 × 500px | Max 1MB)</MDTypography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <MDBox mb={2}>
              <Button
                variant="contained"
                component="label"
                color="white"
                sx={{ marginRight: "20px" }}
              >
                Upload Image
                <input
                  type="file"
                  hidden
                  name="boosterLogo"
                  onChange={(e) => handleFilePathChange(e, "boosterLogo")}
                  accept=".jpg,.jpeg,.png"
                />
              </Button>
              {minigame.boosterLogo !== null && minigame.boosterLogo !== "" && (
                <Button
                  variant="contained"
                  component="label"
                  color="error"
                  onClick={() => handleRemoveImage("boosterLogo")}
                >
                  Remove Image
                </Button>
              )}
            </MDBox>
            {minigame.boosterLogo !== null && minigame.boosterLogo !== "" && (
              <MDAvatar
                src={minigame.boosterLogo}
                alt="boosterLogo"
                sx={{ width: "250px", height: "250px", borderRadius: "0px" }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3.5}>
            <MDTypography fontSize="15px">No to collect to unlock reward</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormField
              type="number"
              value={minigame.unlockRewardNumber}
              onChange={(e) => handleSubmitChangeNumber("unlockRewardNumber", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4} alignItems="center">
          <Grid item xs={12} sm={3.5}>
            <MDTypography fontSize="15px">Reward to unlock</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <StyledAutocomplete
              value={minigame.unlockReward}
              options={Object.keys(UnlockRewardType)}
              getOptionLabel={(option) => `${UnlockRewardType[option]} `}
              onChange={(e, v) => {
                handleSubmitChange("unlockReward", parseFloat(v));
              }}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose type"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3.5}>
            <MDTypography fontSize="15px">No reward item to send</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormField
              type="number"
              value={minigame.rewardSendTimeNumber}
              onChange={(e) => handleSubmitChangeNumber("rewardSendTimeNumber", e.target.value)}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Grid container justifyContent="flex-end">
        <MDButton color="secondary" size="large" onClick={() => handleNext()}>
          Next
        </MDButton>
      </Grid>
    </MDBox>
  );
}

MiniGameThemeForm.defaultProps = {
  item: null,
  prevHandler: {},
  nextHandler: {},
  changeHandler: {},
};

MiniGameThemeForm.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  prevHandler: PropTypes.func,
  nextHandler: PropTypes.func,
  changeHandler: PropTypes.func,
};

export default MiniGameThemeForm;
