import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import MDButton from "components/MDButton";

import { customTableHeader } from "layouts/common/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontSize: "13px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "13px",
  },
}));

const StyledTableButton = styled(MDButton)(({ theme }) => ({
  [`&.MuiButton-root`]: {
    color: theme.palette.background.sidenav,
    background: "#FFFFFF",
    border: "1px solid #C4C4C4",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    minWidth: "30px",
    marginRight: "5px",
  },
}));

function UserResponseTable({ data, viewResponse }) {
  const handleView = (id) => () => {
    viewResponse(id);
  };
  return (
    <TableContainer component={Paper} sx={{ background: "transparent", boxShadow: "none" }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead sx={(theme) => customTableHeader(theme)}>
          <TableRow>
            <StyledTableCell align="left">Username</StyledTableCell>
            <StyledTableCell align="left">Rewards Sent</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <TableRow key={row.id}>
              <StyledTableCell align="left" component="th" scope="row">
                {row?.user?.firstName}&nbsp;{row?.user?.lastName}
              </StyledTableCell>
              <StyledTableCell align="left">Yes</StyledTableCell>
              <StyledTableCell align="center">
                <StyledTableButton
                  size="small"
                  sx={{ padding: "0px 5px" }}
                  color="secondary"
                  onClick={handleView(row.id)}
                >
                  View Responses
                </StyledTableButton>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

UserResponseTable.defaultProps = {
  data: [],
  viewResponse: {},
};

// Typechecking props for the DashboardNavbar
UserResponseTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  viewResponse: PropTypes.func,
};

export default UserResponseTable;
