import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import { Autocomplete, Divider, Grid, TextField } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { dateAndTimeToDate, dateToDateAndTime } from "utils/converter";
import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";

import MatchesService from "services/MatchService";
import { setLoading } from "store/appSlice";

import { EventTabs, UserRole, MiniGameType } from "layouts/common/constants";
import FormField from "components/FormField";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .MuiOutlinedInput-root .MuiAutocomplete-input`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
    padding: 0,
    width: "35px",
  },
  [`& .MuiOutlinedInput-root`]: {
    padding: "0px",
    paddingLeft: "10px",
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

function MiniGameDetailForm({ item, nextHandler, changeHandler }) {
  const initialData = {
    team: {
      name: "Adelaide Giants",
    },
    match: {
      title: "No match / event allocation",
    },
    title: "Zambrero Hit Nation",
    description: "",
    type: 0,
    lifeCount: 10,
    refreshTime: 1440,
    refreshAmount: 2,
    start: "2022-11-14T20:00:01.957Z",
    end: "2022-11-28T20:00:01.957Z",
  };

  const initialState = {
    id: null,
    team: null,
    match: null,
    title: "",
    description: "",
    type: 0,
    lifeCount: 0,
    refreshTime: 0,
    refreshAmount: 0,
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
  };

  const resetState = {
    team: "",
    match: "",
    title: "",
    description: "",
    type: 0,
    lifeCount: 0,
    refreshTime: 0,
    refreshAmount: 0,
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminProfile = useSelector((state) => state.users.profile);
  const teams = useSelector((state) => state.teams);

  const [minigame, setMiniGame] = useState(initialState);
  const [validationMsg, setValidationMsg] = useState(resetState);
  const [matches, setMatches] = useState([]);

  const getMatches = useCallback(async () => {
    let temp = [{ title: "No match / event allocation" }];
    let search = null;
    if (adminProfile.role === UserRole.TeamAdmin) search = adminProfile.teamId;
    else if (minigame.team) {
      search = minigame.team.id;
    } else {
      setMatches([]);
      return;
    }
    const promises = await Promise.all([
      new Promise((resolve) => {
        let data = [];
        MatchesService.retrive(EventTabs.Ongoing, search, null, null, null, null, true).then(
          (res) => {
            data = res.data.data;
            resolve({ data });
          },
        );
      }),
      new Promise((resolve) => {
        let data = [];
        MatchesService.retrive(EventTabs.Upcoming, search, null, null, null, null, true).then(
          (res) => {
            data = res.data.data;
            resolve({ data });
          },
        );
      }),
    ]);

    promises.forEach(({ data }) => {
      temp = [...temp].concat(data);
    });

    temp = temp.sort((a, b) => {
      if (a.start < b.start) return 1;
      if (a.start > b.start) return -1;
      return 0;
    });

    setMatches(temp);
  }, [minigame?.team, adminProfile]);

  useEffect(() => {
    getMatches();
  }, [getMatches]);

  useEffect(() => {
    if (item === null) {
      setMiniGame(initialState);
    } else {
      // Divide Datetime to Date and Time.
      const newItem = { ...item };
      if (newItem.id && !newItem.match) {
        newItem.match = { title: "No match / event allocation" };
      }

      const startDateTime = dateToDateAndTime(new Date(newItem.start));
      const endDateTime = dateToDateAndTime(new Date(newItem.end));
      newItem.startDate = startDateTime.date;
      newItem.startTime = startDateTime.time;
      newItem.endDate = endDateTime.date;
      newItem.endTime = endDateTime.time;

      delete newItem.start;
      delete newItem.end;

      setMiniGame(newItem);
    }
  }, [item]);

  const validate = () => {
    let validated = true;
    let errorMsg = validationMsg;
    const keys = Object.keys(resetState);
    for (let i = 0; i < keys.length; i += 1) {
      let msg = "";
      if (minigame[keys[i]] === "" || (minigame[keys[i]] === null && keys[i] !== "team")) {
        msg = "Field should not be left empty";
        validated = false;
      }
      if (
        minigame[keys[i]] === "" ||
        (minigame[keys[i]] === null &&
          keys[i] === "team" &&
          adminProfile.role === UserRole.SuperAdmin)
      ) {
        msg = "Field should not be left empty";
        validated = false;
      }
      errorMsg = { ...errorMsg, [keys[i]]: msg };
    }

    setValidationMsg(errorMsg);

    return validated;
  };

  const handleSubmitChange = (field, value) => {
    setMiniGame({ ...minigame, [field]: value });
  };

  const handleSubmitChangeNumber = (field, value) => {
    setMiniGame({ ...minigame, [field]: parseFloat(value) });
  };

  const handleNext = () => {
    if (!validate()) {
      return;
    }
    const temp = minigame;
    temp.start = dateAndTimeToDate(temp.startDate, temp.startTime).toISOString();
    temp.end = dateAndTimeToDate(temp.endDate, temp.endTime).toISOString();

    ["startDate", "startTime", "endDate", "endTime"].forEach((d) => {
      delete temp[d];
    });

    if (changeHandler(temp)) {
      nextHandler();
    }
  };

  const handleBack = () => {
    navigate("/games");
  };

  async function getMiniGame(_id) {
    const temp = { ...initialData };
    dispatch(setLoading(true));
    await setTimeout(() => {
      dispatch(setLoading(false));
    }, 100);
    temp.id = _id;
    return temp;
  }

  const handleReset = async () => {
    if (!minigame.id) {
      let temp = { ...minigame };
      const keys = Object.keys(resetState);
      for (let i = 0; i < keys.length; i += 1) {
        temp = { ...temp, [keys[i]]: initialState[keys[i]] };
      }
      setMiniGame(temp);
    } else {
      const data = await getMiniGame(minigame.id);
      const newItem = { ...data };

      const startDateTime = dateToDateAndTime(new Date(newItem.start));
      const endDateTime = dateToDateAndTime(new Date(newItem.end));
      newItem.startDate = startDateTime.date;
      newItem.startTime = startDateTime.time;
      newItem.endDate = endDateTime.date;
      newItem.endTime = endDateTime.time;

      let temp = { ...minigame };
      const keys = Object.keys(resetState);
      for (let i = 0; i < keys.length; i += 1) {
        temp = { ...temp, [keys[i]]: newItem[keys[i]] };
      }
      setMiniGame(temp);
    }
  };

  return (
    <MDBox>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "300px" }}>
          {minigame.id ? "Edit Mini Game | Details" : "Add Mini Game | Details"}
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handleBack()}>
            Back
          </MDButton>
          <MDButton size="small" onClick={() => handleReset()}>
            Reset
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox sx={(theme) => customFormContainer(theme)}>
        {adminProfile.role === UserRole.SuperAdmin && (
          <Grid container spacing={2} mb={4} alignItems="center">
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px">Select Team</MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <StyledAutocomplete
                value={minigame.team}
                options={teams}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => handleSubmitChange("team", v)}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
                placeholder="Choose Team"
                error={validationMsg.team !== ""}
              />
            </Grid>
          </Grid>
        )}
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Details
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Match / Event</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <StyledAutocomplete
              value={minigame.match}
              options={matches}
              getOptionLabel={(option) => `${option.title} `}
              onChange={(e, v) => {
                handleSubmitChange("match", v);
              }}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose type"
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Mini Game Title</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormField
              value={minigame.title}
              onChange={(e) => handleSubmitChange("title", e.target.value)}
              error={validationMsg.title !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Mini Game Description</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormField
              value={minigame.description}
              onChange={(e) => handleSubmitChange("description", e.target.value)}
              error={validationMsg.description !== ""}
            />
          </Grid>

          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Select game type</MDTypography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <StyledAutocomplete
              value={minigame.type}
              options={Object.keys(MiniGameType)}
              getOptionLabel={(option) => `${MiniGameType[option]} `}
              onChange={(e, v) => {
                handleSubmitChange("type", parseFloat(v));
              }}
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
              placeholder="Choose type"
            />
          </Grid>
        </Grid>
        <MDTypography fontSize="15px" fontWeight="bold" mb={3} sx={{ textDecoration: "underline" }}>
          Game Rules
        </MDTypography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Number of lives (start)</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              value={minigame.lifeCount}
              onChange={(e) => handleSubmitChangeNumber("lifeCount", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Refresh time (min)</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              value={minigame.refreshTime}
              onChange={(e) => handleSubmitChangeNumber("refreshTime", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={3} md={2.5}>
            <MDTypography fontSize="15px">Refresh amount (lives)</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={1.5}>
            <FormField
              type="number"
              value={minigame.refreshAmount}
              onChange={(e) => handleSubmitChangeNumber("refreshAmount", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px">Start Date & Time</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="date"
              label=""
              value={minigame.startDate}
              onChange={(e) => handleSubmitChange("startDate", e.target.value)}
              error={validationMsg.startDate !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="time"
              label=""
              value={minigame.startTime}
              onChange={(e) => handleSubmitChange("startTime", e.target.value)}
              error={validationMsg.startTime !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2.5}>
            <MDTypography fontSize="15px">Expiration Date & Time</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="date"
              label=""
              value={minigame.endDate}
              onChange={(e) => handleSubmitChange("endDate", e.target.value)}
              error={validationMsg.endDate !== ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4.5}>
            <FormField
              type="time"
              label=""
              value={minigame.endTime}
              onChange={(e) => handleSubmitChange("endTime", e.target.value)}
              error={validationMsg.endTime !== ""}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Grid container justifyContent="flex-end">
        <MDButton color="secondary" size="large" onClick={() => handleNext()}>
          Next
        </MDButton>
      </Grid>
    </MDBox>
  );
}

MiniGameDetailForm.defaultProps = {
  item: null,
  nextHandler: {},
  changeHandler: {},
};

MiniGameDetailForm.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  nextHandler: PropTypes.func,
  changeHandler: PropTypes.func,
};

export default MiniGameDetailForm;
