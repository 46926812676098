import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { customFormContainer, pageHeaderContainer, flexContainer } from "layouts/common/styles";
import { GameTypes, MiniGameType } from "layouts/common/constants";

import ToastrService from "services/ToastrService";
import GameService from "services/GamesService";
import { setLoading } from "store/appSlice";
import { dateToDateAndTime } from "utils/converter";

import WinnerTableMiniGame from "../WinnerTableMiniGame";

function ViewMiniGameResult() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState(null);

  const getResult = (_id) => {
    dispatch(setLoading(true));
    GameService.getResult(GameTypes.MiniGame, _id)
      .then((response) => {
        const temp = { ...response.data };

        const startDateTime = dateToDateAndTime(new Date(temp.start));
        const endDateTime = dateToDateAndTime(new Date(temp.end));

        temp.start = `${startDateTime.date}  ${startDateTime.time}`;
        temp.end = `${endDateTime.date}  ${endDateTime.time}`;

        setData(temp);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        ToastrService.error(
          e.response?.data?.message?.message || "Failed to load mini game result.",
        );
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      getResult(id);
    }
  }, [id]);

  const handleBack = () => {
    navigate(`/game/view-minigame/${id}`);
  };

  return (
    <DashboardLayout>
      <MDBox my={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "250px" }}>
          Mini Game | Result
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handleBack()}>
            Back
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox>
        <MDBox sx={(theme) => customFormContainer(theme)}>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Details
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.team?.name}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Match / Event
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.match?.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Mini Game Title
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Mini Game Description
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">{data?.description}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Mini Game Logo
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              {data?.avatar && data?.avatar !== "" && (
                <MDAvatar
                  src={data?.avatar}
                  alt="Avatar"
                  sx={{ width: "250px", height: "250px" }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={3} md={2.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Game Type
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <MDTypography fontSize="15px">
                {data?.type ? MiniGameType[data?.type] : ""}
              </MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Results
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3} md={4}>
              <MDTypography fontSize="15px" fontWeight="bold">
                playCount
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">{data?.playMiniGame?.length}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3} md={4}>
              <MDTypography fontSize="15px" fontWeight="bold">
                playerCount
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography fontSize="15px">
                {data?.playMiniGame?.filter((d) => d.rank).length}
              </MDTypography>
            </Grid>
          </Grid>
          <MDBox mb={3}>
            <MDTypography
              fontSize="15px"
              fontWeight="bold"
              mb={2}
              sx={{ textDecoration: "underline" }}
            >
              Winners (Full Campaign)
            </MDTypography>
            <WinnerTableMiniGame data={data?.playMiniGame?.filter((d) => d.rank) || []} />
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default ViewMiniGameResult;
