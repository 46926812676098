import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import { Divider, TextField, Grid, Autocomplete, Button } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import { pageHeaderContainer, customFormContainer, flexContainer } from "layouts/common/styles";
import ColorPicker from "components/ColorPicker";

import ToastrService from "services/ToastrService";
import { validateFileSize } from "utils/validation";

import colourTheme from "../data/colours";
import fontList from "../data/fonts";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .MuiOutlinedInput-root .MuiAutocomplete-input`]: {
    height: "35px",
    color: theme.palette.common.black,
    borderRadius: 0,
    padding: 0,
    width: "35px",
  },
  [`& .MuiOutlinedInput-root`]: {
    padding: "0px",
    paddingLeft: "10px",
  },
  [` & .MuiInputBase-input`]: {
    height: "35px",
    borderRadius: 0,
  },
  [` & .MuiOutlinedInput-notchedOutline`]: {
    height: "40px",
    borderRadius: 0,
  },
}));

function TeamThemeForm({ item, initialState, prevHandler, nextHandler, changeHandler }) {
  const resetState = {
    backgroundTheme: "Light",
    landingPageBackgroundColor: "#131418",
    landingPageBackgroundTopLayerColor: "#14264b",
    landingPageBackgroundBottomLayerColor: "#e41e2e",
    landingPageTextColor: "#ffffff",
    authInputBackgroundColor: "#ffffff",
    primaryTextColor: "#2f2f2f",
    headingTextColor: "#2f2f2f",
    menuTextColor: "#6B6B6B",
    secondaryTextColor: "#6B6B6B",
    primaryButtonColor: "#e41e2e",
    secondaryButtonColor: "#596169",
    modalBackgroundColor: "#ffffff",
    tabHighlightColor: "#e41e2e",
    buttonTextColor: "#ced2d6",
    tokenTextColor: "#e41e2e",
    kudosTextColor: "#feb201",
    kudosIconColor: "#feb201",
    dateTextColor: "#14264b",
    successColor: "#33be51",
    errorColor: "#ff0000",
    warningColor: "#feb201",
    iconColor: "#e41e2e",
    landingPageCardColor: "#14264b",
    mainPageCardColor: "#ffffff",
    mainPageBackgroundColor: "#f5f5f5",
    mainPageHeaderColor: "#ffffff",
    mainPageSidebarColor: "#ffffff",
    membershipTierOneColor: "#c6cfd9",
    membershipTierTwoColor: "#9eddff",
    membershipTierThreeColor: "#ee7fce",
    membershipTierFourColor: "#ffed93",
    primaryFont: 0,
    secondaryFont: 0,
    tokenImage: "",
    tokenImageFile: null,
    appLogoImage: "",
    appLogoImageFile: null,
    landingPageHeroImage: "",
    landingPageHeroImageFile: null,
    pwaIcon: "",
    pwaIconFile: null,
  };

  const validateState = {
    tokenImage: "",
    appLogoImage: "",
    landingPageHeroImage: "",
    pwaIcon: "",
  };

  const [team, setTeam] = useState(resetState);
  const [validationMsg, setValidationMsg] = useState(validateState);

  const handleSubmitChange = (key, value) => {
    setTeam({ ...team, [key]: value });
  };

  const handleThemeChange = useCallback(
    (value) => {
      let theme = {
        backgroundTheme: value,
      };
      if (value === "Light") {
        theme = {
          ...theme,
          ...colourTheme.Light,
        };
      } else {
        theme = {
          ...theme,
          ...colourTheme.Dark,
        };
      }

      setTeam((prevState) => ({ ...prevState, ...theme }));
    },
    [colourTheme],
  );

  useEffect(() => {
    if (item) {
      let theme = {
        backgroundTheme: item.backgroundTheme,
      };

      if (item.backgroundTheme === "Light") {
        Object.keys(colourTheme.Light).forEach((keyValue) => {
          theme = { ...theme, [keyValue]: item[keyValue] ?? colourTheme.Light[keyValue] };
        });
      } else {
        Object.keys(colourTheme.Dark).forEach((keyValue) => {
          theme = { ...theme, [keyValue]: item[keyValue] ?? colourTheme.Dark[keyValue] };
        });
      }

      setTeam({ ...item, ...theme });
    }
  }, [item, handleThemeChange]);

  const validate = () => {
    let validated = true;
    let errorMsg = validationMsg;
    const keys = Object.keys(validateState);
    for (let i = 0; i < keys.length; i += 1) {
      let msg = "";
      if (team[keys[i]] === "") {
        msg = "Field should not be left empty";
        validated = false;
      }
      errorMsg = { ...errorMsg, [keys[i]]: msg };
    }
    if (!validated) {
      ToastrService.warning("Please upload images.");
      return validated;
    }
    if (team.tokenImageFile && !validateFileSize(team.tokenImageFile.size)) {
      ToastrService.warning("File size of Token logo should be less than 1MB");
      validated = false;
    }
    if (team.appLogoImageFile && !validateFileSize(team.appLogoImageFile.size)) {
      ToastrService.warning("File size of AppLogoImageFile should be less than 1MB");
      validated = false;
    }
    if (team.landingPageHeroImageFile && !validateFileSize(team.landingPageHeroImageFile.size)) {
      ToastrService.warning("File size of LandingPageHeroImage should be less than 1MB");
      validated = false;
    }
    if (team.pwaIconFile && !validateFileSize(team.pwaIconFile.size)) {
      ToastrService.warning("File size of PWA Icon should be less than 1MB");
      validated = false;
    }
    setValidationMsg(errorMsg);

    return validated;
  };

  const handleFilePathChange = (event) => {
    const name = event.target.name || "tokenImage";
    const [file] = event.target.files;
    setTeam({ ...team, [name]: URL.createObjectURL(file), [`${name}File`]: file });
  };

  const handleRemoveImage = (key) => {
    setTeam({ ...team, [key]: "", [`${key}File`]: null });
  };

  const handleNext = () => {
    if (!validate()) return;

    if (changeHandler(team)) {
      nextHandler();
    }
  };

  const handleReset = () => {
    let temp = { ...team };
    const keys = Object.keys(resetState);
    for (let i = 0; i < keys.length; i += 1) {
      temp = { ...temp, [keys[i]]: initialState[keys[i]] };
    }
    setTeam(temp);
    setValidationMsg(validateState);
  };

  const handleBack = () => {
    prevHandler();
  };

  return (
    <MDBox my={3}>
      <MDBox mb={3} sx={(theme) => pageHeaderContainer(theme)}>
        <MDTypography fontSize="20px" fontWeight="bold" sx={{ width: "100%" }}>
          {team?.id ? "Edit Team" : "Add Team"} - Theme
        </MDTypography>
        <MDBox sx={(theme) => flexContainer(theme)} justifyContent="flex-end">
          <MDButton size="small" onClick={() => handleBack()}>
            Back
          </MDButton>
          <MDButton size="small" onClick={() => handleReset()}>
            Reset
          </MDButton>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox>
        <MDBox sx={(theme) => customFormContainer(theme)}>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px" fontWeight="bold">
                Team Name
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <MDTypography fontSize="15px">{team?.name}</MDTypography>
            </Grid>
          </Grid>
          <MDTypography
            fontSize="15px"
            fontWeight="bold"
            mb={3}
            sx={{ textDecoration: "underline" }}
          >
            Theme Details
          </MDTypography>
          <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
            Colours
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Background theme</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <StyledAutocomplete
                value={team.backgroundTheme || resetState.backgroundTheme}
                options={["Dark", "Light"]}
                onChange={(e, v) => handleThemeChange(v)}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
                placeholder="Choose Anyone"
                error={validationMsg.type !== ""}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Landing Page Background Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={
                  team.landingPageBackgroundColor ?? resetState.landingPageBackgroundColor
                }
                changeHandler={(v) => handleSubmitChange("landingPageBackgroundColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Landing Page Backgroud Top Layer Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={
                  team.landingPageBackgroundTopLayerColor ??
                  resetState.landingPageBackgroundTopLayerColor
                }
                changeHandler={(v) => handleSubmitChange("landingPageBackgroundTopLayerColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">
                Landing Page Background Bottom Layer Color
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={
                  team.landingPageBackgroundBottomLayerColor ??
                  resetState.landingPageBackgroundBottomLayerColor
                }
                changeHandler={(v) =>
                  handleSubmitChange("landingPageBackgroundBottomLayerColor", v)
                }
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Landing Page Text Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.landingPageTextColor ?? resetState.landingPageTextColor}
                changeHandler={(v) => handleSubmitChange("landingPageTextColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Auth Input Background Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.authInputBackgroundColor ?? resetState.authInputBackgroundColor}
                changeHandler={(v) => handleSubmitChange("authInputBackgroundColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Primary Text Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.primaryTextColor ?? resetState.primaryTextColor}
                changeHandler={(v) => handleSubmitChange("primaryTextColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Heading Text Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.headingTextColor ?? resetState.headingTextColor}
                changeHandler={(v) => handleSubmitChange("headingTextColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Menu Text Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.menuTextColor ?? resetState.menuTextColor}
                changeHandler={(v) => handleSubmitChange("menuTextColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Secondary Text Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.secondaryTextColor ?? resetState.secondaryTextColor}
                changeHandler={(v) => handleSubmitChange("secondaryTextColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Primary Button Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.primaryButtonColor ?? resetState.primaryButtonColor}
                changeHandler={(v) => handleSubmitChange("primaryButtonColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Secondary Button Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.secondaryButtonColor ?? resetState.secondaryButtonColor}
                changeHandler={(v) => handleSubmitChange("secondaryButtonColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Tab Hightlight Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.tabHighlightColor ?? resetState.tabHighlightColor}
                changeHandler={(v) => handleSubmitChange("tabHighlightColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Button Text Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.buttonTextColor ?? resetState.buttonTextColor}
                changeHandler={(v) => handleSubmitChange("buttonTextColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Token Text Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.tokenTextColor ?? resetState.tokenTextColor}
                changeHandler={(v) => handleSubmitChange("tokenTextColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Kudos Text Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.kudosTextColor ?? resetState.kudosTextColor}
                changeHandler={(v) => handleSubmitChange("kudosTextColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Kudos Icon Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.kudosIconColor ?? resetState.kudosIconColor}
                changeHandler={(v) => handleSubmitChange("kudosIconColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Date Text Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.dateTextColor ?? resetState.dateTextColor}
                changeHandler={(v) => handleSubmitChange("dateTextColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Success Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.successColor ?? resetState.successColor}
                changeHandler={(v) => handleSubmitChange("successColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Error Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.errorColor ?? resetState.errorColor}
                changeHandler={(v) => handleSubmitChange("errorColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Warning Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.warningColor ?? resetState.warningColor}
                changeHandler={(v) => handleSubmitChange("warningColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Icon Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.iconColor ?? resetState.iconColor}
                changeHandler={(v) => handleSubmitChange("iconColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Landing Page Card Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.landingPageCardColor ?? resetState.landingPageCardColor}
                changeHandler={(v) => handleSubmitChange("landingPageCardColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Main Page Card Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.mainPageCardColor ?? resetState.mainPageCardColor}
                changeHandler={(v) => handleSubmitChange("mainPageCardColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Main Page Background Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.mainPageBackgroundColor ?? resetState.mainPageBackgroundColor}
                changeHandler={(v) => handleSubmitChange("mainPageBackgroundColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Main Page Header Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.mainPageHeaderColor ?? resetState.mainPageHeaderColor}
                changeHandler={(v) => handleSubmitChange("mainPageHeaderColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Main Page Sidebar Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.mainPageSidebarColor ?? resetState.mainPageSidebarColor}
                changeHandler={(v) => handleSubmitChange("mainPageSidebarColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Modal Background Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.modalBackgroundColor ?? resetState.modalBackgroundColor}
                changeHandler={(v) => handleSubmitChange("modalBackgroundColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Memebership Tier One Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.membershipTierOneColor ?? resetState.membershipTierOneColor}
                changeHandler={(v) => handleSubmitChange("membershipTierOneColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Membership Tier Two Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.membershipTierTwoColor ?? resetState.membershipTierTwoColor}
                changeHandler={(v) => handleSubmitChange("membershipTierTwoColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Membership Tier Three Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.membershipTierThreeColor ?? resetState.membershipTierThreeColor}
                changeHandler={(v) => handleSubmitChange("membershipTierThreeColor", v)}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Membership Tier Four Color</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ColorPicker
                initialColor={team.membershipTierFourColor ?? resetState.membershipTierFourColor}
                changeHandler={(v) => handleSubmitChange("membershipTierFourColor", v)}
              />
            </Grid>
          </Grid>
          <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
            Fonts
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Primary font (Headings)</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledAutocomplete
                value={fontList[team.primaryFont || resetState.primaryFont]}
                options={fontList || []}
                getOptionLabel={(option) => option.label}
                onChange={(e, v) => handleSubmitChange("primaryFont", v.value)}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
                placeholder="Choose Anyone"
                error={validationMsg.type !== ""}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Secondary font (Sub headings, Main text)</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledAutocomplete
                value={fontList[team.secondaryFont || resetState.secondaryFont]}
                options={fontList || []}
                getOptionLabel={(option) => option.label}
                onChange={(e, v) => handleSubmitChange("secondaryFont", v.value)}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
                placeholder="Choose Anyone"
                error={validationMsg.type !== ""}
              />
            </Grid>
          </Grid>
          <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
            Landing Page
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Landing page hero image</MDTypography>
              <MDTypography fontSize="12px">(Recommended - 520 × 520px | Max 1MB)</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <MDBox mb={2}>
                <Button
                  variant="contained"
                  component="label"
                  color="white"
                  sx={{ marginRight: "20px" }}
                >
                  Upload Image
                  <input
                    type="file"
                    hidden
                    name="landingPageHeroImage"
                    onChange={handleFilePathChange}
                    accept=".jpg,.jpeg,.png"
                  />
                </Button>
                {team.landingPageHeroImage !== null && team.landingPageHeroImage !== "" && (
                  <Button
                    variant="contained"
                    component="label"
                    color="error"
                    onClick={() => handleRemoveImage("landingPageHeroImage")}
                  >
                    Remove Image
                  </Button>
                )}
              </MDBox>
              {team.landingPageHeroImage !== null && team.landingPageHeroImage !== "" && (
                <MDAvatar
                  src={team.landingPageHeroImage}
                  alt="Avatar"
                  sx={{ width: "250px", height: "250px" }}
                />
              )}
            </Grid>
          </Grid>
          <MDTypography fontSize="15px" fontWeight="bold" mb={3}>
            Logos
          </MDTypography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">Token Logo</MDTypography>
              <MDTypography fontSize="12px">(Recommended - 190 × 190px | Max 1MB)</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <MDBox mb={2}>
                <Button
                  variant="contained"
                  component="label"
                  color="white"
                  sx={{ marginRight: "20px" }}
                >
                  Upload Image
                  <input
                    type="file"
                    hidden
                    name="tokenImage"
                    onChange={handleFilePathChange}
                    accept=".jpg,.jpeg,.png"
                  />
                </Button>
                {team.tokenImage !== null && team.tokenImage !== "" && (
                  <Button
                    variant="contained"
                    component="label"
                    color="error"
                    onClick={() => handleRemoveImage("tokenImage")}
                  >
                    Remove Image
                  </Button>
                )}
              </MDBox>
              {team.tokenImage !== null && team.tokenImage !== "" && (
                <MDAvatar
                  src={team.tokenImage}
                  alt="Avatar"
                  sx={{ width: "250px", height: "250px" }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">App Logo</MDTypography>
              <MDTypography fontSize="12px">(Recommended - 180 × 180px | Max 1MB)</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <MDBox mb={2}>
                <Button
                  variant="contained"
                  component="label"
                  color="white"
                  sx={{ marginRight: "20px" }}
                >
                  Upload Image
                  <input
                    type="file"
                    hidden
                    name="appLogoImage"
                    onChange={handleFilePathChange}
                    accept=".jpg,.jpeg,.png"
                  />
                </Button>
                {team.appLogoImage !== null && team.appLogoImage !== "" && (
                  <Button
                    variant="contained"
                    component="label"
                    color="error"
                    onClick={() => handleRemoveImage("appLogoImage")}
                  >
                    Remove Image
                  </Button>
                )}
              </MDBox>
              {team.appLogoImage !== null && team.appLogoImage !== "" && (
                <MDAvatar
                  src={team.appLogoImage}
                  alt="Avatar"
                  sx={{ width: "250px", height: "250px" }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <MDTypography fontSize="15px">PWA Icon</MDTypography>
              <MDTypography fontSize="12px">(Recommended - 192 × 192px | Max 1MB)</MDTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <MDBox mb={2}>
                <Button
                  variant="contained"
                  component="label"
                  color="white"
                  sx={{ marginRight: "20px" }}
                >
                  Upload Image
                  <input
                    type="file"
                    hidden
                    name="pwaIcon"
                    onChange={handleFilePathChange}
                    accept=".jpg,.jpeg,.png"
                  />
                </Button>
                {team.pwaIcon !== null && team.pwaIcon !== "" && (
                  <Button
                    variant="contained"
                    component="label"
                    color="error"
                    onClick={() => handleRemoveImage("pwaIcon")}
                  >
                    Remove Image
                  </Button>
                )}
              </MDBox>
              {team.pwaIcon !== null && team.pwaIcon !== "" && (
                <MDAvatar
                  src={team.pwaIcon}
                  alt="Avatar"
                  sx={{ width: "250px", height: "250px" }}
                />
              )}
            </Grid>
          </Grid>
        </MDBox>
        <Grid container justifyContent="flex-end">
          <MDButton color="secondary" size="large" onClick={handleNext}>
            Next
          </MDButton>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

TeamThemeForm.defaultProps = {
  item: null,
  initialState: null,
  nextHandler: {},
  prevHandler: {},
  changeHandler: {},
};

TeamThemeForm.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  initialState: PropTypes.objectOf(PropTypes.any),
  nextHandler: PropTypes.func,
  prevHandler: PropTypes.func,
  changeHandler: PropTypes.func,
};
export default TeamThemeForm;
