import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

function Tokenmanagement() {
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox py={3}>
        <MDBox mb={3}>Token Management</MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tokenmanagement;
